import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./MakePaymentConfirmationModal.module.css";

// TODO: correct text for modal
const strings = {
  title: "Confirm Payment",
  grayBody1:
    "Confirm all of the following before clicking Disclosure Complete.",
  grayBody2: "To correct any information, click cancel.",
  bodyText1:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  bodyText2:
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  bodyText3:
    "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  complete: "Disclosure Complete",
  cancel: "Cancel",
};

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onComplete: () => void;
};

export default function MakePaymentConfirmationModal(props: Props) {
  return (
    <BaseModal
      className={styles.modal}
      label={strings.title}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      <div className={styles.header}>
        <div className={styles.title}>{strings.title}</div>
        <ModalCloseButton onClick={props.onRequestClose} />
      </div>
      <div className={styles.grayBox}>
        <div>{strings.grayBody1}</div>
        <div>{strings.grayBody2}</div>
      </div>
      <div>
        <div className={styles.bodyText}>{strings.bodyText1}</div>
        <div className={styles.bodyText}>{strings.bodyText2}</div>
        <div className={styles.bodyText}>{strings.bodyText3}</div>
      </div>
      <div className={styles.buttons}>
        <StyledButton
          className={styles.completeButton}
          onClick={props.onComplete}
        >
          {strings.complete}
        </StyledButton>
        <TextButton onClick={props.onRequestClose}>{strings.cancel}</TextButton>
      </div>
    </BaseModal>
  );
}
