import classNames from "classnames";
import closeXIcon from "../../../../assets/close-x-icon.svg";
import styles from "./ModalCloseButton.module.css";

const strings = {
  closeModal: "Close Modal",
};

type Props = {
  className?: string;
  onClick: () => void;
};

const ModalCloseButton = ({ className, onClick }: Props) => {
  return (
    <button
      className={classNames(styles.button, className)}
      onClick={onClick}
      type="button"
    >
      <img src={closeXIcon} alt={strings.closeModal} />
    </button>
  );
};

export default ModalCloseButton;
