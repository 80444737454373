import warningIcon from "../../../assets/warning-icon.svg";
import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./MakePaymentPastDateConfirmationModal.module.css";

const strings = {
  title: "Past Date",
  bodyText: "The date to print is before today. Is this correct?",
  confirm: "Confirm",
  cancel: "Cancel",
};

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
};

export default function MakePaymentPastDateConfirmationModal(props: Props) {
  return (
    <BaseModal
      className={styles.modal}
      label={strings.title}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      <div className={styles.header}>
        <div className={styles.title}>{strings.title}</div>
        <ModalCloseButton
          className={styles.closeButton}
          onClick={props.onRequestClose}
        />
      </div>
      <img src={warningIcon} alt="" />
      <div className={styles.bodyText}>{strings.bodyText}</div>
      <div className={styles.buttons}>
        <StyledButton
          className={styles.confirmButton}
          onClick={props.onConfirm}
        >
          {strings.confirm}
        </StyledButton>
        <TextButton onClick={props.onRequestClose}>{strings.cancel}</TextButton>
      </div>
    </BaseModal>
  );
}
