import {
  GetPartyRestrictionsResponse,
  LabeledValue,
  RESTRICTION_NONE,
} from "../../../lib/api/models";
import {
  useIsAgentRoleAddPaymentRestriction,
  useIsAgentRoleRemovePaymentRestriction,
} from "../../../lib/roleContext";

const useMemoizedRestrictions = (
  canEditRestrictions: boolean,
  cannotEditRestrictions: boolean,
  canAddPaymentRestriction: boolean,
  currentRestriction: string,
  partyRestrictions: LabeledValue[],
) => {
  let restrictionsArr = partyRestrictions;
  if (canEditRestrictions || cannotEditRestrictions) {
    return restrictionsArr;
  }

  // If roles canAddPaymentRestriction missing & removePaymentRestriction present, only allow current restriction or none to be selectable
  if (!canAddPaymentRestriction) {
    restrictionsArr = restrictionsArr.filter(
      (r) => r.value === RESTRICTION_NONE || r.value === currentRestriction,
    );

    // If roles canAddPaymentRestriction present & removePaymentRestriction is missing, none is no longer selectable
  } else {
    if (currentRestriction !== RESTRICTION_NONE) {
      restrictionsArr = restrictionsArr.filter(
        (r) => r.value !== RESTRICTION_NONE,
      );
    }
  }
  return restrictionsArr;
};

export const usePermissionBasedRestrictions = (
  currentBankRestriction: string,
  currentCardRestriction: string,
  partyRestrictions: GetPartyRestrictionsResponse,
) => {
  const canAddPaymentRestriction = useIsAgentRoleAddPaymentRestriction();
  const canRemovePaymentRestriction = useIsAgentRoleRemovePaymentRestriction();

  const canEditRestrictions =
    canAddPaymentRestriction && canRemovePaymentRestriction;
  const cannotEditRestrictions =
    !canAddPaymentRestriction && !canRemovePaymentRestriction;

  const canEditBankRestrictions =
    canEditRestrictions ||
    (currentBankRestriction === RESTRICTION_NONE
      ? canAddPaymentRestriction
      : canRemovePaymentRestriction);

  const canEditCardRestrictions =
    canEditRestrictions ||
    (currentCardRestriction === RESTRICTION_NONE
      ? canAddPaymentRestriction
      : canRemovePaymentRestriction);

  const bankRestrictionsOptions = useMemoizedRestrictions(
    canEditRestrictions,
    cannotEditRestrictions,
    canAddPaymentRestriction,
    currentBankRestriction,
    partyRestrictions.bankRestrictions,
  );

  const cardRestrictionsOptions = useMemoizedRestrictions(
    canEditRestrictions,
    cannotEditRestrictions,
    canAddPaymentRestriction,
    currentCardRestriction,
    partyRestrictions.cardRestrictions,
  );

  return {
    canEditBankRestrictions,
    canEditCardRestrictions,
    bankRestrictionsOptions,
    cardRestrictionsOptions,
  };
};
