import { RouterProvider } from "react-router";
import styles from "./App.module.css";
import { useRoleState } from "./lib/roleContext";
import router from "./lib/router";
import StyledError from "./modules/StyledError";
import Spinner from "./modules/common/Spinner";

const strings = {
  errorTitle: "Agent account failed to load",
};

export default function App() {
  const {
    isLoadingRoles: isLoadingRoles,
    getRolesError: getRolesError,
    refetchRoles: refetchRoles,
  } = useRoleState();

  return isLoadingRoles ? (
    <div className={styles.container}>
      <Spinner />
    </div>
  ) : getRolesError ? (
    <div className={styles.container}>
      <StyledError
        error={getRolesError}
        errorTitle={strings.errorTitle}
        refetch={refetchRoles}
      />
    </div>
  ) : (
    <RouterProvider router={router} />
  );
}
