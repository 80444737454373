import { CycleToDatePostedTransaction } from "../../../lib/api/models";
import {
  centsToDollarString,
  convertDateStringToMonthDayYearFomat,
} from "../../../lib/formatting";
import CycleToDateTransactionsEmptyTable from "../CycleToDateTransactionsEmptyTable";
import styles from "./CycleToDateTransactionsPostedTable.module.css";

const strings = {
  transactionDate: "Transaction Date",
  postDate: "Post Date",
  description: "Description",
  auth: "Auth #/POS entry mode",
  merchantCategory: "Merchant Category",
  amount: "Amount",
  newBalance: "New Balance",
};

type Props = {
  transactions: CycleToDatePostedTransaction[];
};

export default function CycleToDateTransactionsPostedTable({
  transactions,
}: Props) {
  return (
    <>
      <table className={styles.cycleToDateTransactionsPostedTable}>
        <thead>
          <tr>
            <th>{strings.transactionDate}</th>
            <th>{strings.postDate}</th>
            <th>{strings.description}</th>
            <th>{strings.auth}</th>
            <th>{strings.merchantCategory}</th>
            <th>{strings.amount}</th>
            <th>{strings.newBalance}</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((t, i) => (
            <tr
              key={t.id}
              className={i % 2 === 1 ? styles.evenRow : styles.oddRow}
            >
              <td>{convertDateStringToMonthDayYearFomat(t.transactionDate)}</td>
              <td>{convertDateStringToMonthDayYearFomat(t.postDate)}</td>
              <td>{t.description}</td>
              <td className={styles.authAndPOSContainer}>
                {`${t.authNumber}\n${t.posEntryMode}`}
              </td>
              <td>{t.merchantCategories}</td>
              <td>{centsToDollarString(t.amountCents)}</td>
              <td>{centsToDollarString(t.newBalanceCents)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {transactions.length < 1 ? <CycleToDateTransactionsEmptyTable /> : null}
    </>
  );
}
