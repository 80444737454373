import styles from "./CycleToDateTransactionsEmptyTable.module.css";

const strings = {
  emptyTransactions: "No Transactions to display yet",
};

export default function CycleToDateTransactionsEmptyTable() {
  return (
    <div className={styles.emptyTransactionsContainer}>
      <div className={styles.emptyTransactionsDescription}>
        {strings.emptyTransactions}
      </div>
    </div>
  );
}
