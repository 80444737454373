import classNames from "classnames";
import dialogueImage from "../../../../assets/dialogue.svg";
import styles from "./DialogueScript.module.css";

type Props = {
  dialogue: string;
  className?: string;
};

export default function DialogueScript({ dialogue, className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <img className={styles.dialogueBox} src={dialogueImage} alt="" />
      <div className={styles.dialogue}>{dialogue}</div>
    </div>
  );
}
