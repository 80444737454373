import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import bankIcon from "../../../assets/account-bank.svg";
import creditIcon from "../../../assets/account-credit.svg";
import debitIcon from "../../../assets/account-debit.svg";
import dropDownIcon from "../../../assets/drop-down.svg";
import expiredIcon from "../../../assets/expired.svg";
import restrictedIcon from "../../../assets/restricted.svg";
import { PaymentMethod } from "../../../lib/api/models";
import { getStylizedPaymentMethodName } from "../../../lib/sourceAccounts";
import { NEW_SOURCE_ACCOUNT } from "../contants";
import styles from "./SourceAccountSelect.module.css";

const strings = {
  select: "Select",
  lastUsed: (displayDateStr: string) => `Last Used ${displayDateStr}`,
  newAccount: "New Source Account",
  sourceAccount: "Source Account*",
  checkingAccount: "Checking Account",
  mastercard: "Mastercard",
  visa: "Visa",
};

type Props = {
  selectedValue: string;
  onChange: (v: string) => void;
  onAddNew: (v: string) => void;
  accounts: PaymentMethod[];
  className?: string;
  disabled?: boolean;
  validationMessages?: string[];
};

export default function SourceAccountSelect(props: Props) {
  const selectedOptionIndex = props.accounts.findIndex(
    (account) => account.id === props.selectedValue,
  );

  let label =
    selectedOptionIndex >= 0
      ? getStylizedPaymentMethodName(props.accounts[selectedOptionIndex])
      : strings.select;

  if (props.selectedValue === NEW_SOURCE_ACCOUNT) {
    label = strings.newAccount;
  }

  const [showDropdown, setShowDropdown] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const optionRefs = useRef<HTMLButtonElement[] | null[]>([]);
  const newAccountButtonRef = useRef<HTMLButtonElement>(null);

  const invalid =
    !!props.validationMessages && props.validationMessages.length > 0;

  useEffect(() => {
    function handleOutsideClick(e: MouseEvent) {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function renderDropdown() {
    const lastOptionIndex = props.accounts.length - 1;
    return (
      <div className={styles.dropdownContainer}>
        {props.accounts.map((a, i) => {
          let imgSrc = bankIcon;
          if (a.isExpired) {
            imgSrc = expiredIcon;
          } else if (a.hasRestrictions) {
            imgSrc = restrictedIcon;
          } else if (a.type === "credit_card") {
            imgSrc = creditIcon;
          } else if (a.type === "debit_card") {
            imgSrc = debitIcon;
          }

          return (
            <button
              key={a.id}
              ref={(e) => (optionRefs.current[i] = e)}
              className={styles.option}
              type="button"
              onClick={() => {
                props.onChange(a.id);
                setShowDropdown(false);
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.code === "ArrowUp") {
                  if (i !== 0) {
                    optionRefs.current[i - 1]?.focus();
                  } else {
                    selectButtonRef.current?.focus();
                  }
                } else if (e.code === "ArrowDown") {
                  if (i !== lastOptionIndex) {
                    optionRefs.current[i + 1]?.focus();
                  } else {
                    newAccountButtonRef.current?.focus();
                  }
                }
              }}
            >
              <img
                src={imgSrc}
                alt=""
                className={a.isExpired ? styles.expiredIcon : undefined}
              />
              <div className={styles.accountInfo}>
                <div className={styles.accountName}>
                  {getStylizedPaymentMethodName(a)}
                </div>
                <div className={styles.accountCardHolderName}>
                  {a.cardHolderFirstName} {a.cardHolderLastName}
                </div>
                <div className={styles.accountLastUsed}>
                  {strings.lastUsed(a.lastUsedDate)}
                </div>
              </div>
            </button>
          );
        })}
        <button
          ref={newAccountButtonRef}
          className={classNames(styles.option, styles.newAccount)}
          type="button"
          onClick={() => {
            props.onAddNew(NEW_SOURCE_ACCOUNT);
            setShowDropdown(false);
          }}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.code === "ArrowUp") {
              if (props.accounts.length) {
                optionRefs.current[lastOptionIndex]?.focus();
              } else {
                selectButtonRef.current?.focus();
              }
            } else if (e.code === "ArrowDown") {
              selectButtonRef.current?.focus();
            }
          }}
        >
          {strings.newAccount}
        </button>
      </div>
    );
  }

  return (
    <div
      ref={selectContainerRef}
      className={classNames(styles.container, props.className)}
    >
      <div className={styles.label}>{strings.sourceAccount}</div>
      <button
        ref={selectButtonRef}
        className={classNames({
          [styles.selectButton]: true,
          [styles.invalid]: invalid,
        })}
        type="button"
        onClick={() => setShowDropdown((prev) => !prev)}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.code === "ArrowUp") {
            newAccountButtonRef.current?.focus();
          } else if (e.code === "ArrowDown") {
            if (props.accounts.length) {
              optionRefs.current[0]?.focus();
            } else {
              newAccountButtonRef.current?.focus();
            }
          }
        }}
        disabled={props.disabled}
      >
        {label}
        <img src={dropDownIcon} alt="" />
      </button>
      {showDropdown ? renderDropdown() : null}
      {props.validationMessages && props.validationMessages.length > 0 ? (
        <div className={styles.validationMessages}>
          {props.validationMessages.join("\n")}
        </div>
      ) : null}
    </div>
  );
}
