// TODO: confirm contact ids are correct
export enum ContactId {
  Cardmember = "1",
  AuthUser = "2",
  Parent = "3",
  ThirdPartyKnownToCM = "11",
  Other = "98",
  Merchant = "5",
  ThirdPartyUnknownToCM = "12",
}

export enum ShipReplacementTo {
  Billing = "1",
  Temporary = "2",
}

export enum ReplacementType {
  Standard = "4",
  Fedex = "3",
  RushFedex = "1",
  RushPostal = "2",
}

export enum TypeOfLoss {
  LostCard = "1",
  Stolen = "2",
  NotReceived = "3",
  FraudApp = "4",
  Counterfeit = "5",
  AccountTakeover = "6",
  AllMailAndPhone = "7",
}
