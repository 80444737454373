import { InstructionScript } from "../../../../lib/api/models";
import classNames from "classnames";
import styles from "./InstructionsScript.module.css";

type Props = {
  instructions: InstructionScript[];
  className?: string;
};

export default function InstructionsScript({ instructions, className }: Props) {
  return (
    <div className={classNames(styles.instructions, className)}>
      {instructions.map((i, index) => {
        return (
          <span
            key={index}
            className={classNames({
              [styles.bold]: i.styleOptions?.some((so) => so === "bold"),
              [styles.italic]: i.styleOptions?.some((so) => so === "italic"),
            })}
          >
            {i.text}
          </span>
        );
      })}
    </div>
  );
}
