import { Col, Container, Row } from "react-grid-system";
import {
  convertDateStringToMonthDayYearFomat,
  convertYearMonthDateStringToMonthYearFormat,
  formatInCentralTime,
  validateBeforeCentsToDollarString,
} from "../../lib/formatting.ts";
import {
  useGetAccountDetails,
  useUpdateAccountLockStatus,
} from "../../lib/api/queries";
import {
  useIsAgentRoleUnlock,
  useIsAgentRoleViewAccountNumber,
} from "../../lib/roleContext.ts";

import AccountDetailsHeaderRow from "./AccountDetailsHeaderRow.tsx";
import { GetAccountResponse } from "../../lib/api/models";
import Spinner from "../common/Spinner";
import { StyledButton } from "../common/buttons/StyledButton/StyledButton.tsx";
import ToolLoadError from "../common/ToolLoadError";
import autopayEnrollmentIcon from "../../assets/autopay-enrollment.svg";
import classNames from "classnames";
import { getDisplayError } from "../../lib/api/endpoints";
import styles from "./AccountDetailsHeader.module.css";
import { useState } from "react";

const strings = {
  accountDetailsHeader: "Account Details Header",
  accountIdAndCardNumber: "Account ID / Card #",
  activate: "Activate",
  activated: "Activated",
  amountDelq: "Amt Delq",
  amountDue: "Amt Due",
  authFlag: "Auth Flag",
  authorizedUser: "Authorized User",
  cmInfo: "CM Info",
  credit: "Credit",
  creditAvail: "Credit Avail",
  creditLimit: "Credit Limit",
  currentBalance: "Current Balance",
  delinquent: "Delinquent #Days/Bucket",
  employee: "Employee/VIP Ind",
  enrolledAutopay: "Autopay Enrolled",
  intExtStatus: "Int/Ext Status",
  lastContactCode: "Last Contact Code",
  lastContactDate: "Last Contact Date",
  locked: "Locked",
  manage: "Manage",
  minPayDue: "Min Pay Due",
  nextCycle: "Next Cycle / Stmt Dt",
  no: "No",
  notEnrolledAutopay: "Not Autopay Enrolled",
  oclAmount: "OCL Amount",
  openDate: "Open Date",
  partyId: "Party ID",
  paymentDueDate: "Pmt Due Date",
  permanentQueue: "Permanent Queue",
  preselected: "Preselected for C2C on Web",
  prev1: "Prev 1",
  prev2: "Prev 2",
  prev3: "Prev 3",
  p2d: "P2D",
  registrationDateTime: "Registered On",
  special: "Special Hdlg Ind",
  state: "State",
  statusReason: "Status Reason Code",
  temporaryQueue: "Temporary Queue",
  totalAmountDue: "Total Amount Due",
  transferReason: "Transfer Reason",
  unlock: "Unlock",
  unlocked: "Unlocked",
  webStatus: "Web Status",
  yes: "Yes",
  mastercardAbbreviated: "M",
  visaAbbreviated: "V",
  affinity: "Affinity",
  username: "Username",
  close: "Close",
};

function getCardTypeAbbreviated(cardNumber?: string | null): string {
  if (!cardNumber) {
    return "";
  }
  if (cardNumber.startsWith("2") || cardNumber.startsWith("5")) {
    return strings.mastercardAbbreviated;
  } else if (cardNumber.startsWith("4")) {
    return strings.visaAbbreviated;
  }
  return "";
}

type Props = {
  account: GetAccountResponse;
  className?: string;
  onDisplayGeneralErrors: (error: unknown) => void;
};

export function AccountDetailsHeader({
  account,
  className,
  onDisplayGeneralErrors,
}: Props) {
  const { data, isLoading, isError, error, refetch, isRefetching } =
    useGetAccountDetails(account.currentDwbuid);
  const canViewAccountNumber = useIsAgentRoleViewAccountNumber();

  const [showCardNumber, setShowCardNumber] = useState(false);

  const {
    mutateAsync: updateAccountLockStatus,
    isLoading: isUpdateAccountLockStatusLoading,
  } = useUpdateAccountLockStatus();

  const renderActivationStatus = (activated: boolean) => {
    if (activated) {
      return <span>{strings.yes}</span>;
    } else {
      return (
        <div className={styles.activationStatusContainer}>
          <div className={styles.noActivation}>{strings.no}</div>
        </div>
      );
    }
  };

  const canUnlockCardmember = useIsAgentRoleUnlock();

  const renderAbbreviatedCardNumber = (cardNumber: string) => {
    if (canViewAccountNumber && !showCardNumber) {
      return (
        <StyledButton
          className={styles.cardNumberButton}
          onClick={() => setShowCardNumber(true)}
          variant="transparent"
        >
          {getCardTypeAbbreviated(cardNumber)}
          {cardNumber?.slice(12)}
        </StyledButton>
      );
    } else {
      return (
        <div className={styles.marginLeft}>
          {`${getCardTypeAbbreviated(cardNumber)}${cardNumber?.slice(12)}`}
        </div>
      );
    }
  };

  return (
    <div className={classNames(styles.mainContainer, className)}>
      <Container>
        {isLoading ? (
          <Row>
            <Col>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        ) : isError ? (
          <Row>
            <Col>
              <ToolLoadError
                toolName={strings.accountDetailsHeader}
                errorDescription={getDisplayError(error)}
                onRetry={refetch}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={3}>
              <div>
                <div className={styles.headerRow}>
                  <span className={styles.headerRowBoldValue}>{data.name}</span>
                  <span className={styles.headerRowBoldValue}>
                    {strings.credit}
                  </span>
                </div>
                <div className={styles.accountIdAndCardNumberContainer}>
                  <div className={styles.label}>
                    {strings.accountIdAndCardNumber}
                  </div>
                  <div className={styles.accountIdAndCardNumber}>
                    <div className={classNames(styles.value, styles.flexRow)}>
                      <span>{`${data.currentDwbuid} / `}</span>
                      {data.cardNumber ? (
                        <>{renderAbbreviatedCardNumber(data.cardNumber)}</>
                      ) : (
                        <span className={styles.marginLeft}>-</span>
                      )}
                    </div>
                    {showCardNumber ? (
                      <div className={classNames(styles.value, styles.flexRow)}>
                        <div className={styles.cardNumber}>
                          {data.cardNumber}
                        </div>
                        <StyledButton
                          className={styles.cardNumberButton}
                          onClick={() => setShowCardNumber(false)}
                          variant="transparent"
                        >
                          {strings.close}
                        </StyledButton>
                      </div>
                    ) : null}
                  </div>
                </div>
                <AccountDetailsHeaderRow
                  label={strings.authorizedUser}
                  value={strings.affinity}
                />
                <AccountDetailsHeaderRow label={strings.p2d} value={data.p2d} />
                <AccountDetailsHeaderRow
                  label={strings.lastContactDate}
                  value={strings.affinity}
                />
                <AccountDetailsHeaderRow
                  label={strings.lastContactCode}
                  value={strings.affinity}
                />
                <AccountDetailsHeaderRow
                  label={strings.state}
                  value={data.state}
                />
                <AccountDetailsHeaderRow
                  label={strings.temporaryQueue}
                  value={strings.affinity}
                />
                <AccountDetailsHeaderRow
                  label={strings.permanentQueue}
                  value={strings.affinity}
                />
                <AccountDetailsHeaderRow
                  label={strings.delinquent}
                  value={`${data.daysDelinquent}/${data.delinquentBucket}`}
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className={styles.headerRow}>
                <span className={styles.headerRowValue}>
                  {strings.currentBalance}
                </span>
                <span className={styles.headerRowBoldValue}>
                  {validateBeforeCentsToDollarString(data.currentBalanceCents)}
                </span>
              </div>
              <AccountDetailsHeaderRow
                label={strings.creditLimit}
                value={validateBeforeCentsToDollarString(data.creditLimitCents)}
              />
              <AccountDetailsHeaderRow
                label={strings.creditAvail}
                value={validateBeforeCentsToDollarString(
                  data.creditAvailableCents,
                )}
              />
              <AccountDetailsHeaderRow
                label={strings.totalAmountDue}
                value={validateBeforeCentsToDollarString(
                  data.totalAmountDueCents,
                )}
              />
              <AccountDetailsHeaderRow
                label={strings.amountDue}
                value={validateBeforeCentsToDollarString(data.amountDueCents)}
              />
              <AccountDetailsHeaderRow
                label={strings.amountDelq}
                value={validateBeforeCentsToDollarString(
                  data.delinquentAmountCents,
                )}
              />
              <AccountDetailsHeaderRow
                label={strings.oclAmount}
                value={validateBeforeCentsToDollarString(data.oclAmountCents)}
              />
              <AccountDetailsHeaderRow
                label={strings.minPayDue}
                value={validateBeforeCentsToDollarString(data.minPayDueCents)}
              />
              <AccountDetailsHeaderRow
                label={strings.paymentDueDate}
                value={convertDateStringToMonthDayYearFomat(
                  data.paymentDueDate,
                )}
              />
              <AccountDetailsHeaderRow
                label={strings.nextCycle}
                value={convertDateStringToMonthDayYearFomat(data.nextCycleDate)}
              />
            </Col>
            <Col xs={3}>
              <AccountDetailsHeaderRow
                label={strings.transferReason}
                value={strings.affinity}
              />
              <AccountDetailsHeaderRow
                label={strings.employee}
                value={strings.affinity}
              />
              <AccountDetailsHeaderRow
                label={strings.special}
                value={strings.affinity}
              />
              <AccountDetailsHeaderRow
                label={strings.prev1}
                value={data.prevDwbuid}
              />
              <AccountDetailsHeaderRow
                label={strings.prev2}
                value={data.prevDwbuid2}
              />
              <AccountDetailsHeaderRow
                label={strings.prev3}
                value={data.prevDwbuid3}
              />
              <AccountDetailsHeaderRow
                label={strings.partyId}
                value={data.partyId}
              />
              <AccountDetailsHeaderRow
                label={strings.preselected}
                value={data.isC2CPreselected ? strings.yes : strings.no}
              />
            </Col>
            <Col xs={3}>
              <AccountDetailsHeaderRow
                label={strings.intExtStatus}
                value={`${data.internalStatusCode}/${data.externalStatusCode}`}
              />
              <AccountDetailsHeaderRow
                label={strings.statusReason}
                value={data.statusReasonCode}
              />
              <AccountDetailsHeaderRow
                label={strings.openDate}
                value={convertYearMonthDateStringToMonthYearFormat(
                  data.openDate,
                )}
              />
              <AccountDetailsHeaderRow
                label={strings.username}
                value={data.username}
              />
              <AccountDetailsHeaderRow
                label={strings.registrationDateTime}
                value={
                  data.registrationDateTime
                    ? formatInCentralTime(
                        new Date(data.registrationDateTime),
                        "MM/dd/yyyy hh:mmaa",
                      )
                    : null
                }
              />
              <div className={styles.accountDetailsHeaderRow}>
                <span className={styles.label}>{strings.activated}</span>
                <div className={styles.value}>
                  {data.activated !== null || data.activated === undefined
                    ? renderActivationStatus(data.activated)
                    : "--"}
                </div>
              </div>
              <div
                className={classNames({
                  [styles.infoContainer]: true,
                  [styles.greenBackground]: data.isEnrolledAutopay,
                })}
              >
                <div className={styles.infoStatusWithImage}>
                  <img
                    className={styles.autopayEnrollmentIcon}
                    src={autopayEnrollmentIcon}
                    alt=""
                  />
                  <span>
                    {data.isEnrolledAutopay
                      ? strings.enrolledAutopay
                      : strings.notEnrolledAutopay}
                  </span>
                </div>
              </div>
              <div className={styles.infoContainer}>
                <div className={styles.infoStatus}>
                  <span>{`${strings.webStatus} - ${
                    data.isLocked ? strings.locked : strings.unlocked
                  }`}</span>
                </div>
                {data.isLocked && canUnlockCardmember ? (
                  <StyledButton
                    className={styles.unlockButton}
                    variant="transparent"
                    onClick={() =>
                      updateAccountLockStatus({
                        dwbuid: account.currentDwbuid,
                      }).catch(onDisplayGeneralErrors)
                    }
                    isLoading={isUpdateAccountLockStatusLoading || isRefetching}
                  >
                    {strings.unlock}
                  </StyledButton>
                ) : null}
              </div>
              <div className={styles.infoContainer}>
                <div className={styles.infoStatus}>
                  <span>{`${strings.authFlag}`}</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
