import { MakePaymentNegAmResponse } from "../../../lib/api/models";
import { centsToDollarString } from "../../../lib/formatting";
import StyledButton from "../../common/buttons/StyledButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./MakePaymentNegAmModal.module.css";

const strings = {
  title: "Prevent Negative Amortization",
  useCurrentAmount: "Use Current Amount",
  increasePaymentBy: "Increase Payment By",
};

type Props = {
  makePaymentNegAmResponse?: MakePaymentNegAmResponse;
  onRequestClose: () => void;
  onDeclineIncrease: () => void;
  onConfirmIncrease: () => void;
};

export default function MakePaymentNegAmModal({
  makePaymentNegAmResponse,
  onRequestClose,
  onDeclineIncrease,
  onConfirmIncrease,
}: Props) {
  return (
    <BaseModal
      className={styles.modal}
      label={makePaymentNegAmResponse?.title ?? strings.title}
      isOpen={!!makePaymentNegAmResponse}
      onRequestClose={onRequestClose}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          {makePaymentNegAmResponse?.title ?? strings.title}
        </div>
        <ModalCloseButton
          className={styles.closeButton}
          onClick={onRequestClose}
        />
      </div>
      <div className={styles.bodyText}>{makePaymentNegAmResponse?.message}</div>
      <div className={styles.buttons}>
        <StyledButton onClick={onDeclineIncrease} variant="secondary">
          {strings.useCurrentAmount}
        </StyledButton>
        <StyledButton onClick={onConfirmIncrease}>
          {strings.increasePaymentBy}{" "}
          {makePaymentNegAmResponse?.increaseAmountCents
            ? centsToDollarString(makePaymentNegAmResponse?.increaseAmountCents)
            : ""}
        </StyledButton>
      </div>
    </BaseModal>
  );
}
