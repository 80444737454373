import classNames from "classnames";
import styles from "./Spinner.module.css";

type Props = {
  className?: string;
};

// NOTE(CK): Spinner takes on the 'color' of its parent
export default function Spinner({ className }: Props) {
  return (
    <div className={classNames(styles.spinner, className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
