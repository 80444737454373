import warningIcon from "../../../assets/warning-icon.svg";
import { ScheduledPayment } from "../../../lib/api/models";
import { centsToDollarString } from "../../../lib/formatting";
import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./DuplicatePaymentModal.module.css";

const strings = {
  title: "Payments were recently authorized",
  scheduledFor: "Scheduled for",
  areYouSure: "Are you sure you want to make an additional payment?",
  confirm: "Make Additional Payment",
  cancel: "Close Tool",
};

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  scheduledPayments: ScheduledPayment[];
  onConfirm: () => void;
};

export default function DuplicatePaymentModal(props: Props) {
  return (
    <BaseModal
      className={styles.modal}
      label={strings.title}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      <div className={styles.closeRow}>
        <div className={styles.spacer} />
        <ModalCloseButton onClick={props.onRequestClose} />
      </div>
      <img src={warningIcon} alt="" />
      <div className={styles.header}>{strings.title}</div>
      <div className={styles.grayBox}>
        {props.scheduledPayments.map((p) => (
          <div key={p.id} className={styles.scheduledText}>
            {centsToDollarString(p.amountCents)} {strings.scheduledFor}{" "}
            {p.scheduledPaymentDate}
          </div>
        ))}
      </div>
      <div className={styles.areYouSure}>{strings.areYouSure}</div>
      <StyledButton onClick={props.onConfirm} className={styles.confirmButton}>
        {strings.confirm}
      </StyledButton>
      <TextButton onClick={props.onRequestClose}>{strings.cancel}</TextButton>
    </BaseModal>
  );
}
