import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./StyledLinkButton.module.css";

type Props = {
  children: string;
  className?: string;
  to: string;
};

export function StyledLinkButton({ children, className, to }: Props) {
  const finalClassName = classNames(styles.container, className);
  return (
    <Link className={finalClassName} to={to}>
      {children}
    </Link>
  );
}
