import { useCallback, useState } from "react";
import {
  AddBankSourceAccountRequest,
  EditBankSourceAccountRequest,
  GetAccountResponse,
  OwnershipType,
  PaymentMethod,
  PaymentMethodCheckingAccount,
  ValidationErrors,
} from "../../../lib/api/models";
import { NEW_SOURCE_ACCOUNT } from "../../MakePayment/contants";
import {
  useAddBankSourceAccount,
  useEditBankSourceAccount,
} from "../../../lib/api/queries";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../../lib/validation";

export type AddAutopayFormInput = {
  ownerType?: OwnershipType;
  paymentMethodId: string;
  abaNumber: string;
  selectedBankName: string;
  accountNumber: string;
  confirmAccountNumber: string;
  cardHolderFirstName: string;
  cardHolderLastName: string;
  phoneNumber: string;
  cardHolderFirstName2?: string;
  cardHolderLastName2?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateCode: string;
  zipcode: string;
  isComplete: boolean; // source account can be used
  isDirty: boolean; // has form changed in any way from initial render
  canAddSourceAccount: boolean;
  canEditSourceAccount: boolean;
};

const INITIAL_SOURCE_ACCOUNT_FORM_STATE: AddAutopayFormInput = {
  paymentMethodId: "",
  abaNumber: "",
  selectedBankName: "",
  accountNumber: "",
  confirmAccountNumber: "",
  cardHolderFirstName: "",
  cardHolderLastName: "",
  phoneNumber: "",
  addressLine1: "",
  city: "",
  stateCode: "",
  zipcode: "",
  isComplete: false,
  isDirty: false,
  canAddSourceAccount: false,
  canEditSourceAccount: false,
};

const isCheckingsComplete = (formState: AddAutopayFormInput) => {
  let isComplete =
    !!formState.abaNumber &&
    !!formState.accountNumber &&
    !!formState.cardHolderFirstName.trim() &&
    !!formState.cardHolderLastName.trim() &&
    !!formState.addressLine1.trim() &&
    !!formState.city.trim() &&
    !!formState.stateCode &&
    !!formState.zipcode;

  if (formState.paymentMethodId === NEW_SOURCE_ACCOUNT) {
    isComplete =
      isComplete &&
      !!formState.selectedBankName &&
      formState.accountNumber === formState.confirmAccountNumber;
  }
  return isComplete;
};

const formHasChanged = (
  formState: AddAutopayFormInput,
  initialFormState: AddAutopayFormInput,
) => {
  return (Object.keys(formState) as Array<keyof AddAutopayFormInput>).some(
    (key) =>
      key !== "isComplete" &&
      key !== "isDirty" &&
      key !== "canAddSourceAccount" &&
      key !== "canEditSourceAccount" &&
      formState[key] !== initialFormState[key],
  );
};

const isFormComplete = (
  formState: AddAutopayFormInput,
  initialFormState: AddAutopayFormInput,
) => {
  return (
    isCheckingsComplete(formState) &&
    !formHasChanged(formState, initialFormState) &&
    formState.paymentMethodId !== NEW_SOURCE_ACCOUNT
  );
};

const getUpdatedFormState = (
  selectedSourceAccount?: PaymentMethodCheckingAccount,
): AddAutopayFormInput => {
  if (!selectedSourceAccount) {
    return {
      ...INITIAL_SOURCE_ACCOUNT_FORM_STATE,
      paymentMethodId: NEW_SOURCE_ACCOUNT,
    };
  }

  return {
    paymentMethodId: selectedSourceAccount.id,
    abaNumber: selectedSourceAccount.abaNumber,
    selectedBankName: selectedSourceAccount.bankName ?? "",
    accountNumber: selectedSourceAccount.accountNumber,
    confirmAccountNumber: "",
    cardHolderFirstName: selectedSourceAccount.cardHolderFirstName,
    cardHolderFirstName2: undefined,
    cardHolderLastName: selectedSourceAccount.cardHolderLastName,
    cardHolderLastName2: undefined,
    phoneNumber: selectedSourceAccount.phoneNumber ?? "",
    addressLine1: selectedSourceAccount.addressLine1,
    addressLine2: selectedSourceAccount.addressLine2 ?? undefined,
    city: selectedSourceAccount.city,
    stateCode: selectedSourceAccount.stateCode,
    zipcode: selectedSourceAccount.zipcode,
    isComplete: false,
    isDirty: false,
    canAddSourceAccount: false,
    canEditSourceAccount: false,
  };
};

export const useAddAutopayState = (): [
  AddAutopayFormInput,
  {
    generalDispatch: (
      payload: Partial<
        Omit<AddAutopayFormInput, "ownerType" | "selectedSourceAccountId">
      >,
    ) => void;
    updateSelectedSourceAccount: (
      selectedSourceAccount?: PaymentMethodCheckingAccount,
    ) => void;
    updateOwnerType: (ownerType: OwnershipType) => void;
    validationMessages?: ValidationErrors;
    setValidationMessages: React.Dispatch<
      React.SetStateAction<ValidationErrors | undefined>
    >;
  },
] => {
  const [formState, formDispatch] = useState<AddAutopayFormInput>(
    INITIAL_SOURCE_ACCOUNT_FORM_STATE,
  );
  const [initialFormState, setInitialFormState] = useState<AddAutopayFormInput>(
    INITIAL_SOURCE_ACCOUNT_FORM_STATE,
  );
  const [validationMessages, setValidationMessages] =
    useState<ValidationErrors>();

  const clearValidationMessages = useCallback((...keys: string[]) => {
    setValidationMessages((prev) => {
      const next = { ...prev };
      keys.forEach((key) => {
        next[key] = [];
      });

      return next;
    });
  }, []);

  const generalDispatch = useCallback(
    (
      payload: Partial<
        Omit<AddAutopayFormInput, "ownerType" | "selectedSourceAccountId">
      >,
    ) => {
      clearValidationMessages(...Object.keys(payload));

      formDispatch((prevFormState) => {
        const newFormState = {
          ...prevFormState,
          ...payload,
        };

        return {
          ...newFormState,
          isComplete: isFormComplete(newFormState, initialFormState),
          isDirty: formHasChanged(newFormState, initialFormState),
          canAddSourceAccount:
            isCheckingsComplete(newFormState) &&
            newFormState.paymentMethodId === NEW_SOURCE_ACCOUNT,
          canEditSourceAccount:
            isCheckingsComplete(newFormState) &&
            formHasChanged(newFormState, initialFormState) &&
            newFormState.paymentMethodId !== NEW_SOURCE_ACCOUNT,
        };
      });
    },
    [clearValidationMessages, initialFormState],
  );

  // Clear entire form on owner update
  const updateOwnerType = useCallback((ownerType: OwnershipType) => {
    const newFormState = {
      ...INITIAL_SOURCE_ACCOUNT_FORM_STATE,
      ownerType,
    };

    formDispatch({
      ...newFormState,
      isComplete: isFormComplete(
        newFormState,
        INITIAL_SOURCE_ACCOUNT_FORM_STATE,
      ),
      isDirty: formHasChanged(newFormState, INITIAL_SOURCE_ACCOUNT_FORM_STATE),
    });
    setInitialFormState(INITIAL_SOURCE_ACCOUNT_FORM_STATE);
    setValidationMessages(undefined);
  }, []);

  // Update form values with selected source account info
  const updateSelectedSourceAccount = useCallback(
    (selectedSourceAccount?: PaymentMethodCheckingAccount) => {
      const newForm = {
        ...getUpdatedFormState(selectedSourceAccount),
        ownerType: formState.ownerType,
      };

      formDispatch({
        ...newForm,
        isComplete: isFormComplete(newForm, newForm),
        isDirty: formHasChanged(newForm, INITIAL_SOURCE_ACCOUNT_FORM_STATE),
      });
      setInitialFormState(newForm);
      setValidationMessages((prev) => {
        if (prev) {
          return { ownerType: prev ? prev["ownerType"] : [] };
        }
        return undefined;
      });
    },
    [formState.ownerType],
  );
  return [
    formState,
    {
      generalDispatch,
      updateOwnerType,
      updateSelectedSourceAccount,
      validationMessages,
      setValidationMessages,
    },
  ];
};

export const useAddAutopayFormQueries = (
  account: GetAccountResponse,
  formState: AddAutopayFormInput,
  onSuccess: (sourceAccount: PaymentMethod) => void,
  setValidationMessages: React.Dispatch<
    React.SetStateAction<ValidationErrors | undefined>
  >,
  onDisplayGeneralErrors: (error: unknown) => void,
): {
  onUpdateSourceAccount: (isAddingSourceAccount: boolean) => void;
  isLoading: boolean;
} => {
  const {
    mutateAsync: addBankSourceAccount,
    isLoading: isAddBankSourceAccountLoading,
  } = useAddBankSourceAccount();

  const {
    mutateAsync: editBankSourceAccount,
    isLoading: isEditBankSourceAccountLoading,
  } = useEditBankSourceAccount();

  const isLoading =
    isAddBankSourceAccountLoading || isEditBankSourceAccountLoading;

  const onAddSourceAccount = useCallback(() => {
    if (!formState.canAddSourceAccount || !formState.ownerType) {
      return;
    }

    const bankSourceAccountRequest: AddBankSourceAccountRequest = {
      partyId: account.partyId,
      ownerType: formState.ownerType,
      accountNumber: formState.accountNumber,
      abaNumber: formState.abaNumber,
      addressLine1: formState.addressLine1,
      addressLine2: formState.addressLine2,
      city: formState.city,
      stateCode: formState.stateCode,
      zipcode: formState.zipcode,
      phoneNumber: formState.phoneNumber,
      cardHolderFirstName: formState.cardHolderFirstName,
      cardHolderLastName: formState.cardHolderLastName,
      cardHolderFirstName2: formState.cardHolderFirstName2,
      cardHolderLastName2: formState.cardHolderLastName2,
    };

    addBankSourceAccount(bankSourceAccountRequest)
      .then(onSuccess)
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  }, [
    account,
    addBankSourceAccount,
    formState,
    onDisplayGeneralErrors,
    onSuccess,
    setValidationMessages,
  ]);

  const onEditSourceAccount = useCallback(() => {
    if (!formState.canEditSourceAccount || !formState.ownerType) {
      return;
    }

    const bankSourceAccountRequest: EditBankSourceAccountRequest = {
      partyId: account.partyId,
      sourceAccountId: formState.paymentMethodId,
      addressLine1: formState.addressLine1,
      addressLine2: formState.addressLine2,
      city: formState.city,
      stateCode: formState.stateCode,
      zipcode: formState.zipcode,
      phoneNumber: formState.phoneNumber,
      cardHolderFirstName: formState.cardHolderFirstName,
      cardHolderLastName: formState.cardHolderLastName,
      cardHolderFirstName2: formState.cardHolderFirstName2,
      cardHolderLastName2: formState.cardHolderLastName2,
    };

    editBankSourceAccount(bankSourceAccountRequest)
      .then(onSuccess)
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  }, [
    account,
    editBankSourceAccount,
    formState,
    onDisplayGeneralErrors,
    onSuccess,
    setValidationMessages,
  ]);

  const onUpdateSourceAccount = useCallback(
    (isAddingNewSourceAccount: boolean) => {
      if (isAddBankSourceAccountLoading || isEditBankSourceAccountLoading) {
        return;
      }

      if (isAddingNewSourceAccount) {
        onAddSourceAccount();
      } else {
        onEditSourceAccount();
      }
    },
    [
      isAddBankSourceAccountLoading,
      isEditBankSourceAccountLoading,
      onAddSourceAccount,
      onEditSourceAccount,
    ],
  );

  return { onUpdateSourceAccount, isLoading };
};
