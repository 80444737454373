import classNames from "classnames";
import { useId } from "react";
import { ToolType } from "..";
import successCheck from "../../../assets/green-filled-check.svg";
import { toolTypeToName } from "../utils";
import styles from "./CompletedTool.module.css";

const strings = {
  successful: " Successful",
  summary: "Summary: ",
  labelDescription: "Tool Name success message",
};
type CompletedToolProps = {
  summary: string;
  type: ToolType;
  className?: string;
};
export default function CompletedTool(props: CompletedToolProps) {
  const summaryContentId = useId();
  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.title}>
        <img src={successCheck} alt="" />
        <div className={styles.titleLabel}>
          {toolTypeToName(props.type) + strings.successful}
        </div>
      </div>
      <div className={styles.summary}>
        <label className={styles.summaryLabel} htmlFor={summaryContentId}>
          {strings.summary}
        </label>
        <div id={summaryContentId} className={styles.summaryContent}>
          {props.summary}
        </div>
      </div>
    </div>
  );
}
