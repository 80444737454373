import {
  formatDollarInput,
  handlePaymentAmountInputChange,
  validateInputAndFormatToWholeDollar,
} from "../../lib/formatting";

import StyledInput from "./inputs/StyledInput";

const MAX_AMOUNT_LENGTH = 6;

type Props = {
  label: string;
  amountCents: string;
  onChange: (v: string) => void;
  disabled?: boolean;
  validationMessages?: string[];
  clearValidationMessages?: () => void;
  onBlur?: () => void;
  showPopover?: boolean;
};

export default function CustomAmountInput({
  label,
  amountCents,
  onChange,
  clearValidationMessages,
  onBlur,
  ...rest
}: Props) {
  return (
    <StyledInput
      label={label}
      value={amountCents}
      onChange={(e) => {
        const val = e.target.value;
        const wholeDollarInput = validateInputAndFormatToWholeDollar(val);
        if (wholeDollarInput !== null) {
          onChange(`$${wholeDollarInput}`);
        } else {
          onChange(`$${val}`);
        }

        if (clearValidationMessages) {
          clearValidationMessages();
        }
      }}
      onBlur={() => {
        const handledChange = handlePaymentAmountInputChange(amountCents);
        if (handledChange) {
          onChange(`$${formatDollarInput(handledChange)}`);
        } else {
          onChange("$0.00");
        }

        if (onBlur) {
          onBlur();
        }
      }}
      onFocus={() => validateInputAndFormatToWholeDollar(amountCents)}
      {...rest}
      maxLength={MAX_AMOUNT_LENGTH}
    />
  );
}
