import { format } from "date-fns";
import { PermissionToDiscussPermission } from "../../../lib/api/models";
import ColoredLabelBox from "../../common/ColoredLabelBox";
import TextButton from "../../common/buttons/TextButton";
import styles from "./PermissionToDiscussTable.module.css";

const strings = {
  action: "Action",
  dateEntered: "Date Entered",
  agent: "Agent",
  ch: "CH",
  effectiveDate: "Effective Date",
  status: "Status",
  firstName: "First Name",
  lastName: "Last Name",
  relation: "Relation",
  source: "Source",
  active: "Active",
  inactive: "Inactive",
  edit: "Edit",
  remove: "Remove",
  emptyPermissions: "No Permissions to display yet",
};

type Props = {
  permissions: PermissionToDiscussPermission[];
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  disableEditing?: boolean;
};

export default function PermissionToDiscussTable({
  permissions,
  onEdit,
  onRemove,
  disableEditing,
}: Props) {
  return (
    <>
      <table className={styles.permissionToDiscussTable}>
        <thead>
          <tr>
            <th>{strings.dateEntered}</th>
            <th>{strings.agent}</th>
            <th>{strings.ch}</th>
            <th>{strings.effectiveDate}</th>
            <th>{strings.status}</th>
            <th>{strings.firstName}</th>
            <th>{strings.lastName}</th>
            <th>{strings.relation}</th>
            <th>{strings.source}</th>
            <th id="table-header-actions" aria-label={strings.action} />
            <th id="table-header-actions" aria-label={strings.action} />
          </tr>
        </thead>
        <tbody>
          {permissions.map((d, i) => (
            <tr
              key={d.id}
              className={i % 2 === 1 ? styles.evenRow : styles.oddRow}
            >
              <td>{format(new Date(d.date), "MM/dd/yyyy HH:mm")}</td>
              <td>{d.agent}</td>
              <td>{d.channel}</td>
              <td>{format(new Date(d.effectiveDate), "MM/dd/yyyy")}</td>
              <td>
                <ColoredLabelBox
                  className={styles.statusBox}
                  status={
                    d.status === "active"
                      ? "success"
                      : d.status === "inactive"
                        ? "danger"
                        : "old"
                  }
                >
                  {d.status === "active" || d.status === "active_history"
                    ? strings.active
                    : strings.inactive}
                </ColoredLabelBox>
              </td>
              <td>{d.firstName}</td>
              <td>{d.lastName}</td>
              <td>{d.relation.relationship}</td>
              <td>{d.source}</td>
              <td>
                {d.status === "active" ? (
                  <TextButton
                    onClick={() => onEdit(d.id)}
                    className={styles.editButton}
                    disabled={disableEditing}
                  >
                    {strings.edit}
                  </TextButton>
                ) : null}
              </td>
              <td>
                {d.status === "active" ? (
                  <TextButton
                    onClick={() => onRemove(d.id)}
                    className={styles.removeButton}
                  >
                    {strings.remove}
                  </TextButton>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {permissions.length < 1 ? (
        <div className={styles.emptyPermissionsContainer}>
          <div className={styles.emptyPermissionsDescription}>
            {strings.emptyPermissions}
          </div>
        </div>
      ) : null}
    </>
  );
}
