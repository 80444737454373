import { MakeOneTimePaymentOverrideMessage } from "../../../lib/api/models";
import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import styles from "./MakePaymentOverrideModal.module.css";

const strings = {
  title: "Make Payment Override",
  authorize: "Authorize",
  decline: "Decline",
};

type Props = {
  overrideMessage: MakeOneTimePaymentOverrideMessage | undefined;
  onAuthorize: (overrideKey: string) => void;
  onDecline: () => void;
};

export default function MakePaymentOverrideModal({
  overrideMessage,
  onAuthorize,
  onDecline,
}: Props) {
  return (
    <BaseModal
      className={styles.container}
      isOpen={!!overrideMessage}
      label={strings.title}
      onRequestClose={onDecline}
    >
      <div className={styles.body}>
        <h2>{strings.title}</h2>
        <div>{overrideMessage?.overrideInfo}</div>
        <StyledButton
          className={styles.authorizeButton}
          onClick={() => {
            if (!overrideMessage) {
              return;
            }
            onAuthorize(overrideMessage.overrideKey);
          }}
        >
          {strings.authorize}
        </StyledButton>
        <TextButton onClick={onDecline}>{strings.decline}</TextButton>
      </div>
    </BaseModal>
  );
}
