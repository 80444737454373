import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useBlocker, useSearchParams } from "react-router-dom";
import { getDisplayError } from "../../lib/api/endpoints";
import { GetAccountResponse } from "../../lib/api/models";
import { useGetAccount, useGetCallInfo } from "../../lib/api/queries";
import {
  ToolState,
  ToolType,
  useToolState,
} from "../../modules/AccountDetails";
import CloseToolConfirmationModal from "../../modules/AccountDetails/CloseToolConfirmationModal/CloseToolConfirmationModal";
import CompletedTool from "../../modules/AccountDetails/CompletedTool";
import { toolTypeToName } from "../../modules/AccountDetails/utils";
import AccountDetailsHeader from "../../modules/AccountDetailsHeader";
import ChangeUsername from "../../modules/ChangeUsername";
import CycleToDateTransactions from "../../modules/CycleToDateTransactions";
import LostStolen from "../../modules/LostStolen";
import MakePayment from "../../modules/MakePayment";
import PaymentHistory from "../../modules/PaymentHistory";
import PaymentTransactions from "../../modules/PaymentTransactions";
import PermissionToDiscuss from "../../modules/PermissionToDiscuss";
import SendTestPushNotification from "../../modules/SendTestPushNotification";
import ManageSourceAccounts from "../../modules/SourceAccounts";
import StatementHistory from "../../modules/StatementHistory";
import StyledError from "../../modules/StyledError";
import Toolbar from "../../modules/Toolbar";
import Spinner from "../../modules/common/Spinner";
import { NetworkErrorModal } from "../../modules/common/modals";
import styles from "./AccountDetailsPage.module.css";
import CreditLineIncrease from "../../modules/CreditLineIncrease";
import { reportError } from "../../lib/bugReporting";
import Terms from "../../modules/Terms";
import Autopay from "../../modules/ManageAutopay";

const strings = {
  errorTitle: "Account failed to load",
};

const COMPLETED_TOOLS_ID = "completed-tools";

type Props = {
  dwbuid: string;
};

const AccountDetailsPage = ({ dwbuid }: Props) => {
  const toolState = useToolState();

  const blocker = useBlocker(
    toolState.activeTools.some((tool) => tool.inProgress),
  );
  const [exitConfirmationModalOpen, setExitConfirmationModalOpen] =
    useState(false);
  const inProgressToolNames = useMemo(
    () =>
      toolState.activeTools
        .filter((tool) => tool.inProgress)
        .map((tool) => toolTypeToName(tool.type)),
    [toolState.activeTools],
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setExitConfirmationModalOpen(true);
    }
  }, [blocker]);

  const handleShouldBlockProceed = (proceed: boolean) => {
    if (blocker.state === "blocked") {
      if (proceed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
    setExitConfirmationModalOpen(false);
  };

  const [closeConfirmationTool, setCloseConfirmationTool] =
    useState<ToolState>();
  const onClickCloseTool = (
    tool: ToolState,
    bypassProgressConfirmation?: boolean,
  ) => {
    if (
      tool.inProgress &&
      !closeConfirmationTool &&
      !bypassProgressConfirmation
    ) {
      setCloseConfirmationTool(tool);
    } else {
      toolState.onRequestCloseTool(tool.id);
      setCloseConfirmationTool(undefined);
    }
  };

  const {
    data: getAccountData,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
  } = useGetAccount(dwbuid);

  const [displayError, setDisplayError] = useState<string>();
  const [searchParams] = useSearchParams();
  const [
    hasSubmittedCreditLineIncreaseThisSession,
    setHasSubmittedCreditLineIncreaseThisSession,
  ] = useState(false);

  const {
    data: getCallInfoData,
    isError: isGetCallInfoError,
    error: getCallInfoError,
  } = useGetCallInfo({
    dwbuid,
    callId: searchParams.get("callId") ?? "",
  });

  // Silently report GET callId errors to sentry
  useEffect(() => {
    if (isGetCallInfoError) {
      reportError(new Error("Programmer Error: Error retrieving callId info"), {
        error: getCallInfoError,
      });
    }
  }, [getCallInfoError, isGetCallInfoError]);

  const renderTool = (
    tool: ToolState,
    accountData: GetAccountResponse,
  ): JSX.Element => {
    const type = tool.type;
    switch (type) {
      case ToolType.ChangeUsername:
        return (
          <ChangeUsername
            key={tool.id}
            toolId={tool.id}
            className={styles.tool}
            account={accountData}
            onSuccess={(summaryWithoutTime) =>
              toolState.onCompleteTool(tool.id, summaryWithoutTime)
            }
            onDisplayGeneralErrors={(err) =>
              setDisplayError(getDisplayError(err))
            }
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.TestPushNotification:
        return (
          <SendTestPushNotification
            key={tool.id}
            toolId={tool.id}
            className={styles.tool}
            account={accountData}
            onDisplayGeneralErrors={(err) =>
              setDisplayError(getDisplayError(err))
            }
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.PaymentTransactions:
        return (
          <PaymentTransactions
            key={tool.id}
            toolId={tool.id}
            className={styles.tool}
            account={accountData}
            openScheduledPayment={(editPaymentData) => {
              toolState.onAddTool({
                type: ToolType.MakePayment,
                inProgress: false,
                editPaymentData,
              });
              requestAnimationFrame(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              });
            }}
            activeTools={toolState.activeTools}
            onRequestClose={() => onClickCloseTool(tool)}
            onDisplayGeneralErrors={(err) =>
              setDisplayError(getDisplayError(err))
            }
          />
        );
      case ToolType.PaymentHistory:
        return (
          <PaymentHistory
            key={tool.id}
            className={styles.tool}
            account={accountData}
            toolId={tool.id}
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.MakePayment:
        return (
          <MakePayment
            key={tool.id}
            className={styles.tool}
            account={accountData}
            onClose={() => {
              toolState.onRequestCloseTool(tool.id);
            }}
            onRequestClose={() => onClickCloseTool(tool)}
            onComplete={(summaryWithoutTime: string) => {
              toolState.onCompleteTool(tool.id, summaryWithoutTime);
            }}
            onSetToolInProgress={toolState.onSetToolInProgress}
            tool={tool}
            onDisplayGeneralErrors={(err) =>
              setDisplayError(getDisplayError(err))
            }
          />
        );
      case ToolType.SourceAccounts:
        return (
          <ManageSourceAccounts
            key={tool.id}
            className={styles.tool}
            account={accountData}
            tool={tool}
            onSetToolInProgress={toolState.onSetToolInProgress}
            onDisplayGeneralErrors={(err) =>
              setDisplayError(getDisplayError(err))
            }
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.PermissionToDiscuss:
        return (
          <PermissionToDiscuss
            key={tool.id}
            className={styles.tool}
            account={accountData}
            toolId={tool.id}
            onSetToolInProgress={toolState.onSetToolInProgress}
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.CycleToDateTransactions:
        return (
          <CycleToDateTransactions
            key={tool.id}
            className={styles.tool}
            account={accountData}
            toolId={tool.id}
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.StatementHistory:
        return (
          <StatementHistory
            key={tool.id}
            className={styles.tool}
            toolId={tool.id}
            onRequestClose={() => onClickCloseTool(tool)}
            account={accountData}
          />
        );
      case ToolType.LostStolen:
        return (
          <LostStolen
            key={tool.id}
            className={styles.tool}
            account={accountData}
            toolId={tool.id}
            onRequestClose={(bypassProgressConfirmation) =>
              onClickCloseTool(tool, bypassProgressConfirmation)
            }
            onSetToolInProgress={toolState.onSetToolInProgress}
          />
        );
      case ToolType.CreditLineIncrease:
        return (
          <CreditLineIncrease
            account={accountData}
            key={tool.id}
            className={styles.tool}
            toolId={tool.id}
            onRequestClose={() => onClickCloseTool(tool)}
            onSetToolInProgress={toolState.onSetToolInProgress}
            referenceId={getCallInfoData?.referenceId}
            hasSubmittedCreditLineIncreaseThisSession={
              hasSubmittedCreditLineIncreaseThisSession
            }
            onSubmitCreditLineIncrease={() =>
              setHasSubmittedCreditLineIncreaseThisSession(true)
            }
          />
        );
      case ToolType.Terms:
        return (
          <Terms
            key={tool.id}
            className={styles.tool}
            toolId={tool.id}
            onRequestClose={() => onClickCloseTool(tool)}
          />
        );
      case ToolType.ManageAutopay:
        return (
          <Autopay
            key={tool.id}
            className={styles.tool}
            tool={tool}
            account={accountData}
            onRequestClose={() => onClickCloseTool(tool)}
            onDisplayGeneralErrors={(err) =>
              setDisplayError(getDisplayError(err))
            }
            onComplete={(summaryWithoutTime: string) => {
              toolState.onCompleteTool(tool.id, summaryWithoutTime);
            }}
            onSetToolInProgress={toolState.onSetToolInProgress}
          />
        );
    }
  };

  return (
    <main className={styles.main}>
      {isLoading || isFetching ? (
        <Container>
          <Row>
            <Col offset={{ xs: 6 }} xs={1}>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        </Container>
      ) : isError ? (
        <Container>
          <Row>
            <Col>
              <StyledError
                error={error}
                errorTitle={strings.errorTitle}
                refetch={refetch}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Toolbar
            account={getAccountData}
            toolState={toolState}
            scrollToCompleted={() => {
              document
                .getElementById(COMPLETED_TOOLS_ID)
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          />
          <div className={styles.pageContent}>
            <AccountDetailsHeader
              account={getAccountData}
              className={styles.tool}
              onDisplayGeneralErrors={(err) =>
                setDisplayError(getDisplayError(err))
              }
            />
            {toolState.activeTools.map((tool) =>
              renderTool(tool, getAccountData),
            )}
            <div id={COMPLETED_TOOLS_ID} />
            {toolState.completedTools.map((tool) => (
              <CompletedTool
                key={tool.id}
                className={styles.summary}
                summary={tool.completed?.summary ?? ""}
                type={tool.type}
              />
            ))}
          </div>
          <NetworkErrorModal
            message={displayError}
            onRequestClose={() => setDisplayError(undefined)}
          />
          <CloseToolConfirmationModal
            isOpen={exitConfirmationModalOpen}
            toolNames={inProgressToolNames}
            onCloseTool={() => handleShouldBlockProceed(true)}
            onRequestClose={() => handleShouldBlockProceed(false)}
          />
          <CloseToolConfirmationModal
            isOpen={!!closeConfirmationTool}
            toolNames={
              closeConfirmationTool
                ? [toolTypeToName(closeConfirmationTool.type)]
                : []
            }
            onCloseTool={() => {
              if (closeConfirmationTool) {
                onClickCloseTool(closeConfirmationTool);
              }
            }}
            onRequestClose={() => setCloseConfirmationTool(undefined)}
          />
        </>
      )}
    </main>
  );
};
export default AccountDetailsPage;
