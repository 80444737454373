import { Col, Container, Row } from "react-grid-system";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import {
  useGetClassificationCount,
  useGetSecureMailList,
} from "../../lib/api/queries";
import { useSecureMailRedirectWithoutRole } from "../../lib/routeRedirect.hooks";
import { paths } from "../../lib/router";
import ClassificationList from "../../modules/SecureMailQueue/ClassificationList";
import StyledError from "../../modules/StyledError";
import Spinner from "../../modules/common/Spinner";
import SecureMailTable from "../../modules/common/tables/SecureMailTable";
import styles from "./SecureMailQueuePage.module.css";

const strings = {
  classificationsErrorTitle: "Classifications failed to load",
  error: "Sorry, there was an error retrieving the secure messages.",
  errorTitle: "SecureMail Queue failed to load",
  secureMailQueue: "SecureMail Queue",
  noMessages: "No secure messsages found.",
  retry: "Retry",
};

const PAGE_SIZE = 10;

type PaginationLink = {
  pathname: string;
  search: string;
};

export default function SecureMailQueuePage() {
  useSecureMailRedirectWithoutRole();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [selectedClassificationId, setSelectedClassificationId] = useState(
    searchParams.get("classification") ?? undefined,
  );

  useEffect(() => {
    const next = searchParams.get("classification");
    if (next !== selectedClassificationId && !!next) {
      setSelectedClassificationId(next);
    }
  }, [searchParams, selectedClassificationId, setSelectedClassificationId]);

  const {
    data: classificationCount,
    isLoading: isGetClassificationCountLoading,
    isError: isGetClassificationCountError,
    error: getClassificationCountError,
    refetch: getClassificationCountRefetch,
    isFetching: isGetClassificationCountFetching,
  } = useGetClassificationCount();

  // Default to first classification if selectedClassificationId is unset
  useEffect(() => {
    if (selectedClassificationId) {
      return;
    }

    const classifications = classificationCount?.classifications;
    if (classifications && classifications.length > 0) {
      setSelectedClassificationId(classifications[0].id);
    }
  }, [
    classificationCount,
    selectedClassificationId,
    setSelectedClassificationId,
  ]);

  let selectedPageIndex = 1;
  const parsedPage = parseInt(searchParams.get("page") ?? "");
  if (!isNaN(parsedPage) && parsedPage > 1) {
    selectedPageIndex = parsedPage;
  }

  const {
    data: secureMailList,
    isLoading: isGetSecureMailListLoading,
    isError: isGetSecureMailListError,
    error: getSecureMailListError,
    refetch: getSecureMailListRefetch,
    isFetching: isGetSecureMailListFetching,
  } = useGetSecureMailList(
    {
      classificationId: selectedClassificationId ?? "",
      page: selectedPageIndex - 1,
      size: PAGE_SIZE,
    },
    !!selectedClassificationId,
  );

  function setPaginationLink(pageIndex: number) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", pageIndex.toString());
    return newSearchParams.toString();
  }
  function getPaginationObj() {
    const total = secureMailList ? secureMailList.total : 0;
    const numPages = Math.ceil(total / PAGE_SIZE);

    const paginationLinks: PaginationLink[] = [];
    for (let i = 1; i <= numPages; i++) {
      paginationLinks.push({
        pathname: "/secure-mail",
        search: setPaginationLink(i),
      });
    }

    const paginationObj = {
      forwardArrowPath: {
        pathname: "/secure-mail",
        search: setPaginationLink(selectedPageIndex + 1),
      },
      backArrowPath: {
        pathname: "/secure-mail",
        search: setPaginationLink(selectedPageIndex - 1),
      },

      paginationLinks: paginationLinks,
    };
    return paginationObj;
  }

  return (
    <Container className={styles.container}>
      <Row className={styles.titleRow}>
        <Col xs={4} className={styles.title}>
          {strings.secureMailQueue}
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          {isGetClassificationCountLoading ||
          isGetClassificationCountFetching ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : isGetClassificationCountError ? (
            <StyledError
              variant="vertical"
              errorTitle={strings.classificationsErrorTitle}
              error={getClassificationCountError}
              refetch={getClassificationCountRefetch}
            />
          ) : (
            <ClassificationList
              classifications={classificationCount.classifications}
              selectedClassificationId={selectedClassificationId}
            />
          )}
        </Col>
        <Col xs={9}>
          {isGetSecureMailListLoading || isGetSecureMailListFetching ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : isGetSecureMailListError ? (
            <StyledError
              errorTitle={strings.errorTitle}
              error={getSecureMailListError}
              refetch={getSecureMailListRefetch}
            />
          ) : (
            <SecureMailTable
              className={styles.table}
              variant="classification"
              secureMail={secureMailList}
              pageSize={PAGE_SIZE}
              paginationObj={getPaginationObj()}
              onClickRow={(id, page) =>
                navigate(
                  {
                    pathname: paths.secureMail(id),
                    search: `classification=${selectedClassificationId}&showNextMail=true`,
                  },
                  {
                    state: {
                      backLink: `/secure-mail/?${setPaginationLink(page)}`,
                    },
                  },
                )
              }
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
