import { useCallback, useEffect, useRef, useState } from "react";
import {
  DateString,
  DynamicErrorResponse,
  PaymentSeriesFrequencyType,
  PaymentTransaction,
  PaymentType,
} from "../../lib/api/models";
import {
  formatToDateStringInCentralTime,
  validateBeforeCentsToDollarString,
} from "../../lib/formatting";
import { getStylizedPaymentMethodName } from "../../lib/sourceAccounts";
import { CardholderInfoFormState } from "../CardholderInfo/CardholderInfo";
import {
  PaymentInfoFormState,
  PaymentOption,
} from "../PaymentInfo/PaymentInfo";
import { NEW_SOURCE_ACCOUNT } from "./contants";

const INITIAL_FORM_STATE: MakePaymentFormState = {
  paymentType: "single",
  frequency: "monthly",
  startDate: "",
  paymentOption: undefined,
  amountCents: "0.00",
  numberOfPayments: 1,
  paymentDate: formatToDateStringInCentralTime(new Date()),
  ownerType: undefined,
  paymentMethodId: "",
  currentPaymentMethod: undefined,
  removedPaymentMethod: undefined,
  removeSourceAccount: false,
  paymentMethodType: undefined,
  abaNumber: "",
  bankName: "",
  accountNumber: "",
  confirmAccountNumber: "",
  processingStatusType: "ACH",
  cardNumber: "",
  confirmCardNumber: "",
  expirationDate: "",
  cardHolderFirstName: "",
  cardHolderLastName: "",
  phoneNumber: "",
  cardHolderFirstName2: undefined,
  cardHolderLastName2: undefined,
  addressLine1: "",
  addressLine2: "",
  city: "",
  stateCode: "",
  zipcode: "",
  paymentMethodName: "",
  isComplete: false,
  isSourceAccountComplete: false,
};

type BasicFormInput = {
  paymentType: PaymentType;
  frequency: PaymentSeriesFrequencyType;
  startDate: DateString;
  paymentOption?: PaymentOption;
  amountCents: string;
  numberOfPayments: number;
  paymentDate: DateString;
  removeSourceAccount: boolean;
};

export type MakePaymentFormInput = BasicFormInput &
  PaymentInfoFormState &
  CardholderInfoFormState;

export type MakePaymentFormState = MakePaymentFormInput & {
  isComplete: boolean;
  isSourceAccountComplete: boolean;
};

const isCheckingsComplete = (formState: MakePaymentFormState) => {
  let isComplete = false;
  if (formState.paymentMethodType !== "checking_account") {
    return isComplete;
  }
  isComplete =
    !!formState.abaNumber &&
    !!formState.accountNumber &&
    !!formState.processingStatusType;
  if (formState.paymentMethodId === NEW_SOURCE_ACCOUNT) {
    isComplete =
      isComplete && formState.accountNumber === formState.confirmAccountNumber;
  } else {
    isComplete = isComplete && !!formState.bankName;
  }
  return isComplete;
};

const isCreditOrDebitComplete = (formState: MakePaymentFormState) => {
  let isComplete = false;
  if (
    formState.paymentMethodType !== "credit_card" &&
    formState.paymentMethodType !== "debit_card"
  ) {
    return isComplete;
  }
  isComplete = !!formState.cardNumber && !!formState.expirationDate;
  if (formState.paymentMethodId === NEW_SOURCE_ACCOUNT) {
    isComplete =
      isComplete && formState.cardNumber === formState.confirmCardNumber;
  }
  return isComplete;
};

const isRespectiveSourceAccountComplete = (formState: MakePaymentFormState) => {
  const isSourceAccountComplete =
    formState.paymentMethodType === "checking_account"
      ? isCheckingsComplete(formState)
      : isCreditOrDebitComplete(formState);
  return (
    isSourceAccountComplete &&
    !!formState.cardHolderFirstName.trim() &&
    !!formState.cardHolderLastName.trim() &&
    !!formState.addressLine1.trim() &&
    !!formState.city.trim() &&
    !!formState.stateCode &&
    !!formState.zipcode
  );
};

const formHasChanged = (
  formState: MakePaymentFormState,
  initialFormState: MakePaymentFormState,
) => {
  return (Object.keys(formState) as Array<keyof MakePaymentFormState>).some(
    (key) =>
      key !== "isComplete" &&
      key !== "removedPaymentMethod" &&
      key !== "isSourceAccountComplete" &&
      formState[key] !== initialFormState[key],
  );
};

const isFormComplete = (
  formState: MakePaymentFormState,
  initialFormState: MakePaymentFormState,
) => {
  const isBasicFormComplete =
    !!formState.amountCents &&
    !!formState.paymentMethodId &&
    !!formState.ownerType;

  const isSingleComplete =
    formState.paymentType === "single" &&
    !!formState.paymentOption &&
    !!formState.paymentDate;

  const isSeriesComplete =
    formState.paymentType === "series" &&
    !!formState.frequency &&
    !!formState.numberOfPayments &&
    !!formState.startDate;

  const isSourceAccountComplete = isRespectiveSourceAccountComplete(formState);

  return (
    isBasicFormComplete &&
    isSourceAccountComplete &&
    (isSingleComplete || isSeriesComplete) &&
    formHasChanged(formState, initialFormState)
  );
};

const getInitialFormState = (
  scheduledPaymentTransaction?: PaymentTransaction,
): MakePaymentFormState => {
  if (!scheduledPaymentTransaction) {
    return INITIAL_FORM_STATE;
  }

  const paymentMethod = scheduledPaymentTransaction.paymentMethod;

  const baseScheduledPaymentFormState = {
    paymentType: "single", // TODO: Series transactions: https://github.com/1fbusa/clarity-web/issues/499
    frequency: "monthly",
    numberOfPayments: 1,
    startDate: "",
    paymentOption: "custom",
    amountCents: validateBeforeCentsToDollarString(
      scheduledPaymentTransaction.amountCents,
      true,
    ),
    paymentDate: scheduledPaymentTransaction.effectiveDate, // effectiveDate is aleady a DateString
    ownerType: paymentMethod.ownerType,
    paymentMethodId: paymentMethod.id,
    currentPaymentMethod: paymentMethod,
    removedPaymentMethod: undefined,
    removeSourceAccount: false,
    paymentMethodType: paymentMethod.type,
    processingStatusType: scheduledPaymentTransaction.processingStatusType,
    cardHolderFirstName: paymentMethod.cardHolderFirstName,
    cardHolderLastName: paymentMethod.cardHolderLastName,
    phoneNumber: paymentMethod.phoneNumber,
    addressLine1: paymentMethod.addressLine1,
    addressLine2: paymentMethod.addressLine2,
    city: paymentMethod.city,
    stateCode: paymentMethod.stateCode,
    zipcode: paymentMethod.zipcode,
    paymentMethodName: getStylizedPaymentMethodName(paymentMethod),
    confirmAccountNumber: "",
    confirmCardNumber: "",
    isComplete: false,
    isSourceAccountComplete: false,
  };

  if (paymentMethod.type === "checking_account") {
    return {
      ...baseScheduledPaymentFormState,
      cardHolderFirstName2: paymentMethod.cardHolderFirstName2,
      cardHolderLastName2: paymentMethod.cardHolderLastName2,
      abaNumber: paymentMethod.abaNumber,
      bankName: paymentMethod.bankName,
      accountNumber: paymentMethod.accountNumber,
      cardNumber: "",
      expirationDate: "",
    } as MakePaymentFormState;
  } else {
    return {
      ...baseScheduledPaymentFormState,
      cardNumber: paymentMethod.cardNumber,
      expirationDate: paymentMethod.expirationDate,
      cardHolderFirstName2: undefined,
      cardHolderLastName2: undefined,
      abaNumber: "",
      bankName: "",
      accountNumber: "",
    } as MakePaymentFormState;
  }
};

export const useMakePaymentEnrollmentForm = (
  scheduledPaymentTransaction?: PaymentTransaction,
): [
  MakePaymentFormState,
  {
    generalDispatch: (payload: Partial<MakePaymentFormInput>) => void;
  },
] => {
  const initialFormState = getInitialFormState(scheduledPaymentTransaction);
  const [formState, formDispatch] =
    useState<MakePaymentFormState>(initialFormState);

  const generalDispatch = (payload: Partial<MakePaymentFormState>) => {
    formDispatch((prevFormState) => {
      const newFormState = {
        ...prevFormState,
        ...payload,
      };

      return {
        ...newFormState,
        isComplete: isFormComplete(newFormState, initialFormState),
        isSourceAccountComplete:
          isRespectiveSourceAccountComplete(newFormState),
      };
    });
  };

  return [formState, { generalDispatch }];
};

// FIXME: This function is very close to a copy and paste from
// sourceAccounts.tsx#useDynamicErrorMessages due to a time crunch
// We could reduce the duplicated code by creating a common hook used by both
export function useDynamicPaymentErrors(formState: {
  cardNumber: string;
  accountNumber: string;
}) {
  const [currentDynamicPaymentError, _setCurrentDynamicPaymentError] =
    useState<DynamicErrorResponse>();

  const dynamicPaymentErrorsViewCountsRef = useRef<Record<string, number>>({});

  const setCurrentDynamicPaymentError = useCallback(
    (dynamicErrorResponse: DynamicErrorResponse | undefined) => {
      _setCurrentDynamicPaymentError(dynamicErrorResponse);
      if (!dynamicErrorResponse) {
        return;
      }

      if (
        dynamicPaymentErrorsViewCountsRef.current[dynamicErrorResponse.errorKey]
      ) {
        dynamicPaymentErrorsViewCountsRef.current[
          dynamicErrorResponse.errorKey
        ] += 1;
      } else {
        dynamicPaymentErrorsViewCountsRef.current[
          dynamicErrorResponse.errorKey
        ] = 1;
      }
    },
    [],
  );

  // reset payment dynamic errors when there is a different source account
  useEffect(() => {
    dynamicPaymentErrorsViewCountsRef.current = {};
  }, [formState.cardNumber, formState.accountNumber]);

  return {
    currentDynamicPaymentError,
    setCurrentDynamicPaymentError,
    dynamicPaymentErrorsViewCountsRef,
  };
}
