import classNames from "classnames";
import { addYears, subDays } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
  AddCardSourceAccountRequest,
  DYNAMIC_ERROR,
  DateString,
  DynamicErrorResponse,
  EditOneTimePaymentRequest,
  EditSeriesPaymentRequest,
  GetAccountDetailsResponse,
  GetAccountResponse,
  MakeOneTimePaymentOverrideMessage,
  MakeOneTimePaymentRequest,
  MakePaymentNegAmResponse,
  MakeSeriesPaymentRequest,
  PaymentMethod,
  PaymentOverridesResponse,
  PaymentScriptsResponse,
  PaymentSeriesFrequencyType,
  PaymentType,
  ValidationErrors,
} from "../../lib/api/models";
import {
  useAddBankSourceAccount,
  useAddCardSourceAccount,
  useCancelSeriesPayment,
  useEditBankSourceAccount,
  useEditCardSourceAccount,
  useEditOneTimePayment,
  useEditSeriesPayment,
  useGetAccountDetails,
  useGetPaymentMethods,
  useGetPaymentSeriesSchedule,
  useMakeOneTimePayment,
  useMakeSeriesPayment,
} from "../../lib/api/queries";
import { reportError } from "../../lib/bugReporting";
import {
  dollarStringToCents,
  formatDollarInput,
  formatInCentralTime,
  formatToDateStringInCentralTime,
  handlePaymentAmountInputChange,
  parseOnEditOrFocus,
  validateBeforeCentsToDollarString,
} from "../../lib/formatting";
import {
  getStylizedPaymentMethodName,
  handleAddCardSourceAccountErrorResponse,
  isCardMemberAccount,
  useDynamicErrorMessages,
} from "../../lib/sourceAccounts";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../lib/validation";
import { ToolState } from "../AccountDetails";
import CardholderInfo from "../CardholderInfo";
import PaymentInfo from "../PaymentInfo";
import { PaymentOption } from "../PaymentInfo/PaymentInfo";
import PaymentScriptsModal from "../PaymentScriptsModal";
import OverrideCardAddressCheckModal from "../SourceAccounts/OverrideCardAddressCheckModal";
import StyledError from "../StyledError";
import Container from "../common/Container";
import DateInput from "../common/DateInput";
import Select from "../common/Select";
import Spinner from "../common/Spinner";
import StyledCheckbox from "../common/StyledCheckbox";
import ValidationMessage from "../common/ValidationMessage/ValidationMessage";
import StyledButton from "../common/buttons/StyledButton";
import StyledInput from "../common/inputs/StyledInput";
import DuplicatePaymentModal from "./DuplicatePaymentModal";
import {
  useDynamicPaymentErrors,
  useMakePaymentEnrollmentForm,
} from "./MakePayment.hooks";
import styles from "./MakePayment.module.css";
import MakePaymentDynamicErrorModal from "./MakePaymentDynamicErrorModal/MakePaymentDynamicErrorModal";
import MakePaymentNegAmModal from "./MakePaymentNegAmModal";
import MakePaymentOverrideModal from "./MakePaymentOverrideModal";
import MakePaymentPastDateConfirmationModal from "./MakePaymentPastDateConfirmationModal";
import MakePaymentSubInfo from "./MakePaymentSubInfo";
import PaymentSeriesSchedule from "./PaymentSeriesSchedule";
import { PaymentSeriesScheduleRow } from "./PaymentSeriesSchedule/PaymentSeriesTable";
import StyledNumPaymentsInput from "./StyledNumPaymentsInput";
import { NEW_SOURCE_ACCOUNT } from "./contants";

const strings = {
  addSourceAccount: "Add Source Account",
  autofill: "Autofill",
  calculatePaymentSchedule: "Calculate Payment Schedule",
  close: "Close",
  editSourceAccount: "Edit Source Account",
  endOfMonth: "End of Month",
  everyTwoWeeks: "Every Two Weeks",
  invalidDate: "Invalid Date",
  makePayment: "Make a Payment",
  monthly: "Monthly",
  multiple: "Multiple",
  numberOfPayments: "# of Payments*",
  other: "Other",
  paymentType: "Payment Type*",
  paymentAmount: "Payment Amount ($)",
  paymentAmountRequired: "Payment Amount ($)*",
  paymentDate: "Payment Date*",
  remove: "Remove",
  select: "Select",
  selectFrequency: "Select Frequency*",
  selectPayment: "Select Payment*",
  series: "Series",
  single: "Single",
  startDate: "Start Date*",
  submitPayment: "Submit Payment",
  summaryMade: "Successfully made a one time payment",
  summaryMadeSeries: "Successfully made a series payment",
  summaryEdited: "Successfully edited a one time payment",
  summaryEditedSeries: "Successfully edited a series payment",
  cancelledSelectedPaymentsSummary:
    "Successfully cancelled selected payment(s)",
  weekly: "Weekly",
  amountDueLabel: (amountDue: string) => `Amt Due - ${amountDue}`,
  delinquentAmountLabel: (delinquentAmount: string) =>
    `Amount Delinquent - ${delinquentAmount}`,
  totalAmountDueLabel: (totalAmountDue: string) =>
    `Total Amount Due - ${totalAmountDue}`,
  lastStatementBalanceLabel: (lastStatementBalance: string) =>
    `Last Statement Balance - ${lastStatementBalance}`,
  currentBalanceLabel: (currentBalance: string) =>
    `Current Balance - ${currentBalance}`,
  sourceAccountRestriction: (restriction: string) =>
    `B2C/C2C Restriction: ${restriction}`,
};

const MIN_DAYS_FROM_TODAY = 60;
const MAX_YEARS_FROM_TODAY = 2;

type PaymentTypeOption = {
  label: string;
  value: PaymentType;
};

const PAYMENT_TYPE_OPTIONS: PaymentTypeOption[] = [
  {
    label: strings.single,
    value: "single",
  },
  {
    label: strings.series,
    value: "series",
  },
];
type FrequencyTypeOption = {
  label: string;
  value: PaymentSeriesFrequencyType;
};

const MAX_NUM_PAYMENTS = {
  monthly: 24,
  endOfMonth: 24,
  everyTwoWeeks: 52,
  weekly: 104,
  multiple: 24,
};

const FREQUENCY_OPTIONS: FrequencyTypeOption[] = [
  {
    label: strings.monthly,
    value: "monthly",
  },
  {
    label: strings.weekly,
    value: "weekly",
  },
  {
    label: strings.everyTwoWeeks,
    value: "everyTwoWeeks",
  },
  {
    label: strings.endOfMonth,
    value: "endOfMonth",
  },
  {
    label: strings.multiple,
    value: "multiple",
  },
];

type PaymentOptionSelect = {
  label: string;
  value: PaymentOption;
};

const getPaymentOptions = (accountDetails?: GetAccountDetailsResponse) => {
  if (!accountDetails) {
    return [];
  }

  const paymentOptions: PaymentOptionSelect[] = [];

  const amountDueCents = validateBeforeCentsToDollarString(
    accountDetails.amountDueCents,
  );
  if (amountDueCents) {
    paymentOptions.push({
      label: strings.amountDueLabel(amountDueCents),
      value: "amount_due",
    });
  }

  const delinquentAmount = validateBeforeCentsToDollarString(
    accountDetails.delinquentAmountCents,
  );
  if (delinquentAmount) {
    paymentOptions.push({
      label: strings.delinquentAmountLabel(delinquentAmount),
      value: "amount_delq",
    });
  }

  const totalAmountDue = validateBeforeCentsToDollarString(
    accountDetails.totalAmountDueCents,
  );
  if (totalAmountDue) {
    paymentOptions.push({
      label: strings.totalAmountDueLabel(totalAmountDue),
      value: "amount_total",
    });
  }

  const lastStatementBalance = validateBeforeCentsToDollarString(
    accountDetails.lastStatementBalanceCents,
  );
  if (lastStatementBalance) {
    paymentOptions.push({
      label: strings.lastStatementBalanceLabel(lastStatementBalance),
      value: "last_statement_balance",
    });
  }

  const currentBalance = validateBeforeCentsToDollarString(
    accountDetails.currentBalanceCents,
  );
  if (currentBalance) {
    paymentOptions.push({
      label: strings.currentBalanceLabel(currentBalance),
      value: "current_balance",
    });
  }

  paymentOptions.push({
    label: strings.other,
    value: "custom",
  });

  return paymentOptions;
};

const getIsSeriesEdited = (
  initialEditSeriesPaymentSchedule?: PaymentSeriesScheduleRow[],
  paymentSeriesSchedule?: PaymentSeriesScheduleRow[],
) => {
  if (!initialEditSeriesPaymentSchedule || !paymentSeriesSchedule) {
    return false;
  }
  // Not technically possible but good to safeguard against
  if (
    initialEditSeriesPaymentSchedule.length !== paymentSeriesSchedule.length
  ) {
    return true;
  }

  return initialEditSeriesPaymentSchedule.some((seriesPayment, index) => {
    return (
      seriesPayment.paymentAmount !==
        paymentSeriesSchedule[index].paymentAmount ||
      seriesPayment.paymentDate !== paymentSeriesSchedule[index].paymentDate
    );
  });
};

const isB2C = (paymentMethod: {
  type: "credit_card" | "debit_card" | "checking_account";
}) => {
  return paymentMethod.type === "checking_account";
};

const isC2C = (paymentMethod: {
  type: "credit_card" | "debit_card" | "checking_account";
}) => {
  return (
    paymentMethod.type === "credit_card" || paymentMethod.type === "debit_card"
  );
};

type Props = {
  account: GetAccountResponse;
  onDisplayGeneralErrors: (err: unknown) => void;
  onClose: () => void;
  onRequestClose: () => void;
  onComplete: (summaryWithoutTime: string) => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  tool: ToolState;
  className?: string;
};

export function MakePayment({
  account,
  onDisplayGeneralErrors,
  onClose,
  onRequestClose,
  onComplete,
  onSetToolInProgress,
  tool,
  className,
}: Props) {
  const isMakePaymentMode = !tool.editPaymentData;
  const isEditSeries =
    !!tool.editPaymentData && !!tool.editPaymentData.seriesPayments;
  const seriesId = tool.editPaymentData?.singleTransaction.seriesId;

  const {
    data: getAccountDetailsResponse,
    isLoading: isGetAccountDetailsResponseLoading,
    isError: isGetAccountDetailsResponseError,
    error: getAccountDetailsResponseError,
    refetch: getAccountDetailsResponseRefetch,
  } = useGetAccountDetails(account.currentDwbuid);

  const [showDupPayModal, setShowDupPayModal] = useState(false);
  useEffect(() => {
    if (!getAccountDetailsResponse || !isMakePaymentMode) {
      return;
    }
    if (
      getAccountDetailsResponse?.scheduledPayments &&
      getAccountDetailsResponse?.scheduledPayments.length > 0
    ) {
      setShowDupPayModal(true);
    }
  }, [
    getAccountDetailsResponse,
    getAccountDetailsResponse?.scheduledPayments,
    isMakePaymentMode,
  ]);

  const [paymentScript, setPaymentScript] = useState("");
  const [overrideMessages, setOverrideMessages] = useState<
    MakeOneTimePaymentOverrideMessage[]
  >([]);

  const overrideKeys = useRef(["overrideTooFrequentPaymentCheck"]);

  const {
    data: getPaymentMethodsResponse,
    isLoading: isGetPaymentMethodsResponseLoading,
    isError: isGetPaymentMethodsResponseError,
    error: getPaymentMethodsResponseError,
    refetch: getPaymentMethodsResponseRefetch,
  } = useGetPaymentMethods({
    partyId: account.partyId,
  });

  const filteredPaymentMethods = useMemo(() => {
    if (!getPaymentMethodsResponse) {
      return { cardMemberAccounts: [], thirdPartyAccounts: [] };
    }

    const existingTransactionType =
      tool.editPaymentData?.singleTransaction.type;
    if (
      !existingTransactionType ||
      (existingTransactionType !== "B2C" && existingTransactionType !== "C2C")
    ) {
      return getPaymentMethodsResponse;
    }

    return {
      cardMemberAccounts: getPaymentMethodsResponse.cardMemberAccounts.filter(
        (pm) => (existingTransactionType === "B2C" ? isB2C(pm) : isC2C(pm)),
      ),
      thirdPartyAccounts: getPaymentMethodsResponse.thirdPartyAccounts.filter(
        (pm) => (existingTransactionType === "B2C" ? isB2C(pm) : isC2C(pm)),
      ),
    };
  }, [tool.editPaymentData?.singleTransaction.type, getPaymentMethodsResponse]);

  const [formState, { generalDispatch }] = useMakePaymentEnrollmentForm(
    tool.editPaymentData?.singleTransaction,
  );

  const [localPaymentDate, setLocalPaymentDate] = useState<DateString>();
  const [paymentDateToConfirm, setPaymentDateToConfirm] =
    useState<DateString>();

  const {
    paymentType,
    frequency,
    startDate,
    paymentOption,
    amountCents,
    numberOfPayments,
    paymentDate,
    currentPaymentMethod,
    removedPaymentMethod,
    isComplete: isFormStateComplete,
    isSourceAccountComplete: isFormStateSourceAccountComplete,
  } = formState;

  useEffect(() => {
    onSetToolInProgress(tool.id, tool.inProgress || !isFormStateComplete);
  }, [isFormStateComplete, onSetToolInProgress, tool.id, tool.inProgress]);

  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();

  /* add/edit source account on change */
  const hasCurrentPaymentMethodBeenUpdated = useMemo(() => {
    if (!formState.paymentMethodId) {
      return false;
    }

    if (!currentPaymentMethod) {
      return formState.paymentMethodId === NEW_SOURCE_ACCOUNT;
    }

    if (
      (currentPaymentMethod.type === "credit_card" ||
        currentPaymentMethod.type === "debit_card") &&
      currentPaymentMethod.expirationDate !== formState.expirationDate
    ) {
      return true;
    }

    if (
      currentPaymentMethod.type === "checking_account" &&
      !isCardMemberAccount(currentPaymentMethod) &&
      (currentPaymentMethod.cardHolderFirstName2 !==
        formState.cardHolderFirstName2 ||
        currentPaymentMethod.cardHolderLastName2 !==
          formState.cardHolderLastName2)
    ) {
      return true;
    }

    return (
      currentPaymentMethod.cardHolderFirstName !==
        formState.cardHolderFirstName ||
      currentPaymentMethod.cardHolderLastName !==
        formState.cardHolderLastName ||
      currentPaymentMethod.addressLine1 !== formState.addressLine1 ||
      currentPaymentMethod.addressLine2 !== formState.addressLine2 ||
      currentPaymentMethod.city !== formState.city ||
      currentPaymentMethod.stateCode !== formState.stateCode ||
      currentPaymentMethod.zipcode !== formState.zipcode ||
      currentPaymentMethod.phoneNumber !== formState.phoneNumber
    );
  }, [currentPaymentMethod, formState]);

  const skipNegAmCheckRef = useRef<boolean>(false);
  const [negAmInfo, setNegAmInfo] = useState<MakePaymentNegAmResponse>();

  const isComplete = isFormStateComplete && !hasCurrentPaymentMethodBeenUpdated;

  const isSourceAccountComplete =
    isFormStateSourceAccountComplete && hasCurrentPaymentMethodBeenUpdated;

  const paymentOptions = getPaymentOptions(getAccountDetailsResponse);

  const {
    mutateAsync: makeOneTimePayment,
    isLoading: isMakeOneTimePaymentLoading,
  } = useMakeOneTimePayment();

  const {
    mutateAsync: makeSeriesPayment,
    isLoading: isMakeSeriesPaymentLoading,
  } = useMakeSeriesPayment();

  const clearNetworkValidationMessage = (...keys: string[]) => {
    if (!networkValidationMessages || keys.length < 1) {
      return;
    }

    setNetworkValidationMessages((prev) => {
      const next = { ...prev };
      keys.forEach((key) => {
        next[key] = [];
      });

      return next;
    });
  };

  const onSelectPaymentOption = (paymentOptionKey: PaymentOption) => {
    generalDispatch({ paymentOption: paymentOptionKey });

    let amount: string | undefined = undefined;
    switch (paymentOptionKey) {
      case "amount_due":
        amount = validateBeforeCentsToDollarString(
          getAccountDetailsResponse?.amountDueCents,
          true,
        );
        break;
      case "amount_delq":
        amount = validateBeforeCentsToDollarString(
          getAccountDetailsResponse?.delinquentAmountCents,
          true,
        );
        break;
      case "amount_total":
        amount = validateBeforeCentsToDollarString(
          getAccountDetailsResponse?.totalAmountDueCents,
          true,
        );
        break;
      case "last_statement_balance":
        amount = validateBeforeCentsToDollarString(
          getAccountDetailsResponse?.lastStatementBalanceCents,
          true,
        );
        break;
      case "current_balance":
        amount = validateBeforeCentsToDollarString(
          getAccountDetailsResponse?.currentBalanceCents,
          true,
        );
        break;
      case "custom":
        amount = "0.00";
        break;
    }

    generalDispatch({ amountCents: amount ?? "0.00" });
    clearNetworkValidationMessage("amountCents");
  };

  const initialEditSeriesPaymentSchedule:
    | PaymentSeriesScheduleRow[]
    | undefined = useMemo(() => {
    if (!tool.editPaymentData || !tool.editPaymentData?.seriesPayments) {
      return undefined;
    }

    return tool.editPaymentData.seriesPayments.map((payment) => ({
      paymentAmount: `${
        validateBeforeCentsToDollarString(payment.amountCents) ?? "$0.00"
      }`,
      paymentDate: payment.effectiveDate as DateString,
      checked: false,
      confirmationNumber: payment.confirmationId,
      id: payment.id,
      processingStatusType: payment.processingStatusType,
    }));
  }, [tool.editPaymentData]);
  const initialMakeSeriesPaymentSchedule = useRef<PaymentSeriesScheduleRow[]>();

  const [paymentSeriesSchedule, setPaymentSeriesSchedule] = useState<
    PaymentSeriesScheduleRow[]
  >([]);
  const hasSeriesBeenEdited = getIsSeriesEdited(
    initialEditSeriesPaymentSchedule,
    paymentSeriesSchedule,
  );
  const paymentSeriesScheduleVisible = paymentSeriesSchedule.length > 0;

  const {
    currentDynamicError,
    setCurrentDynamicError,
    dynamicErrorOverridesRef,
    dynamicErrorViewCountsRef,
  } = useDynamicErrorMessages(formState);

  const {
    currentDynamicPaymentError,
    setCurrentDynamicPaymentError,
    dynamicPaymentErrorsViewCountsRef,
  } = useDynamicPaymentErrors(formState);

  const handlePaymentErrorResponse = (err: unknown, pt: PaymentType) => {
    if ((err as PaymentOverridesResponse).overrideMessages) {
      const overrideMessagesResponse = (err as PaymentOverridesResponse)
        .overrideMessages;
      setOverrideMessages(overrideMessagesResponse);
      return;
    }

    if ((err as PaymentScriptsResponse).type === "script") {
      setPaymentScript((err as PaymentScriptsResponse).message);
      return;
    }

    if ((err as DynamicErrorResponse).type === DYNAMIC_ERROR) {
      setCurrentDynamicPaymentError(err as DynamicErrorResponse);
      return;
    }

    // Negam not supported for series payments
    if ((err as MakePaymentNegAmResponse).increaseAmountCents !== undefined) {
      if (pt === "series") {
        reportError(
          new Error("NegAm check not supported with series payments."),
        );
      } else {
        const response = err as MakePaymentNegAmResponse;
        setNegAmInfo(response);
        return;
      }
    }

    setValidationErrorsAndDisplayGeneralErrors(
      err,
      setNetworkValidationMessages,
      onDisplayGeneralErrors,
    );
  };

  const onMakeSeriesPayment = (scriptComplete: boolean) => {
    if (!isComplete || !formState.ownerType) {
      return;
    }

    let hasInvalidSchedule = false;
    setPaymentSeriesSchedule((prev) =>
      prev.map((s) => {
        if (!s.paymentDate) {
          hasInvalidSchedule = true;
          return {
            ...s,
            validationMessages: { paymentDate: strings.invalidDate },
          };
        }
        return s;
      }),
    );

    if (hasInvalidSchedule) {
      return;
    }

    const { ownerType, paymentMethodId, removeSourceAccount, bankName } =
      formState;

    const makeSeriesPaymentRequest: MakeSeriesPaymentRequest = {
      partyId: account.partyId,
      dwbuid: account.currentDwbuid,
      authorizerType: isCardMemberAccount({ ownerType })
        ? "ACCOUNT_OWNER"
        : "THIRD_PARTY",
      bankName,
      paymentMethodId,
      payments: paymentSeriesSchedule
        .filter((pS) => pS.checked)
        .map((pS) => ({
          amountCents: dollarStringToCents(pS.paymentAmount),
          date: pS.paymentDate,
        })),
      removeSourceAccount,
      overrideKeys: overrideKeys.current,
      frequency: getIsSeriesEdited(
        initialMakeSeriesPaymentSchedule.current,
        paymentSeriesSchedule,
      )
        ? "multiple"
        : frequency,
      scriptComplete,
      dynamicErrorOverrides: dynamicErrorOverridesRef.current,
      dynamicErrorViewCounts: dynamicPaymentErrorsViewCountsRef.current,
      processingStatusType: formState.processingStatusType,
    };

    makeSeriesPayment(makeSeriesPaymentRequest)
      .then(() => onComplete(strings.summaryMadeSeries))
      .catch((err) => handlePaymentErrorResponse(err, "series"));
  };

  const onMakeOneTimePayment = (scriptComplete: boolean) => {
    if (!isComplete || !formState.ownerType) {
      return;
    }

    const amountCentsAsNumber = dollarStringToCents(amountCents);

    const {
      ownerType,
      paymentMethodId,
      processingStatusType,
      removeSourceAccount,
      bankName,
    } = formState;

    const makeOneTimePaymentRequest: MakeOneTimePaymentRequest = {
      partyId: account.partyId,
      amountCents: amountCentsAsNumber,
      authorizerType: isCardMemberAccount({ ownerType })
        ? "ACCOUNT_OWNER"
        : "THIRD_PARTY",
      bankName,
      dwbuid: account.currentDwbuid,
      overrideKeys: overrideKeys.current,
      paymentDate: paymentDate,
      paymentMethodId,
      processingStatusType,
      removeSourceAccount,
      skipNegAmCheck: skipNegAmCheckRef.current,
      scriptComplete,
      dynamicErrorOverrides: dynamicErrorOverridesRef.current,
      dynamicErrorViewCounts: dynamicPaymentErrorsViewCountsRef.current,
    };

    makeOneTimePayment(makeOneTimePaymentRequest)
      .then(() => onComplete(strings.summaryMade))
      .catch((err) => handlePaymentErrorResponse(err, "single"));
  };

  const {
    mutateAsync: getPaymentSeriesSchedule,
    isLoading: isGetPaymentSeriesScheduleLoading,
  } = useGetPaymentSeriesSchedule();

  const onGetPaymentSeriesSchedule = () => {
    if (!isComplete) {
      return;
    }

    // Skip getting payment series
    if (frequency === "multiple") {
      const schedule: PaymentSeriesScheduleRow[] = [
        ...Array(numberOfPayments),
      ].map(() => ({
        paymentAmount: `$${amountCents}`,
        paymentDate: "",
        checked: true,
      }));

      setPaymentSeriesSchedule(schedule);
      return;
    }

    const amountCentsAsNumber = dollarStringToCents(amountCents);

    getPaymentSeriesSchedule({
      amountCents: amountCentsAsNumber,
      numberOfPayments,
      startDate: `${startDate}T00:00:00Z`,
      frequency,
    })
      .then((data) => {
        setPaymentSeriesSchedule(
          data.schedule.map((payment) => ({
            paymentAmount: `$${
              validateBeforeCentsToDollarString(payment.amountCents, true) ??
              "0.00"
            }`,
            paymentDate: payment.date as DateString,
            checked: true,
          })),
        );
        initialMakeSeriesPaymentSchedule.current = data.schedule.map(
          (payment) => ({
            paymentAmount: `$${
              validateBeforeCentsToDollarString(payment.amountCents, true) ??
              "0.00"
            }`,
            paymentDate: payment.date as DateString,
            checked: true,
          }),
        );
      })
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  };

  const {
    mutateAsync: editSeriesPayment,
    isLoading: isEditSeriesPaymentLoading,
  } = useEditSeriesPayment();

  const onEditSeries = (scriptComplete: boolean) => {
    if (!seriesId || !hasSeriesBeenEdited) {
      return;
    }

    const {
      paymentMethodId,
      removeSourceAccount,
      processingStatusType,
      bankName,
    } = formState;

    const editSeriesPaymentRequest: EditSeriesPaymentRequest = {
      partyId: account.partyId,
      bankName,
      dwbuid: account.currentDwbuid,
      seriesId,
      paymentMethodId: paymentMethodId,
      payments: paymentSeriesSchedule.map((pS) => ({
        amountCents: dollarStringToCents(pS.paymentAmount),
        date: pS.paymentDate,
        paymentId: pS.id ?? "",
        achProcessingStatus: processingStatusType,
      })),
      removeSourceAccount: removeSourceAccount,
      overrideKeys: overrideKeys.current,
      scriptComplete,
      dynamicErrorOverrides: dynamicErrorOverridesRef.current,
      dynamicErrorViewCounts: dynamicPaymentErrorsViewCountsRef.current,
    };
    editSeriesPayment(editSeriesPaymentRequest)
      .then(() => onComplete(strings.summaryEditedSeries))
      .catch((err) => handlePaymentErrorResponse(err, "series"));
  };

  const onChangePaymentType = (v: PaymentType) => {
    const today = formatToDateStringInCentralTime(new Date());

    switch (v) {
      case "single":
        generalDispatch({
          paymentType: v,
          paymentOption: undefined,
          amountCents: "0.00",
          paymentDate: today,
        });
        break;
      case "series":
        generalDispatch({
          paymentType: v,
          frequency: "monthly",
          amountCents: "0.00",
          numberOfPayments: 2,
          startDate: today,
        });
    }
  };

  const submitLabel = useMemo(() => {
    return formState.paymentType === "single"
      ? strings.submitPayment
      : strings.calculatePaymentSchedule;
  }, [formState]);

  const sourceAccountButtonLabel = useMemo(() => {
    if (formState.paymentMethodId === NEW_SOURCE_ACCOUNT) {
      return strings.addSourceAccount;
    } else {
      return strings.editSourceAccount;
    }
  }, [formState]);

  const updateCurrentPaymentMethod = (pm: PaymentMethod) => {
    const paymentMethodName = getStylizedPaymentMethodName(pm);

    const paymentMethodUpdateRequest = {
      paymentMethodId: pm.id,
      currentPaymentMethod: pm,
      paymentMethodType: pm.type,
      paymentMethodName,
      cardHolderFirstName: pm.cardHolderFirstName,
      cardHolderLastName: pm.cardHolderLastName,
      addressLine1: pm.addressLine1,
      addressLine2: pm.addressLine2,
      city: pm.city,
      stateCode: pm.stateCode,
      zipcode: pm.zipcode,
      phoneNumber: pm.phoneNumber,
    };

    if (pm.type === "checking_account") {
      generalDispatch({
        ...paymentMethodUpdateRequest,
        cardHolderFirstName2: pm.cardHolderFirstName2,
        cardHolderLastName2: pm.cardHolderLastName2,
        abaNumber: pm.abaNumber,
        bankName: pm.bankName ?? formState.bankName,
        accountNumber: pm.accountNumber,
      });
    } else {
      generalDispatch({
        ...paymentMethodUpdateRequest,
        cardNumber: pm.cardNumber,
        expirationDate: pm.expirationDate,
      });
    }
  };

  const {
    mutateAsync: editBankSourceAccount,
    isLoading: isEditBankSourceAccountLoading,
  } = useEditBankSourceAccount();

  const {
    mutateAsync: addBankSourceAccount,
    isLoading: isAddBankSourceAccountLoading,
  } = useAddBankSourceAccount();

  const onUpdateBankSourceAccount = () => {
    if (!formState.ownerType) {
      return;
    }

    const {
      paymentMethodId,
      accountNumber,
      abaNumber,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      phoneNumber,
      cardHolderFirstName,
      cardHolderLastName,
      cardHolderFirstName2,
      cardHolderLastName2,
      ownerType,
    } = formState;

    const baseCheckingsSourceAccount = {
      partyId: account.partyId,
      sourceAccountId: paymentMethodId,
      accountNumber,
      abaNumber,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      phoneNumber,
      cardHolderFirstName,
      cardHolderLastName,
      cardHolderFirstName2,
      cardHolderLastName2,
    };
    const checkingsSourceAccountRequest = !isCardMemberAccount({ ownerType })
      ? {
          ...baseCheckingsSourceAccount,
          cardHolderFirstName2,
          cardHolderLastName2,
          ownerType,
        }
      : {
          ...baseCheckingsSourceAccount,
          ownerType,
        };
    if (paymentMethodId === NEW_SOURCE_ACCOUNT) {
      addBankSourceAccount(checkingsSourceAccountRequest)
        .then(updateCurrentPaymentMethod)
        .catch((err) =>
          setValidationErrorsAndDisplayGeneralErrors(
            err,
            setNetworkValidationMessages,
            onDisplayGeneralErrors,
          ),
        );
    } else {
      editBankSourceAccount({
        ...checkingsSourceAccountRequest,
        sourceAccountId: paymentMethodId,
      })
        .then(updateCurrentPaymentMethod)
        .catch((err) =>
          setValidationErrorsAndDisplayGeneralErrors(
            err,
            setNetworkValidationMessages,
            onDisplayGeneralErrors,
          ),
        );
    }
  };

  const {
    mutateAsync: editCardSourceAccount,
    isLoading: isEditCardSourceAccountLoading,
  } = useEditCardSourceAccount();

  const {
    mutateAsync: addCardSourceAccount,
    isLoading: isAddCardSourceAccountLoading,
  } = useAddCardSourceAccount();

  const onUpdateCardSourceAccount = () => {
    if (!formState.ownerType) {
      return;
    }

    const {
      cardNumber,
      expirationDate,
      paymentMethodType,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      phoneNumber,
      cardHolderFirstName,
      cardHolderLastName,
      ownerType,
      paymentMethodId,
    } = formState;

    const baseCardSourceAccountRequest: AddCardSourceAccountRequest = {
      partyId: account.partyId,
      cardNumber,
      expirationDate,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      phoneNumber,
      cardHolderFirstName,
      cardHolderLastName,
      ownerType,
      creditDebitType: paymentMethodType === "credit_card" ? "CREDIT" : "DEBIT",
      dynamicErrorOverrides: dynamicErrorOverridesRef.current,
      dynamicErrorViewCounts: dynamicErrorViewCountsRef.current,
    };

    if (paymentMethodId === NEW_SOURCE_ACCOUNT) {
      addCardSourceAccount({
        ...baseCardSourceAccountRequest,
      })
        .then(updateCurrentPaymentMethod)
        .catch((err) =>
          handleAddCardSourceAccountErrorResponse(
            err,
            setCurrentDynamicError,
            setNetworkValidationMessages,
            onDisplayGeneralErrors,
          ),
        );
    } else {
      editCardSourceAccount({
        ...baseCardSourceAccountRequest,
        sourceAccountId: paymentMethodId,
      })
        .then(updateCurrentPaymentMethod)
        .catch((err) =>
          handleAddCardSourceAccountErrorResponse(
            err,
            setCurrentDynamicError,
            setNetworkValidationMessages,
            onDisplayGeneralErrors,
          ),
        );
    }
  };

  const {
    mutateAsync: editOneTimePayment,
    isLoading: isEditOneTimePaymentLoading,
  } = useEditOneTimePayment();
  const onEditPayment = (scriptComplete: boolean) => {
    if (!isComplete || !tool.editPaymentData) {
      return;
    }

    const amountCentsAsNumber = dollarStringToCents(amountCents);

    const {
      paymentMethodId,
      removeSourceAccount,
      processingStatusType,
      bankName,
    } = formState;

    const editOneTimePaymentRequest: EditOneTimePaymentRequest = {
      partyId: account.partyId,
      amountCents: amountCentsAsNumber,
      bankName,
      dwbuid: account.currentDwbuid,
      overrideKeys: overrideKeys.current,
      paymentDate: paymentDate,
      paymentId: tool.editPaymentData.singleTransaction.id,
      paymentMethodId,
      paymentConfirmationId:
        tool.editPaymentData.singleTransaction.confirmationId,
      processingStatusType,
      removeSourceAccount,
      scriptComplete,
      dynamicErrorOverrides: dynamicErrorOverridesRef.current,
      dynamicErrorViewCounts: dynamicPaymentErrorsViewCountsRef.current,
    };

    editOneTimePayment(editOneTimePaymentRequest)
      .then(() => onComplete(strings.summaryEdited))
      .catch((err) => handlePaymentErrorResponse(err, "single"));
  };

  const onSubmitSeries = (scriptComplete: boolean) => {
    isEditSeries
      ? onEditSeries(scriptComplete)
      : onMakeSeriesPayment(scriptComplete);
  };

  const onSubmitSourceAccount = () => {
    if (!isSourceAccountComplete) {
      return;
    }

    const { paymentMethodType } = formState;
    if (paymentMethodType === "checking_account") {
      onUpdateBankSourceAccount();
    } else {
      onUpdateCardSourceAccount();
    }
  };

  const onMakePayment = (scriptComplete: boolean) => {
    if (paymentType === "single") {
      if (isMakePaymentMode) {
        onMakeOneTimePayment(scriptComplete);
      } else {
        onEditPayment(scriptComplete);
      }
    } else {
      if (paymentSeriesScheduleVisible) {
        onSubmitSeries(scriptComplete);
      } else {
        onGetPaymentSeriesSchedule();
      }
    }
  };

  const now = new Date();

  const onClickEditSeries = () => {
    generalDispatch({
      paymentType: "series",
    });

    if (!tool.editPaymentData || !tool.editPaymentData.seriesPayments) {
      return;
    }

    const seriesSchedule: PaymentSeriesScheduleRow[] =
      tool.editPaymentData.seriesPayments.map((payment) => ({
        paymentAmount: `${
          validateBeforeCentsToDollarString(payment.amountCents) ?? "$0.00"
        }`,
        paymentDate: payment.effectiveDate as DateString,
        checked: false,
        confirmationNumber: payment.confirmationId,
        id: payment.id,
        processingStatusType: payment.processingStatusType,
      }));

    setPaymentSeriesSchedule(seriesSchedule);
  };

  const {
    mutateAsync: cancelSeriesPayment,
    isLoading: isCancelSeriesPaymentLoading,
  } = useCancelSeriesPayment();
  const cancelSelectedSeriesPayments = () => {
    if (
      !paymentSeriesSchedule ||
      paymentSeriesSchedule.length < 1 ||
      !seriesId
    ) {
      return;
    }

    const paymentIds = paymentSeriesSchedule
      .filter((row) => row.checked)
      .map((row) => row.id)
      .filter(
        (value: string | undefined): value is string => value !== undefined,
      );

    cancelSeriesPayment({ partyId: account.partyId, seriesId, paymentIds })
      .then(() => onComplete(strings.cancelledSelectedPaymentsSummary))
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  };

  const onAutofill = () => {
    if (!getAccountDetailsResponse) {
      return;
    }

    generalDispatch({
      cardHolderFirstName: account.firstName,
      cardHolderLastName: account.lastName,
      phoneNumber: getAccountDetailsResponse.phoneNumber,
      addressLine1: getAccountDetailsResponse.addressLine1,
      addressLine2: getAccountDetailsResponse.addressLine2,
      city: getAccountDetailsResponse.city,
      stateCode: getAccountDetailsResponse.stateCode,
      zipcode: getAccountDetailsResponse.zipcode,
    });

    clearNetworkValidationMessage(
      "cardHolderFirstName",
      "cardHolderLastName",
      "phoneNumber",
      "addressLine1",
      "addressLine2",
      "city",
      "stateCode",
      "zipcode",
    );
  };

  const isSourceAccountsLoading =
    isEditBankSourceAccountLoading ||
    isEditCardSourceAccountLoading ||
    isAddBankSourceAccountLoading ||
    isAddCardSourceAccountLoading;

  const isMakePaymentLoading =
    isMakeOneTimePaymentLoading ||
    isMakeSeriesPaymentLoading ||
    isGetPaymentSeriesScheduleLoading ||
    isEditOneTimePaymentLoading ||
    isEditSeriesPaymentLoading ||
    isCancelSeriesPaymentLoading;

  const isLoading = isMakePaymentLoading || isSourceAccountsLoading;

  const paymentAmountInputDisabled =
    (paymentOption !== "custom" && paymentType === "single") || isLoading;

  return (
    <div className={classNames(styles.mainContainer, className)} id={tool.id}>
      <Container>
        <Row>
          <Col xs={3}>
            <h2 className={styles.header}>{strings.makePayment}</h2>
          </Col>
          <MakePaymentSubInfo account={account} />
        </Row>
        <div className={styles.divider} />
        {isGetAccountDetailsResponseError ? (
          <Row>
            <Col>
              <StyledError
                error={getAccountDetailsResponseError}
                errorTitle={strings.makePayment}
                refetch={getAccountDetailsResponseRefetch}
              />
            </Col>
          </Row>
        ) : isGetAccountDetailsResponseLoading ||
          isGetPaymentMethodsResponseLoading ? (
          <Row>
            <Col offset={{ xs: 6 }} xs={1}>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        ) : isGetPaymentMethodsResponseError ? (
          <Row>
            <Col>
              <StyledError
                error={getPaymentMethodsResponseError}
                errorTitle={strings.makePayment}
                refetch={getPaymentMethodsResponseRefetch}
              />
            </Col>
          </Row>
        ) : paymentSeriesScheduleVisible ? (
          <PaymentSeriesSchedule
            payor={
              formState.cardHolderFirstName + " " + formState.cardHolderLastName
            }
            sourceAccountName={formState.paymentMethodName}
            paymentSeriesSchedule={paymentSeriesSchedule}
            setPaymentSeriesSchedule={setPaymentSeriesSchedule}
            onClose={onRequestClose}
            onEditSeries={() => setPaymentSeriesSchedule([])}
            isCancelPaymentsLoading={isCancelSeriesPaymentLoading}
            onSubmitCancelPayments={cancelSelectedSeriesPayments}
            onSubmit={() => onSubmitSeries(false)}
            isEditSeries={isEditSeries}
            isSubmitDisabled={isEditSeries ? !hasSeriesBeenEdited : !isComplete}
            isSubmitLoading={
              isEditSeriesPaymentLoading || isMakeSeriesPaymentLoading
            }
          />
        ) : (
          <>
            <Row>
              <Col xs={2}>
                <Select
                  className={styles.alignSelect}
                  contentClassName={styles.selectContentPadding}
                  selectedLabelClassName={styles.selectContentLabel}
                  selectedValue={paymentType}
                  options={PAYMENT_TYPE_OPTIONS}
                  onChange={onChangePaymentType}
                  label={strings.paymentType}
                  disabled={isLoading || !isMakePaymentMode}
                />
              </Col>
              <Col xs={2}>
                {paymentType === "series" ? (
                  <Select
                    className={styles.alignSelect}
                    contentClassName={styles.selectContentPadding}
                    selectedLabelClassName={styles.selectContentLabel}
                    selectedValue={frequency}
                    options={FREQUENCY_OPTIONS}
                    onChange={(v) => generalDispatch({ frequency: v })}
                    label={strings.selectFrequency}
                    placeholder={strings.select}
                    disabled={isLoading}
                  />
                ) : (
                  <Select
                    className={styles.alignSelect}
                    contentClassName={styles.selectContentPadding}
                    selectedLabelClassName={styles.selectContentLabel}
                    selectedValue={paymentOption}
                    options={paymentOptions}
                    onChange={onSelectPaymentOption}
                    label={strings.selectPayment}
                    placeholder={strings.select}
                    disabled={isLoading}
                  />
                )}
              </Col>
              <Col xs={2}>
                <StyledInput
                  label={
                    paymentAmountInputDisabled
                      ? strings.paymentAmount
                      : strings.paymentAmountRequired
                  }
                  value={amountCents}
                  onChange={(e) => {
                    const val = e.target.value;
                    try {
                      const parsedVal = parseOnEditOrFocus(val);
                      if (parsedVal !== null) {
                        generalDispatch({
                          amountCents: parsedVal,
                        });
                      }
                    } catch (err) {
                      reportError(
                        new Error("Failed to parse payment amount", {
                          cause: err,
                        }),
                      );
                      generalDispatch({
                        amountCents: val,
                      });
                    }
                    clearNetworkValidationMessage("amountCents");
                  }}
                  onBlur={() => {
                    try {
                      const handledChange =
                        handlePaymentAmountInputChange(amountCents);
                      if (handledChange) {
                        generalDispatch({
                          amountCents: formatDollarInput(handledChange),
                        });
                      }
                    } catch (err) {
                      reportError(
                        new Error("Failed to parse payment amount", {
                          cause: err,
                        }),
                      );
                    }
                  }}
                  onFocus={() => {
                    try {
                      parseOnEditOrFocus(amountCents);
                    } catch (err) {
                      reportError(
                        new Error("Failed to parse payment amount", {
                          cause: err,
                        }),
                      );
                    }
                  }}
                  disabled={paymentAmountInputDisabled}
                  validationMessages={
                    networkValidationMessages
                      ? networkValidationMessages["amountCents"]
                      : undefined
                  }
                />
              </Col>
              {paymentType === "series" ? (
                <Col xs={3}>
                  <div className={styles.inputFlexContainer}>
                    <StyledNumPaymentsInput
                      className={styles.numPayments}
                      label={strings.numberOfPayments}
                      value={numberOfPayments}
                      onChange={(e) =>
                        generalDispatch({
                          numberOfPayments: parseInt(e.currentTarget.value),
                        })
                      }
                      disabled={isLoading}
                      max={MAX_NUM_PAYMENTS[frequency]}
                    />
                    <DateInput
                      className={styles.startDate}
                      labelClassName={styles.dateLabel}
                      inputClassName={styles.dateInput}
                      value={startDate}
                      label={strings.startDate}
                      onChangeDate={(newDateString) => {
                        generalDispatch({ startDate: newDateString });
                        clearNetworkValidationMessage("startDate");
                      }}
                      disabled={isLoading || frequency === "multiple"}
                      validationMessages={
                        networkValidationMessages
                          ? networkValidationMessages["startDate"]
                          : undefined
                      }
                    />
                  </div>
                </Col>
              ) : (
                <Col xs={2}>
                  <DateInput
                    className={styles.paymentDate}
                    labelClassName={styles.dateLabel}
                    inputClassName={styles.dateInput}
                    value={localPaymentDate ?? paymentDate}
                    label={strings.paymentDate}
                    onChangeDate={(newDateString) => {
                      setLocalPaymentDate(newDateString);
                      clearNetworkValidationMessage("paymentDate");
                    }}
                    onBlur={() => {
                      if (!localPaymentDate) {
                        generalDispatch({ paymentDate: "" });
                        return;
                      }
                      setLocalPaymentDate(undefined);

                      const currentDate = formatToDateStringInCentralTime(
                        new Date(),
                      );
                      if (localPaymentDate < currentDate) {
                        setPaymentDateToConfirm(localPaymentDate);
                        return;
                      }
                      generalDispatch({ paymentDate: localPaymentDate });
                      clearNetworkValidationMessage("paymentDate");
                    }}
                    disabled={isLoading}
                    min={
                      formatInCentralTime(
                        subDays(now, MIN_DAYS_FROM_TODAY),
                        "yyyy-MM-dd",
                      ) as DateString
                    }
                    max={
                      formatInCentralTime(
                        addYears(now, MAX_YEARS_FROM_TODAY),
                        "yyyy-MM-dd",
                      ) as DateString
                    }
                    validationMessages={
                      networkValidationMessages
                        ? networkValidationMessages["paymentDate"]
                        : undefined
                    }
                  />
                </Col>
              )}
              {paymentType === "single" ? <Col xs={1} /> : null}
              <Col xs={3}>
                <div className={styles.flexColumnContainer}>
                  <div className={styles.closeButtonContainer}>
                    <StyledButton
                      className={styles.closeButton}
                      variant="secondary"
                      renderLoadingIndicator={false}
                      onClick={onRequestClose}
                    >
                      {strings.close}
                    </StyledButton>
                  </div>
                  {formState.ownerType &&
                  formState.ownerType === "ACCOUNT_OWNER" &&
                  formState.paymentMethodId &&
                  formState.paymentMethodType ? (
                    <div className={styles.extraActionsContainer}>
                      <StyledButton
                        className={styles.autofillButton}
                        variant="secondary"
                        renderLoadingIndicator={false}
                        disabled={isLoading}
                        onClick={onAutofill}
                      >
                        {strings.autofill}
                      </StyledButton>
                      <StyledCheckbox
                        className={styles.removeCheckbox}
                        checked={formState.removeSourceAccount}
                        onChange={(e) =>
                          generalDispatch({
                            removeSourceAccount: e.target.checked,
                          })
                        }
                        label={strings.remove}
                        disabled={isLoading}
                      />
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <PaymentInfo
              rowClassName={styles.row}
              getPaymentMethodsResponse={filteredPaymentMethods}
              formState={formState}
              generalDispatch={generalDispatch}
              isLoading={isLoading}
              updateNameOnPaymentMethodChange={(first, last, first2, last2) => {
                generalDispatch({
                  cardHolderFirstName: first,
                  cardHolderLastName: last,
                  cardHolderFirstName2: first2,
                  cardHolderLastName2: last2,
                });
                clearNetworkValidationMessage(
                  "cardHolderFirstName",
                  "cardHolderFirstName2",
                  "cardHolderLastName",
                  "cardHolderLastName2",
                );
              }}
              updateAddressOnPaymentMethodChange={(
                addressLine1,
                city,
                stateCode,
                zipcode,
                phoneNumber,
                addressLine2,
              ) => {
                generalDispatch({
                  addressLine1,
                  city,
                  stateCode,
                  zipcode,
                  phoneNumber,
                  addressLine2,
                });
                clearNetworkValidationMessage(
                  "addressLine1",
                  "addressLine2",
                  "city",
                  "phoneNumber",
                  "stateCode",
                  "zipcode",
                );
              }}
              networkValidationMessages={networkValidationMessages}
              clearNetworkValidationMessage={clearNetworkValidationMessage}
              scheduledPaymentTransaction={
                tool.editPaymentData?.singleTransaction
              }
            />
            {formState.paymentMethodId ? (
              <>
                <Row className={styles.row}>
                  <CardholderInfo
                    formState={formState}
                    generalDispatch={generalDispatch}
                    isLoading={isLoading}
                    networkValidationMessages={networkValidationMessages}
                    clearNetworkValidationMessage={
                      clearNetworkValidationMessage
                    }
                    onClickEditSeries={
                      isEditSeries ? onClickEditSeries : undefined
                    }
                    isEditSeriesDisabled={hasCurrentPaymentMethodBeenUpdated}
                    disableInput={
                      currentPaymentMethod &&
                      removedPaymentMethod &&
                      currentPaymentMethod.id === removedPaymentMethod.id
                    }
                  />
                  <Col xs={2}>
                    <div className={styles.submitButtonContainer}>
                      <StyledButton
                        className={styles.sourceAccountButton}
                        isLoading={isSourceAccountsLoading}
                        disabled={
                          !hasCurrentPaymentMethodBeenUpdated ||
                          !isSourceAccountComplete
                        }
                        onClick={onSubmitSourceAccount}
                      >
                        {sourceAccountButtonLabel}
                      </StyledButton>
                      <StyledButton
                        className={styles.submitButton}
                        disabled={!isComplete}
                        isLoading={isMakePaymentLoading}
                        onClick={() => onMakePayment(false)}
                      >
                        {submitLabel}
                      </StyledButton>
                    </div>
                  </Col>
                </Row>
                {formState.currentPaymentMethod ? (
                  <Row>
                    <Col offset={{ xs: 8 }} xs={4}>
                      <ValidationMessage
                        validationMessages={formState.currentPaymentMethod.restrictions.map(
                          (r) =>
                            strings.sourceAccountRestriction(r.restriction),
                        )}
                      />
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </Container>
      <DuplicatePaymentModal
        isOpen={showDupPayModal}
        onRequestClose={() => {
          setShowDupPayModal(false);
          onClose();
        }}
        onConfirm={() => setShowDupPayModal(false)}
        scheduledPayments={getAccountDetailsResponse?.scheduledPayments ?? []}
      />
      <MakePaymentOverrideModal
        overrideMessage={
          overrideMessages.length > 0 ? overrideMessages[0] : undefined
        }
        onAuthorize={(overrideKey) => {
          // Update list of override keys, dismiss modal and submit payment
          overrideKeys.current = [...overrideKeys.current, overrideKey];

          const nextOverrideMessages = [...overrideMessages].filter(
            (om) => om.overrideKey !== overrideKey,
          );
          setOverrideMessages(nextOverrideMessages);

          if (nextOverrideMessages.length === 0) {
            onMakePayment(true /* scripts are read before overrides */);
          }
        }}
        onDecline={() => setOverrideMessages([])}
      />
      <MakePaymentPastDateConfirmationModal
        isOpen={!!paymentDateToConfirm}
        onRequestClose={() => {
          setPaymentDateToConfirm(undefined);
        }}
        onConfirm={() => {
          generalDispatch({ paymentDate: paymentDateToConfirm });
          clearNetworkValidationMessage("paymentDate");
          setPaymentDateToConfirm(undefined);
        }}
      />
      <MakePaymentNegAmModal
        makePaymentNegAmResponse={negAmInfo}
        onRequestClose={() => setNegAmInfo(undefined)}
        onDeclineIncrease={() => {
          skipNegAmCheckRef.current = true;
          setNegAmInfo(undefined);
        }}
        onConfirmIncrease={() => {
          generalDispatch({
            amountCents: validateBeforeCentsToDollarString(
              negAmInfo?.totalAmountCents,
              true,
            ),
            paymentOption: "custom",
          });
          skipNegAmCheckRef.current = true;
          setNegAmInfo(undefined);
          // NOTE: We cannot proceed to immediately make a payment because
          // that makePayment callback would still be using the original formState
          // and would therefore make a payment with the old value.
          // https://react.dev/reference/react/useState#ive-updated-the-state-but-logging-gives-me-the-old-value
        }}
      />
      <OverrideCardAddressCheckModal
        currentDynamicError={currentDynamicError}
        onOverride={(dynamicErrorKey) => {
          dynamicErrorOverridesRef.current.push(dynamicErrorKey);
          setCurrentDynamicError(undefined);
          onSubmitSourceAccount();
        }}
        onClose={() => setCurrentDynamicError(undefined)}
      />
      <PaymentScriptsModal
        message={paymentScript}
        onSubmit={() => {
          onMakePayment(true);
          setPaymentScript("");
        }}
        onDecline={() => setPaymentScript("")}
      />
      <MakePaymentDynamicErrorModal
        currentDynamicError={currentDynamicPaymentError}
        onOverride={(dynamicErrorKey) => {
          dynamicErrorOverridesRef.current.push(dynamicErrorKey);
          setCurrentDynamicPaymentError(undefined);
          onMakePayment(true /* scripts are read before any dynamic errors */);
        }}
        onClose={() => setCurrentDynamicPaymentError(undefined)}
      />
    </div>
  );
}
