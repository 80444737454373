import classNames from "classnames";
import styles from "./TextButton.module.css";

type Props = {
  children: React.ComponentProps<"button">["children"];
  className?: string;
  disabled?: boolean;
  onClick?: React.ComponentProps<"button">["onClick"];
  type?: React.ComponentProps<"button">["type"];
};

export function TextButton({
  children,
  className,
  disabled,
  onClick,
  type,
}: Props) {
  return (
    <button
      className={classNames(styles.button, className)}
      disabled={disabled}
      onClick={onClick}
      type={type ?? "button"}
    >
      {children}
    </button>
  );
}
