import { Col } from "react-grid-system";
import { FALLBACK_SUPPORTED_STATES } from "../../lib/api/fallback.data";
import {
  OwnershipType,
  PaymentMethodType,
  ValidationErrors,
} from "../../lib/api/models";
import { isCardMemberAccount } from "../../lib/sourceAccounts";
import PhoneNumberInput from "../common/PhoneNumberInput";
import Select from "../common/Select";
import StyledZipcodeInput from "../common/StyledZipcodeInput";
import StyledButton from "../common/buttons/StyledButton";
import StyledInput from "../common/inputs/StyledInput";
import styles from "./CardholderInfo.module.css";

const strings = {
  address: "Address*",
  city: "City*",
  cardHolderFirstName: "First Name*",
  cardHolderFirstName2: "First Name 2",
  cardHolderLastName: "Last Name*",
  cardHolderLastName2: "Last Name 2",
  editSeries: "Edit Series",
  phoneNumber: "Phone Number",
  select: "Select",
  state: "State*",
  unit: "Unit or Ste.",
  zip: "Zip*",
};

export type CardholderInfoFormState = {
  paymentMethodType?: PaymentMethodType;
  ownerType?: OwnershipType;
  cardHolderFirstName: string;
  cardHolderLastName: string;
  phoneNumber?: string | null;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  stateCode: string;
  zipcode: string;
  cardHolderFirstName2?: string | null;
  cardHolderLastName2?: string | null;
};

type Props = {
  formState: CardholderInfoFormState;
  generalDispatch: (payload: Partial<CardholderInfoFormState>) => void;
  isLoading?: boolean;
  networkValidationMessages?: ValidationErrors;
  clearNetworkValidationMessage: (...keys: string[]) => void;
  onClickEditSeries?: () => void;
  isEditSeriesDisabled?: boolean;
  disableInput?: boolean;
};

export default function CardholderInfo({
  formState,
  generalDispatch,
  isLoading,
  networkValidationMessages,
  clearNetworkValidationMessage,
  onClickEditSeries,
  isEditSeriesDisabled,
  disableInput,
}: Props) {
  const {
    cardHolderFirstName,
    cardHolderLastName,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    stateCode,
    zipcode,
    cardHolderFirstName2,
    cardHolderLastName2,
  } = formState;
  return (
    <>
      <Col xs={2}>
        <StyledInput
          label={strings.cardHolderFirstName}
          value={cardHolderFirstName}
          onChange={(e) => {
            generalDispatch({ cardHolderFirstName: e.currentTarget.value });
            clearNetworkValidationMessage("cardHolderFirstName");
          }}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["cardHolderFirstName"]
              : undefined
          }
        />
      </Col>
      <Col xs={2}>
        <StyledInput
          label={strings.cardHolderLastName}
          value={cardHolderLastName}
          onChange={(e) => {
            generalDispatch({ cardHolderLastName: e.currentTarget.value });
            clearNetworkValidationMessage("cardHolderLastName");
          }}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["cardHolderLastName"]
              : undefined
          }
        />
      </Col>
      <Col xs={2}>
        <PhoneNumberInput
          label={strings.phoneNumber}
          value={phoneNumber ?? ""}
          onChange={(v) => {
            generalDispatch({ phoneNumber: v });
            clearNetworkValidationMessage("phoneNumber");
          }}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["phoneNumber"]
              : undefined
          }
        />
      </Col>
      <div className={styles.spacer} />
      {formState.paymentMethodType === "checking_account" &&
      !isCardMemberAccount({ ownerType: formState.ownerType ?? "UNKNOWN" }) ? (
        <>
          <Col xs={2}>
            <StyledInput
              label={strings.cardHolderFirstName2}
              value={cardHolderFirstName2 ?? ""}
              onChange={(e) => {
                generalDispatch({
                  cardHolderFirstName2: e.currentTarget.value,
                });
                clearNetworkValidationMessage("cardHolderFirstName2");
              }}
              disabled={isLoading || disableInput}
              validationMessages={
                networkValidationMessages
                  ? networkValidationMessages["cardHolderFirstName2"]
                  : undefined
              }
            />
          </Col>
          <Col xs={2}>
            <StyledInput
              label={strings.cardHolderLastName2}
              value={cardHolderLastName2 ?? ""}
              onChange={(e) => {
                generalDispatch({ cardHolderLastName2: e.currentTarget.value });
                clearNetworkValidationMessage("cardHolderLastName2");
              }}
              disabled={isLoading || disableInput}
              validationMessages={
                networkValidationMessages
                  ? networkValidationMessages["cardHolderLastName2"]
                  : undefined
              }
            />
          </Col>
          <div className={styles.spacer} />
        </>
      ) : null}
      <Col xs={3}>
        <StyledInput
          label={strings.address}
          value={addressLine1}
          onChange={(e) => {
            generalDispatch({
              addressLine1: e.currentTarget.value,
            });
            clearNetworkValidationMessage("addressLine1");
          }}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["addressLine1"]
              : undefined
          }
        />
      </Col>
      <Col xs={1}>
        <StyledInput
          label={strings.unit}
          value={addressLine2 ?? ""}
          onChange={(e) => {
            generalDispatch({
              addressLine2: e.currentTarget.value,
            });
            clearNetworkValidationMessage("addressLine2");
          }}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["addressLine2"]
              : undefined
          }
        />
      </Col>
      <Col xs={2}>
        <StyledInput
          label={strings.city}
          value={city}
          onChange={(e) => {
            generalDispatch({
              city: e.currentTarget.value,
            });
            clearNetworkValidationMessage("city");
          }}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["city"]
              : undefined
          }
        />
      </Col>
      <Col xs={2}>
        <Select
          className={styles.alignSelect}
          contentClassName={styles.selectContentPadding}
          selectedLabelClassName={styles.selectContentLabel}
          options={FALLBACK_SUPPORTED_STATES}
          label={strings.state}
          selectedValue={stateCode}
          onChange={(v) => {
            generalDispatch({
              stateCode: v,
            });
            clearNetworkValidationMessage("stateCode");
          }}
          placeholder={strings.select}
          disabled={isLoading || disableInput}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["stateCode"]
              : undefined
          }
        />
      </Col>
      <Col xs={2}>
        <div
          className={
            onClickEditSeries ? styles.sharedRowInputContainer : undefined
          }
        >
          <StyledZipcodeInput
            className={onClickEditSeries ? styles.zipcode : undefined}
            label={strings.zip}
            value={zipcode}
            onChange={(v) => {
              generalDispatch({
                zipcode: v,
              });
              clearNetworkValidationMessage("zipcode");
            }}
            disabled={isLoading || disableInput}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["zipcode"]
                : undefined
            }
          />
          {onClickEditSeries ? (
            <StyledButton
              className={styles.editSeries}
              variant="secondary"
              onClick={onClickEditSeries}
              type="button"
              disabled={isEditSeriesDisabled}
            >
              {strings.editSeries}
            </StyledButton>
          ) : null}
        </div>
      </Col>
    </>
  );
}
