import { useCallback, useState } from "react";
import { Filter } from ".";

export const DEFAULT_FILTER: Filter = {
  destination: "",
  type: "",
  status: "",
  source: "",
};

export function useFilter(initial?: Filter) {
  const [filter, setFilter] = useState<Filter>(initial ?? DEFAULT_FILTER);
  const setFilterItemFunction = useCallback(
    (fieldName: keyof Filter, value: string) =>
      setFilter((previousFilter) => {
        return { ...previousFilter, [fieldName]: value };
      }),
    [],
  );

  const setFilterFullFunction = useCallback(
    (newFilter: Filter) => setFilter(newFilter),
    [],
  );
  const clearFilterFunction = useCallback(() => setFilter(DEFAULT_FILTER), []);

  return {
    filter,
    setFilterItem: setFilterItemFunction,
    setFilter: setFilterFullFunction,
    clearFilter: clearFilterFunction,
  };
}
