import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./StyledLink.module.css";

type Props = {
  children: React.ReactNode;
  path: string;
  className?: string;
};

export function StyledLink({ children, path, className }: Props) {
  return (
    <Link className={classNames(styles.link, className)} to={path}>
      {children}
    </Link>
  );
}
