export function DollarSignIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.78815 11.8135V10.4053C3.22304 10.3962 2.70123 10.346 2.22272 10.2549C1.74876 10.1592 1.3386 10.0361 0.99225 9.88574V8.42969C1.34316 8.59375 1.7761 8.74186 2.29108 8.87402C2.80605 9.00618 3.30508 9.08138 3.78815 9.09961V6.53613C3.12734 6.31283 2.58958 6.0804 2.17487 5.83887C1.76471 5.59733 1.46393 5.31706 1.27252 4.99805C1.08112 4.67448 0.985415 4.28711 0.985415 3.83594C0.985415 3.37109 1.10163 2.97233 1.33405 2.63965C1.57103 2.30241 1.89915 2.03581 2.31842 1.83984C2.74225 1.64388 3.23216 1.52767 3.78815 1.49121V0.376953H4.67682V1.4707C5.18268 1.48438 5.6498 1.54134 6.07819 1.6416C6.51113 1.7373 6.92357 1.87174 7.31549 2.04492L6.81647 3.31641C6.47467 3.17969 6.1192 3.06803 5.75006 2.98145C5.38092 2.89486 5.02317 2.83561 4.67682 2.80371V5.30566C5.23281 5.48796 5.72728 5.68392 6.16022 5.89355C6.59772 6.10319 6.93952 6.36751 7.18561 6.68652C7.43626 7.00098 7.56159 7.41341 7.56159 7.92383C7.56159 8.59375 7.31549 9.14746 6.82331 9.58496C6.33112 10.0179 5.61562 10.2777 4.67682 10.3643V11.8135H3.78815ZM4.67682 9.04492C5.11432 8.99023 5.44017 8.8763 5.65436 8.70312C5.86855 8.52539 5.97565 8.29525 5.97565 8.0127C5.97565 7.82129 5.93008 7.65951 5.83893 7.52734C5.75234 7.39518 5.61334 7.27669 5.42194 7.17188C5.23509 7.06706 4.98672 6.96452 4.67682 6.86426V9.04492ZM3.78815 4.9707V2.83105C3.52383 2.8584 3.30052 2.91309 3.11823 2.99512C2.93594 3.07715 2.79922 3.18424 2.70807 3.31641C2.61692 3.44857 2.57135 3.60579 2.57135 3.78809C2.57135 3.98861 2.61237 4.15951 2.6944 4.30078C2.77643 4.44206 2.90631 4.5651 3.08405 4.66992C3.26634 4.77474 3.50104 4.875 3.78815 4.9707Z"
        fill="#2930DA"
      />
    </svg>
  );
}
