import { z } from "zod";
import * as auth from "../auth";
import * as models from "./models";
import { ValidationErrorResponse } from "./models";

// NOTE: DO NOT CHANGE unless you are certain what you are doing
export const BASE_URL = import.meta.env.VITE_API_URL;

const strings = {
  dataError:
    "An unexpected error has occurred. Please retry or refresh the page.",
};

// custom errors
export class HTTPError extends Error {
  message: string;
  status: number;
  responseText?: string;
  validation?: ValidationErrorResponse;
  constructor({
    message,
    status,
    responseText,
    validation,
  }: {
    message: string;
    status: number;
    responseText?: string;
    validation?: ValidationErrorResponse;
  }) {
    super(message);
    this.message = message;
    this.status = status;
    this.responseText = responseText;
    this.validation = validation;
  }
}

export const genericErrorKey = "general";

export const MakePaymentNegAmStatusCode = 402 as const;
export const PaymentOverrideOrDynamicErrorStatusCode = 406 as const;
export const PaymentScriptsStatusCode = 412 as const;
export const SourceAccountDynamicErrorStatusCode = 406 as const;

// converts a fetching related error (e.g. HTTPError / ZodError / etc) into a presentable string
export function getDisplayError(error: unknown): string {
  if (!(error instanceof HTTPError)) {
    // Programmer Error
    return strings.dataError;
  }
  const errors = error.validation?.errors[genericErrorKey];
  if (errors) {
    let errorToDisplay = "";
    Object.values(errors).forEach((e, index) =>
      index + 1 >= errors.length
        ? (errorToDisplay += e)
        : (errorToDisplay += `${e} `),
    );
    return errorToDisplay;
  }
  // Programmer Error
  return strings.dataError;
}

async function parseError(
  response: Response,
  endpointName: string,
): Promise<never> {
  const responseText = await response.text();
  try {
    const validationError = models.validationErrorResponse.parse(
      JSON.parse(responseText),
    );
    throw new HTTPError({
      message: `Endpoint '${endpointName}' failed with status code ${response.status}`,
      status: response.status,
      validation: validationError,
    });
  } catch (err) {
    if (err instanceof SyntaxError) {
      // This is a text-based error, like "Invalid ID parameter"
      throw new HTTPError({
        message: `Endpoint '${endpointName}' failed with status code ${response.status} and message '${responseText}'`,
        status: response.status,
        responseText,
      });
    }

    // Could be a ZodError, like validationError was JSON but schema validation failed
    throw err;
  }
}

async function parseNetworkResponse<InputT, OutputT>(
  response: Response,
  intoModel: z.ZodType<OutputT, z.ZodTypeDef, InputT>,
  endpointName: string,
) {
  if (response.ok) {
    if (intoModel === models.unknownModel) {
      // doesn't matter really
      return intoModel.parse(await response.text());
    }
    return intoModel.parse(await response.json());
  }

  const responseText = await response.text();
  try {
    const validationError = models.validationErrorResponse.parse(
      JSON.parse(responseText),
    );
    throw new HTTPError({
      message: `Endpoint '${endpointName}' failed with status code ${response.status}`,
      status: response.status,
      validation: validationError,
    });
  } catch (err) {
    if (err instanceof SyntaxError) {
      // This is a text-based error, like "Invalid ID parameter"
      throw new HTTPError({
        message: `Endpoint '${endpointName}' failed with status code ${response.status} and message '${responseText}'`,
        status: response.status,
        responseText,
      });
    }

    // Could be a ZodError, like validationError was JSON but schema validation failed
    throw err;
  }
}

async function baseFetch({
  url,
  method,
  headers,
  body,
}: Pick<RequestInit, "body" | "headers" | "method"> & {
  url: string;
}): Promise<Response> {
  const token = await auth.getAuthorizationToken();
  const BASE_HEADERS: typeof headers = {
    Authorization: `Bearer ${token}`,
  };

  return await fetch(`${BASE_URL}/${url}`, {
    method,
    body,
    headers: {
      ...BASE_HEADERS,
      ...headers,
    },
  });
}

// endpoints
export const getAgentDetails = async () => {
  const response = await baseFetch({
    url: `my-account`,
  });
  return parseNetworkResponse(
    response,
    models.getAgentDetailsResponse,
    "getAgentDetails",
  );
};

export const getAccount = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}`,
  });
  return parseNetworkResponse(
    response,
    models.getAccountResponse,
    "getAccount",
  );
};

export const getAccountDetails = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/details`,
  });
  return parseNetworkResponse(
    response,
    models.getAccountDetailsResponse,
    "getAccountDetails",
  );
};

export const updateUsername = async ({
  partyId,
  newUsername,
}: models.UpdateUsernameRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/username`,
    method: "POST",
    body: JSON.stringify({ newUsername }),
  });
  return parseNetworkResponse(response, models.unknownModel, "updateUsername");
};

export const sendTestPushNotification = async ({
  partyId,
}: models.SendTestPushNotificationRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/push-notification`,
    method: "POST",
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "sendTestPushNotification",
  );
};

export const getAccountLockStatus = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/lock`,
  });
  return parseNetworkResponse(
    response,
    models.getAccountLockStatusResponse,
    "getAccountLockStatus",
  );
};

export const updateAccountLockStatus = async ({
  dwbuid,
}: models.UpdateAccountLockStatusRequest) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/lock`,
    method: "POST",
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "updateAccountLockStatus",
  );
};

export const getPaymentHistory = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/payments/history`,
  });
  return parseNetworkResponse(
    response,
    models.getPaymentHistoryResponse,
    "getPaymentHistory",
  );
};

export const getAutopayEnrollment = async ({
  partyId,
  dwbuid,
}: models.GetAutopayEnrollmentRequest) => {
  const searchParams = new URLSearchParams({
    dwbuid,
  });
  const response = await baseFetch({
    url: `account/${partyId}/autopay-enrollment?${searchParams}`,
  });
  return parseNetworkResponse(
    response,
    models.getAutopayEnrollmentResponse,
    "getAutopayEnrollment",
  );
};

export const getSecureMailList = async (
  req: models.GetSecureMailListRequest,
) => {
  const searchParams = new URLSearchParams({
    page: req.page.toString(),
    size: req.size.toString(),
  });
  const response = await baseFetch({
    url: `secure-mail/classification/${req.classificationId}?${searchParams}`,
  });
  return parseNetworkResponse(
    response,
    models.getSecureMailListResponse,
    "getSecureMailList",
  );
};

export const patchNextSecureMailId = async ({
  classificationId,
  mailId,
}: models.PatchNextSecureMailIdRequest) => {
  const response = await baseFetch({
    url: `secure-mail/classification/${classificationId}`,
    method: "PATCH",
    body: JSON.stringify({ mailId }),
  });
  return parseNetworkResponse(
    response,
    models.patchNextSecureMailIdResponse,
    "patchNextSecureMailId",
  );
};

export const patchSecureMailClassification = async ({
  secureMailId,
  classificationId,
}: models.PatchSecureMailClassificationRequest) => {
  const response = await baseFetch({
    url: `secure-mail/${secureMailId}/classification`,
    method: "PATCH",
    body: JSON.stringify({ classificationId: classificationId }),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "patchSecureMailClassification",
  );
};

export const getClassifications = async () => {
  const response = await baseFetch({
    url: `classifications`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return parseNetworkResponse(
    response,
    models.getClassificationsResponse,
    "getClassifications",
  );
};

export const getClassificationCount = async () => {
  const response = await baseFetch({
    url: `classification-count`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return parseNetworkResponse(
    response,
    models.getClassificationCountResponse,
    "getClassificationCount",
  );
};

export const getCardmemberMailList = async ({
  partyId,
  ...query
}: models.GetCardmemberMailListRequest) => {
  const response = await baseFetch({
    url:
      `secure-mail/card-member/${partyId}?` +
      new URLSearchParams({
        page: query.page.toString(),
        size: query.size.toString(),
        start: query.start ? query.start : "",
        end: query.end ? query.end : "",
      }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return parseNetworkResponse(
    response,
    models.getCardmemberMailListResponse,
    "getCardmemberMailList",
  );
};

export const getSecureMailThread = async ({
  secureMailId,
}: models.GetSecureMailThreadRequest) => {
  const response = await baseFetch({
    url: `secure-mail/${secureMailId}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return parseNetworkResponse(
    response,
    models.getSecureMailThreadResponse,
    "getSecureMailThread",
  );
};

export const addToThread = async (req: models.AddToThreadRequest) => {
  const data = new FormData();
  if (req.messageBody) {
    data.append("body", req.messageBody);
  }
  if (req.file) {
    data.append("file", req.file, req.file.name);
  }

  const response = await baseFetch({
    url: `secure-mail/${req.secureMailId}`,
    method: "POST",
    body: data,
  });
  return parseNetworkResponse(response, models.unknownModel, "addToThread");
};

export const composeSecureMail = async ({
  partyId,
  subject,
  classificationId,
  status,
  body,
  file,
}: models.ComposeSecureMailRequest) => {
  const data = new FormData();
  data.append("subject", subject);
  data.append("classificationId", classificationId);
  data.append("status", status);
  if (body) {
    data.append("body", body);
  }
  if (file) {
    data.append("file", file, file.name);
  }

  const response = await baseFetch({
    url: `secure-mail/${partyId}/compose`,
    method: "POST",
    body: data,
  });
  return parseNetworkResponse(
    response,
    models.composeSecureMailResponse,
    "composeSecureMail",
  );
};

export const updateSecureMailStatus = async ({
  secureMailId,
  ...reqBody
}: models.UpdateSecureMailStatusRequest) => {
  const response = await baseFetch({
    url: `secure-mail/${secureMailId}/status`,
    method: "PATCH",
    body: JSON.stringify(reqBody),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "updateSecureMailStatus",
  );
};

export const viewMailAttachment = async (
  reqBody: models.ViewMailAttachmentRequest,
) => {
  const { mailId, messageId, attachmentId } = reqBody;

  const response = await baseFetch({
    url: `secure-mail/${mailId}/message/${messageId}/attachment/${attachmentId}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return parseNetworkResponse(
    response,
    models.viewMailAttachmentResponse,
    "viewMailAttachment",
  );
};

export const enrollAutopay = async ({
  partyId,
  ...reqBody
}: models.EnrollAutopayRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/autopay-enrollment`,
    method: "POST",
    body: JSON.stringify(reqBody),
  });
  return parseNetworkResponse(response, models.unknownModel, "enrollAutopay");
};

export const removeAutopayEnrollment = async ({
  partyId,
  ...reqBody
}: models.RemoveAutopayEnrollmentRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/autopay-enrollment`,
    method: "DELETE",
    body: JSON.stringify(reqBody),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "removeAutopayEnrollment",
  );
};

export const skipAutopay = async ({
  partyId,
  ...reqBody
}: models.SkipAutopayRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/autopay-enrollment/skip`,
    method: "POST",
    body: JSON.stringify(reqBody),
  });
  return parseNetworkResponse(response, models.unknownModel, "skipAutopay");
};

export const getPaymentTransactions = async (
  req: models.GetPaymentTransactionsRequest,
) => {
  const response = await baseFetch({
    url: `account/${req.dwbuid}/payments/transactions`,
  });
  return parseNetworkResponse(
    response,
    models.getPaymentTransactionsResponse,
    "getPaymentTransactions",
  );
};

export const getPaymentMethods = async (
  req: models.GetPaymentMethodsRequest,
) => {
  const response = await baseFetch({
    url: `account/${req.partyId}/payment-methods`,
  });
  return parseNetworkResponse(
    response,
    models.getPaymentMethodsResponse,
    "getPaymentMethods",
  );
};

export const deleteSourceAccount = async ({
  partyId,
  sourceAccountId,
}: {
  partyId: string;
  sourceAccountId: string;
}) => {
  const response = await baseFetch({
    url: `account/${partyId}/payment-methods/${sourceAccountId}`,
    method: "DELETE",
  });

  return parseNetworkResponse(
    response,
    models.unknownModel,
    "deleteSourceAccount",
  );
};

export const addBankSourceAccount = async ({
  partyId,
  ...body
}: models.AddBankSourceAccountRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payment-methods/bank-account`,
    method: "POST",
    body: JSON.stringify(body),
  });

  // NOTE: B2C Source Accounts do not need to support dynamic errors

  return parseNetworkResponse(
    response,
    models.addBankSourceAccountResponse,
    "addBankSourceAccount",
  );
};

export const editBankSourceAccount = async ({
  partyId,
  sourceAccountId,
  ...body
}: models.EditBankSourceAccountRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payment-methods/bank-account/${sourceAccountId}`,
    method: "PATCH",
    body: JSON.stringify(body),
  });

  // NOTE: B2C Source Accounts do not need to support dynamic errors

  return parseNetworkResponse(
    response,
    models.editBankSourceAccountResponse,
    "editBankSourceAccount",
  );
};

export const addCardSourceAccount = async ({
  partyId,
  ...body
}: models.AddCardSourceAccountRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payment-methods/card-account`,
    method: "POST",
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return models.addCardSourceAccountResponse.parse(await response.json());
  } else if (response.status === SourceAccountDynamicErrorStatusCode) {
    throw {
      ...models.dynamicErrorResponse.parse(await response.json()),
      expectedError: true,
    };
  } else {
    return parseError(response, "addCardSourceAccount");
  }
};

export const editCardSourceAccount = async ({
  partyId,
  sourceAccountId,
  ...body
}: models.EditCardSourceAccountRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payment-methods/card-account/${sourceAccountId}`,
    method: "PATCH",
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return models.editCardSourceAccountResponse.parse(await response.json());
  } else if (response.status === SourceAccountDynamicErrorStatusCode) {
    throw {
      ...models.dynamicErrorResponse.parse(await response.json()),
      expectedError: true,
    };
  } else {
    return parseError(response, "editCardSourceAccount");
  }
};

export const makeOneTimePayment = async ({
  partyId,
  ...reqBody
}: models.MakeOneTimePaymentRequest) => {
  const body = {
    ...reqBody,
    paymentAmount: "custom",
  };
  const response = await baseFetch({
    url: `account/${partyId}/payments/onetime`,
    method: "POST",
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return models.makeOneTimePaymentResponse.parse(await response.json());
  } else if (response.status === MakePaymentNegAmStatusCode) {
    throw {
      ...models.makePaymentNegAmResponse.parse(await response.json()),
      expectedError: true,
    };
  } else if (response.status === PaymentScriptsStatusCode) {
    throw {
      ...models.paymentScriptsResponse.parse(await response.json()),
      expectedError: true,
    };
  } else if (response.status === PaymentOverrideOrDynamicErrorStatusCode) {
    const json = await response.json();
    if (json.type === models.DYNAMIC_ERROR) {
      throw { ...models.dynamicErrorResponse.parse(json), expectedError: true };
    } else {
      throw {
        ...models.paymentOverridesResponse.parse(json),
        expectedError: true,
      };
    }
  } else {
    return parseError(response, "makeOneTimePayment");
  }
};

export const makeSeriesPayment = async ({
  partyId,
  ...body
}: models.MakeSeriesPaymentRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payments/series`,
    method: "POST",
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return models.makeSeriesPaymentResponse.parse(await response.json());
  } else if (response.status === MakePaymentNegAmStatusCode) {
    throw {
      ...models.makePaymentNegAmResponse.parse(await response.json()),
      expectedError: true,
    };
  } else if (response.status === PaymentScriptsStatusCode) {
    throw {
      ...models.paymentScriptsResponse.parse(await response.json()),
      expectedError: true,
    };
  } else if (response.status === PaymentOverrideOrDynamicErrorStatusCode) {
    const json = await response.json();
    if (json.type === models.DYNAMIC_ERROR) {
      throw { ...models.dynamicErrorResponse.parse(json), expectedError: true };
    } else {
      throw {
        ...models.paymentOverridesResponse.parse(json),
        expectedError: true,
      };
    }
  } else {
    return parseError(response, "makeSeriesPayment");
  }
};

export const getPaymentSeriesSchedule = async (
  req: models.GetPaymentSeriesScheduleRequest,
) => {
  const response = await baseFetch({
    url: "payments/series-schedule",
    method: "POST",
    body: JSON.stringify(req),
  });
  return parseNetworkResponse(
    response,
    models.getPaymentSeriesScheduleResponse,
    "getPaymentSeriesSchedule",
  );
};

export const getPartyRestrictions = async (partyId: string) => {
  const response = await baseFetch({
    url: `account/${partyId}/restrictions`,
  });
  return parseNetworkResponse(
    response,
    models.getPartyRestrictionsResponse,
    "getPartyRestrictions",
  );
};

export const getBankNames = async (abaNumber: string) => {
  const response = await baseFetch({
    url: `bank-names/${abaNumber}`,
  });
  return parseNetworkResponse(
    response,
    models.getBankNamesResponse,
    "getBankNames",
  );
};

export const addBankRestriction = async ({
  partyId,
  ...body
}: models.AddRestrictionRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/restrictions/bank`,
    method: "POST",
    body: JSON.stringify(body),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "addBankRestriction",
  );
};

export const addCardRestriction = async ({
  partyId,
  ...body
}: models.AddRestrictionRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/restrictions/card`,
    method: "POST",
    body: JSON.stringify(body),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "addCardRestriction",
  );
};

export const cancelPayment = async ({
  partyId,
  paymentId,
}: {
  partyId: string;
  paymentId: string;
}) => {
  const response = await baseFetch({
    url: `account/${partyId}/payments/${paymentId}`,
    method: "DELETE",
  });
  return parseNetworkResponse(response, models.unknownModel, "cancelPayment");
};

export const editOneTimePayment = async ({
  partyId,
  paymentId,
  ...reqBody
}: models.EditOneTimePaymentRequest) => {
  const body = { ...reqBody, paymentAmount: "custom" };
  const response = await baseFetch({
    url: `account/${partyId}/payments/${paymentId}`,
    method: "PATCH",
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return models.editOneTimePaymentResponse.parse(await response.json());
  } else if (response.status === PaymentScriptsStatusCode) {
    throw {
      ...models.paymentScriptsResponse.parse(await response.json()),
      expectedError: true,
    };
  } else if (response.status === PaymentOverrideOrDynamicErrorStatusCode) {
    const json = await response.json();
    if (json.type === models.DYNAMIC_ERROR) {
      throw { ...models.dynamicErrorResponse.parse(json), expectedError: true };
    } else {
      throw {
        ...models.paymentOverridesResponse.parse(json),
        expectedError: true,
      };
    }
  } else {
    return parseError(response, "editOneTimePayment");
  }
};

export const editSeriesPayment = async ({
  partyId,
  seriesId,
  ...reqBody
}: models.EditSeriesPaymentRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payments/series/${seriesId}`,
    method: "PATCH",
    body: JSON.stringify(reqBody),
  });

  if (response.ok) {
    return models.unknownModel.parse(await response.text());
  } else if (response.status === PaymentScriptsStatusCode) {
    throw {
      ...models.paymentScriptsResponse.parse(await response.json()),
      expectedError: true,
    };
  } else if (response.status === PaymentOverrideOrDynamicErrorStatusCode) {
    const json = await response.json();
    if (json.type === models.DYNAMIC_ERROR) {
      throw { ...models.dynamicErrorResponse.parse(json), expectedError: true };
    } else {
      throw {
        ...models.paymentOverridesResponse.parse(json),
        expectedError: true,
      };
    }
  } else {
    return parseError(response, "editSeriesPayment");
  }
};

export const cancelSeriesPayment = async ({
  partyId,
  seriesId,
  ...reqBody
}: models.CancelSeriesPaymentRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/payments/series/${seriesId}`,
    method: "DELETE",
    body: JSON.stringify(reqBody),
  });

  return parseNetworkResponse(
    response,
    models.unknownModel,
    "cancelSeriesPayment",
  );
};

export const getPermissionToDiscuss = async ({
  partyId,
  includeHistory,
  includeInactive,
}: models.GetPermissionToDiscussRequest) => {
  const searchParams = new URLSearchParams({
    history: includeHistory.toString(),
    includeInactive: includeInactive.toString(),
  });
  const response = await baseFetch({
    url: `account/${partyId}/permission-to-discuss?${searchParams}`,
  });
  return parseNetworkResponse(
    response,
    models.getPermissionToDiscussResponse,
    "getPermissionToDiscuss",
  );
};

export const addPermissionToDicuss = async ({
  partyId,
  ...rest
}: models.AddPermissionToDiscussRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/permission-to-discuss`,
    method: "POST",
    body: JSON.stringify(rest),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "addPermissionToDiscuss",
  );
};

export const editPermissionToDicuss = async ({
  partyId,
  permissionId,
  ...rest
}: models.EditPermissionToDiscussRequest) => {
  const response = await baseFetch({
    url: `account/${partyId}/permission-to-discuss/${permissionId}`,
    method: "PATCH",
    body: JSON.stringify(rest),
  });

  return parseNetworkResponse(
    response,
    models.unknownModel,
    "editPermissionToDiscuss",
  );
};

export const verifyPermissionToDiscuss = async ({
  partyId,
  ...rest
}: models.VerifyPermissionToDiscussRequest): Promise<unknown> => {
  const response = await baseFetch({
    url: `account/${partyId}/permission-to-discuss/verify`,
    method: "POST",
    body: JSON.stringify(rest),
  });

  return parseNetworkResponse(
    response,
    models.unknownModel,
    "verifyPermissionToDiscuss",
  );
};

export const removePermissionToDiscuss = async ({
  partyId,
  permissionId,
  ...rest
}: models.RemovePermissionToDiscussRequest): Promise<unknown> => {
  const response = await baseFetch({
    url: `account/${partyId}/permission-to-discuss/${permissionId}`,
    method: "DELETE",
    body: JSON.stringify(rest),
  });

  return parseNetworkResponse(
    response,
    models.unknownModel,
    "deletePermissionToDiscuss",
  );
};

export const getCycleToDateTransactions = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/cycle-transactions`,
  });
  return parseNetworkResponse(
    response,
    models.getCycleToDateTransactionsResponse,
    "getCycleToDateTransactions",
  );
};

export const getStatementHistory = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/statement-history`,
  });
  return parseNetworkResponse(
    response,
    models.getStatementHistoryResponse,
    "getStatementHistory",
  );
};

export const getLostStolenOptions = async (dwbuid: string) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/lost-stolen-options`,
  });
  return parseNetworkResponse(
    response,
    models.lostStolenOptionsResponse,
    "getLostStolenOptions",
  );
};

export const submitLostStolen = async ({
  dwbuid,
  ...rest
}: models.SubmitLostStolenRequest) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/lost-stolen`,
    method: "POST",
    body: JSON.stringify(rest),
  });
  return parseNetworkResponse(
    response,
    models.unknownModel,
    "submitLostStolen",
  );
};

export const getCreditLineIncreaseEligibility = async ({
  dwbuid,
  referenceId = "",
}: models.GetCreditLineIncreaseEligibilityRequest) => {
  const searchParams = new URLSearchParams({
    referenceId,
  });
  const response = await baseFetch({
    url: `account/${dwbuid}/credit-line-increase?${searchParams}`,
  });
  return parseNetworkResponse(
    response,
    models.getCreditLineIncreaseEligibilityResponse,
    "getCreditLineIncreaseEligibility",
  );
};

export const submitCreditLineIncrease = async ({
  dwbuid,
  ...rest
}: models.SubmitCreditLineIncreaseRequest) => {
  const response = await baseFetch({
    url: `account/${dwbuid}/credit-line-increase`,
    method: "POST",
    body: JSON.stringify(rest),
  });
  return parseNetworkResponse(
    response,
    models.submitCreditLineIncreaseResponse,
    "submitCreditLineIncrease",
  );
};

export const getCallInfo = async ({
  dwbuid,
  callId,
}: models.GetCallInfoRequest) => {
  const searchParams = new URLSearchParams({
    callId,
  });
  const response = await baseFetch({
    url: `account/${dwbuid}/call-info?${searchParams}`,
    method: "GET",
  });
  return parseNetworkResponse(
    response,
    models.getCallInfoResponse,
    "getCallInfo",
  );
};
