import { useCallback, useEffect, useRef, useState } from "react";
import {
  DYNAMIC_ERROR,
  DynamicErrorResponse,
  GetPaymentMethodsResponse,
  OwnershipType,
  PaymentMethod,
  PaymentMethodType,
} from "./api/models";
import { reportError } from "./bugReporting";
import { setValidationErrorsAndDisplayGeneralErrors } from "./validation";

const strings = {
  checkingAccount: "Checking Account",
  mastercard: "Mastercard",
  visa: "Visa",
  isAuthorized: "Cardmember is Authorized",
  isOwner: "Cardmember is Owner",
  isThirdParty: "Caller is Third Party",
  debitCard: "Debit Card",
  creditCard: "Credit Card",
};

/*
Returns a new GetPaymentMethodsResponse WITHOUT mutating the
original GetPaymentMethodsResponse.
*/
export function getUpdatedSourceAccountsWithDelete(
  deletedSourceAccountId: string,
  currentSourceAccounts: GetPaymentMethodsResponse,
): GetPaymentMethodsResponse | undefined {
  let baseArray: keyof GetPaymentMethodsResponse = "cardMemberAccounts";
  let baseArrayIndex = currentSourceAccounts[baseArray].findIndex(
    (account) => account.id === deletedSourceAccountId,
  );
  if (baseArrayIndex < 0) {
    baseArray = "thirdPartyAccounts";
    baseArrayIndex = currentSourceAccounts[baseArray].findIndex(
      (account) => account.id === deletedSourceAccountId,
    );
    if (baseArrayIndex < 0) {
      reportError(
        new Error(
          "getUpdatedSourceAccountsWithDelete: Could not find source account to delete in cached data",
        ),
        {
          deletedSourceAccountId,
        },
      );
      return currentSourceAccounts;
    }
  }

  return {
    ...currentSourceAccounts,
    [baseArray]: [
      ...currentSourceAccounts[baseArray].slice(0, baseArrayIndex),
      ...currentSourceAccounts[baseArray].slice(baseArrayIndex + 1),
    ],
  };
}

/*
Returns a new GetPaymentMethodsResponse WITHOUT mutating the
original GetPaymentMethodsResponse.
*/
export function getUpdatedSourceAccountsWithAdd(
  newSourceAccount: PaymentMethod,
  currentSourceAccounts: GetPaymentMethodsResponse,
): GetPaymentMethodsResponse {
  const baseArray = isCardMemberAccount(newSourceAccount)
    ? "cardMemberAccounts"
    : "thirdPartyAccounts";
  return {
    ...currentSourceAccounts,
    [baseArray]: [newSourceAccount, ...currentSourceAccounts[baseArray]],
  };
}

/*
Returns a new GetPaymentMethodsResponse WITHOUT mutating the
original GetPaymentMethodsResponse.
*/
export function getUpdatedSourceAccountsWithEdit(
  editedSourceAccount: PaymentMethod,
  originalSourceAccountId: string,
  currentSourceAccounts: GetPaymentMethodsResponse,
): GetPaymentMethodsResponse | undefined {
  const baseArray = isCardMemberAccount(editedSourceAccount)
    ? "cardMemberAccounts"
    : "thirdPartyAccounts";
  const accountToEditIndex = currentSourceAccounts[baseArray].findIndex(
    (sourceAccount) => sourceAccount.id === originalSourceAccountId,
  );
  if (accountToEditIndex < 0) {
    reportError(
      new Error(
        "Progammer Error: Could not find original source account to update with edits",
      ),
    );
    return;
  }

  return {
    ...currentSourceAccounts,
    [baseArray]: [
      ...currentSourceAccounts[baseArray].slice(0, accountToEditIndex),
      editedSourceAccount,
      ...currentSourceAccounts[baseArray].slice(accountToEditIndex + 1),
    ],
  };
}

type Params = Pick<PaymentMethod, "ownerType">;
export function isCardMemberAccount({ ownerType }: Params) {
  // a return value of false means third_party account
  return ownerType === "ACCOUNT_OWNER" || ownerType === "AUTHORIZED_USER";
}

export const getStylizedPaymentMethodName = (paymentMethod: PaymentMethod) => {
  let accountType = strings.checkingAccount;
  if (paymentMethod.brand === "mc") {
    accountType = strings.mastercard;
  } else if (paymentMethod.brand === "visa") {
    accountType = strings.visa;
  }
  return `${accountType} \u2022\u2022\u2022\u2022 ${paymentMethod.last4}`;
};

type OwnershipTypeOptions = {
  label: string;
  value: OwnershipType;
};
export const OWNERSHIP_OPTIONS: OwnershipTypeOptions[] = [
  {
    label: strings.isOwner,
    value: "ACCOUNT_OWNER",
  },
  {
    label: strings.isAuthorized,
    value: "AUTHORIZED_USER",
  },
  {
    label: strings.isThirdParty,
    value: "THIRD_PARTY",
  },
];

type PaymentMethodTypeOption = {
  label: string;
  value: PaymentMethodType;
};
export const PAYMENT_METHOD_TYPE_OPTIONS: PaymentMethodTypeOption[] = [
  {
    label: strings.checkingAccount,
    value: "checking_account",
  },
  {
    label: strings.debitCard,
    value: "debit_card",
  },
  {
    label: strings.creditCard,
    value: "credit_card",
  },
];

export const handleAddCardSourceAccountErrorResponse = (
  err: unknown,
  setCurrentDynamicError: (dynamicError: DynamicErrorResponse) => void,
  setNetworkValidationMessages: React.Dispatch<
    React.SetStateAction<Record<string, string[]> | undefined>
  >,
  onDisplayGeneralErrors: (err: unknown) => void,
) => {
  if (err && (err as DynamicErrorResponse).type === DYNAMIC_ERROR) {
    setCurrentDynamicError(err as DynamicErrorResponse);
    return;
  }

  setValidationErrorsAndDisplayGeneralErrors(
    err,
    setNetworkValidationMessages,
    onDisplayGeneralErrors,
  );
};

export function useDynamicErrorMessages(formState: { cardNumber: string }) {
  const [currentDynamicError, _setCurrentDynamicError] =
    useState<DynamicErrorResponse>();

  const dynamicErrorOverridesRef = useRef<string[]>([]);
  const dynamicErrorViewCountsRef = useRef<Record<string, number>>({});

  const setCurrentDynamicError = useCallback(
    (dynamicErrorResponse: DynamicErrorResponse | undefined) => {
      _setCurrentDynamicError(dynamicErrorResponse);
      if (!dynamicErrorResponse) {
        return;
      }

      if (dynamicErrorViewCountsRef.current[dynamicErrorResponse.errorKey]) {
        dynamicErrorViewCountsRef.current[dynamicErrorResponse.errorKey] += 1;
      } else {
        dynamicErrorViewCountsRef.current[dynamicErrorResponse.errorKey] = 1;
      }
    },
    [],
  );

  // reset all dynamic errors when there is a different source account,
  // considering card number changes as source account changes
  // (b2c does not involve dynamic errors)
  useEffect(() => {
    dynamicErrorOverridesRef.current = [];
    dynamicErrorViewCountsRef.current = {};
  }, [formState.cardNumber]);

  return {
    currentDynamicError,
    setCurrentDynamicError,
    dynamicErrorOverridesRef,
    dynamicErrorViewCountsRef,
  };
}
