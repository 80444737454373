import classNames from "classnames";
import bankIcon from "../../../assets/account-bank.svg";
import creditIcon from "../../../assets/account-credit.svg";
import debitIcon from "../../../assets/account-debit.svg";
import expiredIcon from "../../../assets/expired.svg";
import restrictedIcon from "../../../assets/restricted.svg";
import * as models from "../../../lib/api/models";
import TextButton from "../../common/buttons/TextButton";
import styles from "./SourceAccountsRow.module.css";

const strings = {
  checkingAccount: "Checking Account",
  mastercard: "Mastercard",
  visa: "Visa",
  creditCard: "Credit Card",
  debitCard: "Debit Card",
  isDefault: " (Default)",
  aba: "ABA",
  edit: "Edit",
  lastUsed: (displayDateStr: string) => `Last Used ${displayDateStr}`,
  expired: (displayDateStr: string) => `Expired ${displayDateStr}`,
  expiredAlt: `Expired Source Account`,
  restrictedAlt: "Restricted Source Account",
};

type Props = {
  account: models.PaymentMethod;
  onEdit: (account: models.PaymentMethod) => void;
};

export default function SourceAccountsRow({ account, onEdit }: Props) {
  let imgSrc = bankIcon;
  let imgAlt = strings.checkingAccount;
  if (account.isExpired) {
    imgSrc = expiredIcon;
    imgAlt = strings.expiredAlt;
  } else if (account.hasRestrictions) {
    imgSrc = restrictedIcon;
    imgAlt = strings.restrictedAlt;
  } else if (account.type === "credit_card") {
    imgSrc = creditIcon;
    imgAlt = strings.creditCard;
  } else if (account.type === "debit_card") {
    imgSrc = debitIcon;
    imgAlt = strings.debitCard;
  }

  let accountName = strings.checkingAccount;
  if (account.brand === "mc") {
    accountName = strings.mastercard;
  } else if (account.brand === "visa") {
    accountName = strings.visa;
  }

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <div className={styles.nameContainer}>
        <div className={styles.accountName}>
          {accountName}
          {" \u2022\u2022\u2022\u2022 "}
          {account.last4}
          {account.isDefault ? strings.isDefault : null}
        </div>
        <div className={styles.userName}>
          {account.cardHolderFirstName} {account.cardHolderLastName}
        </div>
      </div>
      <div className={styles.detailsContainer}>
        {account.type === "checking_account" ? (
          <>
            <div className={styles.abaNumber}>
              {strings.aba} {account.abaNumber}
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.spacer} />
      <div className={styles.editAndDateContainer}>
        <TextButton
          className={styles.editButton}
          onClick={() => onEdit(account)}
        >
          {strings.edit}
        </TextButton>
        <div
          className={classNames(
            styles.lastUsedText,
            account.isExpired && styles.lastUsedExpired,
          )}
        >
          {strings.lastUsed(account.lastUsedDate)}
        </div>
        {(account.type === "debit_card" || account.type === "credit_card") &&
        account.isExpired &&
        account.expirationDate ? (
          <div className={styles.expiredText}>
            {strings.expired(account.expirationDate)}
          </div>
        ) : null}
      </div>
    </div>
  );
}
