import classNames from "classnames";
import {
  PaymentTransactionStatus,
  UpdateDateStatus,
} from "../../../lib/api/models";
import styles from "./ColoredLabelBox.module.css";

type ColoredLabelBoxStatus =
  | PaymentTransactionStatus
  | UpdateDateStatus
  | "old";

type Props = {
  className?: string;
  children: React.ReactNode;
  status: ColoredLabelBoxStatus;
};

const getStatusToStylesMap = (status: ColoredLabelBoxStatus): string => {
  switch (status) {
    case "processed":
      return styles.processedStatus;
    case "pending":
      return styles.pendingStatus;
    case "success":
      return styles.successStatus;
    case "danger":
      return styles.dangerStatus;
    case "old":
      return styles.oldStatus;
  }
};
export default function ColoredLabelBox({
  className,
  children,
  status,
}: Props) {
  return (
    <div
      className={classNames(
        styles.container,
        getStatusToStylesMap(status),
        className,
      )}
    >
      {children}
    </div>
  );
}
