import classNames from "classnames";
import { Col, Row } from "react-grid-system";
import { GetAccountResponse } from "../../lib/api/models";
import { useGetPaymentTransactions } from "../../lib/api/queries";
import { centsToDollarString } from "../../lib/formatting";
import { ToolState, ToolType } from "../AccountDetails";
import { EditPaymentData } from "../AccountDetails/hooks";
import { toolTypeToName } from "../AccountDetails/utils";
import StyledError from "../StyledError";
import Container from "../common/Container";
import Select from "../common/Select";
import Spinner from "../common/Spinner";
import StyledButton from "../common/buttons/StyledButton";
import TextButton from "../common/buttons/TextButton";
import styles from "./PaymentTransactions.module.css";
import PaymentTransactionsTable from "./PaymentTransactionsTable";
import { useFilter } from "./hooks";

const strings = {
  clearFilters: "Clear Filters",
  close: "Close",
  destination: "Destination",
  destinationSelectAriaLabel: "Destination Select",
  errorTitle: "Failed to load payment transactions",
  paymentType: "Payment Type",
  paymentTypeAriaLabel: "Payment Type Select",
  pendingPayments: (
    numberOfPendingPayments: number,
    totalAmountCents: number,
  ) =>
    `Pending ${numberOfPendingPayments} payment(s) totaling ${centsToDollarString(
      totalAmountCents,
    )}`,
  source: "Source",
  sourceSelectAriaLabel: "Source Select",
  status: "Status",
  statusSelectAriaLabel: "Status Select",
};

type PaymentTransactionsProps = {
  account: GetAccountResponse;
  openScheduledPayment: (editPaymentDate: EditPaymentData) => void;
  activeTools: ToolState[];
  className?: string;
  toolId: string;
  onRequestClose: () => void;
  onDisplayGeneralErrors: (error: unknown) => void;
};

export default function PaymentTransactions({
  account,
  openScheduledPayment,
  activeTools,
  className,
  toolId,
  onRequestClose,
  onDisplayGeneralErrors,
}: PaymentTransactionsProps) {
  const {
    data: getPaymentTransactions,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
  } = useGetPaymentTransactions({
    dwbuid: account.currentDwbuid,
  });

  const { filter, clearFilter, setFilterItem } = useFilter();

  const renderTitle = () => {
    return (
      <h2 className={styles.title}>
        {toolTypeToName(ToolType.PaymentTransactions)}
      </h2>
    );
  };

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isLoading || isFetching ? (
          <>
            <div className={styles.header}>{renderTitle()}</div>
            <div>
              <Spinner />
            </div>
          </>
        ) : isError ? (
          <>
            <div className={styles.header}>{renderTitle()}</div>
            <StyledError
              errorTitle={strings.errorTitle}
              error={error}
              refetch={refetch}
            />
          </>
        ) : (
          <>
            <div className={styles.header}>
              {renderTitle()}
              <div className={styles.infoBlock}>
                {strings.pendingPayments(
                  getPaymentTransactions.numberOfPendingPayments,
                  getPaymentTransactions.pendingPaymentsAmountCents,
                )}
              </div>
              <div className={styles.selectContainer}>
                <Select
                  className={styles.selectSize}
                  options={getPaymentTransactions?.statusFilters}
                  selectedValue={filter.status}
                  onChange={(newValue) => setFilterItem("status", newValue)}
                  ariaLabel={strings.statusSelectAriaLabel}
                  placeholder={strings.status}
                />
                <Select
                  className={styles.getPaymentTransactions}
                  options={getPaymentTransactions?.typeFilters}
                  selectedValue={filter.type}
                  onChange={(newValue) => setFilterItem("type", newValue)}
                  ariaLabel={strings.paymentTypeAriaLabel}
                  placeholder={strings.paymentType}
                />
                <Select
                  className={styles.selectSize}
                  options={getPaymentTransactions?.destinationFilters}
                  selectedValue={filter.destination}
                  onChange={(newValue) =>
                    setFilterItem("destination", newValue)
                  }
                  ariaLabel={strings.destinationSelectAriaLabel}
                  placeholder={strings.destination}
                />
                <Select
                  className={styles.selectSize}
                  options={getPaymentTransactions?.sourceFilters}
                  selectedValue={filter.source}
                  onChange={(newValue) => setFilterItem("source", newValue)}
                  ariaLabel={strings.sourceSelectAriaLabel}
                  placeholder={strings.source}
                />
                <TextButton onClick={() => clearFilter()}>
                  {strings.clearFilters}
                </TextButton>
              </div>
            </div>
            <Row>
              <Col md={1} offset={{ md: 11 }}>
                <StyledButton
                  className={styles.closeButton}
                  variant="secondary"
                  renderLoadingIndicator={false}
                  onClick={onRequestClose}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <PaymentTransactionsTable
                  account={account}
                  transactions={getPaymentTransactions.paymentTransactions}
                  filter={filter}
                  openScheduledPayment={openScheduledPayment}
                  activeTools={activeTools}
                  onDisplayGeneralErrors={onDisplayGeneralErrors}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
