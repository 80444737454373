import { PaymentTransaction } from "../../../../lib/api/models";
import StyledButton from "../../../common/buttons/StyledButton";
import BaseModal from "../../../common/modals/BaseModal/BaseModal";
import styles from "./CancelPaymentConfirmationModal.module.css";

type Props = {
  transactionToCancel?: PaymentTransaction;
  onConfirmCancel: () => void;
  onDeclineCancel: () => void;
};

export default function CancelPaymentConfirmationModal({
  transactionToCancel,
  onConfirmCancel,
  onDeclineCancel,
}: Props) {
  return (
    <BaseModal
      className={styles.container}
      isOpen={!!transactionToCancel}
      label={"Are you sure you want to cancel this payment?"}
      onRequestClose={onDeclineCancel}
    >
      <div className={styles.body}>
        <div className={styles.messageText}>
          {"Are you sure you want to cancel the payment with confirmation # "}
          <span className={styles.accountNameText}>
            {transactionToCancel?.confirmationId}
          </span>
          {"?"}
        </div>
        <StyledButton
          className={styles.firstButton}
          variant="danger"
          onClick={onConfirmCancel}
        >
          {`Cancel Payment # ${transactionToCancel?.confirmationId}`}
        </StyledButton>
        <StyledButton
          className={styles.secondButton}
          variant="secondary"
          onClick={onDeclineCancel}
        >
          {"Return to Payment Transactions"}
        </StyledButton>
      </div>
    </BaseModal>
  );
}
