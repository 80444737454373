import classNames from "classnames";
import eyeSlashedIcon from "../../../../assets/password-eye-slashed.svg";
import eyeIcon from "../../../../assets/password-eye.svg";

import styles from "./EyeButton.module.css";

type Props = {
  show: boolean;
  onClick: () => void;
  altShown: string;
  altHidden: string;
  className?: string;
};

export default function EyeButton({
  show,
  onClick,
  altShown,
  altHidden,
  className,
}: Props) {
  return (
    <button
      className={classNames(styles.button, className)}
      onClick={onClick}
      type="button"
    >
      <img
        src={show ? eyeSlashedIcon : eyeIcon}
        alt={show ? altHidden : altShown}
      />
    </button>
  );
}
