import { useState } from "react";
import { Col, Row } from "react-grid-system";
import warningIcon from "../../../assets/warning-icon.svg";
import {
  PermissionToDiscussPermission,
  PermissionToDiscussRelationshipType,
  ValidationErrors,
} from "../../../lib/api/models";
import { formatToDateStringInCentralTime } from "../../../lib/formatting";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../../lib/validation";
import Select from "../../common/Select";
import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import StyledInput from "../../common/inputs/StyledInput";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./EditPermissionToDiscuss.module.css";
import { P2DEdit } from "./EditPermissionToDiscuss.types";

type EditPermissionToDiscussKeyType = keyof P2DEdit;

const MAX_NAME_LENGTH = 30;

const strings = {
  edit: "Edit P2D",
  firstName: "First Name",
  lastName: "Last Name",
  relation: "Relationship",
  select: "Select",
  effectiveDate: "Effective Date",
  submit: "Submit",
  cancel: "Cancel",
  dismissChanges: "Are you sure you want to dismiss your changes?",
  dismiss: "Dismiss",
};

type Props = {
  editableData: PermissionToDiscussPermission;
  relationshipTypesById: Record<string, PermissionToDiscussRelationshipType>;
  onSubmit: (data: P2DEdit) => Promise<unknown>;
  onCancel: () => void;
  isSubmitting: boolean;
  isDisabled?: boolean;
};

export default function EditPermissionToDiscuss({
  editableData,
  relationshipTypesById,
  onSubmit,
  onCancel,
  isSubmitting,
  isDisabled,
}: Props) {
  const [dirty, setDirty] = useState(false);
  const effectiveDate = formatToDateStringInCentralTime(new Date());
  const [permission, setPermission] = useState<P2DEdit>({
    id: editableData.id,
    firstName: editableData.firstName,
    lastName: editableData.lastName,
    relationshipId: editableData.relation.id,
  });
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();

  const clearNetworkValidationMessages = (
    keys: EditPermissionToDiscussKeyType[],
  ) => {
    if (networkValidationMessages) {
      const newVal = keys.reduce((validation, key) => {
        validation[key] = [];
        return validation;
      }, {} as ValidationErrors);

      setNetworkValidationMessages((prev) => ({
        ...prev,
        ...newVal,
      }));
    }
  };

  const onUpdatePermission = (payload: Partial<P2DEdit>) => {
    const valKeys = Object.keys(payload);
    setDirty(true);
    clearNetworkValidationMessages(valKeys as EditPermissionToDiscussKeyType[]);
    setPermission((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  const submitDisabled =
    Object.values(permission).some((v) => !v.trim()) ||
    isDisabled ||
    isSubmitting ||
    !dirty ||
    (networkValidationMessages &&
      Object.values(networkValidationMessages).some((val) => val.length > 0));

  const onEditPermission = () => {
    if (submitDisabled) {
      return;
    }

    setNetworkValidationMessages(undefined);
    onSubmit(permission).catch((err) =>
      setValidationErrorsAndDisplayGeneralErrors(
        err,
        setNetworkValidationMessages,
        () => null,
      ),
    );
  };

  return (
    <>
      <Row>
        <Col>
          <div className={styles.background} />
        </Col>
      </Row>
      <Row className={styles.container}>
        <Col md={1}>
          <div className={styles.title}>{strings.edit}</div>
        </Col>
        <Col md={2}>
          <StyledInput
            value={permission.firstName}
            onChange={(e) => {
              onUpdatePermission({ firstName: e.target.value });
            }}
            label={strings.firstName}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["firstName"]
                : undefined
            }
            maxLength={MAX_NAME_LENGTH}
          />
        </Col>
        <Col md={2}>
          <StyledInput
            value={permission.lastName}
            onChange={(e) => {
              onUpdatePermission({ lastName: e.target.value });
            }}
            label={strings.lastName}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["lastName"]
                : undefined
            }
            maxLength={MAX_NAME_LENGTH}
          />
        </Col>
        <Col md={2}>
          <Select
            className={styles.select}
            contentClassName={styles.selectContent}
            selectedLabelClassName={styles.selectLabel}
            options={Object.values(relationshipTypesById).map((r) => ({
              value: r.id,
              label: r.relationship,
            }))}
            selectedValue={permission.relationshipId}
            onChange={(id) =>
              onUpdatePermission({
                relationshipId: id,
              })
            }
            placeholder={strings.select}
            label={strings.relation}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["relationshipId"]
                : undefined
            }
          />
        </Col>
        <Col md={2} className={styles.dateCol}>
          <div className={styles.dateLabel}>{strings.effectiveDate}</div>
          <div className={styles.dateValue}>{effectiveDate}</div>
        </Col>
        <Col md={2}>
          <StyledButton
            className={styles.submit}
            disabled={submitDisabled}
            onClick={onEditPermission}
            isLoading={isSubmitting}
          >
            {strings.submit}
          </StyledButton>
        </Col>
        <Col md={1}>
          <TextButton
            className={styles.cancel}
            onClick={() => {
              dirty ? setConfirmCancel(true) : onCancel();
            }}
            disabled={isSubmitting}
          >
            {strings.cancel}
          </TextButton>
        </Col>
      </Row>

      <BaseModal
        isOpen={confirmCancel}
        onRequestClose={() => setConfirmCancel(false)}
        label={strings.dismissChanges}
        className={styles.modalBody}
      >
        <ModalCloseButton
          className={styles.closeButton}
          onClick={() => setConfirmCancel(false)}
        />
        <img src={warningIcon} alt="" />
        <span>{strings.dismissChanges}</span>
        <StyledButton
          className={styles.firstButton}
          variant="danger"
          onClick={onCancel}
        >
          {strings.dismiss}
        </StyledButton>
        <StyledButton
          className={styles.secondButton}
          variant="primary"
          onClick={() => setConfirmCancel(false)}
        >
          {strings.cancel}
        </StyledButton>
      </BaseModal>
    </>
  );
}
