import classNames from "classnames";
import checkboxFilledIcon from "../../../assets/checkbox-filled.svg";
import checkboxUnfilledIcon from "../../../assets/checkbox-unfilled.svg";
import styles from "./StyledCheckbox.module.css";

const strings = {
  checked: "Checked",
  unchecked: "Unchecked",
};

type Props = React.ComponentProps<"input"> & {
  label: string;
  disabled?: boolean;
};

export default function StyledCheckbox(props: Props) {
  return (
    <label className={classNames(styles.label, props.className)}>
      <input {...props} className={styles.input} type="checkbox" />
      <img
        className={styles.icon}
        src={props.checked ? checkboxFilledIcon : checkboxUnfilledIcon}
        alt={props.checked ? strings.checked : strings.unchecked}
      />
      {props.label}
    </label>
  );
}
