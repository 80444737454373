import { Fragment } from "react";
import emptyPaymentHistory from "../../../assets/empty-payment-history.svg";
import { GetPaymentHistoryResponse } from "../../../lib/api/models";
import {
  centsToDollarString,
  convertDateStringToMonthDayYearFomat,
} from "../../../lib/formatting";
import styles from "./PaymentHistoryTable.module.css";

const strings = {
  emptyPaymentHistory: "No Payment History to display yet",
};

const columns = [
  "CYCLE DATE",
  "AMOUNT DUE",
  "DELQ AMOUNT",
  "DELQ BUCKET",
  "DUE DATE",
  "AMOUNT PAID",
  "POST DATE",
  "EFF DATE",
  "TRANSACTION",
];

type PaymentHistoryTableProps = {
  history: GetPaymentHistoryResponse;
};

export default function PaymentHistoryTable({
  history,
}: PaymentHistoryTableProps) {
  return (
    <>
      <table className={styles.paymentHistoryTable}>
        <thead>
          <tr className={styles.stickyHeaders}>
            {columns.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {history.map((row, index) => {
            const rowStyle =
              index % 2 === 1 ? styles.evenSection : styles.oddSection;

            return (
              <Fragment key={index}>
                <tr className={rowStyle} tabIndex={0}>
                  <td>{convertDateStringToMonthDayYearFomat(row.cycleDate)}</td>
                  <td>{centsToDollarString(row.amountDue, true)}</td>
                  <td>{centsToDollarString(row.amountDelq, true)}</td>
                  <td>{row.delqBucket}</td>
                  <td>{convertDateStringToMonthDayYearFomat(row.dueDate)}</td>
                  <td>
                    {row.paymentInfo[0]
                      ? centsToDollarString(row.paymentInfo[0].amountPaid, true)
                      : "--"}
                  </td>
                  <td>
                    {convertDateStringToMonthDayYearFomat(
                      row.paymentInfo[0]?.postDate,
                    )}
                  </td>
                  <td>
                    {convertDateStringToMonthDayYearFomat(
                      row.paymentInfo[0]?.effectiveDate,
                    )}
                  </td>
                  <td>{row.paymentInfo[0]?.transactionType ?? "--"}</td>
                </tr>
                {row.paymentInfo.map((paymentInfo, paymentInfoIndex) => {
                  if (paymentInfoIndex === 0) {
                    return undefined;
                  }
                  return (
                    <tr key={paymentInfoIndex} className={rowStyle}>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        {centsToDollarString(paymentInfo.amountPaid, true)}
                      </td>
                      <td>
                        {convertDateStringToMonthDayYearFomat(
                          paymentInfo.postDate,
                        )}
                      </td>
                      <td>
                        {convertDateStringToMonthDayYearFomat(
                          paymentInfo.effectiveDate,
                        )}
                      </td>
                      <td>{paymentInfo.transactionType}</td>
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      {history.length < 1 ? (
        <div className={styles.emptyPaymentHistoryContainer}>
          <img src={emptyPaymentHistory} alt="" />
          <div className={styles.emptyPaymentHistoryDescription}>
            {strings.emptyPaymentHistory}
          </div>
        </div>
      ) : null}
    </>
  );
}
