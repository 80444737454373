import classNames from "classnames";
import { GetAccountResponse } from "../../../lib/api/models";
import { useIsAgentRoleUSA } from "../../../lib/roleContext";
import { paths } from "../../../lib/router";
import StyledLinkButton from "../../common/StyledLinkButton";
import StyledButton from "../../common/buttons/StyledButton";
import styles from "./AccountFound.module.css";

const strings = {
  account: (dwbuid: string) => `Account ${dwbuid}`,
  viewAccount: "View Account",
  viewSecureMail: "View SecureMail",
};

type Props = {
  account: GetAccountResponse;
  className?: string;
  dwbuid: string;
};

function AccountFound({ account, className, dwbuid }: Props) {
  const canViewSecureMail = useIsAgentRoleUSA();

  const secureMailButton = () => {
    if (!canViewSecureMail) {
      return null;
    }
    if (account?.registrationDateTime) {
      return (
        <StyledLinkButton
          className={styles.linkButton}
          to={paths.accountSecureMail(dwbuid)}
        >
          {strings.viewSecureMail}
        </StyledLinkButton>
      );
    }
    return (
      <StyledButton
        className={styles.linkButton}
        disabled={!account?.registrationDateTime}
      >
        {strings.viewSecureMail}
      </StyledButton>
    );
  };

  return (
    <div className={classNames(styles.container, className)}>
      <h2 className={styles.account}>{strings.account(dwbuid)}</h2>
      <StyledLinkButton
        className={styles.linkButton}
        to={paths.accountDetails(dwbuid)}
      >
        {strings.viewAccount}
      </StyledLinkButton>
      {secureMailButton()}
    </div>
  );
}
export default AccountFound;
