import { ToolType } from "../../AccountDetails";
import { toolTypeToName } from "../../AccountDetails/utils";
import styles from "./SourceAccountsTitle.module.css";

export default function SourceAccountsTitle() {
  return (
    <div className={styles.title}>
      {toolTypeToName(ToolType.SourceAccounts)}
    </div>
  );
}
