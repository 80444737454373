import classNames from "classnames";
import downArrow from "../../../assets/down-arrow.svg";
import styles from "./ToolbarSummaryButton.module.css";

const strings = {
  summary: "Summary",
};

type Prop = {
  className?: string;
  completedToolsCount: number;
  onClick: () => void;
  disabled?: boolean;
};
export default function ToolSummaryButton({
  className,
  completedToolsCount: completedTools,
  onClick,
  disabled,
}: Prop) {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.button, className)}
      disabled={disabled}
      type="button"
    >
      <img
        className={classNames(styles.icon, disabled && styles.disabled)}
        src={downArrow}
        alt=""
      />
      <div className={styles.font}>{strings.summary}</div>
      <div className={classNames(styles.circle, styles.font)}>
        {completedTools}
      </div>
    </button>
  );
}
