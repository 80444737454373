import classNames from "classnames";
import { useRef, useState } from "react";
import uploadIcon from "../../../assets/attach-file.svg";
import removeIcon from "../../../assets/remove-image-icon.svg";
import sendIcon from "../../../assets/send-icon.svg";

import StyledButton from "../../common/buttons/StyledButton";
import styles from "./MessageInput.module.css";

const secureMailBodyMaxLength = 4000;

function getFileType(fileType: string) {
  const typeParts = fileType.split("/");
  if (typeParts.length > 1) {
    return typeParts[1];
  }
  return fileType;
}

type Props = {
  className?: string;
  message: string;
  attachment: File | undefined;
  onChangeMessage: (value: string) => void;
  onSend: () => void;
  onAttachment: (value: File | undefined) => void;
  isSendMessageLoading: boolean;
  isSendMessageDisabled?: boolean;
};

export default function MessageInput({
  className,
  message,
  attachment,
  onChangeMessage,
  onSend,
  onAttachment,
  isSendMessageLoading,
  isSendMessageDisabled,
}: Props) {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleClick = () => {
    if (fileInputField && fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      onAttachment(selectedFile);
    }
  };

  return (
    <div className={className}>
      {attachment && (
        <div
          className={classNames({
            [styles.fileInput]: true,
            [styles.focusedFileInput]: isFocused,
          })}
        >
          <div className={styles.uploadedFileContainer}>
            {attachment.type.split("/")[0] === "image" ? (
              <img
                src={URL.createObjectURL(attachment)}
                alt="uploaded preview"
                className={styles.attachmentPreview}
              />
            ) : (
              <span className={styles.attachmentPreview}>
                {getFileType(attachment.type).toUpperCase()}
              </span>
            )}
            <div className={styles.fileNameContainer}>
              <span className={styles.fileNameText}>{attachment.name}</span>
            </div>
            <button
              type="button"
              onClick={() => attachment && onAttachment(undefined)}
              className={styles.removeIcon}
            >
              <img src={removeIcon} alt="" />
            </button>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.messageInputContainer}>
          <textarea
            className={classNames({
              [styles.messageInput]: true,
              [styles.messageInputWithAttachments]: attachment,
            })}
            placeholder="Enter Message Here"
            value={message}
            onChange={(e) => {
              onChangeMessage(e.target.value);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            maxLength={secureMailBodyMaxLength}
            disabled={isSendMessageLoading}
            onInput={(e) => {
              const messageReplyBox = e.currentTarget;
              if (messageReplyBox) {
                messageReplyBox.setAttribute(
                  "style",
                  "height:" + messageReplyBox.scrollHeight + "px;",
                );
              }
            }}
          />
          <div className={styles.textboxButtons}>
            <button
              className={classNames(styles.uploadButton, styles.button)}
              type="button"
              onClick={handleClick}
              disabled={isSendMessageLoading || !!attachment}
            >
              <img src={uploadIcon} alt="upload file" />
            </button>
            <StyledButton
              isLoading={isSendMessageLoading}
              variant={"primary"}
              onClick={() => onSend()}
              className={styles.sendButton}
              disabled={isSendMessageDisabled}
            >
              <img src={sendIcon} alt="send message" />
            </StyledButton>
            <input
              type="file"
              accept=".pdf"
              ref={fileInputField}
              className={styles.hidden}
              disabled={isSendMessageLoading}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
