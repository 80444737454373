import { useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { reportError } from "../../lib/bugReporting";

const strings = {
  oops: "Oops!",
  unexpectedError: "Sorry, an unexpected error has occured.",
  noDetails: "No additional details available.",
};

function ErrorPage() {
  const error = useRouteError();
  useEffect(() => {
    reportError(
      new Error("Error Page has been shown, see originalError for details"),
      { originalError: error },
    );
  }, [error]);
  let errorDescription = "";
  if (isRouteErrorResponse(error)) {
    errorDescription = error.statusText;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorDescription = (error as any)?.message ?? strings.noDetails;
  }
  return (
    <>
      <header>
        <h1>{strings.oops}</h1>
      </header>
      <main>
        <p>{strings.unexpectedError}</p>
        <p>
          <i>{errorDescription}</i>
        </p>
      </main>
    </>
  );
}

export default ErrorPage;
