import { Col, Row } from "react-grid-system";
import {
  EnrollmentType,
  GetAutopayEnrollmentResponse,
} from "../../../../lib/api/models";
import Subtool from "../../../common/Subtool";
import StyledButton from "../../../common/buttons/StyledButton";
import styles from "./CreateAutopayIneligible.module.css";

const strings = {
  close: "Close",
  subheader: "Autopay Enrollment Disabled",
};

type Props = {
  onClose: () => void;
  ineligibleAutopayEnrollmentResponse: GetAutopayEnrollmentResponse & {
    enrollmentType: EnrollmentType.Ineligible;
  };
};

export function CreateAutopayIneligible({
  onClose,
  ineligibleAutopayEnrollmentResponse,
}: Props) {
  return (
    <Row>
      <Col xs={4}>
        <Subtool>
          <span className={styles.subheader}>{strings.subheader}</span>
          <div className={styles.autopayDisabledReason}>
            {ineligibleAutopayEnrollmentResponse.autopayDisabledReason}
          </div>
          <StyledButton
            className={styles.button}
            variant="secondary"
            onClick={onClose}
          >
            {strings.close}
          </StyledButton>
        </Subtool>
      </Col>
    </Row>
  );
}
