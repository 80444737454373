import { format } from "date-fns";
import { CycleToDateDeclinedTransaction } from "../../../lib/api/models";
import { centsToDollarString } from "../../../lib/formatting";
import ColoredLabelBox from "../../common/ColoredLabelBox";
import CycleToDateTransactionsEmptyTable from "../CycleToDateTransactionsEmptyTable";
import styles from "./CycleToDateTransactionsDeclinedTable.module.css";

const strings = {
  status: "Status",
  date: "Date",
  description: "Description",
  merchant: "Merchant Category",
  auth: "Auth #/POS Entry Mode",
  reset: "Reset",
  amount: "Amount",
  declined: "Declined",
};

type Props = {
  transactions: CycleToDateDeclinedTransaction[];
};

export default function CycleToDateTransactionsDeclinedTable({
  transactions,
}: Props) {
  return (
    <>
      <table className={styles.cycleToDateTransactionsDeclinedTable}>
        <thead>
          <tr>
            <th>{strings.status}</th>
            <th>{strings.date}</th>
            <th>{strings.description}</th>
            <th>{strings.merchant}</th>
            <th>{strings.auth}</th>
            <th>{strings.reset}</th>
            <th>{strings.amount}</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((t, i) => (
            <tr
              key={t.id}
              className={i % 2 === 1 ? styles.evenRow : styles.oddRow}
            >
              <td>
                <ColoredLabelBox className={styles.status} status="danger">
                  {strings.declined}
                </ColoredLabelBox>
              </td>
              <td>{format(new Date(t.date), "MM/dd/yyyy HH:mm:ss")}</td>
              <td>{t.description}</td>
              <td>{t.merchantCategories}</td>
              <td className={styles.authAndPOSContainer}>
                {`${t.authNumber && t.authNumber.length > 0 ? t.authNumber : " "}\n${t.posEntryMode && t.posEntryMode.length > 0 ? t.posEntryMode : " "}`}
              </td>
              <td>{t.reset}</td>
              <td>{centsToDollarString(t.amountCents)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {transactions.length < 1 ? <CycleToDateTransactionsEmptyTable /> : null}
    </>
  );
}
