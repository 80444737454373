import { isBefore } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { Filter } from "..";
import darkGreenCheck from "../../../assets/dark-green-check.svg";
import darkYellowClock from "../../../assets/dark-yellow-clock.svg";
import emptyPaymentTransactions from "../../../assets/empty-payment-history.svg";
import {
  GetAccountResponse,
  PaymentTransaction,
  PaymentTransactionStatus,
} from "../../../lib/api/models";
import { useCancelPayment } from "../../../lib/api/queries";
import {
  centsToDollarString,
  convertDateStringToMonthDayYearFomat,
} from "../../../lib/formatting";
import { ToolState, ToolType } from "../../AccountDetails";
import { EditPaymentData } from "../../AccountDetails/hooks";
import ColoredLabelBox from "../../common/ColoredLabelBox";
import TextButton from "../../common/buttons/TextButton";
import CancelPaymentConfirmationModal from "./CancelPaymentConfirmationModal";
import styles from "./PaymentTransactionsTable.module.css";

const strings = {
  actions: "Actions",
  agent: "AGENT",
  amount: "AMOUNT",
  confirmation: "CONFIRMATION #",
  creationDate: "CREATION DATE",
  destination: "DESTINATION",
  edit: "Edit",
  cancel: "Cancel",
  effectiveDate: "EFFECTIVE DATE",
  emptyPaymentTransactions: "No Payment Transactions to display yet",
  processingStatusType: "PROCESSED AS",
  source: "SOURCE",
  status: "STATUS",
  type: "TYPE",
  pending: "Pending",
  processed: "Processed",
};

type transactionStatusMapType = {
  [key: string]: { status: PaymentTransactionStatus; logo: React.ReactNode };
};

const transactionStatusMap: transactionStatusMapType = {
  processed: { status: "processed", logo: <img src={darkGreenCheck} alt="" /> },
  pending: { status: "pending", logo: <img src={darkYellowClock} alt="" /> },
};

function renderTableHeaderCell(label: string, onClick: () => void) {
  return (
    <button type="button" className={styles.headerCell} onClick={onClick}>
      {label}
      <div className={styles.sortIcon}>
        <div className={styles.triangleUp} />
        <div className={styles.triangleDown} />
      </div>
    </button>
  );
}

function showTransaction(transaction: PaymentTransaction, filter: Filter) {
  for (const key in filter) {
    if (
      !transaction[key as keyof Filter]
        .toString()
        .toLowerCase()
        .match(filter[key as keyof Filter].toLowerCase())
    ) {
      return false;
    }
  }
  return true;
}

const getPaymentTransactionStatusLabel = (
  status: PaymentTransactionStatus,
): string => {
  switch (status) {
    case "processed":
      return strings.processed;
    case "pending":
      return strings.pending;
  }
};

type SortField = keyof PaymentTransaction;

function sortTransactions(
  sortField: SortField,
  a: PaymentTransaction,
  b: PaymentTransaction,
) {
  {
    switch (sortField) {
      case "status":
        if (a.status === "pending" && b.status === "processed") {
          return -1;
        } else if (a.status === "processed" && b.status === "pending") {
          return 1;
        } else {
          return 0;
        }
      case "type":
      case "destination":
      case "source":
      case "amountCents":
      case "confirmationId":
      case "agent":
        if (a[sortField] < b[sortField]) {
          return -1;
        } else if (a[sortField] > b[sortField]) {
          return 1;
        } else {
          return 0;
        }
      case "processingStatusType":
        if (a.processingStatusType && b.processingStatusType) {
          if (a.processingStatusType < b.processingStatusType) {
            return -1;
          } else if (a.processingStatusType > b.processingStatusType) {
            return 1;
          } else {
            return 0;
          }
        } else if (a.processingStatusType && !b.processingStatusType) {
          return -1;
        } else if (!a.processingStatusType && b.processingStatusType) {
          return 1;
        } else {
          return 0;
        }
      case "effectiveDate":
      case "creationDateStr":
        if (isBefore(new Date(a[sortField]), new Date(b[sortField]))) {
          return -1;
        } else if (isBefore(new Date(b[sortField]), new Date(a[sortField]))) {
          return 1;
        } else {
          return 0;
        }
      default:
        return 0;
    }
  }
}

type PaymentTransactionsTableProps = {
  account: GetAccountResponse;
  transactions: PaymentTransaction[];
  filter: Filter;
  openScheduledPayment: (editPaymentData: EditPaymentData) => void;
  activeTools: ToolState[];
  onDisplayGeneralErrors: (error: unknown) => void;
};

export default function PaymentTransactionsTable({
  account,
  transactions,
  filter,
  openScheduledPayment,
  activeTools,
  onDisplayGeneralErrors,
}: PaymentTransactionsTableProps) {
  const cancelPayment = useCancelPayment();
  const isMakePaymentToolOpen = activeTools.some(
    (tool) => tool.type === ToolType.MakePayment,
  );

  const [sortDirection, setSortDirection] = useState<"ASC" | "DSC">("ASC");
  const [sortField, setSortField] = useState<SortField>("status");

  function updateSort(field: SortField) {
    if (sortField === field) {
      setSortDirection((prev) => (prev === "ASC" ? "DSC" : "ASC"));
    } else {
      setSortDirection("ASC");
    }
    setSortField(field);
  }

  const filteredAndSortedTransactions = useMemo(() => {
    let arr = transactions
      .filter((transaction) => showTransaction(transaction, filter))
      .sort((a, b) => sortTransactions(sortField, a, b));
    if (sortDirection === "DSC") {
      arr = arr.reverse();
    }
    return arr;
  }, [filter, sortDirection, sortField, transactions]);

  const editTransaction = (transactionToEdit: PaymentTransaction) => {
    let seriesPayments: PaymentTransaction[] = [];
    if (transactionToEdit.seriesId) {
      seriesPayments = transactions.filter(
        (pm) =>
          pm.seriesId === transactionToEdit.seriesId && pm.status === "pending",
      );
    }

    openScheduledPayment({
      singleTransaction: transactionToEdit,
      seriesPayments: seriesPayments.length > 0 ? seriesPayments : undefined,
    });
  };

  const [transactionToCancel, setTransactionToCancel] =
    useState<PaymentTransaction>();
  const onConfirmCancel = useCallback(() => {
    if (!transactionToCancel) {
      return;
    }
    setTransactionToCancel(undefined);
    cancelPayment
      .mutateAsync({
        partyId: account.partyId,
        paymentId: transactionToCancel.id,
      })
      .catch(onDisplayGeneralErrors);
  }, [
    account.partyId,
    cancelPayment,
    onDisplayGeneralErrors,
    transactionToCancel,
  ]);

  return (
    <>
      <table className={styles.paymentTransactionsTable}>
        <thead>
          <tr className={styles.headerStyle}>
            <th>
              {renderTableHeaderCell(strings.status, () =>
                updateSort("status"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.type, () => updateSort("type"))}
            </th>
            <th>
              {renderTableHeaderCell(strings.processingStatusType, () =>
                updateSort("processingStatusType"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.effectiveDate, () =>
                updateSort("effectiveDate"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.destination, () =>
                updateSort("destination"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.source, () =>
                updateSort("source"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.amount, () =>
                updateSort("amountCents"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.confirmation, () =>
                updateSort("confirmationId"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.creationDate, () =>
                updateSort("creationDateStr"),
              )}
            </th>
            <th>
              {renderTableHeaderCell(strings.agent, () => updateSort("agent"))}
            </th>
            <th id="table-header-actions" aria-label={strings.actions}></th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedTransactions.map((paymentTransaction) => {
            return (
              <tr
                className={styles.dataRow}
                key={paymentTransaction.confirmationId}
              >
                <td>
                  <ColoredLabelBox
                    status={
                      transactionStatusMap[paymentTransaction.status].status
                    }
                    className={styles.status}
                  >
                    {transactionStatusMap[paymentTransaction.status].logo}
                    {getPaymentTransactionStatusLabel(
                      paymentTransaction.status,
                    )}
                  </ColoredLabelBox>
                </td>
                <td>{paymentTransaction.type}</td>
                <td>{paymentTransaction.processingStatusType ?? ""}</td>
                <td>
                  {convertDateStringToMonthDayYearFomat(
                    paymentTransaction.effectiveDate,
                  )}
                </td>
                <td>{paymentTransaction.destination}</td>
                <td>{paymentTransaction.source}</td>
                <td className={styles.alignRight}>
                  {centsToDollarString(paymentTransaction.amountCents, true)}
                </td>
                <td className={styles.alignRight}>
                  {paymentTransaction.confirmationId}
                </td>
                <td>{paymentTransaction.creationDateStr}</td>
                <td>{paymentTransaction.agent}</td>
                <td>
                  {paymentTransaction.status === "pending" ? (
                    <>
                      <TextButton
                        onClick={() => editTransaction(paymentTransaction)}
                        className={styles.textButton}
                        disabled={isMakePaymentToolOpen}
                      >
                        {strings.edit}
                      </TextButton>
                      <TextButton
                        onClick={() =>
                          setTransactionToCancel(paymentTransaction)
                        }
                        className={styles.textButton}
                        disabled={cancelPayment.isLoading}
                      >
                        {strings.cancel}
                      </TextButton>
                    </>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {filteredAndSortedTransactions.length < 1 ? (
        <div className={styles.emptyPaymentTransactionsContainer}>
          <img src={emptyPaymentTransactions} alt="" />
          <div className={styles.emptyPaymentTransactionsDescription}>
            {strings.emptyPaymentTransactions}
          </div>
        </div>
      ) : null}
      <CancelPaymentConfirmationModal
        transactionToCancel={transactionToCancel}
        onConfirmCancel={onConfirmCancel}
        onDeclineCancel={() => setTransactionToCancel(undefined)}
      />
    </>
  );
}
