import classNames from "classnames";
import styles from "./SelectToolButton.module.css";

type Props = {
  inactiveIcon: string;
  activeIcon: string;
  label: string;
  active: boolean;
  onClick: NonNullable<React.ComponentProps<"button">["onClick"]>;
  className?: string;
};

const strings = {
  active: "Active",
  inactive: "Inactive",
};

export function SelectToolButton({
  inactiveIcon,
  activeIcon,
  label,
  active,
  className,
  onClick,
}: Props) {
  return (
    <button
      className={classNames(styles.button, className)}
      aria-label={`${label}, ${active ? strings.active : strings.inactive}`}
      onClick={onClick}
      disabled={active}
      type="button"
    >
      <img src={active ? activeIcon : inactiveIcon} alt="" />
      <label
        className={classNames({
          [styles.label]: true,
          [styles.activeLabel]: active,
        })}
        aria-hidden={true}
      >
        {label}
      </label>
    </button>
  );
}
