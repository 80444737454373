import classNames from "classnames";

import styles from "./LabeledInfo.module.css";

export default function LabeledInfo(props: { label: string; value: string }) {
  return (
    <div className={classNames(styles.row, styles.labeledInfo)}>
      <span className={styles.label}>{props.label}</span>
      <span className={styles.value}>{props.value}</span>
    </div>
  );
}
