import classNames from "classnames";
import { useLayoutEffect, useRef } from "react";
import { Col, Row } from "react-grid-system";
import { getDisplayError } from "../../lib/api/endpoints";
import { GetAccountResponse } from "../../lib/api/models";
import { useGetPaymentHistory } from "../../lib/api/queries";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import Container from "../common/Container";
import Spinner from "../common/Spinner";
import ToolLoadError from "../common/ToolLoadError";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./PaymentHistory.module.css";
import PaymentHistoryTable from "./PaymentHistoryTable";

const strings = { close: "Close" };

type PaymentHistoryProps = {
  account: GetAccountResponse;
  className?: string;
  toolId: string;
  onRequestClose: () => void;
};

export default function PaymentHistory({
  className,
  account,
  toolId,
  onRequestClose,
}: PaymentHistoryProps) {
  const getPaymentHistoryQuery = useGetPaymentHistory(account.currentDwbuid);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const header = headerRef.current;
    const headerStyle = header && window.getComputedStyle(header);
    const bodyStyle =
      containerRef.current && window.getComputedStyle(containerRef.current);
    const values = [
      header?.offsetHeight && header?.offsetHeight + "px",
      headerStyle?.marginTop,
      headerStyle?.marginBottom,
      bodyStyle?.paddingTop,
      bodyStyle?.paddingBottom,
    ];
    const calcString = `calc(var(--PAYMENT-HISTORY-MAX-HEIGHT) - ${values
      .filter((val) => !!val)
      .join(" - ")})`;
    if (tableContainerRef.current) {
      tableContainerRef.current.style.maxHeight = calcString;
    }
  }, []);
  return (
    <div
      className={classNames(styles.mainContainer, className)}
      ref={containerRef}
      id={toolId}
    >
      <Container>
        <Row>
          <Col md={11}>
            <h2 className={styles.header} ref={headerRef}>
              {toolTypeToName(ToolType.PaymentHistory)}
            </h2>
          </Col>
          <Col md={1}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              renderLoadingIndicator={false}
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {getPaymentHistoryQuery.isLoading ? (
              <Spinner />
            ) : getPaymentHistoryQuery.isError ? (
              <ToolLoadError
                toolName={toolTypeToName(ToolType.PaymentHistory)}
                errorDescription={getDisplayError(getPaymentHistoryQuery.error)}
                onRetry={getPaymentHistoryQuery.refetch}
              />
            ) : (
              <div className={styles.tableContainer} ref={tableContainerRef}>
                <PaymentHistoryTable history={getPaymentHistoryQuery.data} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
