import { useState } from "react";
import { Col, Row } from "react-grid-system";
import {
  GetAccountResponse,
  GetPartyRestrictionsResponse,
  GetPaymentMethodsResponse,
  PaymentMethod,
  RESTRICTION_NONE,
} from "../../../lib/api/models";
import {
  useAddBankRestriction,
  useAddCardRestriction,
} from "../../../lib/api/queries";
import Select from "../../common/Select";
import StyledButton from "../../common/buttons/StyledButton";
import SourceAccountsTable from "../SourceAccountsTable";
import SourceAccountsTitle from "../SourceAccountsTitle";
import { usePermissionBasedRestrictions } from "./ViewSourceAccounts.hooks";
import styles from "./ViewSourceAccounts.module.css";

const strings = {
  close: "Close",
  newSourceAccount: "New Source Account",
  b2c: "B2C Restricted",
  c2c: "C2C Restricted",
  save: "Save",
};

type Props = {
  cardmemberAccount: GetAccountResponse;
  onAddPaymentMethod: () => void;
  onEditPaymentMethod: (paymentMethod: PaymentMethod) => void;
  partyRestrictions: GetPartyRestrictionsResponse;
  paymentMethods: GetPaymentMethodsResponse;
  onRequestClose: () => void;
};

export default function ViewSourceAccounts({
  cardmemberAccount,
  onAddPaymentMethod,
  onEditPaymentMethod,
  partyRestrictions,
  paymentMethods,
  onRequestClose,
}: Props) {
  const addBankRestriction = useAddBankRestriction();
  const addCardRestriction = useAddCardRestriction();

  const [selectedBankRestriction, setSelectedBankRestriction] =
    useState<string>();
  const [selectedCardRestriction, setSelectedCardRestriction] =
    useState<string>();

  const currentBankRestriction =
    partyRestrictions.partyBankRestriction[0]?.restriction ?? RESTRICTION_NONE;
  const currentCardRestriction =
    partyRestrictions.partyCardRestriction[0]?.restriction ?? RESTRICTION_NONE;

  const {
    canEditBankRestrictions,
    canEditCardRestrictions,
    bankRestrictionsOptions,
    cardRestrictionsOptions,
  } = usePermissionBasedRestrictions(
    currentBankRestriction,
    currentCardRestriction,
    partyRestrictions,
  );

  return (
    <>
      <Row>
        <Col md={3}>
          <SourceAccountsTitle />
        </Col>
        <Col md={2} offset={{ md: 6 }}>
          <StyledButton
            className={styles.newAccountButton}
            variant="secondary"
            renderLoadingIndicator={false}
            onClick={onAddPaymentMethod}
          >
            {strings.newSourceAccount}
          </StyledButton>
        </Col>
        <Col md={1}>
          <StyledButton
            className={styles.closeButton}
            variant="secondary"
            renderLoadingIndicator={false}
            onClick={onRequestClose}
          >
            {strings.close}
          </StyledButton>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Select
            className={styles.select}
            label={strings.b2c}
            options={bankRestrictionsOptions}
            selectedValue={selectedBankRestriction ?? currentBankRestriction}
            onChange={(restrictionOrNone) => {
              setSelectedBankRestriction(restrictionOrNone);
            }}
            disabled={!canEditBankRestrictions}
          />
        </Col>
        <Col md={1} className={styles.saveRestrictionsColumn}>
          <StyledButton
            className={styles.saveRestrictionsButton}
            disabled={
              !selectedBankRestriction ||
              selectedBankRestriction === currentBankRestriction
            }
            isLoading={addBankRestriction.isLoading}
            onClick={() => {
              if (!selectedBankRestriction) {
                return;
              }

              addBankRestriction.mutate({
                partyId: cardmemberAccount.partyId,
                // We don't do anything special with RESTRICTION_NONE, we just
                // POST it like any other value
                restriction: selectedBankRestriction,
              });
            }}
          >
            {strings.save}
          </StyledButton>
        </Col>
        <Col md={4}>
          <Select
            className={styles.select}
            label={strings.c2c}
            options={cardRestrictionsOptions}
            selectedValue={selectedCardRestriction ?? currentCardRestriction}
            onChange={(restrictionOrNone) => {
              setSelectedCardRestriction(restrictionOrNone);
            }}
            disabled={!canEditCardRestrictions}
          />
        </Col>
        <Col md={1} className={styles.saveRestrictionsColumn}>
          <StyledButton
            className={styles.saveRestrictionsButton}
            disabled={
              !selectedCardRestriction ||
              selectedCardRestriction === currentCardRestriction
            }
            isLoading={addCardRestriction.isLoading}
            onClick={() => {
              if (!selectedCardRestriction) {
                return;
              }

              addCardRestriction.mutate({
                partyId: cardmemberAccount.partyId,
                // We don't do anything special with RESTRICTION_NONE, we just
                // POST it like any other value
                restriction: selectedCardRestriction,
              });
            }}
          >
            {strings.save}
          </StyledButton>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <div className={styles.divider} />
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <SourceAccountsTable
            type="cardmember"
            accounts={paymentMethods.cardMemberAccounts}
            onEdit={onEditPaymentMethod}
          />
        </Col>
        <Col md={5}>
          <SourceAccountsTable
            type="third_party"
            accounts={paymentMethods.thirdPartyAccounts}
            onEdit={onEditPaymentMethod}
          />
        </Col>
      </Row>
    </>
  );
}
