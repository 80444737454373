import { PaymentMethod } from "../../../lib/api/models";
import SourceAccountsRow from "../SourceAccountsRow";
import styles from "./SourceAccountsTable.module.css";

const strings = {
  cardmember: "Cardmember",
  thirdParty: "Third Party",
  sourceAccounts: "Source Accounts",
};

type Props = {
  className?: string;
  type: "cardmember" | "third_party";
  accounts: PaymentMethod[];
  onEdit: (accountId: PaymentMethod) => void;
};

export default function SourceAccountsTable(props: Props) {
  let typeText = "";
  if (props.type === "cardmember") {
    typeText = strings.cardmember;
  } else if (props.type === "third_party") {
    typeText = strings.thirdParty;
  }

  return (
    <div className={props.className}>
      <div className={styles.header}>
        {typeText} {strings.sourceAccounts}
      </div>
      <div>
        {props.accounts.map((a) => (
          <SourceAccountsRow key={a.id} account={a} onEdit={props.onEdit} />
        ))}
      </div>
    </div>
  );
}
