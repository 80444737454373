import classNames from "classnames";
import styles from "./InfoValueTable.module.css";

type InfoValue = {
  label: string;
  value: string;
};

type InfoValueTableProps = {
  data: InfoValue[];
} & (
  | {
      headerType: "large";
      title: string;
      subtitle?: string;
      rightText?: string;
    }
  | {
      headerType: "small";
      title: string;
    }
  | {
      headerType?: "none";
    }
);

export default function InfoValueTable(props: InfoValueTableProps) {
  function renderHeader() {
    if (props.headerType === "small") {
      return (
        <div className={classNames(styles.boldText, styles.header)}>
          {props.title}
        </div>
      );
    } else if (props.headerType === "large") {
      return (
        <div className={classNames(styles.largeHeaderHeight, styles.header)}>
          <div>
            <div className={classNames(styles.boldText, styles.largeText)}>
              {props.title}
            </div>
            {props.subtitle}
          </div>
          {props.rightText && (
            <div className={classNames(styles.boldText, styles.alignRight)}>
              {props.rightText}
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      {props.data.map((infoValue) => (
        <div className={styles.row} key={infoValue.label}>
          {infoValue.label}
          <div className={classNames(styles.boldText, styles.alignRight)}>
            {infoValue.value}
          </div>
        </div>
      ))}
    </div>
  );
}
