import classNames from "classnames";
import { ReactNode } from "react";
import ReactModal from "react-modal";
import styles from "./BaseModal.module.css";

type Props = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  label: string;
  onRequestClose: () => void;
};
const BaseModal = ({
  children,
  className,
  isOpen,
  label,
  onRequestClose,
}: Props) => {
  return (
    <ReactModal
      contentLabel={label}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      overlayClassName={classNames(styles.overlay)}
      className={classNames(styles.modal, className)}
    >
      {children}
    </ReactModal>
  );
};

export default BaseModal;
