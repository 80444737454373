import classNames from "classnames";
import { format, isBefore, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import warningIcon from "../../assets/warning-icon.svg";
import { getDisplayError } from "../../lib/api/endpoints";
import {
  DateString,
  GetAccountResponse,
  PermissionToDiscussPermission,
  ValidationErrors,
} from "../../lib/api/models";
import {
  useAddPermissionToDiscuss,
  useEditPermissionToDiscuss,
  useGetPermissionToDiscuss,
  useRemovePermissionToDicuss,
  useVerifyPermissionToDiscuss,
} from "../../lib/api/queries";
import { convertDateStringToMonthDayYearFomat } from "../../lib/formatting";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../lib/validation";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import StyledError from "../StyledError";
import Container from "../common/Container";
import DateInput from "../common/DateInput";
import Spinner from "../common/Spinner";
import StyledCheckbox from "../common/StyledCheckbox";
import StyledButton from "../common/buttons/StyledButton";
import { NetworkErrorModal } from "../common/modals";
import BaseModal from "../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../common/modals/ModalCloseButton/ModalCloseButton";
import AddPermissionToDiscuss from "./AddPermissionToDiscuss";
import { AddPermisionToDiscuss } from "./AddPermissionToDiscuss/AddPermissionToDiscuss.types";
import EditPermissionToDiscuss from "./EditPermissionToDiscuss";
import { P2DEdit } from "./EditPermissionToDiscuss/EditPermissionToDiscuss.types";
import styles from "./PermissionToDiscuss.module.css";
import PermissionToDiscussTable from "./PermissionToDiscussTable";

const strings = {
  includeInactive: "Include Inactive",
  includeHistory: "Show History",
  sortByName: "Sort by Name",
  lastVerifiedDate: "Last Verified Date",
  verify: "Verify",
  addP2D: "Add P2D",
  close: "Close",
  errorTitle: "Failed to load Permission to Discuss",
  removePermissionToDiscuss:
    "Are you sure you want to remove this person from Permission to Discuss?",
  removeFromP2D: "Remove from P2D",
  cancel: "Cancel",
  removeP2DLabel: "Remove Permission to Discuss?",
  neverVerified: "Never Verified",
  removedDate: "Removal Effective Date",
};

const MIN_EFFECTIVE_DATE = "1987-05-22";

type Props = {
  account: GetAccountResponse;
  className?: string;
  toolId: string;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  onRequestClose: () => void;
};

export default function PermissionToDiscuss({
  account,
  className,
  toolId,
  onSetToolInProgress,
  onRequestClose,
}: Props) {
  const [includeInactive, setIncludeInactive] = useState(false);
  const [includeHistory, setIncludeHistory] = useState(false);
  const [sortByName, setSortByName] = useState(false);
  const [adding, setAdding] = useState(false);
  const [permissionToEditId, setPermissionToEditId] = useState("");
  const [removePermissionId, setRemovePermissionId] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [removedDate, setRemovedDate] = useState<DateString>("");
  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();

  const {
    data: getPermissionToDiscussResponse,
    isLoading: isGetPermissionToDiscussResponseLoading,
    isError: isGetPermissionToDiscussResponseError,
    error: getPermissionToDiscussResponseError,
    refetch: getPermissionToDiscussResponseRefetch,
  } = useGetPermissionToDiscuss({
    partyId: account.partyId,
    includeHistory: includeHistory,
    includeInactive,
  });

  const { mutateAsync: addPermissionToDiscuss, isLoading: isAdding } =
    useAddPermissionToDiscuss({
      partyId: account.partyId,
      includeHistory,
      includeInactive,
    });

  const { mutateAsync: editPermissionToDiscuss, isLoading: isEditing } =
    useEditPermissionToDiscuss({
      partyId: account.partyId,
      includeHistory,
      includeInactive,
    });

  const {
    mutateAsync: verifyPermissionToDiscuss,
    isLoading: isVerificationInProgress,
  } = useVerifyPermissionToDiscuss({
    partyId: account.partyId,
    includeHistory,
    includeInactive,
  });

  const { mutateAsync: removePermissionToDiscuss, isLoading: isRemoving } =
    useRemovePermissionToDicuss({
      partyId: account.partyId,
      includeHistory,
      includeInactive,
    });

  useEffect(() => {
    if (adding || permissionToEditId) {
      onSetToolInProgress(toolId, true);
    } else {
      onSetToolInProgress(toolId, false);
    }
  }, [adding, permissionToEditId, onSetToolInProgress, toolId]);

  const onAddPermissionToDiscuss = ({
    firstName,
    lastName,
    relationshipId,
    effectiveDate,
  }: AddPermisionToDiscuss): Promise<unknown> => {
    if (isAdding) {
      return new Promise<unknown>(() => null);
    }

    return addPermissionToDiscuss({
      dwbuid: account.currentDwbuid,
      effectiveDate,
      firstName,
      lastName,
      partyId: account.partyId,
      relationshipId,
    })
      .then(() => setAdding(false))
      .catch((err) => {
        setDisplayError(getDisplayError(err));
        throw err;
      });
  };

  const onEditPermission = (permissionToEdit: P2DEdit): Promise<unknown> => {
    if (isEditing || !permissionToEditId) {
      return new Promise<unknown>(() => null);
    }

    return editPermissionToDiscuss({
      partyId: account.partyId,
      permissionId: permissionToEdit.id,
      dwbuid: account.currentDwbuid,
      firstName: permissionToEdit.firstName,
      lastName: permissionToEdit.lastName,
      relationshipId: permissionToEdit.relationshipId,
    })
      .then(() => setPermissionToEditId(""))
      .catch((err) => {
        setDisplayError(getDisplayError(err));
        throw err;
      });
  };

  const verifyP2D = () => {
    if (isVerificationInProgress) {
      return;
    }

    verifyPermissionToDiscuss({
      partyId: account.partyId,
      dwbuid: account.currentDwbuid,
    }).catch((err) => setDisplayError(getDisplayError(err)));
  };

  const clearRemovedDateValidation = () => {
    if (networkValidationMessages) {
      setNetworkValidationMessages((prev) => ({
        ...prev,
        ["removedDate"]: [],
      }));
    }
  };

  const onCloseRemoveP2DModal = () => {
    setRemovePermissionId("");
    setRemovedDate("");
    clearRemovedDateValidation();
  };

  const onRemovePermissionToDiscuss = () => {
    if (isRemoving || !removePermissionId || !removedDate) {
      return;
    }

    clearRemovedDateValidation();

    removePermissionToDiscuss({
      partyId: account.partyId,
      permissionId: removePermissionId,
      removedDate,
    })
      .then(() => {
        setRemovePermissionId("");
        setRemovedDate("");
      })
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          () => null,
        ),
      );
  };

  let permissionToRemoveModalName = "";
  if (removePermissionId && getPermissionToDiscussResponse) {
    const removeData =
      getPermissionToDiscussResponse.permissionsById[removePermissionId];
    permissionToRemoveModalName = `${removeData?.firstName} ${removeData?.lastName}`;
  }

  function renderTitle() {
    return (
      <h2 className={styles.header}>
        {toolTypeToName(ToolType.PermissionToDiscuss)}
      </h2>
    );
  }

  let sortedPermissionsList = getPermissionToDiscussResponse?.permissionsList;
  if (sortedPermissionsList && sortByName) {
    sortedPermissionsList = [...sortedPermissionsList].sort((a, b) => {
      const currLastName = a.lastName.toLowerCase();
      const nextLastName = b.lastName.toLowerCase();
      if (currLastName < nextLastName) {
        return -1;
      } else if (currLastName > nextLastName) {
        return 1;
      } else {
        const currFirstName = a.firstName.toLowerCase();
        const nextFirstName = b.firstName.toLowerCase();
        if (currFirstName < nextFirstName) {
          return -1;
        } else if (currFirstName > nextFirstName) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetPermissionToDiscussResponseLoading ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Spinner />
            </Row>
          </>
        ) : isGetPermissionToDiscussResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <StyledError
                  errorTitle={strings.errorTitle}
                  error={getPermissionToDiscussResponseError}
                  refetch={getPermissionToDiscussResponseRefetch}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={2}>{renderTitle()}</Col>
              <Col md={4}>
                <StyledCheckbox
                  className={styles.checkbox}
                  label={strings.includeInactive}
                  checked={includeInactive}
                  onChange={(e) => setIncludeInactive(e.target.checked)}
                />
                <StyledCheckbox
                  className={styles.checkbox}
                  label={strings.includeHistory}
                  checked={includeHistory}
                  onChange={(e) => setIncludeHistory(e.target.checked)}
                />
                <StyledCheckbox
                  className={styles.checkbox}
                  label={strings.sortByName}
                  checked={sortByName}
                  onChange={(e) => setSortByName(e.target.checked)}
                />
              </Col>
              <Col md={2}>
                <div className={styles.lastVerifiedLabel}>
                  {strings.lastVerifiedDate}
                </div>
                <div
                  className={classNames({
                    [styles.lastVerifiedValue]: true,
                    [styles.outdated]:
                      !getPermissionToDiscussResponse.lastVerifiedDate ||
                      isBefore(
                        new Date(
                          getPermissionToDiscussResponse.lastVerifiedDate,
                        ),
                        subMonths(new Date(), 6),
                      ),
                  })}
                >
                  {getPermissionToDiscussResponse.lastVerifiedDate
                    ? convertDateStringToMonthDayYearFomat(
                        getPermissionToDiscussResponse.lastVerifiedDate,
                      )
                    : strings.neverVerified}
                </div>
              </Col>
              <Col md={1}>
                <StyledButton
                  className={styles.button}
                  variant="secondary"
                  onClick={verifyP2D}
                  disabled={isVerificationInProgress}
                >
                  {strings.verify}
                </StyledButton>
              </Col>
              <Col md={2}>
                <StyledButton
                  className={styles.addButton}
                  variant="secondary"
                  onClick={() => setAdding(true)}
                  disabled={adding || !!permissionToEditId}
                >
                  {strings.addP2D}
                </StyledButton>
              </Col>
              <Col md={1}>
                <StyledButton
                  className={styles.button}
                  variant="secondary"
                  onClick={onRequestClose}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.divider} />
              </Col>
            </Row>
            {adding && !permissionToEditId ? (
              <AddPermissionToDiscuss
                onSubmit={onAddPermissionToDiscuss}
                onCancel={() => setAdding(false)}
                relationshipTypesById={
                  getPermissionToDiscussResponse.relationshipTypesById
                }
                isSubmitting={isAdding}
                isDisabled={isEditing}
              />
            ) : null}
            {permissionToEditId && !adding ? (
              <EditPermissionToDiscuss
                onSubmit={onEditPermission}
                onCancel={() => setPermissionToEditId("")}
                editableData={
                  getPermissionToDiscussResponse.permissionsById[
                    permissionToEditId
                  ]
                }
                relationshipTypesById={
                  getPermissionToDiscussResponse.relationshipTypesById
                }
                isSubmitting={isEditing}
                isDisabled={isAdding}
              />
            ) : null}
            <Row>
              <Col>
                <PermissionToDiscussTable
                  permissions={
                    sortedPermissionsList as PermissionToDiscussPermission[]
                  }
                  onEdit={setPermissionToEditId}
                  onRemove={setRemovePermissionId}
                  disableEditing={adding || !!permissionToEditId}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
      <NetworkErrorModal
        message={displayError}
        onRequestClose={() => setDisplayError("")}
      />
      <BaseModal
        isOpen={!!removePermissionId}
        label={strings.removeP2DLabel}
        onRequestClose={onCloseRemoveP2DModal}
        className={styles.modal}
      >
        <ModalCloseButton
          onClick={onCloseRemoveP2DModal}
          className={styles.modalCloseButton}
        />
        <img src={warningIcon} alt="" />
        <div className={styles.modalDesc}>
          {strings.removePermissionToDiscuss}
        </div>
        <h5>{permissionToRemoveModalName}</h5>
        <DateInput
          className={styles.removedDate}
          labelClassName={styles.dateLabel}
          inputClassName={styles.dateInput}
          label={strings.removedDate}
          value={removedDate}
          onChangeDate={(d) => {
            clearRemovedDateValidation();
            setRemovedDate(d);
          }}
          validationMessages={
            networkValidationMessages
              ? networkValidationMessages["removedDate"]
              : undefined
          }
          min={MIN_EFFECTIVE_DATE}
          max={format(new Date(), "yyyy-MM-dd").toString() as DateString}
        />
        <StyledButton
          variant="danger"
          className={styles.modalRemove}
          onClick={onRemovePermissionToDiscuss}
          disabled={!removedDate}
        >
          {strings.removeFromP2D}
        </StyledButton>
        <StyledButton
          variant="primary"
          className={styles.modalCancel}
          onClick={onCloseRemoveP2DModal}
        >
          {strings.cancel}
        </StyledButton>
      </BaseModal>
    </div>
  );
}
