import downloadIcon from "../../../assets/download-icon-active.svg";
import disabledDownloadIcon from "../../../assets/download-icon-inactive.svg";
import { SecureMailAttachment } from "../../../lib/api/models";
import styles from "./AttachmentButton.module.css";

type AttachmentButtonProps = {
  onAttachmentClick: (attachmentId: string) => void;
  attachment: SecureMailAttachment;
  disabled?: boolean;
};

export default function AttachmentButton(props: AttachmentButtonProps) {
  const { disabled, attachment, onAttachmentClick } = props;
  return (
    <button
      disabled={disabled}
      onClick={() => {
        onAttachmentClick(attachment.id);
      }}
      className={styles.button}
      type="button"
    >
      <span className={styles.label}>{attachment.name}</span>
      <img src={disabled ? disabledDownloadIcon : downloadIcon} alt="" />
    </button>
  );
}
