import {
  ContactId,
  ShipReplacementTo,
  ReplacementType,
  TypeOfLoss,
} from "./FieldIds";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import Container from "../common/Container";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./LostStolen.module.css";
import LostStolenForm from "./LostStolenForm";
import { DateString, GetAccountResponse } from "../../lib/api/models";
import {
  useGetLostStolenOptions,
  useSubmitLostStolen,
} from "../../lib/api/queries";
import Spinner from "../common/Spinner";
import StyledError from "../StyledError";
import { getDisplayError } from "../../lib/api/endpoints";
import { NetworkErrorModal } from "../common/modals";

const strings = {
  close: "Close",
  errorTitle: "Failed to load Lost/Stolen",
  successful: "Lost Report Successful",
  ok: "OK",
};

export type FormState = {
  contactId?: ContactId;
  cardInPossession?: boolean;
  typeOfLoss?: TypeOfLoss;
  fraudOnAccount?: boolean;
  transferToSecurity?: boolean;
  cid?: string;
  bestWayToContact?: string;
  shipReplacementTo?: ShipReplacementTo;
  addressChangeToday?: boolean;
  newAddressVerify?: boolean;
  cid2?: string;
  tempAddressVerify?: boolean;
  reasonForTempAddress?: string;
  tempAddress?: {
    address1?: string;
    address2?: string;
    zip?: string;
    city?: string;
    state?: string;
  };
  replacementOption?: ReplacementType;
  waiveReplacementFee?: boolean;
  waiveRushFee?: boolean;
  dateLost?: DateString;
  lossLocation?: string;
  stateOfLoss?: string;
  pinCompromised?: boolean;
  fraudCharges?: string;
};

type Props = {
  className?: string;
  toolId: string;
  account: GetAccountResponse;
  onRequestClose: (bypassProgressConfirmation?: boolean) => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
};

export default function LostStolen({
  className,
  toolId,
  account,
  onRequestClose,
  onSetToolInProgress,
}: Props) {
  const [formState, setFormState] = useState<FormState>({});
  const [displayError, setDisplayError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const {
    data: getLostStolenOptionsResponse,
    isLoading: isGetLostStolenOptionsResponseLoading,
    isError: isGetLostStolenOptionsResponseError,
    error: getLostStolenOptionsResponseError,
    refetch: getLostStolenOptionsResponseRefetch,
  } = useGetLostStolenOptions(account.currentDwbuid);

  useEffect(() => {
    if (
      formState.contactId &&
      formState.contactId !== ContactId.Merchant &&
      formState.contactId !== ContactId.ThirdPartyUnknownToCM
    ) {
      onSetToolInProgress(toolId, true);
    }
  }, [formState.contactId, onSetToolInProgress, toolId]);

  const { mutateAsync: submitLostStolen, isLoading: isSubmitting } =
    useSubmitLostStolen();

  function onSubmit() {
    if (
      !formState.contactId ||
      !formState.typeOfLoss ||
      !formState.shipReplacementTo ||
      !formState.replacementOption ||
      formState.waiveReplacementFee === undefined ||
      !formState.dateLost ||
      !formState.lossLocation ||
      !formState.stateOfLoss ||
      formState.pinCompromised === undefined
    ) {
      return;
    }

    submitLostStolen({
      dwbuid: account.currentDwbuid,
      addressChange: !!formState.addressChangeToday,
      cid: formState.cid ?? "",
      contactId: formState.contactId,
      dateLost: formState.dateLost,
      fraudCharges: formState.fraudCharges ?? "",
      lossLocationId: formState.lossLocation,
      lossStateId: formState.stateOfLoss,
      pinCompromised: formState.pinCompromised,
      replacementOptionId: formState.replacementOption,
      shipToId: formState.shipReplacementTo,
      typeOfLossId: formState.typeOfLoss,
      waiveReplacementFee: formState.waiveReplacementFee,
      waiveRushFee: formState.waiveRushFee ?? false,
    })
      .then(() => {
        onSetToolInProgress(toolId, false);
        setSubmitted(true);
        // TODO: show script
        // TODO: show emergency replacement card checkbox
      })
      .catch((err) => setDisplayError(getDisplayError(err)));
  }

  function renderTitle() {
    return (
      <h2 className={styles.header}>{toolTypeToName(ToolType.LostStolen)}</h2>
    );
  }

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetLostStolenOptionsResponseLoading ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </>
        ) : isGetLostStolenOptionsResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <StyledError
                  errorTitle={strings.errorTitle}
                  error={getLostStolenOptionsResponseError}
                  refetch={getLostStolenOptionsResponseRefetch}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={11}>{renderTitle()}</Col>
              <Col md={1}>
                <StyledButton
                  className={styles.closeButton}
                  variant="secondary"
                  onClick={() => onRequestClose()}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {submitted ? (
                  <div>
                    <div className={styles.successful}>
                      {strings.successful}
                    </div>
                    {/* TODO: emergency replacmeent checkbox */}
                    <StyledButton onClick={() => onRequestClose()}>
                      {strings.ok}
                    </StyledButton>
                  </div>
                ) : (
                  <LostStolenForm
                    closeTool={() => onRequestClose(true)}
                    formState={formState}
                    setFormState={setFormState}
                    options={getLostStolenOptionsResponse}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                  />
                )}
              </Col>
              <Col md={6}>TODO: scripts here</Col>
            </Row>
          </>
        )}
      </Container>
      <NetworkErrorModal
        message={displayError}
        onRequestClose={() => setDisplayError("")}
      />
    </div>
  );
}
