import classNames from "classnames";
import { SecureMailAttachment } from "../../../lib/api/models";
import {
  formatInCentralTime,
  getEmployeeFullName,
} from "../../../lib/formatting";
import LabeledInfo from "../../common/LabeledInfo";
import AgentInitials from "../AgentInitials";
import AttachmentButton from "../AttachmentButton";
import styles from "./MessageBubble.module.css";

const strings = {
  accountId: "Account ID:",
  cardmember: "CM:",
  employeeId: "Employee:",
  readOn: (formattedTimeRead: string) => `Read on ${formattedTimeRead}`,
};

type Props = {
  timestamp: Date;
  message: string;
  dwbuid: string;
  isEmployee: boolean;
  employeeFirstName?: string | null;
  employeeLastName?: string | null;
  className?: string;
  attachments?: SecureMailAttachment[];
  onAttachmentClick: (attachmentId: string) => void;
  attachmentButtonDisabled?: boolean;
  timeRead?: Date | null;
};

export default function MessageBubble(props: Props) {
  const formattedTimestamp = formatInCentralTime(
    props.timestamp,
    "MM/dd/yy hh:mm a",
  );

  const formattedTimeRead = props.timeRead
    ? formatInCentralTime(props.timeRead, "MM/dd/yy hh:mm a")
    : null;

  const employeeFullname = getEmployeeFullName(
    props.isEmployee,
    props.employeeFirstName,
    props.employeeLastName,
  );

  return (
    <div
      className={classNames(
        styles.container,
        props.isEmployee ? styles.outgoing : styles.incoming,
        props.className,
      )}
    >
      <div className={styles.bubble}>
        <span className={classNames(styles.row, styles.timestamp)}>
          {formattedTimestamp}
          {formattedTimeRead ? (
            <div>{strings.readOn(formattedTimeRead)}</div>
          ) : null}
        </span>
        {props.attachments ? (
          <div className={styles.attachmentsContainer}>
            {props.attachments.map((attachment) => {
              return (
                <AttachmentButton
                  disabled={props.attachmentButtonDisabled}
                  key={attachment.id}
                  onAttachmentClick={props.onAttachmentClick}
                  attachment={attachment}
                />
              );
            })}
          </div>
        ) : null}
        {props.message ? (
          <p className={styles.message}>{props.message}</p>
        ) : null}
        <div className={styles.labeledInfoRow}>
          <LabeledInfo label={strings.accountId} value={props.dwbuid} />
          {employeeFullname && (
            <LabeledInfo label={strings.employeeId} value={employeeFullname} />
          )}
        </div>
      </div>
      {props.isEmployee && props.employeeFirstName && props.employeeLastName ? (
        <AgentInitials
          firstName={props.employeeFirstName}
          lastName={props.employeeLastName}
        />
      ) : null}
    </div>
  );
}
