import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { getDisplayError } from "../../lib/api/endpoints";
import {
  ClassificationOptions,
  SecureMailConversationType,
} from "../../lib/api/models";
import {
  useComposeSecureMail,
  useGetAccount,
  useGetClassifications,
} from "../../lib/api/queries";
import { useSecureMailRedirectWithoutRole } from "../../lib/routeRedirect.hooks";
import { paths } from "../../lib/router";
import MessageInput from "../../modules/SecureMail/MessageInput";
import MessagesHeader from "../../modules/SecureMail/MessagesHeader";
import StyledError from "../../modules/StyledError";
import StyledLink from "../../modules/StyledLink";
import Select from "../../modules/common/Select";
import Spinner from "../../modules/common/Spinner";
import StyledInput from "../../modules/common/inputs/StyledInput";
import { NetworkErrorModal } from "../../modules/common/modals";
import styles from "./ComposeSecureMailPage.module.css";

const strings = {
  back: "< Back",
  classification: "Classification",
  errorTitle: "Compose SecureMail failed to load",
  retry: "Retry",
  selectClassificationAriaLabel: "assign-classification-select",
  selectStatusAriaLabel: "assign-status-select",
  subject: "Subject",
};

const SECURE_MAIL_SUBJECT_MAX_LENGTH = 40;

type StatusListItem = {
  id: SecureMailConversationType;
  description: string;
};

const statusList: StatusListItem[] = [
  {
    id: "NEW",
    description: "New",
  },
  {
    id: "OPEN",
    description: "Open",
  },
  {
    id: "READONLY",
    description: "Read Only",
  },
];

type Props = {
  dwbuid: string;
};

export default function ComposeSecureMailPage({ dwbuid }: Props) {
  useSecureMailRedirectWithoutRole();
  const navigate = useNavigate();

  const [selectedClassification, setSelectedClassification] =
    useState<string>();
  const [selectedStatus, setSelectedStatus] =
    useState<SecureMailConversationType>("NEW");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [messageAttachment, setMessageAttachment] = useState<File>();
  const [displayError, setDisplayError] = useState<string>();

  const {
    data: account,
    isError: isGetAccountError,
    error: getAccountError,
    isLoading: isGetAccountLoading,
    isFetching: isGetAccountFetching,
    refetch: getAccountRefetch,
  } = useGetAccount(dwbuid);

  const {
    mutateAsync: composeSecureMail,
    isLoading: isComposeSecureMailLoading,
  } = useComposeSecureMail();

  const partyId = isGetAccountFetching ? undefined : account?.partyId;

  const canSendMessage =
    !!partyId &&
    subject.trim() !== "" &&
    (message.trim() !== "" || messageAttachment !== undefined) &&
    selectedClassification !== undefined;

  const onSubmit = () => {
    if (!canSendMessage || selectedClassification === undefined) {
      return;
    }

    composeSecureMail({
      partyId,
      subject,
      classificationId: selectedClassification,
      status: selectedStatus,
      body: message,
      file: messageAttachment,
    })
      .then((response) => {
        navigate({
          pathname: paths.secureMail(response.id),
        });
      })
      .catch((err) => setDisplayError(getDisplayError(err)));
  };

  const {
    data: classificationsList,
    isLoading: isGetClassificationsLoading,
    isError: isGetClassificationsError,
    error: getClassificationsError,
    refetch: getClassificationsRefetch,
    isFetching: isGetClassificationsFetching,
  } = useGetClassifications();

  const classificationsToParse = classificationsList?.classifications ?? [];
  const classifications: ClassificationOptions[] = classificationsToParse.map(
    (classification) => ({
      label: classification.description,
      value: classification.id,
    }),
  );

  const statusOptions = statusList.map((status) => ({
    label: status.description,
    value: status.id,
  }));

  useEffect(() => {
    if (account && !account.registrationDateTime) {
      navigate(paths.root);
    }
  }, [account, navigate]);

  return (
    <Container>
      <Row>
        <Col>
          <div className={styles.topRowButtons}>
            <StyledLink path={paths.accountSecureMail(dwbuid)}>
              {strings.back}
            </StyledLink>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          {isGetClassificationsLoading || isGetClassificationsFetching ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : isGetClassificationsError ? (
            <StyledError
              variant="vertical"
              errorTitle={strings.errorTitle}
              error={getClassificationsError}
              refetch={getClassificationsRefetch}
            />
          ) : (
            <div className={styles.classificationBox}>
              <Select
                options={classifications}
                selectedValue={selectedClassification}
                onChange={(selectedId) => setSelectedClassification(selectedId)}
                ariaLabel={strings.selectClassificationAriaLabel}
                disabled={isComposeSecureMailLoading}
                placeholder={strings.classification}
              />
              <Select
                options={statusOptions}
                selectedValue={selectedStatus}
                onChange={(selectedId) => setSelectedStatus(selectedId)}
                ariaLabel={strings.selectStatusAriaLabel}
                disabled={isComposeSecureMailLoading}
              />
            </div>
          )}
        </Col>
        <Col xs={9}>
          {isGetAccountLoading || isGetAccountFetching ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : isGetAccountError ? (
            <StyledError
              error={getAccountError}
              errorTitle={strings.errorTitle}
              refetch={getAccountRefetch}
            />
          ) : (
            <>
              <div className={styles.messagingContainer}>
                <MessagesHeader
                  className={styles.messagesHeader}
                  subject={subject}
                  dwbuid={dwbuid}
                  customerFirstName={account.firstName}
                  customerLastName={account.lastName}
                />
                <StyledInput
                  label={strings.subject}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  disabled={isComposeSecureMailLoading}
                  maxLength={SECURE_MAIL_SUBJECT_MAX_LENGTH}
                />
                <MessageInput
                  message={message}
                  attachment={messageAttachment}
                  onChangeMessage={setMessage}
                  onSend={onSubmit}
                  onAttachment={setMessageAttachment}
                  isSendMessageLoading={isComposeSecureMailLoading}
                  isSendMessageDisabled={!canSendMessage}
                  className={styles.messageInput}
                />
              </div>
              <NetworkErrorModal
                message={displayError}
                onRequestClose={() => setDisplayError(undefined)}
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
