import alertIcon from "../../../assets/alert-icon.svg";
import StyledButton from "../buttons/StyledButton";
import styles from "./ToolLoadError.module.css";

const strings = {
  failedToLoad: (toolName: string) => `${toolName} failed to load`,
  retry: "Retry",
};

type Props = {
  toolName: string;
  errorDescription: string;
  onRetry: () => void;
};

const ToolLoadError = ({ toolName, errorDescription, onRetry }: Props) => {
  return (
    <div className={styles.mainContainer}>
      <img src={alertIcon} alt="" />
      <div className={styles.textContainer}>
        <h2 className={styles.toolName}>{strings.failedToLoad(toolName)}</h2>
        <span className={styles.errorDescription}>{errorDescription}</span>
      </div>
      <div>
        <StyledButton
          className={styles.retryButton}
          onClick={onRetry}
          variant="secondary"
        >
          {strings.retry}
        </StyledButton>
      </div>
    </div>
  );
};

export default ToolLoadError;
