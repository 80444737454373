import { DynamicErrorResponse } from "../../../lib/api/models";
import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import styles from "./MakePaymentDynamicErrorModal.module.css";

const strings = {
  title: "Fisverv Auth Issue",
  onEditDetails: "Edit Details",
  authorize: "Authorize and Continue",
};

type Props = {
  currentDynamicError: DynamicErrorResponse | undefined;
  onOverride: (dynamicErrorKey: string) => void;
  onClose: () => void;
};

export default function MakePaymentDynamicErrorModal({
  currentDynamicError,
  onOverride,
  onClose,
}: Props) {
  return (
    <BaseModal
      className={styles.container}
      isOpen={!!currentDynamicError}
      label={strings.title}
      onRequestClose={onClose}
    >
      <div className={styles.body}>
        <h2>{strings.title}</h2>
        <div>{currentDynamicError?.message}</div>
        <StyledButton
          className={styles.editButton}
          onClick={onClose}
          variant="primary"
        >
          {strings.onEditDetails}
        </StyledButton>
        {currentDynamicError?.isOverrideable ? (
          <TextButton onClick={() => onOverride(currentDynamicError.errorKey)}>
            {strings.authorize}
          </TextButton>
        ) : null}
      </div>
    </BaseModal>
  );
}
