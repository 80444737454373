import styles from "./ValidationMessage.module.css";

type Props = {
  validationMessages: string[];
};

export default function ValidationMessage({ validationMessages }: Props) {
  return validationMessages.length > 0 ? (
    <div className={styles.validationMessages}>
      {validationMessages.join("\n")}
    </div>
  ) : null;
}
