import classNames from "classnames";
import { Col, Container, Row } from "react-grid-system";
import AddAutopay from "./AddAutopay";
import {
  EnrollmentType,
  GetAccountResponse,
  GetAutopayEnrollmentResponse,
} from "../../lib/api/models";
import UpdateAutopay from "./UpdateAutopay";
import {
  useGetAccountDetails,
  useGetAutopayEnrollment,
} from "../../lib/api/queries";
import StyledButton from "../common/buttons/StyledButton";
import StyledError from "../StyledError";
import Spinner from "../common/Spinner";
import CreateAutopayIneligible from "./UpdateAutopay/CreateAutopayIneligible";
import { ToolState } from "../AccountDetails";
import { useCallback } from "react";

import styles from "./ManageAutopay.module.css";

const strings = {
  addAutopay: "Add Autopay",
  manageAutopay: "Manage Autopay",
  close: "Close",
};

type Props = {
  tool: ToolState;
  account: GetAccountResponse;
  onRequestClose: () => void;
  onDisplayGeneralErrors: (err: unknown) => void;
  onComplete: (summaryWithoutTime: string) => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  className?: string;
};

const getTitle = (
  autopayEnrollmentResponse?: GetAutopayEnrollmentResponse,
): string => {
  if (
    autopayEnrollmentResponse &&
    autopayEnrollmentResponse.enrollmentType === EnrollmentType.Eligible &&
    !autopayEnrollmentResponse.isEnrolledAutopay
  ) {
    return strings.addAutopay;
  }
  return strings.manageAutopay;
};

export default function ManageAutopay({
  tool,
  account,
  onRequestClose,
  onDisplayGeneralErrors,
  onComplete,
  onSetToolInProgress,
  className,
}: Props) {
  const {
    data: getAutopayEnrollmentResponse,
    isLoading: isGetAutopayEnrollmentResponseLoading,
    isError: isGetAutopayEnrollmentResponseError,
    error: getAutopayEnrollmentResponseError,
    refetch: getAutopayEnrollmentResponseRefetch,
  } = useGetAutopayEnrollment({
    partyId: account.partyId,
    dwbuid: account.currentDwbuid,
  });

  const {
    data: getAccountDetailsResponse,
    isLoading: isGetAccountDetailsResponseLoading,
    isError: isGetAccountDetailsResponseError,
    error: getAccountDetailsResponseError,
    refetch: getAccountDetailsResponseRefetch,
  } = useGetAccountDetails(account.currentDwbuid);

  const setToolInProgress = useCallback(
    (b: boolean) => {
      onSetToolInProgress(tool.id, tool.inProgress || b);
    },
    [onSetToolInProgress, tool.id, tool.inProgress],
  );

  const renderAddAutopay = () => {
    return isGetAccountDetailsResponseLoading ? (
      <Row>
        <Col>
          <Spinner className={styles.spinner} />
        </Col>
      </Row>
    ) : isGetAccountDetailsResponseError ? (
      <Row>
        <Col>
          <StyledError
            error={getAccountDetailsResponseError}
            errorTitle={strings.addAutopay}
            refetch={getAccountDetailsResponseRefetch}
          />
        </Col>
      </Row>
    ) : (
      <AddAutopay
        account={account}
        accountDetails={getAccountDetailsResponse}
        onDisplayGeneralErrors={onDisplayGeneralErrors}
        onComplete={onComplete}
        setToolInProgress={setToolInProgress}
      />
    );
  };

  return (
    <div className={classNames(styles.mainContainer, className)} id={tool.id}>
      <Container>
        <Row>
          <Col md={11} className={styles.centerCol}>
            <h2 className={styles.header}>
              {getTitle(getAutopayEnrollmentResponse)}
            </h2>
          </Col>
          <Col md={1} className={styles.centerCol}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        {isGetAutopayEnrollmentResponseLoading ? (
          <Row>
            <Col>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        ) : isGetAutopayEnrollmentResponseError ? (
          <Row>
            <Col>
              <StyledError
                error={getAutopayEnrollmentResponseError}
                errorTitle={strings.manageAutopay}
                refetch={getAutopayEnrollmentResponseRefetch}
              />
            </Col>
          </Row>
        ) : getAutopayEnrollmentResponse.enrollmentType ===
          EnrollmentType.Eligible ? (
          getAutopayEnrollmentResponse.isEnrolledAutopay ? (
            <UpdateAutopay
              account={account}
              autopayInfo={getAutopayEnrollmentResponse}
              onComplete={onComplete}
              onDisplayGeneralErrors={onDisplayGeneralErrors}
              setToolInProgress={setToolInProgress}
            />
          ) : (
            renderAddAutopay()
          )
        ) : (
          <CreateAutopayIneligible
            onClose={onRequestClose}
            ineligibleAutopayEnrollmentResponse={getAutopayEnrollmentResponse}
          />
        )}
      </Container>
    </div>
  );
}
