import StyledInput from "../common/inputs/StyledInput";

const MIN_NUM = 2;

const strings = {
  tooHigh: (max: number) => `Max value is ${max}`,
  tooLow: `Min value is ${MIN_NUM}`,
};

type Props = {
  className?: string;
  label: string;
  value: number;
  onChange: NonNullable<React.ComponentProps<"input">["onChange"]>;
  disabled?: boolean;
  max: number;
};

export default function StyledNumPaymentsInput(props: Props) {
  let validationMessages: string[] = [];
  if (props.value > props.max) {
    validationMessages = [strings.tooHigh(props.max)];
  } else if (props.value < MIN_NUM) {
    validationMessages = [strings.tooLow];
  }

  return (
    <StyledInput
      label={props.label}
      className={props.className}
      value={isNaN(props.value) ? "" : props.value}
      onChange={props.onChange}
      max={props.max}
      min={MIN_NUM}
      type="number"
      maxLength={2}
      validationMessages={validationMessages}
      disabled={props.disabled}
    />
  );
}
