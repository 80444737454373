import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOMClient from "react-dom/client";
import { setConfiguration } from "react-grid-system";
import { setAppElement } from "react-modal";
import App from "./App.tsx";
import "./index.css";
import { BASE_URL } from "./lib/api/endpoints.ts";
import { queryClient } from "./lib/api/queries.ts";
import { initializeBugReporting } from "./lib/bugReporting.ts";
import { Provider as AuthProvider } from "./lib/roleContext.ts";

initializeBugReporting();

function prepare() {
  if (import.meta.env.VITE_ENABLE_MOCK_MODE === "true") {
    return import("./lib/api/mocks/setupBrowserMocking.ts").then(
      ({ serviceWorker }) =>
        serviceWorker.start({
          onUnhandledRequest: (req, print) => {
            if (req.url.pathname.startsWith(BASE_URL)) {
              // only warn for missed API calls, not things like Sentry, GA, etc.
              print.warning();
            }
          },
        }),
    );
  }

  return Promise.resolve();
}

setConfiguration({
  gutterWidth: 24,
});

prepare().then(() => {
  const root = document.getElementById("root") as HTMLElement;
  setAppElement(root);

  ReactDOMClient.createRoot(root).render(
    <React.StrictMode>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </AuthProvider>
    </React.StrictMode>,
  );
});
