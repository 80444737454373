import classNames from "classnames";
import alertIcon from "../../assets/alert-icon.svg";
import { getDisplayError } from "../../lib/api/endpoints";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./StyledError.module.css";

const strings = {
  retry: "Retry",
};

type Props = {
  errorTitle: string;
  error: unknown | unknown[];
  refetch: () => void;
  variant?: "horizontal" | "vertical";
};

export function StyledError({
  error,
  errorTitle,
  refetch,
  variant = "horizontal",
}: Props) {
  return (
    <div
      className={classNames({
        [styles.error]: true,
        [styles.displayErrorAsColumn]: variant === "vertical",
      })}
    >
      <img className={styles.image} src={alertIcon} alt="" />
      <div className={styles.errorRightContent}>
        <span className={styles.bold}>{errorTitle}</span>
        {Array.isArray(error)
          ? error.map(getDisplayError).join(" ")
          : getDisplayError(error)}
      </div>
      <StyledButton
        className={styles.retryButton}
        onClick={refetch}
        variant="secondary"
      >
        {strings.retry}
      </StyledButton>
    </div>
  );
}
