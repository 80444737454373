import { Col, Row } from "react-grid-system";
import {
  DateString,
  GetAccountDetailsResponse,
  GetAccountResponse,
  GetPartyRestrictionsResponse,
  PaymentMethod,
  RESTRICTION_INDEFINITE,
  RESTRICTION_NONE,
} from "../../../lib/api/models";
import { useIsAgentRoleViewPCIData } from "../../../lib/roleContext";
import {
  OWNERSHIP_OPTIONS,
  PAYMENT_METHOD_TYPE_OPTIONS,
  getStylizedPaymentMethodName,
  useDynamicErrorMessages,
} from "../../../lib/sourceAccounts";
import CardholderInfo from "../../CardholderInfo";
import StyledPasswordInput from "../../StyledPasswordInput";
import DateInput from "../../common/DateInput";
import ExpirationDateInput from "../../common/ExpirationDateInput";
import Select from "../../common/Select";
import StyledCheckbox from "../../common/StyledCheckbox";
import StyledButton from "../../common/buttons/StyledButton";
import StyledInput from "../../common/inputs/StyledInput";
import DeleteSourceAccountConfirmationModal from "../DeleteSourceAccountConfirmationModal";
import OverrideCardAddressCheckModal from "../OverrideCardAddressCheckModal";
import SourceAccountsTitle from "../SourceAccountsTitle";
import {
  getDefaultRestrictionEndDate,
  useEditSourceAccountQueries,
  useEditSourceAccountState,
} from "./EditSourceAccount.hooks";
import styles from "./EditSourceAccount.module.css";

type Props = {
  restrictions: GetPartyRestrictionsResponse;
  cardmemberAccount: GetAccountResponse;
  cardmemberAccountDetails: GetAccountDetailsResponse;
  paymentMethod: PaymentMethod;
  onCancel: () => void;
  onSuccess: () => void;
  onDisplayGeneralErrors: (err: unknown) => void;
  onRequestClose: () => void;
};
export default function EditSourceAccount({
  restrictions,
  cardmemberAccount,
  cardmemberAccountDetails,
  paymentMethod,
  onSuccess,
  onCancel,
  onDisplayGeneralErrors,
  onRequestClose,
}: Props) {
  const {
    editSourceAccountState,
    dispatch,
    validationMessages,
    setValidationMessages,
  } = useEditSourceAccountState(paymentMethod);

  const {
    currentDynamicError,
    setCurrentDynamicError,
    dynamicErrorOverridesRef,
    dynamicErrorViewCountsRef,
  } = useDynamicErrorMessages({ cardNumber: "constant" });

  const {
    onSubmit,
    isLoading,
    onClickDelete,
    isDeleteModalOpen,
    onConfirmDelete,
    onCancelDelete,
  } = useEditSourceAccountQueries(
    cardmemberAccount,
    paymentMethod,
    editSourceAccountState,
    onSuccess,
    setValidationMessages,
    onDisplayGeneralErrors,
    setCurrentDynamicError,
    dynamicErrorOverridesRef,
    dynamicErrorViewCountsRef,
  );

  const canViewPCIData = useIsAgentRoleViewPCIData();

  return (
    <>
      <Row>
        <Col md={2}>
          <SourceAccountsTitle />
        </Col>
        <Col md={3} className={styles.sourceAccountNameContainer}>
          <div className={styles.sourceAccountName}>
            {getStylizedPaymentMethodName(paymentMethod)}
          </div>
        </Col>
        <Col md={1} offset={{ md: 6 }}>
          <StyledButton
            className={styles.closeButton}
            variant="secondary"
            renderLoadingIndicator={false}
            onClick={onRequestClose}
          >
            {"Close"}
          </StyledButton>
        </Col>
      </Row>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Row className={styles.row}>
          <Col xs={2}>
            <Select
              disabled
              options={OWNERSHIP_OPTIONS}
              selectedValue={paymentMethod.ownerType}
              placeholder={"Select"}
              label={"Ownership"}
              validationMessages={validationMessages?.ownerType}
            />
          </Col>
          <Col xs={2}>
            <Select
              disabled
              options={PAYMENT_METHOD_TYPE_OPTIONS}
              selectedValue={paymentMethod.type}
              placeholder={"Select"}
              label={"Method Type"}
              validationMessages={validationMessages?.paymentMethodType}
            />
          </Col>
          {paymentMethod.type === "checking_account" ? (
            <>
              <Col xs={2}>
                <StyledInput
                  disabled
                  value={paymentMethod.abaNumber}
                  label={"Routing or ABA Number"}
                  validationMessages={validationMessages?.abaNumber}
                />
              </Col>
              <Col xs={2}>
                <StyledPasswordInput
                  disabled
                  value={paymentMethod.accountNumber}
                  label={"Account Number"}
                  validationMessages={validationMessages?.accountNumber}
                  alwaysHideInput={!canViewPCIData}
                />
              </Col>
            </>
          ) : (
            <>
              <Col xs={2}>
                <StyledPasswordInput
                  disabled
                  value={paymentMethod.cardNumber}
                  label={"Card Number"}
                  validationMessages={validationMessages?.cardNumber}
                  alwaysHideInput={!canViewPCIData}
                />
              </Col>
              <Col xs={1}>
                <ExpirationDateInput
                  disabled={isLoading}
                  value={editSourceAccountState.expirationDate}
                  onChange={(v) => {
                    dispatch({ expirationDate: v });
                  }}
                  label={"Expiration Date*"}
                  validationMessages={validationMessages?.expirationDate}
                />
              </Col>
            </>
          )}
          {paymentMethod.ownerType === "ACCOUNT_OWNER" ? (
            <Col md={2} className={styles.autofillButtonContainer}>
              <StyledButton
                className={styles.autofillButton}
                variant="secondary"
                disabled={isLoading}
                onClick={() =>
                  dispatch({
                    cardHolderFirstName: cardmemberAccount.firstName,
                    cardHolderLastName: cardmemberAccount.lastName,
                    phoneNumber: cardmemberAccountDetails.phoneNumber,
                    addressLine1: cardmemberAccountDetails.addressLine1,
                    addressLine2: cardmemberAccountDetails.addressLine2,
                    city: cardmemberAccountDetails.city,
                    stateCode: cardmemberAccountDetails.stateCode,
                    zipcode: cardmemberAccountDetails.zipcode,
                  })
                }
              >
                {"Autofill"}
              </StyledButton>
            </Col>
          ) : null}
        </Row>
        <Row className={styles.row}>
          <CardholderInfo
            isLoading={isLoading}
            formState={{
              ...editSourceAccountState,
              ownerType: paymentMethod.ownerType,
              paymentMethodType: paymentMethod.type,
            }}
            generalDispatch={(payload) => dispatch(payload)}
            clearNetworkValidationMessage={() => {
              // This is handled in dispatch as each field changes
            }}
            networkValidationMessages={validationMessages}
          />
        </Row>
        <Row className={styles.buttonRow}>
          <Col md={2}>
            <Select
              className={styles.select}
              label={"Restriction"}
              options={
                paymentMethod.type === "checking_account"
                  ? restrictions.bankRestrictions
                  : restrictions.cardRestrictions
              }
              selectedValue={
                editSourceAccountState.newRestriction?.restriction ??
                RESTRICTION_NONE
              }
              onChange={(restrictionOrNone) => {
                dispatch({
                  newRestriction: {
                    restriction: restrictionOrNone,
                    endDate: editSourceAccountState.newRestriction.endDate,
                  },
                });
              }}
              validationMessages={validationMessages?.newRestriction}
            />
          </Col>
          <Col md={2}>
            {editSourceAccountState.newRestriction.restriction !==
            RESTRICTION_NONE ? (
              <DateInput
                className={styles.paymentDate}
                labelClassName={styles.dateLabel}
                inputClassName={styles.dateInput}
                disabled={
                  isLoading ||
                  editSourceAccountState.newRestriction.endDate ===
                    RESTRICTION_INDEFINITE
                }
                label={"Restricted Until"}
                value={
                  editSourceAccountState.newRestriction.endDate as DateString
                }
                onChangeDate={(newDateString) => {
                  dispatch({
                    newRestriction: {
                      restriction:
                        editSourceAccountState.newRestriction.restriction,
                      endDate: newDateString,
                    },
                  });
                }}
                validationMessages={validationMessages?.newRestriction}
              />
            ) : null}
          </Col>
          <Col md={2} className={styles.buttonContainer}>
            {editSourceAccountState.newRestriction.restriction !==
            RESTRICTION_NONE ? (
              <StyledCheckbox
                checked={
                  editSourceAccountState.newRestriction.endDate ===
                  RESTRICTION_INDEFINITE
                }
                onChange={(e) =>
                  dispatch({
                    newRestriction: {
                      restriction:
                        editSourceAccountState.newRestriction.restriction,
                      endDate: e.target.checked
                        ? RESTRICTION_INDEFINITE
                        : getDefaultRestrictionEndDate(),
                    },
                  })
                }
                disabled={isLoading}
                label="Indefinite"
              />
            ) : null}
          </Col>
          <Col md={2} className={styles.buttonContainer}>
            <StyledButton
              disabled={isLoading}
              className={styles.button}
              onClick={
                onCancel /* TODO: confirmation modal: https://github.com/1fbusa/clarity-web/issues/571 */
              }
              variant={"secondary"}
            >
              {"Cancel"}
            </StyledButton>
          </Col>
          <Col md={2} className={styles.buttonContainer}>
            <StyledButton
              className={styles.button}
              type="submit"
              disabled={!editSourceAccountState.isComplete || isLoading}
            >
              {"Save"}
            </StyledButton>
          </Col>
        </Row>
        <Row>
          <Col md={4} offset={{ md: 6 }} className={styles.buttonContainer}>
            <StyledButton
              variant="danger"
              disabled={isLoading}
              className={styles.button}
              onClick={onClickDelete}
            >
              {"Remove Account"}
            </StyledButton>
          </Col>
        </Row>
      </form>
      <DeleteSourceAccountConfirmationModal
        isOpen={isDeleteModalOpen}
        sourceAccountName={getStylizedPaymentMethodName(paymentMethod)}
        onConfirmDelete={onConfirmDelete}
        onCancelDelete={onCancelDelete}
      />
      <OverrideCardAddressCheckModal
        currentDynamicError={currentDynamicError}
        onOverride={(dynamicErrorKey) => {
          dynamicErrorOverridesRef.current.push(dynamicErrorKey);
          setCurrentDynamicError(undefined);
          onSubmit();
        }}
        onClose={() => setCurrentDynamicError(undefined)}
      />
    </>
  );
}
