import StyledButton from "../../common/buttons/StyledButton";
import styles from "./CurrentTermsTable.module.css";
import eyeSlashedIcon from "../../../assets/password-eye-slashed.svg";
import eyeIcon from "../../../assets/password-eye.svg";
import { useState } from "react";
import classNames from "classnames";

const strings = {
  currentTerms: "Current Terms",
  viewScript: "View Script",
  hideScript: "Hide Script",
  currentPurchaseAPR: "Current Purchase APR",
  currentCashAPR: "Current Cash APR",
  dailyCompounding: "Daily Compounding",
  primeRate: "Prime Rate",
  interestGrace: "Interest Grace",
  rewards: "Rewards",
  yes: "Yes",
  cash: "Cash",
  programName: "Program Name",
  startDate: "Start Date",
  termDate: "Term Date",
};

type ScriptType = "current" | "rewards";

export default function CurrentTermsTable() {
  const [scriptToView, setScriptToView] = useState<ScriptType>();
  const [viewRewards, setViewRewards] = useState(false);

  const renderViewScriptButton = (script: ScriptType) => {
    return (
      <StyledButton
        variant="secondary"
        onClick={() =>
          scriptToView === script
            ? setScriptToView(undefined)
            : setScriptToView(script)
        }
      >
        {scriptToView === script ? (
          <>
            <img src={eyeSlashedIcon} alt="" className={styles.eyeIcon} />
            {strings.hideScript}
          </>
        ) : (
          <>
            <img src={eyeIcon} alt="" className={styles.eyeIcon} />
            {strings.viewScript}
          </>
        )}
      </StyledButton>
    );
  };
  // TODO: API Hookup and Props
  return (
    <>
      <table className={styles.table}>
        <thead>
          <th className={styles.row}>
            <span>{strings.currentTerms}</span>
            {renderViewScriptButton("current")}
          </th>
        </thead>
        <tbody>
          <tr className={styles.oddRow}>
            <td className={styles.row}>
              <span>{strings.currentPurchaseAPR}</span>
              <span>{"23.90%"}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td className={styles.row}>
              <span>{strings.currentCashAPR}</span>
              <span>{"26.90%"}</span>
            </td>
          </tr>
          <tr className={styles.oddRow}>
            <td className={styles.row}>
              <span>{strings.dailyCompounding}</span>
              <span>{"YES"}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td className={styles.row}>
              <span>{strings.primeRate}</span>
              <span>{"3.25%"}</span>
            </td>
          </tr>
          <tr className={styles.oddRow}>
            <td className={styles.row}>
              <span>{strings.interestGrace}</span>
              <span>{"Purch Only"}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td className={styles.row}>
              <span>{strings.rewards}</span>
              <StyledButton
                className={styles.yesButton}
                variant="transparent"
                onClick={() => setViewRewards(true)}
              >
                {strings.yes}
              </StyledButton>
            </td>
          </tr>
        </tbody>
      </table>

      {viewRewards ? (
        <table className={classNames(styles.table, styles.cashRewardsTable)}>
          <thead>
            <th className={styles.row}>
              <span>{strings.cash}</span>
              {renderViewScriptButton("rewards")}
            </th>
          </thead>
          <tbody>
            <tr className={styles.oddRow}>
              <td className={styles.row}>
                <span>{strings.programName}</span>
                <span>{"1.5% Purch & Cash Advances"}</span>
              </td>
            </tr>
            <tr className={styles.evenRow}>
              <td className={styles.row}>
                <span>{strings.startDate}</span>
                <span>{"7/11/2019"}</span>
              </td>
            </tr>
            <tr className={styles.oddRow}>
              <td className={styles.row}>
                <span>{strings.termDate}</span>
                <span>{"No Expiration"}</span>
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </>
  );
}
