import classNames from "classnames";
import styles from "./Subtool.module.css";

type Props = {
  children: React.ReactNode;
  className?: string;
  isActive?: boolean;
};

export function Subtool({ children, className, isActive = false }: Props) {
  return (
    <div
      className={classNames(
        { [styles.container]: true, [styles.active]: isActive },
        className,
      )}
    >
      {children}
    </div>
  );
}
