import { format } from "date-fns";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import warningIcon from "../../../assets/warning-icon.svg";
import {
  DateString,
  PermissionToDiscussRelationshipType,
  ValidationErrors,
} from "../../../lib/api/models";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../../lib/validation";
import DateInput from "../../common/DateInput";
import Select from "../../common/Select";
import StyledButton from "../../common/buttons/StyledButton";
import TextButton from "../../common/buttons/TextButton";
import StyledInput from "../../common/inputs/StyledInput";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./AddPermissionToDiscuss.module.css";
import { AddPermisionToDiscuss } from "./AddPermissionToDiscuss.types";

const MIN_EFFECTIVE_DATE = "1987-05-22";
const MAX_NAME_LENGTH = 30;

type AddPermisionToDiscussKeyType = keyof AddPermisionToDiscuss;

const strings = {
  add: "Add P2D",
  firstName: "First Name",
  lastName: "Last Name",
  relation: "Relationship",
  select: "Select",
  effectiveDate: "Effective Date",
  submit: "Submit",
  cancel: "Cancel",
  dismissChanges: "Are you sure you want to dismiss your changes?",
  dismiss: "Dismiss",
};

type Props = {
  relationshipTypesById: Record<string, PermissionToDiscussRelationshipType>;
  onSubmit: (permission: AddPermisionToDiscuss) => Promise<unknown>;
  onCancel: () => void;
  isSubmitting: boolean;
  isDisabled?: boolean;
};

export default function AddPermissionToDiscuss({
  relationshipTypesById,
  onSubmit,
  onCancel,
  isSubmitting,
  isDisabled,
}: Props) {
  const [permission, setPermission] = useState<AddPermisionToDiscuss>({
    firstName: "",
    lastName: "",
    relationshipId: "",
    effectiveDate: "",
  });
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();

  const clearNetworkValidationMessages = (
    keys: AddPermisionToDiscussKeyType[],
  ) => {
    if (networkValidationMessages) {
      const newVal = keys.reduce((validation, key) => {
        validation[key] = [];
        return validation;
      }, {} as ValidationErrors);

      setNetworkValidationMessages((prev) => ({
        ...prev,
        ...newVal,
      }));
    }
  };

  const updatePermission = (payload: Partial<AddPermisionToDiscuss>) => {
    const valKeys = Object.keys(payload);
    clearNetworkValidationMessages(valKeys as AddPermisionToDiscussKeyType[]);
    setPermission((prev) => ({ ...prev, ...payload }));
  };

  const isDirty = Object.values(permission).some((v) => !!v);

  const submitDisabled =
    Object.values(permission).some((v) => !v.trim()) ||
    isDisabled ||
    isSubmitting ||
    (networkValidationMessages &&
      Object.values(networkValidationMessages).some((val) => val.length > 0));

  const onAddPermission = () => {
    if (submitDisabled) {
      return;
    }

    setNetworkValidationMessages(undefined);
    onSubmit(permission).catch((err) =>
      setValidationErrorsAndDisplayGeneralErrors(
        err,
        setNetworkValidationMessages,
        () => null,
      ),
    );
  };

  return (
    <>
      <Row>
        <Col>
          <div className={styles.background} />
        </Col>
      </Row>
      <Row className={styles.container}>
        <Col md={1}>
          <div className={styles.title}>{strings.add}</div>
        </Col>
        <Col md={2}>
          <StyledInput
            value={permission.firstName}
            onChange={(e) => updatePermission({ firstName: e.target.value })}
            label={strings.firstName}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["firstName"]
                : undefined
            }
            maxLength={MAX_NAME_LENGTH}
          />
        </Col>
        <Col md={2}>
          <StyledInput
            value={permission.lastName}
            onChange={(e) => updatePermission({ lastName: e.target.value })}
            label={strings.lastName}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["lastName"]
                : undefined
            }
            maxLength={MAX_NAME_LENGTH}
          />
        </Col>
        <Col md={2}>
          <Select
            className={styles.select}
            contentClassName={styles.selectContent}
            selectedLabelClassName={styles.selectLabel}
            options={Object.values(relationshipTypesById).map((r) => ({
              value: r.id,
              label: r.relationship,
            }))}
            selectedValue={permission.relationshipId}
            onChange={(v) => updatePermission({ relationshipId: v })}
            placeholder={strings.select}
            label={strings.relation}
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["relationshipId"]
                : undefined
            }
          />
        </Col>
        <Col md={2}>
          <DateInput
            labelClassName={styles.dateLabel}
            inputClassName={styles.dateInput}
            label={strings.effectiveDate}
            value={permission.effectiveDate}
            onChange={(v) =>
              updatePermission({ effectiveDate: v.target.value as DateString })
            }
            validationMessages={
              networkValidationMessages
                ? networkValidationMessages["effectiveDate"]
                : undefined
            }
            min={MIN_EFFECTIVE_DATE}
            max={format(new Date(), "yyyy-MM-dd").toString() as DateString}
          />
        </Col>
        <Col md={2}>
          <StyledButton
            className={styles.submit}
            disabled={submitDisabled}
            onClick={onAddPermission}
            isLoading={isSubmitting}
          >
            {strings.submit}
          </StyledButton>
        </Col>
        <Col md={1}>
          <TextButton
            className={styles.cancel}
            onClick={() => {
              isDirty ? setConfirmCancel(true) : onCancel();
            }}
            disabled={isSubmitting}
          >
            {strings.cancel}
          </TextButton>
        </Col>
      </Row>

      <BaseModal
        isOpen={confirmCancel}
        onRequestClose={() => setConfirmCancel(false)}
        label={strings.dismissChanges}
        className={styles.modalBody}
      >
        <ModalCloseButton
          className={styles.closeButton}
          onClick={() => setConfirmCancel(false)}
        />
        <img src={warningIcon} alt="" />
        <span>{strings.dismissChanges}</span>
        <StyledButton
          className={styles.firstButton}
          variant="danger"
          onClick={() => {
            setNetworkValidationMessages(undefined);
            onCancel();
          }}
        >
          {strings.dismiss}
        </StyledButton>
        <StyledButton
          className={styles.secondButton}
          variant="primary"
          onClick={() => setConfirmCancel(false)}
        >
          {strings.cancel}
        </StyledButton>
      </BaseModal>
    </>
  );
}
