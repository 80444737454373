import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../lib/router";

type Props = {
  children: (dwbuid: string) => JSX.Element;
};

export function DwbuidExtrator({ children }: Props) {
  const navigate = useNavigate();
  const { dwbuid } = useParams();
  if (!dwbuid) {
    navigate(paths.root);
    return null;
  }

  return children(dwbuid);
}
