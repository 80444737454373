import { addMonths, startOfMonth } from "date-fns";
import { useCallback, useState } from "react";
import {
  DateString,
  DynamicErrorResponse,
  GetAccountResponse,
  PaymentMethod,
  RESTRICTION_NONE,
  SourceAccountRestriction,
  ValidationErrors,
} from "../../../lib/api/models";
import {
  useDeleteSourceAccount,
  useEditBankSourceAccount,
  useEditCardSourceAccount,
} from "../../../lib/api/queries";
import { formatToDateStringInCentralTime } from "../../../lib/formatting";
import { handleAddCardSourceAccountErrorResponse } from "../../../lib/sourceAccounts";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../../lib/validation";

type EditSourceAccountState = {
  /* Derived state */
  isComplete: boolean;

  /* Editable values */
  expirationDate: string;
  cardHolderFirstName: string;
  cardHolderLastName: string;
  phoneNumber?: string | null;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  stateCode: string;
  zipcode: string;
  cardHolderFirstName2?: string | null;
  cardHolderLastName2?: string | null;

  /* restriction */
  newRestriction: SourceAccountRestriction;
};

export function getDefaultRestrictionEndDate(): DateString {
  return formatToDateStringInCentralTime(
    startOfMonth(addMonths(new Date(), 7)),
  );
}

function isEditSourceAccountComplete(
  s: EditSourceAccountState,
  originalSourceAccount: PaymentMethod,
): boolean {
  return (
    (originalSourceAccount.type === "checking_account"
      ? true
      : !!s.expirationDate) &&
    !!s.cardHolderFirstName.trim() &&
    !!s.cardHolderLastName.trim() &&
    !!s.addressLine1.trim() &&
    !!s.city.trim() &&
    !!s.stateCode &&
    !!s.zipcode
  );
}

// returns undefined if no change
function getNewRestrictionsIfChanged(
  originalSourceAccount: PaymentMethod,
  { newRestriction }: EditSourceAccountState,
): SourceAccountRestriction | undefined {
  const originalRestriction: SourceAccountRestriction | undefined =
    originalSourceAccount.restrictions[0];
  if (!originalRestriction) {
    return newRestriction.restriction !== RESTRICTION_NONE
      ? newRestriction
      : undefined;
  }

  return originalRestriction.restriction !== newRestriction.restriction ||
    originalRestriction.endDate !== newRestriction.endDate
    ? newRestriction
    : undefined;
}

export function useEditSourceAccountState(
  originalSourceAccount: PaymentMethod,
) {
  const [validationMessages, setValidationMessages] =
    useState<ValidationErrors>();
  const clearValidationMessages = useCallback((...keys: string[]) => {
    setValidationMessages((prev) => {
      const next = { ...prev };
      keys.forEach((key) => {
        next[key] = [];
      });

      return next;
    });
  }, []);

  const [editSourceAccountState, setEditSourceAccountState] =
    useState<EditSourceAccountState>({
      isComplete: false, // initial value false ensures save does not light up until first edit
      expirationDate:
        originalSourceAccount.type === "checking_account"
          ? ""
          : originalSourceAccount.expirationDate,
      cardHolderFirstName: originalSourceAccount.cardHolderFirstName,
      cardHolderLastName: originalSourceAccount.cardHolderLastName,
      phoneNumber: originalSourceAccount.phoneNumber,
      addressLine1: originalSourceAccount.addressLine1,
      addressLine2: originalSourceAccount.addressLine2,
      city: originalSourceAccount.city,
      stateCode: originalSourceAccount.stateCode,
      zipcode: originalSourceAccount.zipcode,
      cardHolderFirstName2:
        originalSourceAccount.type === "checking_account"
          ? originalSourceAccount.cardHolderFirstName2
          : undefined,
      cardHolderLastName2:
        originalSourceAccount.type === "checking_account"
          ? originalSourceAccount.cardHolderLastName2
          : undefined,
      newRestriction: originalSourceAccount.restrictions[0] ?? {
        restriction: RESTRICTION_NONE,
        endDate: getDefaultRestrictionEndDate(),
      },
    });

  const dispatch = useCallback(
    (next: Partial<EditSourceAccountState>) =>
      setEditSourceAccountState((prev) => {
        // reset validation messages on changed fields
        clearValidationMessages(...Object.keys(next));

        const nextState = {
          ...prev,
          ...next,
        };
        nextState.isComplete = isEditSourceAccountComplete(
          nextState,
          originalSourceAccount,
        );
        return nextState;
      }),
    [setEditSourceAccountState, clearValidationMessages, originalSourceAccount],
  );

  return {
    editSourceAccountState,
    dispatch,
    validationMessages,
    setValidationMessages,
  };
}

export function useEditSourceAccountQueries(
  cardmemberAccount: GetAccountResponse,
  originalSourceAccount: PaymentMethod,
  editSourceAccountState: EditSourceAccountState,
  onSuccess: () => void,
  setValidationMessages: React.Dispatch<
    React.SetStateAction<Record<string, string[]> | undefined>
  >,
  onDisplayGeneralError: (error: unknown) => void,
  setCurrentDynamicError: (dynamicError: DynamicErrorResponse) => void,
  dynamicErrorOverridesRef: React.MutableRefObject<string[]>,
  dyanmicErrorViewCountsRef: React.MutableRefObject<Record<string, number>>,
) {
  const deleteSourceAccount = useDeleteSourceAccount();
  const editBankSourceAccount = useEditBankSourceAccount();
  const editCardSourceAccount = useEditCardSourceAccount();
  const isLoading =
    editBankSourceAccount.isLoading ||
    editCardSourceAccount.isLoading ||
    deleteSourceAccount.isLoading;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onClickDelete = useCallback(
    () => setIsDeleteModalOpen((prev) => !prev),
    [],
  );
  const onCancelDelete = useCallback(() => setIsDeleteModalOpen(false), []);
  const onConfirmDelete = useCallback(() => {
    deleteSourceAccount
      .mutateAsync({
        partyId: cardmemberAccount.partyId,
        sourceAccountId: originalSourceAccount.id,
      })
      .then(onSuccess)
      .catch(onDisplayGeneralError);
    setIsDeleteModalOpen(false);
  }, [
    cardmemberAccount.partyId,
    deleteSourceAccount,
    originalSourceAccount.id,
    onSuccess,
    onDisplayGeneralError,
  ]);

  const onSubmit = useCallback(() => {
    if (!editSourceAccountState.isComplete || isLoading) {
      return;
    }

    let promise;
    const editSourceAccountCommon = {
      sourceAccountId: originalSourceAccount.id,
      partyId: cardmemberAccount.partyId,
      addressLine1: editSourceAccountState.addressLine1,
      addressLine2: editSourceAccountState.addressLine2,
      city: editSourceAccountState.city,
      stateCode: editSourceAccountState.stateCode,
      zipcode: editSourceAccountState.zipcode,
      phoneNumber: editSourceAccountState.phoneNumber,
      cardHolderFirstName: editSourceAccountState.cardHolderFirstName,
      cardHolderLastName: editSourceAccountState.cardHolderLastName,
      newRestriction: getNewRestrictionsIfChanged(
        originalSourceAccount,
        editSourceAccountState,
      ),
    };
    if (originalSourceAccount.type === "checking_account") {
      promise = editBankSourceAccount.mutateAsync({
        ...editSourceAccountCommon,
        cardHolderFirstName2: editSourceAccountState.cardHolderFirstName2,
        cardHolderLastName2: editSourceAccountState.cardHolderLastName2,
      });
    } else {
      promise = editCardSourceAccount.mutateAsync({
        ...editSourceAccountCommon,
        expirationDate: editSourceAccountState.expirationDate,
        dynamicErrorOverrides: dynamicErrorOverridesRef.current,
        dynamicErrorViewCounts: dyanmicErrorViewCountsRef.current,
      });
    }
    promise.then(onSuccess).catch((error) => {
      originalSourceAccount.type === "checking_account"
        ? setValidationErrorsAndDisplayGeneralErrors(
            error,
            setValidationMessages,
            onDisplayGeneralError,
          )
        : handleAddCardSourceAccountErrorResponse(
            error,
            setCurrentDynamicError,
            setValidationMessages,
            onDisplayGeneralError,
          );
    });
  }, [
    cardmemberAccount.partyId,
    dyanmicErrorViewCountsRef,
    dynamicErrorOverridesRef,
    editBankSourceAccount,
    editCardSourceAccount,
    editSourceAccountState,
    isLoading,
    onDisplayGeneralError,
    onSuccess,
    originalSourceAccount,
    setCurrentDynamicError,
    setValidationMessages,
  ]);

  return {
    onSubmit,
    isLoading,
    onClickDelete,
    isDeleteModalOpen,
    onConfirmDelete,
    onCancelDelete,
  };
}
