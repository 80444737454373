import classNames from "classnames";
import collapseMenu from "../../../assets/collapse-menu.svg";
import expandMenu from "../../../assets/expand-menu.svg";
import styles from "./ToolbarExpansionButton.module.css";

const strings = {
  isCollapsedAlt: "Expand Toolbar",
  isExpandedAlt: "Collapse Toolbar",
};

type Props = {
  isExpanded: boolean;
  onClick: () => void;
  className?: string;
};

export function ToolbarExpansionButton({
  isExpanded,
  onClick,
  className,
}: Props) {
  return (
    <button
      className={classNames(styles.button, className)}
      onClick={onClick}
      aria-live="polite"
      aria-label={isExpanded ? strings.isExpandedAlt : strings.isCollapsedAlt}
      type="button"
    >
      <img
        className={styles.icon}
        src={isExpanded ? collapseMenu : expandMenu}
        alt=""
      />
    </button>
  );
}
