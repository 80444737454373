export enum ToolType {
  ChangeUsername = "change_username",
  TestPushNotification = "test_push_notification",
  PaymentTransactions = "payment_transactions",
  PaymentHistory = "payment_history",
  ManageAutopay = "manage_autopay",
  MakePayment = "make_payment",
  SourceAccounts = "source_accounts",
  PermissionToDiscuss = "permission_to_discuss",
  CycleToDateTransactions = "cycle_to_date_transactions",
  StatementHistory = "statement_history",
  LostStolen = "lost_stolen",
  CreditLineIncrease = "credit_line_increase",
  Terms = "terms",
}
