import { useEffect, useState } from "react";
import StyledButton from "../../common/buttons/StyledButton";
import styles from "./SearchForm.module.css";

const strings = {
  enterAccountId: "Enter Account ID",
  search: "Search",
};

type Props = {
  dwbuid: string | null;
  labelId: string;
  isLoading: boolean;
  onSubmit: (dwbuid: string) => void;
};
const SearchForm = ({
  dwbuid: dwbuidProp,
  labelId,
  isLoading,
  onSubmit,
}: Props) => {
  const [dwbuid, setDwbuid] = useState(dwbuidProp ?? "");
  useEffect(() => {
    setDwbuid(dwbuidProp ?? "");
  }, [dwbuidProp]);
  return (
    <form
      autoComplete="new-password"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(dwbuid);
      }}
    >
      <div className={styles.container}>
        <input
          className={styles.input}
          placeholder={strings.enterAccountId}
          aria-labelledby={labelId}
          value={dwbuid}
          onChange={(e) => setDwbuid(e.target.value)}
        />
        <StyledButton
          type="submit"
          disabled={!dwbuid}
          className={styles.searchButton}
          isLoading={isLoading}
        >
          {strings.search}
        </StyledButton>
      </div>
    </form>
  );
};

export default SearchForm;
