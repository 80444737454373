import { INPUT_MASKS, maskInput } from "../../lib/formatting";
import StyledInput from "./inputs/StyledInput";
import { StyledInputProps } from "./inputs/StyledInput/StyledInput";

type Props = Omit<StyledInputProps, "value" | "onChange"> & {
  value: string;
  onChange: (v: string) => void;
  labelClassName?: string;
} & (
    | {
        invalid: boolean;
        validationMessages?: never;
      }
    | {
        invalid?: never;
        validationMessages?: string[];
      }
  );

export default function StyledZipcodeInput(props: Props) {
  return (
    <StyledInput
      {...props}
      className={props.className}
      value={props.value}
      onChange={(e) =>
        props.onChange(maskInput(INPUT_MASKS.zipCode, e.target.value))
      }
      label={props.label}
      labelClassName={props.labelClassName}
    />
  );
}
