import classNames from "classnames";
import leftPaginationArrow from "../../../assets/left-pagination-arrow.svg";
import rightPaginationArrow from "../../../assets/right-pagination-arrow.svg";

import { Link, To, useSearchParams } from "react-router-dom";
import styles from "./PaginationControl.module.css";

type ArrowButtonProps = {
  isBackButton: boolean;
  selectedPageIndex: number;
  numPages: number;
  to: To;
};

function ArrowButton({
  isBackButton,
  selectedPageIndex,
  numPages,
  to,
}: ArrowButtonProps) {
  return (
    <Link
      className={classNames(
        styles.paginationButton,
        styles.arrowButton,
        ((isBackButton && selectedPageIndex <= 1) ||
          (!isBackButton && selectedPageIndex >= numPages)) &&
          styles.disabled,
      )}
      to={to}
    >
      <img
        className={styles.buttonContent}
        src={isBackButton ? leftPaginationArrow : rightPaginationArrow}
        alt="arrow"
      />
    </Link>
  );
}

type PageButtonProps = {
  isSelected: boolean;
  pageIndex: number;
  to: To;
};

function PageButton({ isSelected, pageIndex, to }: PageButtonProps) {
  return (
    <Link
      className={classNames(
        styles.paginationButton,
        isSelected && styles.selectedButton,
        pageIndex < 1 && styles.disabled,
      )}
      to={to}
    >
      <span
        className={classNames(
          styles.buttonContent,
          isSelected ? styles.selectedText : styles.unselectedText,
        )}
      >
        {pageIndex < 1 ? "..." : pageIndex}
      </span>
    </Link>
  );
}

type Props = {
  className?: string;
  numPages: number;
  paginationObj: {
    forwardArrowPath: { pathname: string; search: string };
    backArrowPath: { pathname: string; search: string };
    paginationLinks: { pathname: string; search: string }[];
  };
};

export default function PaginationControl({
  className,
  numPages,
  paginationObj,
}: Props) {
  // determine page bounds
  const [searchParams] = useSearchParams();
  const selectedPageIndex = parseInt(searchParams.get("page") || "1");
  const startIndex = Math.max(1, selectedPageIndex - 2);
  const endIndex = Math.min(numPages - 2, selectedPageIndex + 2);

  let clickablePages: number[] = [];
  const clickableLength = endIndex - startIndex + 1;
  if (clickableLength > 0) {
    clickablePages = Array(endIndex - startIndex + 1)
      .fill(1)
      .map((_, idx) => startIndex + idx);
  }

  return (
    <div className={classNames(styles.container, className)}>
      <ArrowButton
        isBackButton={true}
        numPages={numPages}
        selectedPageIndex={selectedPageIndex}
        to={paginationObj.backArrowPath}
      />
      <PageButton
        isSelected={selectedPageIndex === 1}
        pageIndex={1}
        to={paginationObj.paginationLinks[0]}
      />
      {selectedPageIndex - 2 > 1 ? (
        <PageButton isSelected={false} pageIndex={-1} to={{}} />
      ) : null}

      {clickablePages.map((pageIndex) => (
        <PageButton
          key={pageIndex}
          isSelected={selectedPageIndex === pageIndex + 1}
          pageIndex={pageIndex + 1}
          to={paginationObj.paginationLinks[pageIndex]}
        />
      ))}

      {selectedPageIndex + 3 < numPages ? (
        <PageButton isSelected={false} pageIndex={-1} to={{}} />
      ) : null}
      {numPages > 1 ? (
        <PageButton
          isSelected={selectedPageIndex === numPages}
          pageIndex={numPages}
          to={paginationObj.paginationLinks[numPages - 1]}
        />
      ) : null}
      <ArrowButton
        isBackButton={false}
        numPages={numPages}
        selectedPageIndex={selectedPageIndex}
        to={paginationObj.forwardArrowPath}
      />
    </div>
  );
}
