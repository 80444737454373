import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import {
  GetSecureMailListResponse,
  UpdateDateStatus,
} from "../../../../lib/api/models";
import { formatInCentralTime } from "../../../../lib/formatting";
import PaginationControl from "../../../SecureMailQueue/PaginationControl";
import ColoredLabelBox from "../../ColoredLabelBox";
import styles from "./SecureMailTable.module.css";
import Table from "./Table";

function getUpdateDateStatus(updateDate: Date): UpdateDateStatus {
  const d = new Date();
  d.setDate(d.getDate() - 1); // 1 day ago
  if (updateDate < d) {
    return "danger";
  }
  return "success";
}

type Props = {
  className?: string;
  variant: "classification" | "cardmember";
  secureMail: GetSecureMailListResponse;
  pageSize: number;
  paginationObj: {
    forwardArrowPath: { pathname: string; search: string };
    backArrowPath: { pathname: string; search: string };
    paginationLinks: { pathname: string; search: string }[];
  };
  onClickRow?: (id: string, pageNumber: number) => void;
};

const ACCOUNT_COLUMN_SIZE = 3;
const SUBJECT_COLUMN_SIZE = 10;
const LAST_UPDATED_COLUMN_SIZE = 4;
const STATUS_COLUMN_SIZE = 3;

export default function SecureMailTable({
  className,
  variant,
  secureMail,
  pageSize,
  paginationObj,
  onClickRow,
}: Props) {
  const columns: {
    header: string;
    alignment: "left" | "center" | "right";
    size: number;
    render: (
      row: GetSecureMailListResponse["secureMail"][number],
    ) => React.ReactNode;
  }[] = [];

  if (variant === "classification") {
    columns.push({
      header: "Account ID",
      size: ACCOUNT_COLUMN_SIZE,
      alignment: "left",
      render: (row) => <div className={styles.accountId}>{row.dwbuid}</div>,
    });
  }

  columns.push(
    {
      header: "Subject",
      size: SUBJECT_COLUMN_SIZE,
      alignment: "left",
      render: (row) => row.subject,
    },
    {
      header: "Last Updated",
      size: LAST_UPDATED_COLUMN_SIZE,
      alignment: "center",
      render: (row) => (
        <ColoredLabelBox status={getUpdateDateStatus(row.updateDate)}>
          {formatInCentralTime(row.updateDate, "MM/dd/yy p")}
        </ColoredLabelBox>
      ),
    },
    {
      header: "Status",
      size: STATUS_COLUMN_SIZE,
      alignment: "center",
      render: (row) => (
        <ColoredLabelBox
          status={
            row.type !== "READONLY" && row.type !== "CMEXPIRE"
              ? "success"
              : "danger"
          }
        >
          {row.type}
        </ColoredLabelBox>
      ),
    },
  );

  const [searchParams] = useSearchParams();
  const selectedPageNumber = parseInt(searchParams.get("page") || "1");
  const numPages = Math.ceil(secureMail.total / pageSize);

  const firstEntry = (selectedPageNumber - 1) * pageSize + 1;
  const lastEntry = Math.min(selectedPageNumber * pageSize, secureMail.total);

  return (
    <div className={classNames(styles.container, className)}>
      <Table
        columns={columns}
        data={secureMail.secureMail}
        onClickRow={(row) => onClickRow?.(row.secureMailId, selectedPageNumber)}
      />
      <div className={styles.paginationControlContainer}>
        <h3 className={styles.currentEntries}>
          {`Showing ${firstEntry} to ${lastEntry} of ${secureMail.total} entries`}
        </h3>
        <PaginationControl
          className={styles.paginationControl}
          numPages={numPages}
          paginationObj={paginationObj}
        />
      </div>
    </div>
  );
}
