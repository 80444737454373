import { INPUT_MASKS, maskInput } from "../../lib/formatting";
import StyledInput from "./inputs/StyledInput";
import { StyledInputProps } from "./inputs/StyledInput/StyledInput";

const strings = {
  invalidDate: "Invalid Date",
  placeholder: "MM/YY",
};

type Props = Omit<StyledInputProps, "value" | "onChange"> & {
  value: string;
  onChange: (v: string) => void;
} & (
    | {
        invalid: boolean;
        validationMessages?: never;
      }
    | {
        invalid?: never;
        validationMessages?: string[];
      }
  );

export default function ExpirationDateInput(props: Props) {
  let validationMessages: string[] = [];
  if (props.value.length === INPUT_MASKS.date.length) {
    const monthNum = parseInt(props.value.slice(0, 2));
    if (monthNum < 1 || monthNum > 12) {
      validationMessages = [strings.invalidDate];
    }
  }

  const finalValidation = props.validationMessages
    ? props.validationMessages.concat(validationMessages)
    : validationMessages;

  return (
    <StyledInput
      className={props.className}
      label={props.label}
      value={props.value}
      onChange={(e) =>
        props.onChange(maskInput(INPUT_MASKS.date, e.target.value))
      }
      validationMessages={finalValidation}
      placeholder={strings.placeholder}
      disabled={props.disabled}
    />
  );
}
