import { nanoid } from "nanoid";
import { useCallback, useMemo, useState } from "react";
import { ToolType } from ".";
import { PaymentTransaction } from "../../lib/api/models";
import { formatInCentralTime } from "../../lib/formatting";

type VisibleToolState = Record<string, ToolState>;

export type EditPaymentData = {
  singleTransaction: PaymentTransaction;
  seriesPayments?: PaymentTransaction[];
};

export type ToolState = {
  id: string;
  type: ToolType;
  completed: null | { summary: string };
  inProgress: boolean;

  // tool metadata
  editPaymentData?: EditPaymentData;
};

export function useToolState() {
  const [visibleTools, setVisibleTools] = useState<VisibleToolState>({});
  const activeTools = useMemo(
    () => Object.values(visibleTools).filter((t) => !t.completed),
    [visibleTools],
  );
  const completedTools = useMemo(
    () => Object.values(visibleTools).filter((t) => !!t.completed),
    [visibleTools],
  );

  const onAddTool = useCallback(
    ({
      type,
      inProgress,
      editPaymentData,
    }: {
      type: ToolType;
      inProgress: boolean;
      editPaymentData?: EditPaymentData;
    }) => {
      const newId = nanoid();
      setVisibleTools((prev) => ({
        [newId]: {
          id: newId,
          type,
          completed: null,
          inProgress,
          editPaymentData,
        },
        ...prev,
      }));
    },
    [],
  );
  const onSetToolInProgress = useCallback((id: string, inProgress: boolean) => {
    setVisibleTools((prev) => ({
      ...prev,
      [id]: { ...prev[id], inProgress },
    }));
  }, []);
  const onCompleteTool = useCallback(
    (id: string, summaryWithoutTime: string) => {
      setVisibleTools((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          originalPayment: undefined,
          completed: {
            summary:
              summaryWithoutTime +
              ` at ${formatInCentralTime(new Date(), "h:mm:ss a")}`,
          },
        },
      }));
    },
    [],
  );
  const onRequestCloseTool = useCallback((id: string) => {
    setVisibleTools((prev) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...rest } = prev;
      return { ...rest };
    });
  }, []);

  return {
    activeTools,
    completedTools,
    onAddTool,
    onSetToolInProgress,
    onCompleteTool,
    onRequestCloseTool,
  };
}
