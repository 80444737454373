import StyledButton from "../../buttons/StyledButton";
import BaseModal from "../BaseModal/BaseModal";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import styles from "./NetworkErrorModal.module.css";

const strings = {
  closeModal: "Close Modal",
  networkError: "Error",
  close: "Close",
};

type Props = {
  message: string | undefined;
  onRequestClose: () => void;
};
const NetworkErrorModal = ({ message, onRequestClose }: Props) => {
  return (
    <BaseModal
      className={styles.container}
      isOpen={!!message}
      label={strings.networkError}
      onRequestClose={onRequestClose}
    >
      <ModalCloseButton onClick={onRequestClose} />
      <div className={styles.body}>
        <h2>{strings.networkError}</h2>
        <div>{message}</div>
        <StyledButton className={styles.closeButton} onClick={onRequestClose}>
          {strings.close}
        </StyledButton>
      </div>
    </BaseModal>
  );
};
export default NetworkErrorModal;
