import classNames from "classnames";
import { useState } from "react";
import { DateString, ProcessingStatusType } from "../../../../lib/api/models";
import StyledCheckbox from "../../../common/StyledCheckbox";
import styles from "./PaymentSeriesTable.module.css";
import PaymentSeriesTableRow from "./PaymentSeriesTableRow";

const columns = ["#", "CONFIRMATION #", "PRINT DATE", "CHECK AMOUNT"];

export type PaymentSeriesScheduleRow = {
  paymentDate: DateString;
  paymentAmount: string;
  checked: boolean;
  confirmationNumber?: string;
  id?: string;
  processingStatusType?: ProcessingStatusType | null;
  validationMessages?: { paymentDate: string };
};

type Props = {
  className?: string;
  paymentSeriesSchedule: PaymentSeriesScheduleRow[];
  setPaymentSeriesSchedule: React.Dispatch<
    React.SetStateAction<PaymentSeriesScheduleRow[]>
  >;
  isEditSeries: boolean;
};

export default function PaymentSeriesTable({
  className,
  paymentSeriesSchedule: schedule,
  setPaymentSeriesSchedule: setSchedule,
  isEditSeries,
}: Props) {
  const [allRowsSelected, setAllRowsSelected] = useState(
    isEditSeries ? false : true,
  );

  return (
    <table className={classNames(styles.paymentSeriesTable, className)}>
      <thead>
        <tr className={styles.stickyHeaders}>
          {columns.map((col, index) => (
            <th key={index}>{col}</th>
          ))}
          <th>
            <StyledCheckbox
              label={""}
              checked={allRowsSelected}
              onChange={(e) => {
                setAllRowsSelected(e.target.checked);
                setSchedule((prevData) =>
                  prevData.map((val) => ({
                    ...val,
                    checked: e.target.checked,
                  })),
                );
              }}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {schedule.map((value, index) => (
          <PaymentSeriesTableRow
            key={index}
            rowNumber={`${index + 1}`}
            paymentDate={value.paymentDate}
            paymentAmount={value.paymentAmount}
            checked={value.checked}
            isEven={index % 2 === 0}
            setPaymentDate={(val: DateString) =>
              setSchedule((prevData) => {
                const newData = [...prevData];
                newData[index].paymentDate = val;
                newData[index].validationMessages = {
                  ...newData[index].validationMessages,
                  paymentDate: "",
                };
                return newData;
              })
            }
            setPaymentAmount={(val) =>
              setSchedule((prevData) => {
                const newData = [...prevData];
                newData[index].paymentAmount = val;
                return newData;
              })
            }
            setChecked={(val) =>
              setSchedule((prevData) => {
                if (!val && allRowsSelected) {
                  setAllRowsSelected(false);
                }
                const newData = [...prevData];
                newData[index].checked = val;
                setAllRowsSelected(!newData.some((r) => !r.checked));
                return newData;
              })
            }
            confirmationNumber={value.confirmationNumber}
            dateValidationMessage={value.validationMessages?.paymentDate}
          />
        ))}
      </tbody>
    </table>
  );
}
