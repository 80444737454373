import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
  centsToDollarString,
  dollarStringToCents,
} from "../../../lib/formatting";
import StyledButton from "../../common/buttons/StyledButton";
import styles from "./PaymentSeriesSchedule.module.css";
import PaymentSeriesTable, {
  PaymentSeriesScheduleRow,
} from "./PaymentSeriesTable";

const strings = {
  cancel: "Cancel Selected Payments",
  payor: "Payor",
  sourceAccount: "Source Account",
  groupOf: "Group of",
  total: "Total",
  close: "Close",
  edit: "Edit Payment Series",
  submit: "Submit Payment Schedule",
  payments: (paymentCount: number) => `${paymentCount} Payments`,
};

function PaymentSeriesInfoGroup({
  className,
  title,
  value,
}: {
  className?: string;
  title: string;
  value: string;
}) {
  return (
    <div className={className}>
      <div className={styles.infoGroupTitle}>{title}</div>
      <div className={styles.infoGroupValue}>{value}</div>
    </div>
  );
}

type Props = {
  payor: string;
  sourceAccountName: string;
  paymentSeriesSchedule: PaymentSeriesScheduleRow[];
  setPaymentSeriesSchedule: React.Dispatch<
    React.SetStateAction<PaymentSeriesScheduleRow[]>
  >;
  onClose: () => void;
  onEditSeries: () => void;
  isCancelPaymentsLoading?: boolean;
  onSubmitCancelPayments: () => void;
  onSubmit: () => void;
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
  isEditSeries: boolean;
};

export default function PaymentSeriesSchedule({
  payor,
  sourceAccountName,
  paymentSeriesSchedule,
  setPaymentSeriesSchedule,
  onClose,
  onEditSeries,
  isCancelPaymentsLoading,
  onSubmitCancelPayments,
  onSubmit,
  isSubmitDisabled,
  isSubmitLoading,
  isEditSeries,
}: Props) {
  const [totalPaymentAmount, setTotalPaymentAmount] = useState("");
  const [totalPaymentCount, setTotalPaymentCount] = useState(0);
  useEffect(() => {
    const checkedRows = paymentSeriesSchedule.filter((row) => row.checked);
    const totalPaymentAmountCents = checkedRows.reduce(
      (sum, row) => sum + dollarStringToCents(row.paymentAmount),
      0,
    );
    setTotalPaymentCount(checkedRows.length);
    setTotalPaymentAmount(centsToDollarString(totalPaymentAmountCents));
  }, [paymentSeriesSchedule]);

  const checkedRows = paymentSeriesSchedule.filter((row) => row.checked);

  return (
    <Row>
      <Col xs={1} offset={{ xs: 11 }}>
        <StyledButton
          className={styles.closeButton}
          variant="secondary"
          onClick={onClose}
        >
          {strings.close}
        </StyledButton>
      </Col>
      <Col xs={9}>
        <PaymentSeriesTable
          className={styles.table}
          paymentSeriesSchedule={paymentSeriesSchedule}
          setPaymentSeriesSchedule={setPaymentSeriesSchedule}
          isEditSeries={isEditSeries}
        />
      </Col>
      <Col xs={3}>
        <PaymentSeriesInfoGroup
          className={styles.infoGroup}
          title={strings.payor}
          value={payor}
        />
        <PaymentSeriesInfoGroup
          className={styles.infoGroup}
          title={strings.sourceAccount}
          value={sourceAccountName}
        />
        <PaymentSeriesInfoGroup
          className={styles.infoGroup}
          title={strings.groupOf}
          value={strings.payments(totalPaymentCount)}
        />
        <PaymentSeriesInfoGroup
          className={styles.infoGroup}
          title={strings.total}
          value={totalPaymentAmount}
        />
        {isEditSeries ? (
          <StyledButton
            className={styles.cancelButton}
            variant="secondary"
            onClick={onSubmitCancelPayments}
            disabled={checkedRows.length === 0}
            isLoading={isCancelPaymentsLoading}
          >
            {strings.cancel}
          </StyledButton>
        ) : (
          <StyledButton
            className={styles.editButton}
            variant="secondary"
            onClick={onEditSeries}
          >
            {strings.edit}
          </StyledButton>
        )}
        <StyledButton
          className={styles.submitButton}
          onClick={onSubmit}
          disabled={isSubmitDisabled || isCancelPaymentsLoading}
          isLoading={isSubmitLoading}
        >
          {strings.submit}
        </StyledButton>
      </Col>
    </Row>
  );
}
