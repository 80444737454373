import classNames from "classnames";
import styles from "./RoundButton.module.css";

type Props = {
  className?: string;
  children: React.ComponentProps<"button">["children"];
  onClick?: React.ComponentProps<"button">["onClick"];
  variant: "blue" | "grey";
};

export default function RoundButton(props: Props) {
  return (
    <button
      className={classNames(
        styles.button,
        props.variant === "grey" && styles.grey,
        props.variant === "blue" && styles.blue,
        props.className,
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
