import {
  MutationCache,
  QueryCache,
  QueryClient,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { reportError } from "../bugReporting";
import {
  getUpdatedSourceAccountsWithAdd,
  getUpdatedSourceAccountsWithDelete,
  getUpdatedSourceAccountsWithEdit,
} from "../sourceAccounts";
import {
  HTTPError,
  addBankRestriction,
  addBankSourceAccount,
  addCardRestriction,
  addCardSourceAccount,
  addPermissionToDicuss,
  addToThread,
  cancelPayment,
  cancelSeriesPayment,
  composeSecureMail,
  deleteSourceAccount,
  editBankSourceAccount,
  editCardSourceAccount,
  editOneTimePayment,
  editPermissionToDicuss,
  editSeriesPayment,
  enrollAutopay,
  getAccount,
  getAccountDetails,
  getAccountLockStatus,
  getAgentDetails,
  getAutopayEnrollment,
  getBankNames,
  getCardmemberMailList,
  getClassificationCount,
  getClassifications,
  getCreditLineIncreaseEligibility,
  getCycleToDateTransactions,
  getLostStolenOptions,
  getPartyRestrictions,
  getPaymentHistory,
  getPaymentMethods,
  getPaymentSeriesSchedule,
  getPaymentTransactions,
  getPermissionToDiscuss,
  getSecureMailList,
  getSecureMailThread,
  getStatementHistory,
  makeOneTimePayment,
  makeSeriesPayment,
  patchNextSecureMailId,
  patchSecureMailClassification,
  removeAutopayEnrollment,
  removePermissionToDiscuss,
  sendTestPushNotification,
  skipAutopay,
  submitLostStolen,
  submitCreditLineIncrease,
  updateAccountLockStatus,
  updateSecureMailStatus,
  updateUsername,
  verifyPermissionToDiscuss,
  viewMailAttachment,
  getCallInfo,
} from "./endpoints";
import {
  AddBankSourceAccountRequest,
  AddCardSourceAccountRequest,
  AddRestrictionRequest,
  AddToThreadRequest,
  EditBankSourceAccountRequest,
  EditCardSourceAccountRequest,
  EnrollAutopayRequest,
  GetAutopayEnrollmentRequest,
  GetCallInfoRequest,
  GetCardmemberMailListRequest,
  GetCreditLineIncreaseEligibilityRequest,
  GetPartyRestrictionsResponse,
  GetPaymentMethodsRequest,
  GetPaymentMethodsResponse,
  GetPaymentSeriesScheduleRequest,
  GetPaymentTransactionsRequest,
  GetPermissionToDiscussRequest,
  GetSecureMailListRequest,
  GetSecureMailThreadRequest,
  GetSecureMailThreadRequestKey,
  MakeOneTimePaymentRequest,
  MakeSeriesPaymentRequest,
  PatchNextSecureMailIdRequest,
  PermissionToDiscussPermission,
  PermissionToDiscussRelationshipType,
  RESTRICTION_NONE,
  RemoveAutopayEnrollmentRequest,
  SendTestPushNotificationRequest,
  SkipAutopayRequest,
  SubmitLostStolenRequest,
  UpdateAccountLockStatusRequest,
  UpdateSecureMailStatusRequest,
  UpdateUsernameRequest,
  ViewMailAttachmentRequest,
} from "./models";

// client
function reportNetworkErrors(error: unknown) {
  if (
    typeof error === "object" &&
    error !== null &&
    "expectedError" in error &&
    error.expectedError === true
  ) {
    return;
  }

  if (error instanceof Error) {
    const meta =
      error instanceof HTTPError ? { validation: error.validation } : {};
    reportError(error, meta);
    return;
  }

  reportError(new Error("Unknown query error"), {
    error,
  });
}
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: reportNetworkErrors,
  }),
  mutationCache: new MutationCache({
    onError: reportNetworkErrors,
  }),
});

// query keys
const queryKeys = {
  account: (dwbuid: string) => ["account", dwbuid] as const,
  accountDetails: (dwbuid: string) => ["accountDetails", dwbuid] as const,
  agentDetails: () => ["agentDetails"] as const,
  lock: (dwbuid: string) => ["login-lock", dwbuid] as const,
  paymentHistory: (dwbuid: string) => ["payment-history", dwbuid] as const,
  autopayEnrollment: ({ partyId, dwbuid }: GetAutopayEnrollmentRequest) =>
    ["autopay-enrollment", partyId, dwbuid] as const,
  classifications: () => ["classifications"] as const,
  classificationCount: () => ["classificationCount"] as const,
  secureMailList: (req: GetSecureMailListRequest) =>
    ["secure-mail-list", req.classificationId, req.page, req.size] as const,
  secureMail: () => ["secure-mail"] as const,
  secureMailThread: ({ secureMailId }: GetSecureMailThreadRequestKey) =>
    ["secure-mail-thread", secureMailId] as const,
  cardmemberMailList: ({ partyId, ...query }: GetCardmemberMailListRequest) => [
    "cardmemberMailList",
    partyId,
    query,
  ],
  // NOTE: Because the API requires dwbuid for payment transactions and other
  // queries that want to invalidate this query use partyId, we will just
  // naively invalidate ALL payment transactions queries.
  // This shouldn't have an impact unless agents are doing weird things like
  // having multiple windows open or switching back and forth between multiple
  // cardmembers.
  paymentTransactions: () => ["payment-transactions"],
  paymentMethods: (req: GetPaymentMethodsRequest) => [
    "payment-methods",
    req.partyId,
  ],
  partyRestrictions: (partyId: string) => ["party-restrictions", partyId],
  bankNames: (abaNumber: string) => ["bank-names", abaNumber],
  permissionToDiscuss: (req: GetPermissionToDiscussRequest) => [
    "permission-to-discuss",
    req.partyId,
    req.includeHistory,
    req.includeInactive,
  ],
  cycleToDateTransactions: (partyId: string) =>
    ["cycle-to-date-transactions", partyId] as const,
  statementHistory: (dwbuid: string) => ["statement-history", dwbuid] as const,
  lostStolenOptions: (dwbuid: string) =>
    ["lost-stolen-options", dwbuid] as const,
  creditLineHistoryEligibility: (
    req: GetCreditLineIncreaseEligibilityRequest,
  ) =>
    ["credit-line-increase-eligibility", req.dwbuid, req.referenceId] as const,
  callInfo: (req: GetCallInfoRequest) =>
    ["call-info", req.dwbuid, req.callId] as const,
} as const;

// queries
export const useGetAgentDetails = () =>
  useQuery({
    queryKey: queryKeys.agentDetails(),
    queryFn: getAgentDetails,
  });

export const useGetAccount = (dwbuid: string) =>
  useQuery({
    enabled: !!dwbuid,
    queryKey: queryKeys.account(dwbuid),
    queryFn: () => getAccount(dwbuid),
  });

export const useGetAccountDetails = (dwbuid: string) =>
  useQuery({
    enabled: !!dwbuid,
    queryKey: queryKeys.accountDetails(dwbuid),
    queryFn: () => getAccountDetails(dwbuid),
  });

export const useUpdateUsername = () =>
  useMutation({
    mutationFn: (params: UpdateUsernameRequest) => updateUsername(params),
    onSuccess: (_data, { dwbuid }) => {
      queryClient.invalidateQueries(queryKeys.accountDetails(dwbuid));
    },
  });

export const useSendTestPushNotification = () =>
  useMutation({
    mutationFn: (params: SendTestPushNotificationRequest) =>
      sendTestPushNotification(params),
  });

export const useGetAccountLockStatus = (dwbuid: string) =>
  useQuery({
    queryKey: queryKeys.lock(dwbuid),
    queryFn: () => getAccountLockStatus(dwbuid),
  });

export const useUpdateAccountLockStatus = () =>
  useMutation({
    mutationFn: (params: UpdateAccountLockStatusRequest) =>
      updateAccountLockStatus(params),
    onSuccess: (_data, { dwbuid }) => {
      const newData = { isLocked: false };
      queryClient.setQueryData(queryKeys.lock(dwbuid), newData);
      queryClient.invalidateQueries(queryKeys.accountDetails(dwbuid));
    },
  });

export const useGetPaymentHistory = (dwbuid: string) =>
  useQuery({
    queryKey: queryKeys.paymentHistory(dwbuid),
    queryFn: () => getPaymentHistory(dwbuid),
  });

export const useGetAutopayEnrollment = (req: GetAutopayEnrollmentRequest) =>
  useQuery({
    queryKey: queryKeys.autopayEnrollment(req),
    queryFn: () => getAutopayEnrollment(req),
  });

export const useGetSecureMailList = (
  req: GetSecureMailListRequest,
  enabled = true,
) =>
  useQuery({
    queryKey: queryKeys.secureMailList(req),
    queryFn: () => getSecureMailList(req),
    enabled,
  });

export const usePatchNextSecureMailId = () =>
  useMutation({
    mutationFn: (params: PatchNextSecureMailIdRequest) =>
      patchNextSecureMailId(params),
  });

export const usePatchSecureMailClassification = () =>
  useMutation({
    mutationFn: patchSecureMailClassification,
    onSuccess: (_, { secureMailId }) => {
      queryClient.invalidateQueries(
        queryKeys.secureMailThread({ secureMailId }),
      );
    },
  });

export const useGetClassifications = () =>
  useQuery({
    queryKey: queryKeys.classifications(),
    queryFn: () => getClassifications(),
  });

export const useGetClassificationCount = () =>
  useQuery({
    queryKey: queryKeys.classificationCount(),
    queryFn: () => getClassificationCount(),
  });

export const useGetCardmemberMailList = (
  req: GetCardmemberMailListRequest,
  enabled = true,
) =>
  useQuery({
    queryKey: queryKeys.cardmemberMailList(req),
    queryFn: () => getCardmemberMailList(req),
    enabled,
  });

export const useGetSecureMailThread = (
  req: GetSecureMailThreadRequest,
  enabled: boolean,
) =>
  useQuery({
    queryKey: queryKeys.secureMailThread(req),
    queryFn: () => getSecureMailThread(req),
    enabled,
  });

export const useAddToThread = () =>
  useMutation({
    mutationFn: (params: AddToThreadRequest) => addToThread(params),
    onSuccess: (_, req) =>
      queryClient.invalidateQueries(queryKeys.secureMailThread(req)),
  });

export const useComposeSecureMail = () =>
  useMutation({
    mutationFn: composeSecureMail,
  });

export const useUpdateSecureMailStatus = () =>
  useMutation({
    mutationFn: (params: UpdateSecureMailStatusRequest) =>
      updateSecureMailStatus(params),
    onSuccess: (_, { secureMailId }) =>
      queryClient.invalidateQueries(
        queryKeys.secureMailThread({ secureMailId }),
      ),
  });

export const useViewMailAttachment = () =>
  useMutation({
    mutationFn: (params: ViewMailAttachmentRequest) =>
      viewMailAttachment(params),
  });

export const useEnrollAutopay = () =>
  useMutation({
    mutationFn: (params: EnrollAutopayRequest) => enrollAutopay(params),
    onSuccess: (_data, { partyId, dwbuid }) => {
      queryClient.invalidateQueries(
        queryKeys.autopayEnrollment({ partyId, dwbuid }),
      );
      queryClient.invalidateQueries(queryKeys.accountDetails(dwbuid));
    },
  });

export const useRemoveAutopayEnrollment = () =>
  useMutation({
    mutationFn: (params: RemoveAutopayEnrollmentRequest) =>
      removeAutopayEnrollment(params),
    onSuccess: (_data, { partyId, dwbuid }) => {
      queryClient.invalidateQueries(
        queryKeys.autopayEnrollment({ partyId, dwbuid }),
      );
      queryClient.invalidateQueries(queryKeys.accountDetails(dwbuid));
    },
  });

export const useSkipAutopay = () =>
  useMutation({
    mutationFn: (params: SkipAutopayRequest) => skipAutopay(params),
    onSuccess: (_data, { partyId, dwbuid }) => {
      queryClient.invalidateQueries(
        queryKeys.autopayEnrollment({ partyId, dwbuid }),
      );
    },
  });

export const useGetPaymentTransactions = (req: GetPaymentTransactionsRequest) =>
  useQuery({
    // NOTE: See note with queryKeys.paymentTransactions
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.paymentTransactions(),
    queryFn: () => getPaymentTransactions(req),
  });

export const useGetPaymentMethods = (req: GetPaymentMethodsRequest) =>
  useQuery({
    queryKey: queryKeys.paymentMethods(req),
    queryFn: () => getPaymentMethods(req),
  });

export const useDeleteSourceAccount = () =>
  useMutation({
    mutationFn: (params: { partyId: string; sourceAccountId: string }) =>
      deleteSourceAccount(params),
    onSuccess: (_, { partyId, sourceAccountId }) => {
      queryClient.setQueryData(
        queryKeys.paymentMethods({ partyId }),
        (oldData: GetPaymentMethodsResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return getUpdatedSourceAccountsWithDelete(sourceAccountId, oldData);
        },
      );
      queryClient.invalidateQueries(queryKeys.paymentMethods({ partyId }));
    },
  });

export const useAddBankSourceAccount = () =>
  useMutation({
    mutationFn: (params: AddBankSourceAccountRequest) =>
      addBankSourceAccount(params),
    onSuccess: (newBankSourceAccount, { partyId }) => {
      queryClient.setQueryData(
        queryKeys.paymentMethods({ partyId }),
        (oldData: GetPaymentMethodsResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return getUpdatedSourceAccountsWithAdd(newBankSourceAccount, oldData);
        },
      );
      queryClient.invalidateQueries(queryKeys.paymentMethods({ partyId }));
    },
  });

export const useEditBankSourceAccount = () =>
  useMutation({
    mutationFn: (params: EditBankSourceAccountRequest) =>
      editBankSourceAccount(params),
    onSuccess: (editedBankSourceAccount, params) => {
      queryClient.setQueryData(
        queryKeys.paymentMethods({ partyId: params.partyId }),
        (oldData: GetPaymentMethodsResponse | undefined) => {
          if (oldData === undefined) {
            return;
          }

          return getUpdatedSourceAccountsWithEdit(
            editedBankSourceAccount,
            params.sourceAccountId,
            oldData,
          );
        },
      );
      queryClient.invalidateQueries(
        queryKeys.paymentMethods({ partyId: params.partyId }),
      );
    },
  });

export const useAddCardSourceAccount = () =>
  useMutation({
    mutationFn: (params: AddCardSourceAccountRequest) =>
      addCardSourceAccount(params),
    onSuccess: (newCardSourceAccount, { partyId }) => {
      queryClient.setQueryData(
        queryKeys.paymentMethods({ partyId }),
        (oldData: GetPaymentMethodsResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return getUpdatedSourceAccountsWithAdd(newCardSourceAccount, oldData);
        },
      );
      queryClient.invalidateQueries(queryKeys.paymentMethods({ partyId }));
    },
  });

export const useEditCardSourceAccount = () =>
  useMutation({
    mutationFn: (params: EditCardSourceAccountRequest) =>
      editCardSourceAccount(params),
    onSuccess: (editedCardSourceAccount, params) => {
      queryClient.setQueryData(
        queryKeys.paymentMethods({ partyId: params.partyId }),
        (oldData: GetPaymentMethodsResponse | undefined) => {
          if (oldData === undefined) {
            return;
          }

          return getUpdatedSourceAccountsWithEdit(
            editedCardSourceAccount,
            params.sourceAccountId,
            oldData,
          );
        },
      );
      queryClient.invalidateQueries(
        queryKeys.paymentMethods({ partyId: params.partyId }),
      );
    },
  });

export const useMakeOneTimePayment = () =>
  useMutation({
    mutationFn: (params: MakeOneTimePaymentRequest) =>
      makeOneTimePayment(params),
    onSuccess: (_data, { dwbuid }) => {
      queryClient.invalidateQueries(queryKeys.accountDetails(dwbuid));
      queryClient.invalidateQueries(queryKeys.paymentTransactions());
    },
  });

export const useMakeSeriesPayment = () =>
  useMutation({
    mutationFn: (params: MakeSeriesPaymentRequest) => makeSeriesPayment(params),
    onSuccess: (_data, { dwbuid }) => {
      queryClient.invalidateQueries(queryKeys.accountDetails(dwbuid));
      queryClient.invalidateQueries(queryKeys.paymentTransactions());
    },
  });

export const useGetPaymentSeriesSchedule = () =>
  useMutation({
    mutationFn: (params: GetPaymentSeriesScheduleRequest) =>
      getPaymentSeriesSchedule(params),
  });

export const useGetPartyRestrictions = (partyId: string) =>
  useQuery({
    queryKey: queryKeys.partyRestrictions(partyId),
    queryFn: () => getPartyRestrictions(partyId),
  });

export const useGetBankNames = (abaNumber: string) =>
  useQuery({
    enabled: !!abaNumber,
    queryKey: queryKeys.bankNames(abaNumber),
    queryFn: () => getBankNames(abaNumber),
  });

export const useAddBankRestriction = () =>
  useMutation({
    mutationFn: (params: AddRestrictionRequest) => addBankRestriction(params),
    async onMutate(params) {
      await queryClient.cancelQueries(
        queryKeys.partyRestrictions(params.partyId),
      );

      const previousRestrictions: GetPartyRestrictionsResponse | undefined =
        queryClient.getQueryData(queryKeys.partyRestrictions(params.partyId));
      queryClient.setQueryData(
        queryKeys.partyRestrictions(params.partyId),
        (old: GetPartyRestrictionsResponse | undefined) => {
          if (!old) {
            return;
          }
          return {
            ...old,
            partyBankRestriction:
              params.restriction === RESTRICTION_NONE
                ? []
                : [{ restriction: params.restriction }],
          };
        },
      );
      queryClient.invalidateQueries(
        queryKeys.paymentMethods({ partyId: params.partyId }),
      );

      return { previousRestrictions };
    },
    onError(_error, { partyId }, context) {
      queryClient.setQueryData(
        queryKeys.partyRestrictions(partyId),
        context?.previousRestrictions,
      );
    },
  });

export const useAddCardRestriction = () =>
  useMutation({
    mutationFn: (params: AddRestrictionRequest) => addCardRestriction(params),
    async onMutate(params) {
      await queryClient.cancelQueries(
        queryKeys.partyRestrictions(params.partyId),
      );

      const previousRestrictions: GetPartyRestrictionsResponse | undefined =
        queryClient.getQueryData(queryKeys.partyRestrictions(params.partyId));
      queryClient.setQueryData(
        queryKeys.partyRestrictions(params.partyId),
        (old: GetPartyRestrictionsResponse | undefined) => {
          if (!old) {
            return;
          }
          return {
            ...old,
            partyCardRestriction:
              params.restriction === RESTRICTION_NONE
                ? []
                : [{ restriction: params.restriction }],
          };
        },
      );
      queryClient.invalidateQueries(
        queryKeys.paymentMethods({ partyId: params.partyId }),
      );

      return { previousRestrictions };
    },
    onError(_error, { partyId }, context) {
      queryClient.setQueryData(
        queryKeys.partyRestrictions(partyId),
        context?.previousRestrictions,
      );
    },
  });

export const useCancelPayment = () =>
  useMutation({
    mutationFn: cancelPayment,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.paymentTransactions());
    },
  });

export const useEditOneTimePayment = () =>
  useMutation({
    mutationFn: editOneTimePayment,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.paymentTransactions());
    },
  });

export const useEditSeriesPayment = () =>
  useMutation({
    mutationFn: editSeriesPayment,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.paymentTransactions());
    },
  });

export const useCancelSeriesPayment = () =>
  useMutation({
    mutationFn: cancelSeriesPayment,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.paymentTransactions());
    },
  });

export const useGetPermissionToDiscuss = (req: GetPermissionToDiscussRequest) =>
  useQuery({
    queryKey: queryKeys.permissionToDiscuss(req),
    queryFn: () => getPermissionToDiscuss(req),
    select: (data) => {
      const relationshipTypesById = data.relationshipTypes.reduce(
        (relationById, curr) => {
          relationById[curr.id] = curr;
          return relationById;
        },
        {} as Record<string, PermissionToDiscussRelationshipType>,
      );
      const permissionsById = data.permissionsList.reduce(
        (permissionById, curr) => {
          permissionById[curr.id] = curr;
          return permissionById;
        },
        {} as Record<string, PermissionToDiscussPermission>,
      );

      return {
        lastVerifiedDate: data.lastVerifiedDate,
        permissionsList: data.permissionsList,
        relationshipTypesById,
        permissionsById,
      };
    },
  });

export const useAddPermissionToDiscuss = (req: GetPermissionToDiscussRequest) =>
  useMutation({
    mutationFn: addPermissionToDicuss,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.permissionToDiscuss(req));
    },
  });

export const useEditPermissionToDiscuss = (
  req: GetPermissionToDiscussRequest,
) =>
  useMutation({
    mutationFn: editPermissionToDicuss,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.permissionToDiscuss(req));
    },
  });

export const useVerifyPermissionToDiscuss = (
  req: GetPermissionToDiscussRequest,
) =>
  useMutation({
    mutationFn: verifyPermissionToDiscuss,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.permissionToDiscuss(req));
    },
  });

export const useRemovePermissionToDicuss = (
  req: GetPermissionToDiscussRequest,
) =>
  useMutation({
    mutationFn: removePermissionToDiscuss,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.permissionToDiscuss(req));
    },
  });

export const useGetCycleToDateTransactions = (dwbuid: string) =>
  useQuery({
    queryKey: queryKeys.cycleToDateTransactions(dwbuid),
    queryFn: () => getCycleToDateTransactions(dwbuid),
  });

export const useGetStatementHistory = (dwbuid: string) =>
  useQuery({
    queryKey: queryKeys.statementHistory(dwbuid),
    queryFn: () => getStatementHistory(dwbuid),
  });

export const useGetLostStolenOptions = (dwbuid: string) =>
  useQuery({
    queryKey: queryKeys.lostStolenOptions(dwbuid),
    queryFn: () => getLostStolenOptions(dwbuid),
  });

export const useSubmitLostStolen = () =>
  useMutation({
    mutationFn: (params: SubmitLostStolenRequest) => submitLostStolen(params),
  });

export const useGetCreditLineIncreaseEligibility = (
  req: GetCreditLineIncreaseEligibilityRequest,
) =>
  useQuery({
    queryKey: queryKeys.creditLineHistoryEligibility(req),
    queryFn: () => getCreditLineIncreaseEligibility(req),
  });

export const useSubmitCreditLineIncrease = () =>
  useMutation({
    mutationFn: submitCreditLineIncrease,
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(
        queryKeys.creditLineHistoryEligibility({
          dwbuid: req.dwbuid,
          referenceId: req.referenceId,
        }),
      );
    },
  });

export const useGetCallInfo = (req: GetCallInfoRequest) =>
  useQuery({
    queryKey: queryKeys.callInfo(req),
    queryFn: () => getCallInfo(req),
    enabled: !!req.callId,
  });
