import classNames from "classnames";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import { GetAccountResponse } from "../../lib/api/models";
import { useGetCycleToDateTransactions } from "../../lib/api/queries";
import { centsToDollarString } from "../../lib/formatting";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import StyledError from "../StyledError";
import Container from "../common/Container";
import Spinner from "../common/Spinner";
import RoundButton from "../common/buttons/RoundButton";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./CycleToDateTransactions.module.css";
import CycleToDateTransactionsDeclinedTable from "./CycleToDateTransactionsDeclinedTable";
import CycleToDateTransactionsPendingTable from "./CycleToDateTransactionsPendingTable";
import CycleToDateTransactionsPostedTable from "./CycleToDateTransactionsPostedTable";
import { format } from "date-fns";

const strings = {
  refresh: "Refresh",
  close: "Close",
  ctdTransactions: "CTD Transactions",
  pendingAuthorizations: "Pending Authorizations",
  declinedAuthorizations: "Declined Authorizations",
  lastStmtBal: "Last Stmt Bal",
  creditLimit: "Credit Limit",
  currentBalance: "Current Balance",
  availableCredit: "Available Credit",
  lastDecline: "Last Decline",
  declines: "Declines",
  plusPending: "Plus Pending Fees & Finance Charges",
  newBalance: "New Balance",
  errorTitle: "Failed to load Cycle to Date Transactions",
};

type Props = {
  className?: string;
  account: GetAccountResponse;
  toolId: string;
  onRequestClose: () => void;
};

export default function CycleToDateTransactions({
  className,
  account,
  toolId,
  onRequestClose,
}: Props) {
  const [currentTab, setCurrentTab] = useState<
    "posted" | "pending" | "declined"
  >("posted");

  const {
    data: getCycleToDateTransactionsResponse,
    isLoading: isGetCycleToDateTransactionsResponseLoading,
    isError: isGetCycleToDateTransactionsResponseError,
    error: getCycleToDateTransactionsResponseError,
    refetch: getCycleToDateTransactionsResponseRefetch,
    isRefetching: isGetCycleToDateTransactionsResponseRefetching,
  } = useGetCycleToDateTransactions(account.currentDwbuid);

  function renderTitle() {
    return (
      <h2 className={styles.header}>
        {toolTypeToName(ToolType.CycleToDateTransactions)}
      </h2>
    );
  }

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetCycleToDateTransactionsResponseLoading ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </>
        ) : isGetCycleToDateTransactionsResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <StyledError
                  errorTitle={strings.errorTitle}
                  error={getCycleToDateTransactionsResponseError}
                  refetch={getCycleToDateTransactionsResponseRefetch}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={5}>{renderTitle()}</Col>
              <Col md={2} offset={{ md: 3 }}>
                <div className={styles.lastStatementBalanceContainer}>
                  {currentTab === "posted" ? (
                    <div className={styles.headerLabelValue}>
                      <div className={styles.headerLabel}>
                        {strings.lastStmtBal}
                      </div>
                      <div className={styles.headerValue}>
                        {centsToDollarString(
                          getCycleToDateTransactionsResponse.lastStatementBalanceCents,
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={1}>
                <StyledButton
                  className={styles.headerButton}
                  renderLoadingIndicator={false}
                  variant="secondary"
                  onClick={() => getCycleToDateTransactionsResponseRefetch()}
                  disabled={isGetCycleToDateTransactionsResponseRefetching}
                >
                  {strings.refresh}
                </StyledButton>
              </Col>
              <Col md={1}>
                <StyledButton
                  className={styles.headerButton}
                  renderLoadingIndicator={false}
                  variant="secondary"
                  onClick={onRequestClose}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.divider} />
              </Col>
            </Row>
            <Row className={styles.tabContainer}>
              <Col md={8}>
                <RoundButton
                  className={styles.tabButton}
                  variant={currentTab === "posted" ? "blue" : "grey"}
                  onClick={() => setCurrentTab("posted")}
                >
                  {strings.ctdTransactions}
                </RoundButton>
                <RoundButton
                  className={styles.tabButton}
                  variant={currentTab === "pending" ? "blue" : "grey"}
                  onClick={() => setCurrentTab("pending")}
                >
                  {strings.pendingAuthorizations}
                </RoundButton>
                <RoundButton
                  className={styles.tabButton}
                  variant={currentTab === "declined" ? "blue" : "grey"}
                  onClick={() => setCurrentTab("declined")}
                >
                  {strings.declinedAuthorizations}
                </RoundButton>
              </Col>
              {currentTab === "pending" ? (
                <Col md={4}>
                  <div className={styles.flex}>
                    <div className={styles.headerLabelValue}>
                      <div className={styles.headerLabel}>
                        {strings.creditLimit}
                      </div>
                      <div className={styles.headerValue}>
                        {centsToDollarString(
                          getCycleToDateTransactionsResponse.creditLimitCents,
                        )}
                      </div>
                    </div>
                    <div className={styles.headerLabelValue}>
                      <div className={styles.headerLabel}>
                        {strings.currentBalance}
                      </div>
                      <div className={styles.headerValue}>
                        {centsToDollarString(
                          getCycleToDateTransactionsResponse.currentBalanceCents,
                        )}
                      </div>
                    </div>
                    <div className={styles.headerLabelValue}>
                      <div className={styles.headerLabel}>
                        {strings.availableCredit}
                      </div>
                      <div className={styles.headerValue}>
                        {centsToDollarString(
                          getCycleToDateTransactionsResponse.availableCreditCents,
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ) : null}
              {currentTab === "declined" ? (
                <Col md={4}>
                  <div className={styles.flex}>
                    <div className={styles.headerLabelValue}>
                      <div className={styles.headerLabel}>
                        {strings.lastDecline}
                      </div>
                      <div className={styles.headerValue}>
                        {getCycleToDateTransactionsResponse.lastDeclineDate
                          ? format(
                              new Date(
                                getCycleToDateTransactionsResponse.lastDeclineDate,
                              ),
                              "MM/dd/yyyy HH:mm:ss",
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className={styles.headerLabelValue}>
                      <div className={styles.headerLabel}>
                        {strings.declines}
                      </div>
                      <div className={styles.headerValue}>
                        {getCycleToDateTransactionsResponse.declinesThisCycle}
                      </div>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col>
                {currentTab === "posted" ? (
                  <CycleToDateTransactionsPostedTable
                    transactions={
                      getCycleToDateTransactionsResponse.postedTransactions
                    }
                  />
                ) : currentTab === "pending" ? (
                  <CycleToDateTransactionsPendingTable
                    transactions={
                      getCycleToDateTransactionsResponse.pendingAuthorizations
                    }
                  />
                ) : (
                  <CycleToDateTransactionsDeclinedTable
                    transactions={
                      getCycleToDateTransactionsResponse.declinedAuthorizations
                    }
                  />
                )}
              </Col>
            </Row>
            {currentTab === "posted" ? (
              <Row>
                <Col md={4} offset={{ md: 8 }}>
                  <div className={styles.postedBox}>
                    <div className={styles.postedBoxLabels}>
                      <div className={styles.headerLabel}>
                        {strings.plusPending}
                      </div>
                      <div className={styles.headerLabel}>
                        {strings.newBalance}
                      </div>
                    </div>
                    <div className={styles.postedBoxValues}>
                      <div className={styles.headerValue}>
                        {centsToDollarString(
                          getCycleToDateTransactionsResponse.pendingFeesAndChargesCents,
                        )}
                      </div>
                      <div className={styles.headerValue}>
                        {centsToDollarString(
                          getCycleToDateTransactionsResponse.currentBalanceCents,
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
}
