import { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DateString,
  GetCardmemberMailListResponse,
} from "../../lib/api/models";
import { useGetAccount, useGetCardmemberMailList } from "../../lib/api/queries";
import { useSecureMailRedirectWithoutRole } from "../../lib/routeRedirect.hooks";
import { paths } from "../../lib/router";
import StyledError from "../../modules/StyledError";
import Container from "../../modules/common/Container";
import DateInput from "../../modules/common/DateInput";
import Spinner from "../../modules/common/Spinner";
import StyledLinkButton from "../../modules/common/StyledLinkButton";
import StyledButton from "../../modules/common/buttons/StyledButton";
import SecureMailTable from "../../modules/common/tables/SecureMailTable";
import styles from "./AccountSecureMailPage.module.css";

const PAGE_SIZE = 5;

const strings = {
  cardMemberName: "CM Name:",
  cardMemberErrorTitle: "Cardmember SecureMail failed to load",
  composeSecureMail: "Compose SecureMail",
  endDate: "End Date",
  errorTitle: "Account SecureMail failed to load",
  retry: "Retry",
  startDate: "Start Date",
  titleRow: (dwbuid: string) => `Account ID: ${dwbuid}`,
};

type Props = {
  dwbuid: string;
};

export default function AccountSecureMailPage({ dwbuid }: Props) {
  useSecureMailRedirectWithoutRole();

  const navigate = useNavigate();
  const {
    data: account,
    isLoading: isGetAccountLoading,
    isFetching: isGetAccountFetching,
    isError: isGetAccountError,
    error: getAccountError,
    refetch: getAccountRefetch,
  } = useGetAccount(dwbuid);
  const partyId = account?.partyId;

  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = (searchParams.get("startDate") as DateString) || "";
  const endDate = (searchParams.get("endDate") as DateString) || "";

  let selectedPageIndex = 1;
  const parsedPage = parseInt(searchParams.get("page") ?? "");
  if (!isNaN(parsedPage) && parsedPage > 1) {
    selectedPageIndex = parsedPage;
  }

  const {
    data: cardmemberMailList,
    isLoading: isGetCardmemberMailListLoading,
    isError: isGetCardmemberMailListError,
    error: getCardmemberMailListError,
    refetch: cardmemberMailListRefetch,
    isFetching: isGetCardmemberMailListFetching,
  } = useGetCardmemberMailList(
    {
      partyId: partyId ?? "",
      size: PAGE_SIZE,
      page: selectedPageIndex - 1,
      start: startDate !== "" ? startDate : undefined,
      end: endDate !== "" ? endDate : undefined,
    },
    !!partyId,
  );

  function setPaginationLink(pageIndex: number) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", pageIndex.toString());
    return newSearchParams.toString();
  }
  function getPaginationObj(mailList: GetCardmemberMailListResponse) {
    const total = mailList.total;
    const numPages = Math.ceil(total / PAGE_SIZE);

    const paginationLinks = [];
    for (let i = 1; i <= numPages; i++) {
      paginationLinks.push({
        pathname: `/account/${dwbuid}/secure-mail`,
        search: setPaginationLink(i),
      });
    }

    const paginationObj = {
      forwardArrowPath: {
        pathname: `/account/${dwbuid}/secure-mail`,
        search: setPaginationLink(selectedPageIndex + 1),
      },
      backArrowPath: {
        pathname: `/account/${dwbuid}/secure-mail`,
        search: setPaginationLink(selectedPageIndex - 1),
      },

      paginationLinks: paginationLinks,
    };
    return paginationObj;
  }

  const disableComposeSecureMail =
    !account?.registrationDateTime ||
    isGetAccountLoading ||
    isGetAccountFetching;
  const disableDateInput =
    !account?.registrationDateTime ||
    isGetAccountLoading ||
    isGetAccountFetching ||
    isGetCardmemberMailListLoading ||
    isGetCardmemberMailListFetching;

  useEffect(() => {
    if (account && !account.registrationDateTime) {
      navigate(paths.root);
    }
  }, [account, navigate]);

  return (
    <Container>
      <div className={styles.titleRow}>
        <div>
          <div className={styles.title}>{strings.titleRow(dwbuid)}</div>
          <div>
            {strings.cardMemberName} {account?.firstName} {account?.lastName}
          </div>
        </div>
        <div className={styles.dateInputs}>
          <DateInput
            className={styles.dateInput}
            label={strings.startDate}
            value={startDate}
            onChangeDate={(newDateString) => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("startDate", newDateString);
              setSearchParams(newSearchParams);
            }}
            disabled={disableDateInput}
          />
          <DateInput
            className={styles.dateInput}
            label={strings.endDate}
            value={endDate}
            onChangeDate={(newDateString) => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("endDate", newDateString);
              setSearchParams(newSearchParams);
            }}
            disabled={disableDateInput}
          />
          {!disableComposeSecureMail ? (
            <StyledLinkButton
              className={styles.composeSecureMailButton}
              to={paths.composeSecureMailPage(dwbuid)}
            >
              {strings.composeSecureMail}
            </StyledLinkButton>
          ) : (
            <StyledButton
              className={styles.composeSecureMailButton}
              disabled={disableComposeSecureMail}
            >
              {strings.composeSecureMail}
            </StyledButton>
          )}
        </div>
      </div>

      {isGetAccountError ? (
        <Row>
          <Col>
            <StyledError
              error={getAccountError}
              errorTitle={strings.errorTitle}
              refetch={getAccountRefetch}
            />
          </Col>
        </Row>
      ) : isGetAccountLoading ||
        isGetAccountFetching ||
        isGetCardmemberMailListLoading ||
        isGetCardmemberMailListFetching ? (
        <Row>
          <Col offset={{ xs: 6 }} xs={1}>
            <Spinner className={styles.spinner} />
          </Col>
        </Row>
      ) : isGetCardmemberMailListError ? (
        <Row>
          <Col>
            <StyledError
              error={getCardmemberMailListError}
              errorTitle={strings.cardMemberErrorTitle}
              refetch={cardmemberMailListRefetch}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <SecureMailTable
              variant="cardmember"
              secureMail={{
                ...cardmemberMailList,
                secureMail: cardmemberMailList.secureMail.map((mail) => {
                  return { ...mail, dwbuid };
                }),
              }}
              pageSize={PAGE_SIZE}
              paginationObj={getPaginationObj(cardmemberMailList)}
              onClickRow={(id, page) =>
                navigate(`/secure-mail/${id}`, {
                  state: {
                    backLink:
                      paths.accountSecureMail(dwbuid) +
                      `?${setPaginationLink(page)}`,
                  },
                })
              }
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}
