import * as Sentry from "@sentry/react";

export const initializeBugReporting = () => {
  Sentry.init({
    dsn: "https://ad69d5f162424f48812bbba032b7e99d@o1271244.ingest.sentry.io/4505398973038592",
    environment: import.meta.env.MODE,
    beforeSend: (event) => {
      if (
        import.meta.env.DEV ||
        (import.meta.env.MODE === "e2e" &&
          import.meta.env.VITE_REPORT_E2E_ERRORS !== "true")
      ) {
        return null;
      }
      return event;
    },
  });
};

const isDev = import.meta.env.DEV;
/* eslint-disable no-console */
export const leaveBreadcrumb = (
  message: string,
  metadata?: Record<string, unknown>,
): void => {
  if (isDev) {
    console.log(message, metadata);
  }
  Sentry.addBreadcrumb({
    message,
    data: metadata,
  });
};

export const reportWarning = (
  w: Error,
  metadata?: Record<string, unknown>,
): void => {
  if (isDev) {
    console.warn(w, metadata);
  }
  Sentry.captureException(w, {
    level: "warning",
    extra: metadata,
  });
};

export const reportError = (
  err: Error,
  metadata?: Record<string, unknown>,
): void => {
  if (isDev) {
    console.error(err, metadata);
  }
  Sentry.captureException(err, {
    level: "error",
    extra: metadata,
  });
};
