import classNames from "classnames";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { getDisplayError } from "../../lib/api/endpoints";
import { GetAccountResponse, PaymentMethod } from "../../lib/api/models";
import {
  useGetAccountDetails,
  useGetPartyRestrictions,
  useGetPaymentMethods,
} from "../../lib/api/queries";
import { ToolState, ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import Container from "../common/Container";
import Spinner from "../common/Spinner";
import ToolLoadError from "../common/ToolLoadError";
import AddSourceAccount from "./AddSourceAccount";
import EditSourceAccount from "./EditSourceAccount";
import styles from "./ManageSourceAccounts.module.css";
import SourceAccountsTitle from "./SourceAccountsTitle";
import ViewSourceAccounts from "./ViewSourceAccounts/ViewSourceAccounts";

type Props = {
  account: GetAccountResponse;
  className?: string;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  tool: ToolState;
  onDisplayGeneralErrors: (err: unknown) => void;
  onRequestClose: () => void;
};

enum SourceAccountMode {
  View = "View",
  Edit = "Edit",
  Add = "Add",
}
type SourceAccountModeState =
  | {
      mode: SourceAccountMode.View | SourceAccountMode.Add;
    }
  | { mode: SourceAccountMode.Edit; paymentMethod: PaymentMethod };

export default function ManageSourceAccounts({
  account: cardmemberAccount,
  className,
  onSetToolInProgress,
  tool,
  onDisplayGeneralErrors,
  onRequestClose,
}: Props) {
  const getPaymentMethodsQuery = useGetPaymentMethods({
    partyId: cardmemberAccount.partyId,
  });
  const getAccountDetailsQuery = useGetAccountDetails(
    cardmemberAccount.currentDwbuid,
  );
  const getPartyRestrictionsQuery = useGetPartyRestrictions(
    cardmemberAccount.partyId,
  );
  const isQueryLoading =
    getPaymentMethodsQuery.isLoading ||
    getAccountDetailsQuery.isLoading ||
    getPartyRestrictionsQuery.isLoading;
  const isQueryError =
    getPaymentMethodsQuery.isError ||
    getAccountDetailsQuery.isError ||
    getPartyRestrictionsQuery.isError;
  const [currentMode, setCurrentMode] = useState<SourceAccountModeState>({
    mode: SourceAccountMode.View,
  });
  useEffect(() => {
    onSetToolInProgress(tool.id, currentMode.mode !== SourceAccountMode.View);
  }, [currentMode, onSetToolInProgress, tool.id]);

  return (
    <div className={classNames(styles.mainContainer, className)} id={tool.id}>
      <Container>
        {isQueryLoading ? (
          <>
            <Row>
              <Col md={3}>
                <SourceAccountsTitle />
              </Col>
            </Row>
            <Row>
              <Col offset={{ xs: 6 }} xs={1}>
                <Spinner className={styles.spinner} />
              </Col>
            </Row>
          </>
        ) : isQueryError ? (
          <>
            <Row>
              <Col md={3}>
                <SourceAccountsTitle />
              </Col>
            </Row>
            <Row>
              <Col>
                <ToolLoadError
                  toolName={toolTypeToName(ToolType.SourceAccounts)}
                  errorDescription={[
                    getPaymentMethodsQuery.error
                      ? getDisplayError(getPaymentMethodsQuery.error)
                      : "",
                    getAccountDetailsQuery.error
                      ? getDisplayError(getAccountDetailsQuery.error)
                      : "",
                    getPartyRestrictionsQuery.error
                      ? getDisplayError(getPartyRestrictionsQuery.error)
                      : "",
                  ].join(" ")}
                  onRetry={() => {
                    // FIXME: A bit cleaner if we only refetch failed queries
                    getPaymentMethodsQuery.refetch();
                    getAccountDetailsQuery.refetch();
                    getPartyRestrictionsQuery.refetch();
                  }}
                />
              </Col>
            </Row>
          </>
        ) : currentMode.mode === SourceAccountMode.View ? (
          <ViewSourceAccounts
            cardmemberAccount={cardmemberAccount}
            onAddPaymentMethod={() =>
              setCurrentMode({ mode: SourceAccountMode.Add })
            }
            onEditPaymentMethod={(paymentMethod) =>
              setCurrentMode({
                mode: SourceAccountMode.Edit,
                paymentMethod,
              })
            }
            partyRestrictions={getPartyRestrictionsQuery.data}
            paymentMethods={getPaymentMethodsQuery.data}
            onRequestClose={onRequestClose}
          />
        ) : currentMode.mode === SourceAccountMode.Add ? (
          <AddSourceAccount
            cardmemberAccount={cardmemberAccount}
            cardmemberAccountDetails={getAccountDetailsQuery.data}
            onSuccess={() => setCurrentMode({ mode: SourceAccountMode.View })}
            onCancel={() => setCurrentMode({ mode: SourceAccountMode.View })}
            onDisplayGeneralErrors={onDisplayGeneralErrors}
            onRequestClose={onRequestClose}
          />
        ) : currentMode.mode === SourceAccountMode.Edit ? (
          <EditSourceAccount
            restrictions={getPartyRestrictionsQuery.data}
            cardmemberAccount={cardmemberAccount}
            cardmemberAccountDetails={getAccountDetailsQuery.data}
            paymentMethod={currentMode.paymentMethod}
            onCancel={() => setCurrentMode({ mode: SourceAccountMode.View })}
            onSuccess={() => setCurrentMode({ mode: SourceAccountMode.View })}
            onDisplayGeneralErrors={onDisplayGeneralErrors}
            onRequestClose={onRequestClose}
          />
        ) : null}
      </Container>
    </div>
  );
}
