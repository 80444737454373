import classNames from "classnames";
import { paths } from "../../../lib/router";
import ClassificationCard from "../ClassificationCard";
import styles from "./ClassificationList.module.css";

type ClassificationCount = {
  id: string;
  description: string;
  count: number;
};

type Props = {
  classifications: ClassificationCount[];
  selectedClassificationId?: string;
  className?: string;
};

export default function ClassificationList({
  classifications,
  selectedClassificationId,
  className,
}: Props) {
  return (
    <div className={classNames(styles.classificationList, className)}>
      {classifications.map((classification) => {
        return (
          <ClassificationCard
            key={classification.id}
            id={classification.id}
            title={classification.description}
            numberOfItems={classification.count}
            selected={selectedClassificationId === classification.id}
            to={{
              pathname: paths.secureMailQueue,
              search: `classification=${classification.id}`,
            }}
          />
        );
      })}
    </div>
  );
}
