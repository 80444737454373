import { nanoid } from "nanoid";
import {
  DynamicErrorResponse,
  EnrollmentType,
  GetAccountDetailsResponse,
  GetAccountResponse,
  GetAgentDetailsResponse,
  GetAutopayEnrollmentResponse,
  GetBankNamesResponse,
  GetCallInfoResponse,
  GetCardmemberMailListResponse,
  GetClassificationCountResponse,
  GetClassificationsResponse,
  GetCreditLineIncreaseEligibilityResponse,
  GetCycleToDateTransactionsResponse,
  GetPartyRestrictionsResponse,
  GetPaymentHistoryResponse,
  GetPaymentMethodsResponse,
  GetPaymentSeriesScheduleResponse,
  GetPaymentTransactionsResponse,
  GetPermissionToDiscussResponse,
  GetSecureMailListResponse,
  GetSecureMailThreadResponse,
  GetStatementHistoryResponse,
  LostStolenOptionsResponse,
  PaymentOverridesResponse,
  PaymentScriptsResponse,
  SecureMailConversationType,
  SubmitCreditLineIncreaseResponse,
} from "../models";

export const MOCK_GET_AGENT_DETAILS_RESPONSE: GetAgentDetailsResponse = {
  roles: [
    "ach",
    "unlock",
    "usa",
    "username",
    "achPaper",
    "viewPCIData",
    "makePayment",
    "addPaymentRestriction",
    "removePaymentRestriction",
    "permissionToDiscuss",
    "cycleToDateTransactions",
    "statementHistory",
    "viewAccountNumber",
    "cli",
  ],
};

export const MOCK_ACCOUNT: GetAccountResponse = {
  currentDwbuid: "unpopulated",
  partyId: "3438",
  firstName: "Ebony",
  lastName: "Black",
  registrationDateTime: new Date("2022-08-14T02:41:00.000Z"),
};

export const MOCK_ACCOUNT_DETAILS: GetAccountDetailsResponse = {
  activated: false,
  addressLine1: "123 Fake St",
  addressLine2: "Suite 106",
  amountDueCents: 0,
  authorizedUser: "Indigo Blue",
  autopayDisabledReason: null,
  cardNumber: "2234123412341234",
  city: "Austin",
  creditAvailableCents: 5000,
  creditLimitCents: 50000,
  currentBalanceCents: 15000,
  currentDwbuid: "unpopulated",
  daysDelinquent: 41,
  delinquentAmountCents: 5000,
  delinquentBucket: "30",
  employeeVipStatus: "No",
  externalStatusCode: "Z",
  internalStatusCode: "A",
  isEnrolledAutopay: true,
  isLocked: true,
  lastContactCode: "12345",
  lastContactDate: "06/23/2022",
  lastStatementBalanceCents: 0,
  minPayDueCents: 1000,
  name: "Ebony Black",
  nextCycleDate: "2023-08-23",
  oclAmountCents: 100,
  openDate: "2023-12-01",
  p2d: ["Kelly Green / Mother", "Frank Green / Father"],
  partyId: "3438",
  paymentDueDate: "2023-07-15",
  permanentQueue: "000",
  phoneNumber: "1234567890",
  prevDwbuid: "lorem",
  prevDwbuid2: "lorem",
  prevDwbuid3: "lorem",
  registrationDateTime: "09/09/2023",
  scheduledPayments: [
    {
      id: "100",
      amountCents: 2500,
      scheduledPaymentDate: "2023-10-18",
      confirmationId: "100",
      paymentAmount: "custom",
      paymentMethod: { id: "1000", name: "Visa" },
      status: "processing",
    },
    {
      id: "101",
      amountCents: 10000,
      scheduledPaymentDate: "2023-10-19",
      confirmationId: "101",
      paymentAmount: "custom",
      paymentMethod: { id: "1000", name: "Visa" },
      status: "processing",
    },
    {
      id: "102",
      amountCents: 10000,
      scheduledPaymentDate: "2023-10-20",
      confirmationId: "102",
      paymentAmount: "custom",
      paymentMethod: { id: "1000", name: "Visa" },
      status: "processing",
    },
    {
      id: "103",
      amountCents: 10000,
      scheduledPaymentDate: "2023-10-21",
      confirmationId: "103",
      paymentAmount: "custom",
      paymentMethod: { id: "1000", name: "Visa" },
      status: "processing",
    },
  ],
  specialHoldingId: "lorem",
  state: "Texas",
  stateCode: "TX",
  statusReasonCode: "lorem",
  temporaryQueue: "103",
  totalAmountDueCents: 15000,
  transferReason: "05/2016",
  zipcode: "77777",
  lastPaymentDate: "2023-01-01",
  lastStatementDate: "2023-01-01",
  username: "MockUsername123",
};

export const MOCK_PAYMENT_HISTORY: GetPaymentHistoryResponse = [
  {
    cycleDate: "2023-04-08",
    amountDue: 75667,
    amountDelq: 50365,
    delqBucket: "56",
    dueDate: "2022-12-22",
    paymentInfo: [
      {
        amountPaid: 39851,
        postDate: "2023-04-23",
        effectiveDate: "2022-09-30",
        transactionType: "PMT",
      },
      {
        amountPaid: -20539,
        postDate: "2023-07-13",
        effectiveDate: "2023-02-21",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: 23818,
        postDate: "2022-12-21",
        effectiveDate: "2022-09-23",
        transactionType: "PMT",
      },
      {
        amountPaid: 83882,
        postDate: "2022-12-15",
        effectiveDate: "2022-12-19",
        transactionType: "PMT",
      },
      {
        amountPaid: 5103,
        postDate: "2023-07-15",
        effectiveDate: "2022-11-10",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -46816,
        postDate: "2023-03-26",
        effectiveDate: "2023-02-01",
        transactionType: "FC Adj",
      },
      {
        amountPaid: 77333,
        postDate: "2023-05-04",
        effectiveDate: "2022-08-23",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -13823,
        postDate: "2023-03-30",
        effectiveDate: "2022-10-19",
        transactionType: "FC Adj",
      },
    ],
  },
  {
    cycleDate: "2023-01-19",
    amountDue: 98262,
    amountDelq: 2302,
    delqBucket: "62",
    dueDate: "2023-02-04",
    paymentInfo: [],
  },
  {
    cycleDate: "2022-12-20",
    amountDue: 81115,
    amountDelq: 1039,
    delqBucket: "69",
    dueDate: "2022-08-29",
    paymentInfo: [
      {
        amountPaid: -6964,
        postDate: "2023-02-19",
        effectiveDate: "2023-04-16",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2022-08-29",
    amountDue: 27352,
    amountDelq: 55577,
    delqBucket: "55",
    dueDate: "2023-03-04",
    paymentInfo: [],
  },
  {
    cycleDate: "2022-09-21",
    amountDue: 8530,
    amountDelq: 60672,
    delqBucket: "31",
    dueDate: "2023-03-17",
    paymentInfo: [
      {
        amountPaid: -98222,
        postDate: "2023-05-16",
        effectiveDate: "2023-06-14",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -20767,
        postDate: "2023-06-03",
        effectiveDate: "2023-02-09",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -11751,
        postDate: "2023-04-22",
        effectiveDate: "2023-07-09",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -88204,
        postDate: "2023-03-08",
        effectiveDate: "2023-05-22",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 4242,
        postDate: "2022-09-18",
        effectiveDate: "2023-03-05",
        transactionType: "PMT RVRSL",
      },
    ],
  },
  {
    cycleDate: "2023-01-05",
    amountDue: 75366,
    amountDelq: 2525,
    delqBucket: "24",
    dueDate: "2022-11-07",
    paymentInfo: [],
  },
  {
    cycleDate: "2023-05-25",
    amountDue: 59539,
    amountDelq: 5221,
    delqBucket: "12",
    dueDate: "2022-08-08",
    paymentInfo: [
      {
        amountPaid: 20673,
        postDate: "2023-01-14",
        effectiveDate: "2022-09-10",
        transactionType: "PMT",
      },
      {
        amountPaid: -5115,
        postDate: "2022-10-06",
        effectiveDate: "2023-06-14",
        transactionType: "PMT",
      },
      {
        amountPaid: 50635,
        postDate: "2022-11-24",
        effectiveDate: "2022-10-18",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2023-03-14",
    amountDue: 59944,
    amountDelq: 29211,
    delqBucket: "25",
    dueDate: "2022-08-26",
    paymentInfo: [
      {
        amountPaid: 74233,
        postDate: "2022-11-06",
        effectiveDate: "2023-05-09",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -64565,
        postDate: "2023-01-07",
        effectiveDate: "2022-12-05",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 29278,
        postDate: "2023-04-01",
        effectiveDate: "2023-02-14",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -89215,
        postDate: "2023-07-22",
        effectiveDate: "2023-01-18",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -55717,
        postDate: "2023-05-20",
        effectiveDate: "2023-07-11",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -53568,
        postDate: "2023-01-26",
        effectiveDate: "2023-01-26",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -76208,
        postDate: "2023-05-22",
        effectiveDate: "2022-09-01",
        transactionType: "PMT",
      },
      {
        amountPaid: 70035,
        postDate: "2023-01-22",
        effectiveDate: "2023-01-01",
        transactionType: "PMT RVRSL",
      },
    ],
  },
  {
    cycleDate: "2022-10-04",
    amountDue: 94518,
    amountDelq: 41494,
    delqBucket: "96",
    dueDate: "2022-12-17",
    paymentInfo: [
      {
        amountPaid: -18318,
        postDate: "2022-10-10",
        effectiveDate: "2023-04-15",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2023-04-20",
    amountDue: 74439,
    amountDelq: 82308,
    delqBucket: "37",
    dueDate: "2023-02-27",
    paymentInfo: [],
  },
  {
    cycleDate: "2023-03-16",
    amountDue: 72138,
    amountDelq: 66919,
    delqBucket: "64",
    dueDate: "2023-02-13",
    paymentInfo: [
      {
        amountPaid: 1976,
        postDate: "2022-10-26",
        effectiveDate: "2022-10-26",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -3009,
        postDate: "2023-05-19",
        effectiveDate: "2022-10-16",
        transactionType: "LF Adj",
      },
    ],
  },
  {
    cycleDate: "2022-11-09",
    amountDue: 75759,
    amountDelq: 4538,
    delqBucket: "65",
    dueDate: "2022-08-15",
    paymentInfo: [
      {
        amountPaid: -44064,
        postDate: "2022-08-26",
        effectiveDate: "2023-06-27",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 5649,
        postDate: "2022-08-11",
        effectiveDate: "2022-11-28",
        transactionType: "FC Adj",
      },
      {
        amountPaid: 68188,
        postDate: "2022-09-04",
        effectiveDate: "2023-01-15",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -2991,
        postDate: "2022-08-14",
        effectiveDate: "2022-12-19",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -53202,
        postDate: "2022-08-06",
        effectiveDate: "2022-12-16",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -66764,
        postDate: "2023-03-11",
        effectiveDate: "2023-04-09",
        transactionType: "PMT",
      },
      {
        amountPaid: 11491,
        postDate: "2022-08-26",
        effectiveDate: "2023-02-08",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: 62142,
        postDate: "2022-11-28",
        effectiveDate: "2022-08-30",
        transactionType: "PMT",
      },
      {
        amountPaid: -49123,
        postDate: "2023-08-02",
        effectiveDate: "2023-06-25",
        transactionType: "LF Adj",
      },
    ],
  },
  {
    cycleDate: "2023-04-08",
    amountDue: 75667,
    amountDelq: 50365,
    delqBucket: "56",
    dueDate: "2022-12-22",
    paymentInfo: [
      {
        amountPaid: 39851,
        postDate: "2023-04-23",
        effectiveDate: "2022-09-30",
        transactionType: "PMT",
      },
      {
        amountPaid: -20539,
        postDate: "2023-07-13",
        effectiveDate: "2023-02-21",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: 23818,
        postDate: "2022-12-21",
        effectiveDate: "2022-09-23",
        transactionType: "PMT",
      },
      {
        amountPaid: 83882,
        postDate: "2022-12-15",
        effectiveDate: "2022-12-19",
        transactionType: "PMT",
      },
      {
        amountPaid: 5103,
        postDate: "2023-07-15",
        effectiveDate: "2022-11-10",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -46816,
        postDate: "2023-03-26",
        effectiveDate: "2023-02-01",
        transactionType: "FC Adj",
      },
      {
        amountPaid: 77333,
        postDate: "2023-05-04",
        effectiveDate: "2022-08-23",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -13823,
        postDate: "2023-03-30",
        effectiveDate: "2022-10-19",
        transactionType: "FC Adj",
      },
    ],
  },
  {
    cycleDate: "2023-01-19",
    amountDue: 98262,
    amountDelq: 2302,
    delqBucket: "62",
    dueDate: "2023-02-04",
    paymentInfo: [],
  },
  {
    cycleDate: "2022-12-20",
    amountDue: 81115,
    amountDelq: 1039,
    delqBucket: "69",
    dueDate: "2022-08-29",
    paymentInfo: [
      {
        amountPaid: -6964,
        postDate: "2023-02-19",
        effectiveDate: "2023-04-16",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2022-08-29",
    amountDue: 27352,
    amountDelq: 55577,
    delqBucket: "55",
    dueDate: "2023-03-04",
    paymentInfo: [],
  },
  {
    cycleDate: "2022-09-21",
    amountDue: 8530,
    amountDelq: 60672,
    delqBucket: "31",
    dueDate: "2023-03-17",
    paymentInfo: [
      {
        amountPaid: -98222,
        postDate: "2023-05-16",
        effectiveDate: "2023-06-14",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -20767,
        postDate: "2023-06-03",
        effectiveDate: "2023-02-09",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -11751,
        postDate: "2023-04-22",
        effectiveDate: "2023-07-09",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -88204,
        postDate: "2023-03-08",
        effectiveDate: "2023-05-22",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 4242,
        postDate: "2022-09-18",
        effectiveDate: "2023-03-05",
        transactionType: "PMT RVRSL",
      },
    ],
  },
  {
    cycleDate: "2023-01-05",
    amountDue: 75366,
    amountDelq: 2525,
    delqBucket: "24",
    dueDate: "2022-11-07",
    paymentInfo: [],
  },
  {
    cycleDate: "2023-05-25",
    amountDue: 59539,
    amountDelq: 5221,
    delqBucket: "12",
    dueDate: "2022-08-08",
    paymentInfo: [
      {
        amountPaid: 20673,
        postDate: "2023-01-14",
        effectiveDate: "2022-09-10",
        transactionType: "PMT",
      },
      {
        amountPaid: -5115,
        postDate: "2022-10-06",
        effectiveDate: "2023-06-14",
        transactionType: "PMT",
      },
      {
        amountPaid: 50635,
        postDate: "2022-11-24",
        effectiveDate: "2022-10-18",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2023-03-14",
    amountDue: 59944,
    amountDelq: 29211,
    delqBucket: "25",
    dueDate: "2022-08-26",
    paymentInfo: [
      {
        amountPaid: 74233,
        postDate: "2022-11-06",
        effectiveDate: "2023-05-09",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -64565,
        postDate: "2023-01-07",
        effectiveDate: "2022-12-05",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 29278,
        postDate: "2023-04-01",
        effectiveDate: "2023-02-14",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -89215,
        postDate: "2023-07-22",
        effectiveDate: "2023-01-18",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -55717,
        postDate: "2023-05-20",
        effectiveDate: "2023-07-11",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -53568,
        postDate: "2023-01-26",
        effectiveDate: "2023-01-26",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -76208,
        postDate: "2023-05-22",
        effectiveDate: "2022-09-01",
        transactionType: "PMT",
      },
      {
        amountPaid: 70035,
        postDate: "2023-01-22",
        effectiveDate: "2023-01-01",
        transactionType: "PMT RVRSL",
      },
    ],
  },
  {
    cycleDate: "2022-10-04",
    amountDue: 94518,
    amountDelq: 41494,
    delqBucket: "96",
    dueDate: "2022-12-17",
    paymentInfo: [
      {
        amountPaid: -18318,
        postDate: "2022-10-10",
        effectiveDate: "2023-04-15",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2023-04-20",
    amountDue: 74439,
    amountDelq: 82308,
    delqBucket: "37",
    dueDate: "2023-02-27",
    paymentInfo: [],
  },
  {
    cycleDate: "2023-03-16",
    amountDue: 72138,
    amountDelq: 66919,
    delqBucket: "64",
    dueDate: "2023-02-13",
    paymentInfo: [
      {
        amountPaid: 1976,
        postDate: "2022-10-26",
        effectiveDate: "2022-10-26",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -3009,
        postDate: "2023-05-19",
        effectiveDate: "2022-10-16",
        transactionType: "LF Adj",
      },
    ],
  },
  {
    cycleDate: "2022-11-09",
    amountDue: 75759,
    amountDelq: 4538,
    delqBucket: "65",
    dueDate: "2022-08-15",
    paymentInfo: [
      {
        amountPaid: -44064,
        postDate: "2022-08-26",
        effectiveDate: "2023-06-27",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 5649,
        postDate: "2022-08-11",
        effectiveDate: "2022-11-28",
        transactionType: "FC Adj",
      },
      {
        amountPaid: 68188,
        postDate: "2022-09-04",
        effectiveDate: "2023-01-15",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -2991,
        postDate: "2022-08-14",
        effectiveDate: "2022-12-19",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -53202,
        postDate: "2022-08-06",
        effectiveDate: "2022-12-16",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -66764,
        postDate: "2023-03-11",
        effectiveDate: "2023-04-09",
        transactionType: "PMT",
      },
      {
        amountPaid: 11491,
        postDate: "2022-08-26",
        effectiveDate: "2023-02-08",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: 62142,
        postDate: "2022-11-28",
        effectiveDate: "2022-08-30",
        transactionType: "PMT",
      },
      {
        amountPaid: -49123,
        postDate: "2023-08-02",
        effectiveDate: "2023-06-25",
        transactionType: "LF Adj",
      },
    ],
  },
];
export const MOCK_PAYMENT_HISTORY_SHORT: GetPaymentHistoryResponse = [
  {
    cycleDate: "2023-04-08",
    amountDue: 756.67,
    amountDelq: 503.65,
    delqBucket: "56",
    dueDate: "2022-12-22",
    paymentInfo: [
      {
        amountPaid: 398.51,
        postDate: "2023-04-23",
        effectiveDate: "2022-09-30",
        transactionType: "PMT",
      },
      {
        amountPaid: -205.39,
        postDate: "2023-07-13",
        effectiveDate: "2023-02-21",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: 238.18,
        postDate: "2022-12-21",
        effectiveDate: "2022-09-23",
        transactionType: "PMT",
      },
      {
        amountPaid: 838.82,
        postDate: "2022-12-15",
        effectiveDate: "2022-12-19",
        transactionType: "PMT",
      },
      {
        amountPaid: 510.3,
        postDate: "2023-07-15",
        effectiveDate: "2022-11-10",
        transactionType: "FC Adj",
      },
      {
        amountPaid: -468.16,
        postDate: "2023-03-26",
        effectiveDate: "2023-02-01",
        transactionType: "FC Adj",
      },
      {
        amountPaid: 773.33,
        postDate: "2023-05-04",
        effectiveDate: "2022-08-23",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -138.23,
        postDate: "2023-03-30",
        effectiveDate: "2022-10-19",
        transactionType: "FC Adj",
      },
    ],
  },
  {
    cycleDate: "2023-01-19",
    amountDue: 982.62,
    amountDelq: 230.2,
    delqBucket: "62",
    dueDate: "2023-02-04",
    paymentInfo: [],
  },
  {
    cycleDate: "2022-12-20",
    amountDue: 811.15,
    amountDelq: 103.9,
    delqBucket: "69",
    dueDate: "2022-08-29",
    paymentInfo: [
      {
        amountPaid: -696.4,
        postDate: "2023-02-19",
        effectiveDate: "2023-04-16",
        transactionType: "PMT",
      },
    ],
  },
  {
    cycleDate: "2022-08-29",
    amountDue: 273.52,
    amountDelq: 555.77,
    delqBucket: "55",
    dueDate: "2023-03-04",
    paymentInfo: [],
  },
  {
    cycleDate: "2022-09-21",
    amountDue: 853.0,
    amountDelq: 606.72,
    delqBucket: "31",
    dueDate: "2023-03-17",
    paymentInfo: [
      {
        amountPaid: -982.22,
        postDate: "2023-05-16",
        effectiveDate: "2023-06-14",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -207.67,
        postDate: "2023-06-03",
        effectiveDate: "2023-02-09",
        transactionType: "LF Adj",
      },
      {
        amountPaid: -117.51,
        postDate: "2023-04-22",
        effectiveDate: "2023-07-09",
        transactionType: "PMT RVRSL",
      },
      {
        amountPaid: -882.04,
        postDate: "2023-03-08",
        effectiveDate: "2023-05-22",
        transactionType: "LF Adj",
      },
      {
        amountPaid: 42.42,
        postDate: "2022-09-18",
        effectiveDate: "2023-03-05",
        transactionType: "PMT RVRSL",
      },
    ],
  },
  {
    cycleDate: "2023-01-05",
    amountDue: 753.66,
    amountDelq: 25.25,
    delqBucket: "24",
    dueDate: "2022-11-07",
    paymentInfo: [],
  },
];

export const MOCK_AUTOPAY_ENROLLMENT: GetAutopayEnrollmentResponse = {
  enrollmentType: EnrollmentType.Eligible,
  enrollmentInfo: {
    abaNumber: "111000025",
    ddaNumber: "1234567890",
    bankName: "Wells Fargo Bank",
    ownerName: "John Doe",
    amount: "Fixed Payment Amount: $50.00",
    party: "ACCOUNT_OWNER",
  },
  enrolledDate: "2024-10-30",
  reEnrollDate: "2023-01-01",
  isAutopaySkipped: false,
  cancelSkipAutopayDisabledReason: null,
  isEnrolledAutopay: false,
};

export const MOCK_AUTOPAY_ENROLLMENT_INELIGIBLE: GetAutopayEnrollmentResponse =
  {
    enrollmentType: EnrollmentType.Ineligible,
    lastEnrollmentDate: "11/05/2022",
    autopayDisabledReason: "Cannot Enroll in Autopay until 05/04/2023",
  };

// Only used for mocking
const MOCK_MAIL_TYPES: SecureMailConversationType[] = [
  "NEW",
  "OPEN",
  "READONLY",
  "CMEXPIRE",
];

export const MOCK_SECURE_MAIL_LIST: GetSecureMailListResponse = {
  total: 430,
  secureMail: [...Array(100).keys()].map((_, index) => {
    const typeOptions = MOCK_MAIL_TYPES;
    const minDate = new Date(2022, 0, 1).getTime();
    const maxDate = new Date(2024 + 1, 0, 1).getTime();

    return {
      // pad w 0's till 7 digits
      dwbuid: Math.round(Math.random() * 100000).toString(),
      subject: `Subject Line ${index}`,
      // randomly generate a date between 2022 and 2024
      updateDate: new Date(Math.random() * (maxDate - 1 - minDate) + minDate),
      // randomly select a type
      type: typeOptions[Math.floor(Math.random() * typeOptions.length)],
      secureMailId: index === 0 ? "abcdef-123456" : nanoid(),
    };
  }),
};

export const MOCK_SECUREMAIL = {
  secureMailId: "test-secure-mail-id",
  classificationId: "testId-secureId",
};

export const MOCK_CLASSIFICATIONS: GetClassificationsResponse = {
  classifications: [
    {
      id: "0",
      description: "Unclassified",
    },
    {
      id: "1",
      description: "CSR: Web-Related",
    },
    {
      id: "2",
      description: "CSR: Credit Line Increase Request",
    },
    {
      id: "3",
      description: "CSR: Card",
    },
  ],
};

export const MOCK_CLASSIFICATION_COUNT: GetClassificationCountResponse = {
  classifications: [
    {
      id: "0",
      description: "Unclassified",
      count: 6,
    },
    {
      id: "1",
      description: "CSR: Web-Related",
      count: 10,
    },
    {
      id: "2",
      description: "CSR: Credit Line Increase Request",
      count: 7,
    },
    {
      id: "3",
      description: "CSR: Card",
      count: 4,
    },
    {
      id: "4",
      description: "Classification 5",
      count: 4,
    },
    {
      id: "5",
      description: "Classification 6",
      count: 4,
    },
    {
      id: "6",
      description: "Classification 7",
      count: 4,
    },
    {
      id: "7",
      description: "Classification 8",
      count: 4,
    },
    {
      id: "8",
      description: "Classification 9",
      count: 4,
    },
    {
      id: "9",
      description: "Classification 10",
      count: 4,
    },
    {
      id: "10",
      description: "Classification",
      count: 4,
    },
  ],
};

export const MOCK_SECURE_MAIL_THREAD: GetSecureMailThreadResponse = {
  id: "abcdef-123456",
  dwbuid: "123456",
  firstName: "Ebony",
  lastName: "Black",
  subject: "Increasing Credit Line",
  type: "OPEN",
  classificationId: "2",
  messages: [
    {
      messageId: "1",
      sender: "John Doe",
      body: "I’ve made timely payments for the last 6 months and hope my responsible card usage supports my case for an increase.",
      createDate: new Date("2022-08-14T02:41:00.000Z"),
      dwbuid: "123456",
      employeeId: null,
      employeeFirstName: null,
      employeeLastName: null,
      actionId: "0",
      attachments: [],
      timeRead: null,
    },
    {
      messageId: "2",
      sender: "1FB",
      body: "Thank you for your credit line increase inquiry. We are reviewing your account and will have a response for you soon.",
      createDate: new Date("2022-08-14T03:41:00.000Z"),
      dwbuid: "123456",
      employeeId: "1111111111",
      employeeFirstName: "Sammy",
      employeeLastName: "Employee",
      actionId: "0",
      attachments: [],
      timeRead: new Date("2022-08-14T04:41:00.000Z"),
    },
    {
      messageId: "3",
      sender: "1FB",
      body: "Hi I'm Johnny! I will be helping you with your credit line increase inquiry. I see your payments for the last 6 months, looks like there was one missed payment!",
      createDate: new Date("2022-08-14T03:41:00.000Z"),
      dwbuid: "123456",
      employeeId: "2222222222",
      employeeFirstName: "Johnny",
      employeeLastName: "Employee",
      actionId: "0",
      attachments: [
        {
          id: "1",
          name: "john_doe_payments_2022.pdf",
          mimeType: "application/pdf",
        },
      ],
      timeRead: new Date("2022-08-14T04:41:00.000Z"),
    },
    {
      messageId: "4",
      sender: "John Doe",
      body: "That looks right for the most part. For the missed payment, I had set up automatic pay from my Chase bank account but my bank card was declined. I talked to Chase and apparently, it was a mistake on their part. I've attached some screen shots here as reference.",
      createDate: new Date("2022-08-14T03:41:00.000Z"),
      dwbuid: "123456",
      employeeId: null,
      employeeFirstName: null,
      employeeLastName: null,
      actionId: "0",
      attachments: [
        {
          id: "2",
          name: "screenshot_1.jpg",
          mimeType: "image/jpeg",
        },
        {
          id: "3",
          name: "screenshot_2.jpg",
          mimeType: "image/jpeg",
        },
      ],
      timeRead: null,
    },
  ],
  employees: [
    {
      employeeId: "1",
      firstname: "Sammy",
      lastname: "Employee",
    },
    {
      employeeId: "2",
      firstname: "Johnny",
      lastname: "Employee",
    },
  ],
};

export const MOCK_CARDMEMBER_MAIL_LIST: GetCardmemberMailListResponse = {
  total: 5,
  secureMail: [
    {
      subject: `A Forgot password`,
      updateDate: new Date(),
      type: "OPEN",
      secureMailId: "be921666-98c2-422e-af05-b53c116312c",
    },
    {
      subject: `B Login issues`,
      updateDate: new Date(),
      type: "NEW",
      secureMailId: "f164ca82-cfde-45b1-8c63-61b6e9b261a2",
    },
    {
      subject: `C Update card information`,
      updateDate: new Date(),
      type: "OPEN",
      secureMailId: "10c38deb-2096-42ac-8874-587d5a64f7e0",
    },
    {
      subject: `D Update card information`,
      updateDate: new Date(),
      type: "OPEN",
      secureMailId: "c05070c0-570f-409b-a629-3dedd1d5cc90",
    },
    {
      subject: `E Update card information`,
      updateDate: new Date(),
      type: "NEW",
      secureMailId: "2266964d-17e6-49d7-b8e1-4a4567e8fb3a",
    },
  ],
};

export const MOCK_PAYMENT_METHODS: GetPaymentMethodsResponse = {
  cardMemberAccounts: [
    {
      id: "cm_account_100",
      type: "credit_card",
      isDefault: true,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: "mc",
      last4: "1234",
      lastUsedDate: "09/11/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      cardNumber: "123",
      expirationDate: "12/22",
      ownerType: "ACCOUNT_OWNER",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "expired_test_cm",
      type: "credit_card",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: "mc",
      last4: "1234",
      lastUsedDate: "09/11/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: true,
      cardNumber: "1234",
      expirationDate: "12/22",
      ownerType: "ACCOUNT_OWNER",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "cm_account_101",
      type: "credit_card",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: "visa",
      last4: "1235",
      lastUsedDate: "09/12/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      cardNumber: "1235",
      expirationDate: "12/22",
      ownerType: "ACCOUNT_OWNER",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "cm_account_102",
      type: "credit_card",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: false,
      brand: "visa",
      last4: "1236",
      lastUsedDate: "09/13/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      cardNumber: "1236",
      expirationDate: "12/22",
      ownerType: "AUTHORIZED_USER",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "cm_account_103",
      type: "credit_card",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: false,
      brand: "visa",
      last4: "1237",
      lastUsedDate: "09/14/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      cardNumber: "1237",
      expirationDate: "12/22",
      ownerType: "AUTHORIZED_USER",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "cm_account_104",
      type: "checking_account",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: null,
      last4: "1324",
      lastUsedDate: "09/15/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      abaNumber: "123456789",
      accountNumber: "12345",
      bankName: "1fb",
      ownerType: "AUTHORIZED_USER",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
  ],
  thirdPartyAccounts: [
    {
      id: "3rd_party_100",
      type: "credit_card",
      isDefault: true,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: "mc",
      last4: "1238",
      lastUsedDate: "09/11/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      cardNumber: "1238",
      expirationDate: "12/22",
      ownerType: "THIRD_PARTY",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "expired_test_3rdparty",
      type: "credit_card",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: "mc",
      last4: "1239",
      lastUsedDate: "09/11/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: true,
      cardNumber: "1239",
      expirationDate: "12/22",
      ownerType: "THIRD_PARTY",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
    {
      id: "3rd_party_101",
      type: "credit_card",
      isDefault: false,
      isInactive: false,
      hasRestrictions: false,
      is1FBAccount: true,
      brand: "visa",
      last4: "1230",
      lastUsedDate: "09/12/2023",
      cardHolderFirstName: "Wilson",
      cardHolderLastName: "McWilson",
      isExpired: false,
      cardNumber: "1230",
      expirationDate: "12/22",
      ownerType: "THIRD_PARTY",
      restrictions: [],
      addressLine1: "123 Somewhere Ln",
      addressLine2: "",
      city: "Austin",
      stateCode: "TX",
      zipcode: "77777",
      phoneNumber: "1234567890",
    },
  ],
};

export const MOCK_PAYMENT_TRANSACTIONS: GetPaymentTransactionsResponse = {
  numberOfPendingPayments: 4,
  pendingPaymentsAmountCents: 14700,
  paymentTransactions: [
    {
      id: "0",
      status: "pending",
      type: "B2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-11-11",
      destination: "x4906",
      source: "x0968",
      amountCents: 1500,
      confirmationId: "paymentId0",
      creationDateStr: "09/12/23 08:09:47 AM",
      agent: "BKN",
      paymentMethod: {
        id: "something_random",
        type: "credit_card",
        isDefault: true,
        isInactive: false,
        hasRestrictions: false,
        is1FBAccount: true,
        brand: "mc",
        last4: "1234",
        lastUsedDate: "2023/11/11",
        cardHolderFirstName: "Removed",
        cardHolderLastName: "Account",
        isExpired: false,
        cardNumber: "123",
        expirationDate: "12/22",
        ownerType: "ACCOUNT_OWNER",
        restrictions: [],
        addressLine1: "123 Somewhere Ln",
        addressLine2: "",
        city: "Austin",
        stateCode: "TX",
        zipcode: "77777",
        phoneNumber: "1234567890",
      },
    },
    {
      id: "1",
      status: "pending",
      type: "B2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-08-30",
      destination: "x4906",
      source: "x0968",
      amountCents: 14788,
      confirmationId: "paymentId1",
      creationDateStr: "08/12/23 08:09:47 AM",
      agent: "MA",
      paymentMethod: MOCK_PAYMENT_METHODS.thirdPartyAccounts[0],
    },
    {
      id: "3",
      status: "pending",
      type: "B2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-11-11",
      destination: "x4906",
      source: "x0968",
      amountCents: 14788,
      confirmationId: "paymentId2",
      creationDateStr: "08/12/23 08:09:47 AM",
      agent: "MA",
      paymentMethod: MOCK_PAYMENT_METHODS.cardMemberAccounts[0],
      seriesId: "include",
    },
    {
      id: "4",
      status: "pending",
      type: "B2C",
      processingStatusType: "PAPER",
      effectiveDate: "2023-11-30",
      destination: "x4906",
      source: "x0968",
      amountCents: 14788,
      confirmationId: "paymentId3",
      creationDateStr: "08/12/23 08:09:47 AM",
      agent: "MA",
      paymentMethod: MOCK_PAYMENT_METHODS.cardMemberAccounts[0],
      seriesId: "include",
    },
    {
      id: "somethingunique34634",
      status: "pending",
      type: "B2C",
      processingStatusType: "PAPER",
      effectiveDate: "2023-12-05",
      destination: "x4906",
      source: "x0968",
      amountCents: 14788,
      confirmationId: "paymentId4",
      creationDateStr: "08/12/23 08:09:47 AM",
      agent: "MA",
      paymentMethod: MOCK_PAYMENT_METHODS.cardMemberAccounts[0],
      seriesId: "include",
    },
    {
      id: "5",
      status: "pending",
      type: "B2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-11-30",
      destination: "x4906",
      source: "x0968",
      amountCents: 14788,
      confirmationId: "paymentId5",
      creationDateStr: "08/12/23 08:09:47 AM",
      agent: "MA",
      paymentMethod: MOCK_PAYMENT_METHODS.thirdPartyAccounts[0],
      seriesId: "don't include",
    },
    {
      id: "6",
      status: "processed",
      type: "C2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-04-30",
      destination: "x4906",
      source: "x0968",
      amountCents: 2000,
      confirmationId: "24542443",
      creationDateStr: "04/12/23 08:09:47 AM",
      agent: "RZK",
      paymentMethod: MOCK_PAYMENT_METHODS.cardMemberAccounts[0],
    },
    {
      id: "7",
      status: "processed",
      type: "B2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-03-30",
      destination: "x4906",
      source: "x0968",
      amountCents: 3400,
      confirmationId: "paymentId6",
      creationDateStr: "03/12/23 08:09:47 AM",
      agent: "381",
      paymentMethod: MOCK_PAYMENT_METHODS.cardMemberAccounts[0],
    },
    {
      id: "8",
      status: "processed",
      type: "C2C",
      processingStatusType: "ACH",
      effectiveDate: "2023-01-30",
      destination: "x4906",
      source: "x0968",
      amountCents: 4500,
      confirmationId: "paymentId7",
      creationDateStr: "01/12/23 08:09:47 AM",
      agent: "BKN",
      paymentMethod: MOCK_PAYMENT_METHODS.cardMemberAccounts[0],
    },
  ],
  statusFilters: [
    { label: "Pending", value: "pending" },
    { label: "Processed", value: "processed" },
  ],
  typeFilters: [
    { label: "B2C", value: "B2C" },
    { label: "C2C", value: "C2C" },
  ],
  destinationFilters: [
    { label: "x4906", value: "x4906" },
    { label: "x0968", value: "x0968" },
  ],
  sourceFilters: [
    { label: "x4906", value: "x4906" },
    { label: "x0968", value: "x0968" },
  ],
};

export const MOCK_PAYMENT_SERIES_SCHEDULE_RESPONSE: GetPaymentSeriesScheduleResponse =
  {
    schedule: [
      {
        date: "2023-10-15",
        amountCents: 100,
      },
      {
        date: "2023-11-15",
        amountCents: 100,
      },
      {
        date: "2023-12-15",
        amountCents: 100,
      },
      {
        date: "2024-01-15",
        amountCents: 100,
      },
      {
        date: "2024-02-15",
        amountCents: 100,
      },
    ],
  };

export const MOCK_PARTY_RESTRICTIONS: GetPartyRestrictionsResponse = {
  cardRestrictions: [
    {
      label: "None",
      value: "NONE",
    },
    {
      label: "Chargeback Unauthorized/Fraud",
      value: "CHARGEBACK_UNAUTHORIZED_FRAUD",
    },
    {
      label: "Chargeback Unrecognized",
      value: "CHARGEBACK_UNRECOGNIZED",
    },
    {
      label: "Possible Fraud",
      value: "POSSIBLE_FRAUD",
    },
  ],
  bankRestrictions: [
    {
      label: "None",
      value: "NONE",
    },
    {
      label: "Account Closed",
      value: "ACCOUNT_CLOSED",
    },
    {
      label: "Acct Frozen",
      value: "ACCOUNT_FROZEN",
    },
    {
      label: "Do Not Rsbmt",
      value: "DO_NOT_RESUBMIT",
    },
  ],
  partyCardRestriction: [
    { restriction: "CHARGEBACK_UNRECOGNIZED" },
    { restriction: "POSSIBLE_FRAUD" },
  ],
  partyBankRestriction: [],
};

export const MOCK_PAYMENT_OVERRIDES_RESPONSE: PaymentOverridesResponse = {
  overrideMessages: [
    {
      overrideInfo: "Mock Payment First Override",
      overrideKey: "overrideMock1",
    },
    {
      overrideInfo: "Mock Payment Second Override",
      overrideKey: "overrideMock2",
    },
  ],
};

export const MOCK_PAYMENT_SCRIPTS_RESPONSE: PaymentScriptsResponse = {
  type: "script",
  message: `'Lorem ipsum dolor sit amet' is a legal phrase indicating a hypothetical or placeholder text used in the drafting of legal documents. It is not a part of this legal agreement, and no party shall be held liable for any damages or losses, whether direct or indirect, arising from any breach of this agreement. Furthermore, neither party shall have any obligation or liability to the other party, except as specifically outlined in this agreement.

  In witness whereof, this agreement shall be governed by and construed in accordance with the laws of the state of [State], without regard to its conflict of laws principles. This agreement may not be amended, modified, or otherwise altered except in writing, signed by both parties. Any waiver of a breach or violation of any provision herein shall not constitute a waiver of any other or subsequent breach or violation.
  
  This agreement contains the entire understanding of the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether oral or written. No term or condition of this agreement may be changed, waived, discharged, or terminated orally, and no waiver or consent shall be valid unless in writing and signed by the party against whom such waiver or consent is asserted. This agreement shall be binding upon and inure to the benefit of the parties hereto and their respective successors and assigns.`,
};

export const MOCK_BANK_NAMES: GetBankNamesResponse = {
  bankNames: ["1fb", "Wells Fargo", "Chase", "Ally"],
};

const mockRelationSister = {
  id: "196fe714-27a4-421b-a3a5-91fe8f9d2863",
  relationship: "Sister",
};
const mockRelationFriend = {
  id: "e3f0f762-f571-47e6-8f5c-294799bdf792",
  relationship: "Friend",
};
const mockRelationBrother = {
  id: "60eae8a8-3dff-4bda-9950-21194ea16c7f",
  relationship: "Brother",
};
const mockRelationEmployer = {
  id: "cbfc0a0c-4003-4c57-ad15-7c5020201cf6",
  relationship: "Employer",
};
export const MOCK_PERMISSION_TO_DISCUSS_RESPONSE: GetPermissionToDiscussResponse =
  {
    lastVerifiedDate: "2023-06-15",
    permissionsList: [
      {
        id: "pCMUV7t-im",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:29:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Jessica",
        lastName: "Smith",
        relation: mockRelationSister,
        source: "Affinity",
        status: "active",
      },
      {
        id: "wKK9DdaEn_",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:28:59.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Jessica",
        lastName: "Smith",
        relation: mockRelationSister,
        source: "Affinity",
        status: "active",
      },
      {
        id: "lG0xCDzvT6",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:25:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Jessica",
        lastName: "Smith",
        relation: mockRelationSister,
        source: "Affinity",
        status: "inactive",
      },
      {
        id: "82lAyMToaL",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:20:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Winston",
        lastName: "Bishop",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "active",
      },
      {
        id: "gDyLRX_yyG",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:12:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Nicholas",
        lastName: "Miller",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "inactive",
      },
      {
        id: "ChEZb-Zp-Y",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:03:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Anthony",
        lastName: "Jones",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "active",
      },
      {
        id: "vmlZ-nF6f5",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:02:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Sam",
        lastName: "Smith",
        relation: mockRelationBrother,
        source: "Affinity",
        status: "active",
      },
      {
        id: "0sGiBiue8R",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:01:59.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Sam",
        lastName: "Smith",
        relation: mockRelationBrother,
        source: "Affinity",
        status: "active",
      },
      {
        id: "Qy87S9vU9B",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:01:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "James",
        lastName: "Black",
        relation: mockRelationEmployer,
        source: "Clarity",
        status: "active",
      },

      {
        id: "IBzCfMR1fK",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-10T13:01:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Bob",
        lastName: "Bobbers",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "active",
      },
    ],
    relationshipTypes: [
      mockRelationEmployer,
      mockRelationBrother,
      mockRelationSister,
      mockRelationFriend,
    ],
  };

export const MOCK_PERMISSION_TO_DISCUSS_PARTIAL_RESPONSE: GetPermissionToDiscussResponse =
  {
    lastVerifiedDate: "2023-06-15",
    permissionsList: [
      {
        id: "i8Otk_wf1P",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:29:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Jessica",
        lastName: "Smith",
        relation: mockRelationSister,
        source: "Affinity",
        status: "active",
      },
      {
        id: "lqzRRSwc2D",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:20:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Winston",
        lastName: "Bishop",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "active",
      },
      {
        id: "pCMUV7t-im",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:12:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Nicholas",
        lastName: "Miller",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "inactive",
      },
      {
        id: "fJbp82hrVJ",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:03:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Anthony",
        lastName: "Jones",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "active",
      },
      {
        id: "5LrRlRBtM5",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:02:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Sam",
        lastName: "Smith",
        relation: mockRelationBrother,
        source: "Affinity",
        status: "active",
      },
      {
        id: "CPYoPnAFz4",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-15T13:01:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "James",
        lastName: "Black",
        relation: mockRelationEmployer,
        source: "Clarity",
        status: "active",
      },

      {
        id: "9y8e5hY5Ag",
        agent: "MGM",
        channel: "RES",
        date: "2024-02-10T13:01:00.000",
        effectiveDate: "2024-02-15T00:00:00",
        firstName: "Bob",
        lastName: "Bobbers",
        relation: mockRelationFriend,
        source: "Affinity",
        status: "active",
      },
    ],
    relationshipTypes: [
      mockRelationEmployer,
      mockRelationBrother,
      mockRelationSister,
      mockRelationFriend,
    ],
  };

export const MOCK_CYCLE_TO_DATE_TRANSACTIONS_RESPONSE: GetCycleToDateTransactionsResponse =
  {
    availableCreditCents: 11013,
    creditLimitCents: 80000,
    currentBalanceCents: 78327,
    cycleEndDate: "2024-03-01",
    cycleStartDate: "2024-02-01",
    declinesThisCycle: 2,
    lastDeclineDate: "2024-02-08",
    lastStatementBalanceCents: 68687,
    pendingFeesAndChargesCents: 2090,
    postedTransactions: [
      {
        id: "001",
        amountCents: 300,
        authNumber: "",
        description: "Tesla Inc Supercharger 877-79837485 CA",
        merchantCategories: "Gas/Fuel",
        newBalanceCents: 68987,
        posEntryMode: "Unknown",
        postDate: "2024-02-06",
        transactionDate: "2024-02-06",
      },
      {
        id: "002",
        amountCents: 1932,
        authNumber: "00672",
        description: "Tesla Inc Supercharger 877-79837485 CA",
        merchantCategories: "Gas/Fuel",
        newBalanceCents: 70919,
        posEntryMode: "Unknown",
        postDate: "2024-02-06",
        transactionDate: "2024-02-06",
      },
      {
        id: "003",
        amountCents: 5318,
        authNumber: "007160",
        description: "SHOPRITE SHREWSBURY S1 SHREWSBURY NJ",
        merchantCategories: "Grocery Stores, Supermarkets",
        newBalanceCents: 76237,
        posEntryMode: "Unknown",
        postDate: "2024-02-08",
        transactionDate: "2024-02-08",
      },
    ],
    pendingAuthorizations: [
      {
        id: "004",
        amountCents: 2607,
        authNumber: "004001",
        date: "2024-02-06",
        description: "AUTH GRANTED SHOPRITE SHREWSBURY S1 SHREWSBURY NJ",
        merchantCategories: "Grocery Stores, Supermarkets",
        posEntryMode: "Chip/Smart",
      },
      {
        id: "Q-jnj4PMAE",
        amountCents: 2607,
        authNumber: "",
        date: "2024-02-07",
        description: "AUTH GRANTED SHOPRITE SHREWSBURY S1 SHREWSBURY NJ",
        merchantCategories: "Grocery Stores, Supermarkets",
        posEntryMode: "Chip/Smart",
      },
    ],
    declinedAuthorizations: [
      {
        id: "005",
        amountCents: 300,
        authNumber: "",
        date: "2024-02-06",
        description: "Invalid CVV2 DUNKIN' MOBILE CANTON MA",
        merchantCategories: "Fast Food Restaurants",
        posEntryMode: "Unknown",
        reset: "No",
      },
      {
        id: "006",
        amountCents: 453,
        authNumber: "004001",
        date: "2024-02-08",
        description: "Invalid CVV2 DUNKIN' MOBILE CANTON MA",
        merchantCategories: "Fast Food Restaurants",
        posEntryMode: "Unknown",
        reset: "NM 50",
      },
    ],
  };

export const MOCK_GET_STATEMENT_HISTORY_RESPONSE: GetStatementHistoryResponse =
  {
    statements: [
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 873600,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "29",
        delinquentAmountCents: 0,
        dueDate: "2024-03-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 823600,
        oclFeeCents: 0,
        paymentAmountCents: 17900,
        paymentsCount: "001",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2024-02-09",
        totalAmountDueCents: 841100,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 891500,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 0,
        dueDate: "2024-02-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 841500,
        oclFeeCents: 0,
        paymentAmountCents: 314500,
        paymentsCount: "001",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2024-01-11",
        totalAmountDueCents: 859400,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1206000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 290300,
        dueDate: "2024-01-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1156000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-12-11",
        totalAmountDueCents: 1206000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1206000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 266100,
        dueDate: "2023-12-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1156000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 2000,
        purchaseCount: "001",
        statementDate: "2023-11-10",
        totalAmountDueCents: 1206000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 266100,
        dueDate: "2023-11-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-10-11",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 217900,
        dueDate: "2023-10-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-09-11",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 193800,
        dueDate: "2023-09-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-08-11",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 169700,
        dueDate: "2023-08-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-07-11",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 145600,
        dueDate: "2023-07-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-06-09",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 121500,
        dueDate: "2023-06-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-05-10",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 97400,
        dueDate: "2023-05-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-04-11",
        totalAmountDueCents: 1204000,
      },
      {
        adbCashCents: 0,
        adbPurchaseCents: 0,
        adjustmentAmountCents: 0,
        adjustmentsCount: "000",
        balanceCents: 1204000,
        cashAmountCents: 0,
        cashBalanceCents: 0,
        cashCount: "000",
        creditAmountCents: 0,
        creditLimitCents: 50000,
        creditsCount: "000",
        daysInCycle: "",
        delinquentAmountCents: 73300,
        dueDate: "2023-04-04",
        fcCashCents: 0,
        fcPurchaseCents: 0,
        lateFeeCents: 0,
        oclAmountCents: 1154000,
        oclFeeCents: 0,
        paymentAmountCents: 0,
        paymentsCount: "000",
        purchaseAmountCents: 0,
        purchaseCount: "000",
        statementDate: "2023-03-11",
        totalAmountDueCents: 1204000,
      },
    ],
  };

export const MOCK_LOST_STOLEN_OPTIONS_RESPONSE: LostStolenOptionsResponse = {
  contactTypes: [
    { id: "1", value: "Cardmember" },
    { id: "2", value: "Authorized user" },
    { id: "3", value: "Parent" },
    { id: "11", value: "3rd party known to CM" },
    { id: "12", value: "3rd party unknown to CM" },
    { id: "5", value: "Merchant" },
    { id: "98", value: "Other" },
  ],
  externalStatus: "OK",
  isLoanAccount: false,
  lossLocations: [
    { id: "ll-1", value: "Auto" },
    { id: "ll-2", value: "Office" },
    { id: "ll-3", value: "Store" },
    { id: "ll-4", value: "Rural" },
    { id: "ll-5", value: "Bar" },
    { id: "ll-6", value: "Hotel/motel" },
    { id: "ll-7", value: "Unknown" },
  ],
  lossStates: [
    { id: "ls-1", value: "Arkansas" },
    { id: "ls-2", value: "Kansas" },
    { id: "ls-3", value: "Michigan" },
    { id: "ls-4", value: "Rhode Island" },
    { id: "ls-5", value: "Texas" },
    { id: "ls-6", value: "Virgina" },
  ],
  lossTypes: [
    { id: "1", value: "Lost card" },
    { id: "2", value: "Stolen" },
    { id: "3", value: "Not received" },
    { id: "4", value: "Fraud app" },
    { id: "5", value: "Counterfeit" },
    { id: "6", value: "Account takeover" },
    { id: "7", value: "All mail and phone" },
  ],
  replacementTypes: [
    { id: "4", value: "Standard" },
    { id: "1", value: "Rush FedEx" },
    { id: "2", value: "Rush Postal Express" },
    { id: "3", value: "FedEx" },
  ],
  shipToTypes: [
    { id: "1", value: "Billing address" },
    { id: "2", value: "Temporary address" },
  ],
  tempAddressReasons: [
    { id: "ta-1", value: "Home address" },
    { id: "ta-2", value: "Parents address" },
    { id: "ta-3", value: "School address" },
    { id: "ta-4", value: "Work address" },
    { id: "ta-5", value: "Vacation/traveling" },
    { id: "ta-6", value: "Security issue w/ billing addr" },
  ],
  waysToContact: [
    { id: "wc-1", value: "Home" },
    { id: "wc-2", value: "Work" },
  ],
};

export const MOCK_CREDIT_LINE_INCREASE_ELIGIBILITY: GetCreditLineIncreaseEligibilityResponse =
  {
    maxCreditLineCents: 125000,
    maxIncreaseCents: 75000,
    reasons: [
      {
        id: "7fI2kEP8ra",
        value: "Income Increased",
      },
      {
        id: "EDCPuKIBVQ",
        value: "Credit Score Increased",
      },
      {
        id: "9RHfVUzb0J",
        value: "Other",
      },
    ],
    rejectionReason: null,
    scriptName: "CLI01",
    letterType: null,
    userData: {
      accountNumber: "12345",
      accountSubType: "",
      authorizationFlag: "",
      behaviorScore: 0,
      creditLimitCents: 50000,
      creditLineLastChangeDate: "2024-01-14T02:41:00.000Z",
      currentBalanceCents: 15000,
      cycledOverlimitCount: 0,
      delinquentBucketId: 0,
      externalStatus: "",
      externalStatusReason: "",
      internalStatus: "",
      lastCreditLineChangeCents: 25000,
      ocl10PercentOrGreater: false,
      oclOrDelinquent: false,
      openDate: "2023-01-14T02:41:00.000Z",
      permanentCollectorCode: "",
      totalNumberOfTimesOneCycleDelinquentCount: 0,
      totalNumberOfTimesThreeCyclesDelinquentCount: 0,
      totalNumberOfTimesTwoCyclesDelinquentCount: 0,
    },
  };

export const MOCK_CREDIT_LINE_INCREASE_SUBMIT_RESPONSE: SubmitCreditLineIncreaseResponse =
  {
    scriptName: "CLI02",
  };

export const MOCK_CREDIT_LINE_INCREASE_SUBMIT_NO_PHONE_RESPONSE: SubmitCreditLineIncreaseResponse =
  {
    scriptName: "CLI18",
  };

export const MOCK_DYNAMIC_ERROR_RESPONSE: DynamicErrorResponse = {
  type: "dynamic_error",
  message: "Example dynamic error.",
  errorKey: "example_dynamic_error",
  isOverrideable: true,
};

export const MOCK_GET_CALL_INFO_RESPONSE: GetCallInfoResponse = {
  referenceId: "123",
};

export const MOCK_PAYMENT_DYNAMIC_ERROR_KEY = "mock_payment_dynamic_error";
export const MOCK_PAYMENT_DYNAMIC_ERROR: DynamicErrorResponse = {
  type: "dynamic_error",
  message: "Example dynamic error, will go away after several tries",
  errorKey: MOCK_PAYMENT_DYNAMIC_ERROR_KEY,
  isOverrideable: false,
};
