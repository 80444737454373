import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getDisplayError } from "../../lib/api/endpoints";
import {
  useAddToThread,
  useGetSecureMailThread,
  usePatchNextSecureMailId,
  useViewMailAttachment,
} from "../../lib/api/queries";
import { useSecureMailRedirectWithoutRole } from "../../lib/routeRedirect.hooks";
import { paths } from "../../lib/router";
import AssignedClassification from "../../modules/SecureMail/AssignedClassification";
import MessageBubble from "../../modules/SecureMail/MessageBubble";
import MessageInput from "../../modules/SecureMail/MessageInput";
import MessagesHeader from "../../modules/SecureMail/MessagesHeader";
import StyledError from "../../modules/StyledError";
import StyledLink from "../../modules/StyledLink";
import Container from "../../modules/common/Container";
import Spinner from "../../modules/common/Spinner";
import { NetworkErrorModal } from "../../modules/common/modals";
import styles from "./SecureMailMessagingPage.module.css";

const strings = {
  back: "< Back",
  errorTitle: "SecureMail thread failed to load",
  retry: "Retry",
  nextMail: "Next Mail >",
};

const EMPLOYEE = "1FB";

export default function SecureMailMessagingPage() {
  useSecureMailRedirectWithoutRole();

  const { secureMailId } = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const showNextMail = searchParams.get("showNextMail") === "true";

  const [messageBody, setMessageBody] = useState("");
  const [messageAttachment, setMessageAttachment] = useState<
    File | undefined
  >();
  const [displayError, setDisplayError] = useState<string>();

  const endOfMessages = useRef<HTMLDivElement>(null);

  const {
    data: secureMail,
    isLoading: isGetSecureMailThreadLoading,
    isError: isGetSecureMailThreadError,
    error: getSecureMailThreadError,
    refetch: getSecureMailThreadRefetch,
    isFetching: isGetSecureMailThreadFetching,
  } = useGetSecureMailThread(
    { secureMailId: secureMailId ?? "" },
    !!secureMailId,
  );

  const {
    mutateAsync: patchNextSecureMailId,
    isLoading: isPatchNextSecureMailIdLoading,
  } = usePatchNextSecureMailId();
  const [nextMailId, setNextMailId] = useState<string | null>(null);
  useEffect(() => {
    if (!secureMail?.classificationId || !secureMailId || !showNextMail) {
      return;
    }

    patchNextSecureMailId({
      classificationId: secureMail.classificationId,
      mailId: secureMailId,
    })
      .then(({ mailId }) => setNextMailId(mailId))
      .catch(() => {
        setNextMailId(null);
      });
  }, [secureMail, secureMailId, showNextMail, patchNextSecureMailId]);

  const canSendMessage =
    secureMailId &&
    (messageBody.trim() !== "" || messageAttachment !== undefined);

  const { mutateAsync: addToThread, isLoading: isAddToThreadLoading } =
    useAddToThread();

  const {
    mutateAsync: viewMailAttachment,
    isLoading: isViewMailAttachmentLoading,
  } = useViewMailAttachment();

  const onClickSend = () => {
    if (!canSendMessage) {
      return;
    }

    addToThread({
      secureMailId,
      messageBody,
      file: messageAttachment,
    })
      .then(() => {
        setMessageBody("");
        setMessageAttachment(undefined);
      })
      .catch((err) => {
        setDisplayError(getDisplayError(err));
      });
  };

  const backLinkPath = useMemo(() => {
    if (location.state) {
      const { backLink } = location.state;
      if (backLink) {
        return backLink;
      }
    }

    return paths.secureMailQueue;
  }, [location.state]);

  if (!secureMailId) {
    navigation(paths.accountSearch);
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className={styles.topRowButtons}>
            <StyledLink path={backLinkPath}>{strings.back}</StyledLink>
          </div>
        </Col>
        {!isGetSecureMailThreadLoading &&
        !isPatchNextSecureMailIdLoading &&
        nextMailId ? (
          <Col md={1} offset={{ md: 10 }}>
            <div className={styles.topRowButtons}>
              <StyledLink
                path={
                  paths.secureMail(nextMailId) +
                  (secureMail?.classificationId
                    ? `?classification=${secureMail.classificationId}&showNextMail=${showNextMail}`
                    : "")
                }
              >
                {strings.nextMail}
              </StyledLink>
            </div>
          </Col>
        ) : null}
      </Row>
      <Row>
        {isGetSecureMailThreadLoading || isGetSecureMailThreadFetching ? (
          <Col>
            <div className={styles.spinner}>
              <Spinner />
            </div>
          </Col>
        ) : isGetSecureMailThreadError ? (
          <Col>
            <StyledError
              error={getSecureMailThreadError}
              errorTitle={strings.errorTitle}
              refetch={getSecureMailThreadRefetch}
            />
          </Col>
        ) : (
          <>
            <Col xs={3}>
              <AssignedClassification
                secureMail={secureMail}
                onError={(err) => setDisplayError(getDisplayError(err))}
              />
            </Col>
            <Col xs={9}>
              <div className={styles.messagingContainer}>
                <MessagesHeader
                  className={styles.messagesHeader}
                  subject={secureMail.subject}
                  dwbuid={secureMail.dwbuid}
                  employees={secureMail.employees}
                  isRequestLoading={isGetSecureMailThreadLoading}
                  customerFirstName={secureMail.firstName}
                  customerLastName={secureMail.lastName}
                />
                <div className={styles.spacing} />
                <div className={styles.scrollableSection}>
                  {secureMail.messages.map((messageElem) => {
                    return (
                      <MessageBubble
                        key={messageElem.messageId}
                        className={styles.messageBubble}
                        timestamp={messageElem.createDate}
                        message={messageElem.body}
                        dwbuid={messageElem.dwbuid}
                        isEmployee={messageElem.sender === EMPLOYEE}
                        employeeFirstName={messageElem.employeeFirstName}
                        employeeLastName={messageElem.employeeLastName}
                        attachments={messageElem.attachments}
                        attachmentButtonDisabled={
                          isViewMailAttachmentLoading || !secureMailId
                        }
                        onAttachmentClick={(attachmentId) => {
                          viewMailAttachment({
                            mailId: secureMailId,
                            messageId: messageElem.messageId,
                            attachmentId: attachmentId,
                          })
                            .then((response) => {
                              window.open(
                                response.url,
                                "_blank",
                                "noopener", // NOTE: We cannot confirm the pop-up appeared while also having noopener
                              );
                            })
                            .catch((err) =>
                              setDisplayError(getDisplayError(err)),
                            );
                        }}
                        timeRead={messageElem.timeRead}
                      />
                    );
                  })}
                  <div className={styles.bottomSpace} ref={endOfMessages} />
                </div>
                {secureMail && secureMail.type !== "READONLY" ? (
                  <MessageInput
                    message={messageBody}
                    attachment={messageAttachment}
                    onChangeMessage={setMessageBody}
                    onSend={onClickSend}
                    onAttachment={setMessageAttachment}
                    isSendMessageLoading={isAddToThreadLoading}
                  />
                ) : null}
              </div>
            </Col>
          </>
        )}
      </Row>
      <NetworkErrorModal
        message={displayError}
        onRequestClose={() => setDisplayError(undefined)}
      />
    </Container>
  );
}
