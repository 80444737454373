import { Container as ReactGridContainer } from "react-grid-system";
import DebugGrid from "../DebugGrid/DebugGrid";
type ContainerProps = {
  children: React.ReactNode;
};

export default function Container(props: ContainerProps) {
  return (
    <ReactGridContainer>
      {props.children}
      {import.meta.env.VITE_SHOW_DEBUG_GRID === "true" ? <DebugGrid /> : null}
    </ReactGridContainer>
  );
}
