import classNames from "classnames";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import { GetAccountResponse } from "../../lib/api/models";
import { useSendTestPushNotification } from "../../lib/api/queries";
import Container from "../../modules/common/Container";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import Subtool from "../common/Subtool";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./SendTestPushNotification.module.css";

const strings = {
  close: "Close",
  hasSentNotification: "Re-Send Notification",
  hasSentNotificationMessage: "Push Notification Sent!",
  sendNotification: "Send Test Push Notification",
  subheader: (dwbuid: string) => `Test Notify Account ${dwbuid}`,
};

type Props = {
  account: GetAccountResponse;
  onDisplayGeneralErrors: (err: unknown) => void;
  className?: string;
  toolId: string;
  onRequestClose: () => void;
};

export function SendTestPushNotification({
  account,
  onDisplayGeneralErrors,
  className,
  toolId,
  onRequestClose,
}: Props) {
  const [hasSentNotification, setHasSentNotification] = useState(false);
  const { mutateAsync: sendTestPushNotification, isLoading } =
    useSendTestPushNotification();

  const onSubmit = () => {
    sendTestPushNotification({ partyId: account.partyId })
      .then(() => setHasSentNotification(true))
      .catch((err) => {
        setHasSentNotification(false);
        onDisplayGeneralErrors(err);
      });
  };

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row className={styles.headerContainer}>
          <Col md={3}>
            <h2 className={styles.header}>
              {toolTypeToName(ToolType.TestPushNotification)}
            </h2>
          </Col>
          <Col md={1}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              renderLoadingIndicator={false}
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Subtool className={styles.container}>
              <span className={styles.subheader}>
                {strings.subheader(account.currentDwbuid)}
              </span>
              {hasSentNotification ? (
                <div className={styles.hasSentNotificationMessage}>
                  {strings.hasSentNotificationMessage}
                </div>
              ) : null}
              <StyledButton
                className={classNames({
                  [styles.button]: true,
                  [styles.buttonAfterSending]: hasSentNotification,
                })}
                onClick={onSubmit}
                isLoading={isLoading}
              >
                {hasSentNotification
                  ? strings.hasSentNotification
                  : strings.sendNotification}
              </StyledButton>
            </Subtool>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
