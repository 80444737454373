import { useEffect } from "react";
import warningIcon from "../../../assets/warning-icon.svg";
import { reportError } from "../../../lib/bugReporting";
import StyledButton from "../../common/buttons/StyledButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import ModalCloseButton from "../../common/modals/ModalCloseButton/ModalCloseButton";
import styles from "./CloseToolConfirmationModal.module.css";

const strings = {
  cancel: "Stay on Page",
  closeTool: "Close Tool",
  closeToolMultiple: "Close Tools",
  messageLine1: " is in progress.",
  messageLine1Multiple: " are in progress.",
  messageLine2:
    "Are you sure you want to close this tool and lose all progress?",
  messageLine2Multiple:
    "Are you sure you want to close these tools and lose all progress?.",
  closeToolConfirmationModal: "Close Tool Confirmation Modal",
};

type Props = {
  isOpen: boolean;
  toolNames: string[];
  onCloseTool: () => void;
  onRequestClose: () => void;
};
const CloseToolConfirmationModal = ({
  isOpen,
  toolNames,
  onCloseTool,
  onRequestClose,
}: Props) => {
  useEffect(() => {
    if (isOpen && toolNames.length === 0) {
      reportError(
        new Error(
          "Error: Attempted to open CloseToolConfirmationModal without any active tools",
        ),
      );
    }
  }, [isOpen, toolNames.length]);

  if (isOpen && toolNames.length === 0) {
    return null;
  }

  const multipleTools = toolNames.length > 1;
  let toolNameListString = "";
  if (multipleTools) {
    const toolsExceptLastToolNames = toolNames.slice(0, -1);
    toolNameListString = `${toolsExceptLastToolNames.join(
      ", ",
    )} and ${toolNames.slice(-1)}`;
  } else {
    toolNameListString = toolNames[0];
  }

  return (
    <BaseModal
      className={styles.container}
      isOpen={isOpen}
      label={strings.closeToolConfirmationModal}
      onRequestClose={onRequestClose}
    >
      <ModalCloseButton onClick={onRequestClose} />
      <div className={styles.body}>
        <img src={warningIcon} alt="" />
        <div className={styles.messageText}>
          <span className={styles.toolNameText}>{toolNameListString}</span>
          {multipleTools ? strings.messageLine1Multiple : strings.messageLine1}
        </div>
        <div className={styles.messageText}>
          {multipleTools ? strings.messageLine2Multiple : strings.messageLine2}
        </div>
        <StyledButton className={styles.cancelButton} onClick={onRequestClose}>
          {strings.cancel}
        </StyledButton>
        <StyledButton
          className={styles.closeToolButton}
          variant="danger"
          onClick={onCloseTool}
        >
          {multipleTools ? strings.closeToolMultiple : strings.closeTool}
        </StyledButton>
      </div>
    </BaseModal>
  );
};
export default CloseToolConfirmationModal;
