import { Script, ScriptType } from "../../../lib/api/models";

import DialogueScript from "./DialogueScript";
import InstructionsScript from "./InstructionsScript";
import classNames from "classnames";
import styles from "./ConversationScript.module.css";

type Props = {
  scripts: Script[];
  scriptName: string;
  className?: string;
};

export default function ConversationScript({
  scripts,
  scriptName,
  className,
}: Props) {
  return (
    <div className={classNames(className, "conversation-script-component")}>
      <div className={styles.scriptName}>{scriptName}</div>
      <div className={styles.scriptContainer}>
        {scripts.map((script, index) => {
          switch (script.scriptType) {
            case ScriptType.Instruction:
              return (
                <InstructionsScript
                  key={index}
                  instructions={script.instructions}
                  className={script.className}
                />
              );
            case ScriptType.Dialogue:
              return (
                <DialogueScript
                  key={index}
                  dialogue={script.dialogue}
                  className={script.className}
                />
              );
          }
        })}
      </div>
    </div>
  );
}
