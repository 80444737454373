import { useState } from "react";
import StyledButton from "../../common/buttons/StyledButton";
import styles from "./FeesTable.module.css";

const strings = {
  fees: "Fees",
  annualFee: "Annual Fee",
  cardFeeDesc: "Card Fee Desc",
  cardFeeAmount: "Card Fee Amount",
  amountLastCharged: "AF/MF Amount Last Charged",
  lastChargedDate: "AF/MF Last Charged Date",
  annualFeeCharge: "Annual Fee Charge Month/Year",
  returnedCheckFee: "Returned Check Fee",
  cashAdvanceFee: "Cash Advance Fee",
  overLimit: "Over Limit Service",
  showScript: "Show Script",
  hideScript: "Hide Script",
};

export default function FeesTable() {
  const [showScript, setShowScript] = useState(false);
  return (
    <table className={styles.table}>
      <thead>
        <th>{strings.fees}</th>
      </thead>
      <tbody>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.annualFee}</span>
            <span>{"YES"}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td className={styles.row}>
            <span>{strings.cardFeeDesc}</span>
            <span>{"Annual"}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.cardFeeAmount}</span>
            <span>{""}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td className={styles.row}>
            <span>{strings.amountLastCharged}</span>
            <span>{"$0"}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.lastChargedDate}</span>
            <span>{"00000000"}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td className={styles.row}>
            <span>{strings.annualFeeCharge}</span>
            <span>{"0000"}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.returnedCheckFee}</span>
            <span>{"$25 or Stmt Amt Due"}</span>
          </td>
        </tr>
        <tr className={styles.blueRow}>
          <td className={styles.row}>
            <span>{strings.cashAdvanceFee}</span>
            <div className={styles.scriptButtonContainer}>
              <span>{"$3 or 3%"}</span>
              <StyledButton
                onClick={() => setShowScript((prev) => !prev)}
                variant="transparent"
              >
                {showScript ? strings.hideScript : strings.showScript}
              </StyledButton>
            </div>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.overLimit}</span>
            <span>{"Not Offered"}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
