import StyledButton from "../common/buttons/StyledButton";
import TextButton from "../common/buttons/TextButton";
import BaseModal from "../common/modals/BaseModal/BaseModal";
import styles from "./PaymentScriptsModal.module.css";

const strings = {
  title: "Payment Scripts",
  submitLabel: "Submit Payment",
  declineLabel: "Decline",
};

type Props = {
  message?: string;
  onSubmit: () => void;
  onDecline: () => void;
};

export default function PaymentScriptsModal({
  message,
  onSubmit,
  onDecline,
}: Props) {
  return (
    <BaseModal
      className={styles.container}
      isOpen={!!message}
      label={strings.title}
      onRequestClose={onDecline}
    >
      <div className={styles.body}>
        <h2>{strings.title}</h2>
        <div className={styles.message}>{message}</div>
        <StyledButton
          className={styles.button}
          onClick={onSubmit}
          variant="primary"
        >
          {strings.submitLabel}
        </StyledButton>
        <TextButton onClick={onDecline}>{strings.declineLabel}</TextButton>
      </div>
    </BaseModal>
  );
}
