import classNames from "classnames";
import {
  getEmployeeFullName,
  getEmployeeInitials,
} from "../../../lib/formatting";

import styles from "./AgentInitials.module.css";

type Props = {
  className?: string;
  firstName: string | null;
  lastName: string | null;
};
export default function AgentInitials({
  className,
  firstName,
  lastName,
}: Props) {
  const initials = getEmployeeInitials(firstName, lastName);
  const fullName = getEmployeeFullName(true, firstName, lastName);

  return (
    <div>
      <label className={classNames(styles.circle, className)} title={fullName}>
        {initials}
      </label>
    </div>
  );
}
