import { Col, Row } from "react-grid-system";
import {
  GetAccountDetailsResponse,
  GetAccountResponse,
  OwnershipType,
  PaymentMethodType,
} from "../../../lib/api/models";
import { INPUT_MASKS, maskInput } from "../../../lib/formatting";
import { useIsAgentRoleViewPCIData } from "../../../lib/roleContext";
import {
  OWNERSHIP_OPTIONS,
  PAYMENT_METHOD_TYPE_OPTIONS,
  useDynamicErrorMessages,
} from "../../../lib/sourceAccounts";
import CardholderInfo from "../../CardholderInfo";
import StyledPasswordInput from "../../StyledPasswordInput";
import ExpirationDateInput from "../../common/ExpirationDateInput";
import Select from "../../common/Select";
import StyledButton from "../../common/buttons/StyledButton";
import StyledInput from "../../common/inputs/StyledInput";
import OverrideCardAddressCheckModal from "../OverrideCardAddressCheckModal";
import SourceAccountsTitle from "../SourceAccountsTitle";
import {
  useAddSourceAccountQueries,
  useAddSourceAccountState,
} from "./AddSourceAccount.hooks";
import styles from "./AddSourceAccount.module.css";

type Props = {
  cardmemberAccount: GetAccountResponse;
  cardmemberAccountDetails: GetAccountDetailsResponse;
  onCancel: () => void;
  onSuccess: () => void;
  onDisplayGeneralErrors: (err: unknown) => void;
  onRequestClose: () => void;
};

export default function AddSourceAccount({
  cardmemberAccount,
  cardmemberAccountDetails,
  onSuccess,
  onCancel,
  onDisplayGeneralErrors,
  onRequestClose,
}: Props) {
  const {
    addSourceAccountState,
    dispatch,
    validationMessages,
    setValidationMessages,
  } = useAddSourceAccountState();

  const {
    currentDynamicError,
    setCurrentDynamicError,
    dynamicErrorOverridesRef,
    dynamicErrorViewCountsRef,
  } = useDynamicErrorMessages(addSourceAccountState);

  const { onSubmit, isLoading } = useAddSourceAccountQueries(
    cardmemberAccount,
    addSourceAccountState,
    onSuccess,
    setValidationMessages,
    onDisplayGeneralErrors,
    setCurrentDynamicError,
    dynamicErrorOverridesRef,
    dynamicErrorViewCountsRef,
  );

  const accountNumberInputsOnFocus = () => {
    setValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber: [],
      };
    });
  };

  const accountNumberInputsOnBlur = () => {
    setValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber:
          addSourceAccountState.confirmAccountNumber &&
          addSourceAccountState.confirmAccountNumber !==
            addSourceAccountState.accountNumber
            ? ["Account Numbers don't match"]
            : [],
      };
    });
  };

  const cardNumberInputsOnFocus = () => {
    setValidationMessages((prev) => {
      return {
        ...prev,
        confirmCardNumber: [],
      };
    });
  };

  const cardNumberInputsOnBlur = () => {
    setValidationMessages((prev) => {
      return {
        ...prev,
        confirmCardNumber:
          addSourceAccountState.confirmCardNumber &&
          addSourceAccountState.confirmCardNumber !==
            addSourceAccountState.cardNumber
            ? ["Card Numbers don't match"]
            : [],
      };
    });
  };

  const canViewPCIData = useIsAgentRoleViewPCIData();

  return (
    <>
      <Row>
        <Col md={2}>
          <SourceAccountsTitle />
        </Col>
        <Col md={3} className={styles.addSourceAccountContainer}>
          <div className={styles.addSourceAccount}>
            {"Add New Source Account"}
          </div>
        </Col>
        <Col md={1} offset={{ md: 6 }}>
          <StyledButton
            className={styles.closeButton}
            variant="secondary"
            renderLoadingIndicator={false}
            onClick={onRequestClose}
          >
            {"Close"}
          </StyledButton>
        </Col>
      </Row>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Row className={styles.row}>
          <Col xs={2}>
            <Select
              disabled={isLoading}
              options={OWNERSHIP_OPTIONS}
              onChange={(ownerType: OwnershipType) => {
                dispatch({ ownerType });
              }}
              selectedValue={addSourceAccountState.ownerType}
              placeholder={"Select"}
              label={"Ownership*"}
              validationMessages={validationMessages?.ownerType}
            />
          </Col>
          <Col xs={2}>
            <Select
              disabled={isLoading}
              options={PAYMENT_METHOD_TYPE_OPTIONS}
              onChange={(paymentMethodType: PaymentMethodType) => {
                dispatch({ paymentMethodType });
              }}
              selectedValue={addSourceAccountState.paymentMethodType}
              placeholder={"Select"}
              label={"Method Type*"}
              validationMessages={validationMessages?.paymentMethodType}
            />
          </Col>
          {addSourceAccountState.paymentMethodType === "checking_account" ? (
            <>
              <Col xs={2}>
                <StyledInput
                  disabled={isLoading}
                  value={addSourceAccountState.abaNumber}
                  onChange={(e) => {
                    dispatch({
                      abaNumber: maskInput(
                        INPUT_MASKS.abaNumber,
                        e.target.value,
                      ),
                    });
                  }}
                  label={"ABA Number*"}
                  validationMessages={validationMessages?.abaNumber}
                />
              </Col>
              <Col xs={2}>
                <StyledPasswordInput
                  disabled={isLoading}
                  value={addSourceAccountState.accountNumber}
                  onChange={(e) => {
                    dispatch({
                      accountNumber: maskInput(
                        INPUT_MASKS.checkingAccountNumber,
                        e.target.value,
                      ),
                    });
                  }}
                  label={"Account Number*"}
                  validationMessages={validationMessages?.accountNumber}
                  onFocus={accountNumberInputsOnFocus}
                  onBlur={accountNumberInputsOnBlur}
                  alwaysHideInput={!canViewPCIData}
                />
              </Col>
              <Col xs={2}>
                <StyledPasswordInput
                  disabled={isLoading}
                  value={addSourceAccountState.confirmAccountNumber}
                  onChange={(e) => {
                    dispatch({
                      confirmAccountNumber: maskInput(
                        INPUT_MASKS.checkingAccountNumber,
                        e.target.value,
                      ),
                    });
                  }}
                  label={"Confirm Account Number*"}
                  validationMessages={validationMessages?.confirmAccountNumber}
                  onFocus={accountNumberInputsOnFocus}
                  onBlur={accountNumberInputsOnBlur}
                  alwaysHideInput={!canViewPCIData}
                />
              </Col>
            </>
          ) : (
            <>
              <Col xs={2}>
                <StyledPasswordInput
                  value={addSourceAccountState.cardNumber}
                  onChange={(e) => {
                    dispatch({
                      cardNumber: maskInput(
                        INPUT_MASKS.creditCard,
                        e.target.value,
                      ),
                    });
                  }}
                  label={"Card Number*"}
                  validationMessages={validationMessages?.cardNumber}
                  onFocus={cardNumberInputsOnFocus}
                  onBlur={cardNumberInputsOnBlur}
                  alwaysHideInput={!canViewPCIData}
                />
              </Col>
              <Col xs={2}>
                <StyledPasswordInput
                  disabled={isLoading}
                  value={addSourceAccountState.confirmCardNumber}
                  onChange={(e) => {
                    dispatch({
                      confirmCardNumber: maskInput(
                        INPUT_MASKS.creditCard,
                        e.target.value,
                      ),
                    });
                  }}
                  label={"Confirm Card Number*"}
                  validationMessages={validationMessages?.confirmCardNumber}
                  onFocus={cardNumberInputsOnFocus}
                  onBlur={cardNumberInputsOnBlur}
                  alwaysHideInput={!canViewPCIData}
                />
              </Col>
              <Col xs={1}>
                <ExpirationDateInput
                  disabled={isLoading}
                  value={addSourceAccountState.expirationDate}
                  onChange={(v) => {
                    dispatch({ expirationDate: v });
                  }}
                  label={"Expiration Date*"}
                  validationMessages={validationMessages?.expirationDate}
                />
              </Col>
            </>
          )}
          {addSourceAccountState.ownerType === "ACCOUNT_OWNER" ? (
            <Col md={2} className={styles.autofillButtonContainer}>
              <StyledButton
                className={styles.autofillButton}
                variant="secondary"
                disabled={isLoading}
                onClick={() =>
                  dispatch({
                    cardHolderFirstName: cardmemberAccount.firstName,
                    cardHolderLastName: cardmemberAccount.lastName,
                    phoneNumber: cardmemberAccountDetails.phoneNumber,
                    addressLine1: cardmemberAccountDetails.addressLine1,
                    addressLine2: cardmemberAccountDetails.addressLine2,
                    city: cardmemberAccountDetails.city,
                    stateCode: cardmemberAccountDetails.stateCode,
                    zipcode: cardmemberAccountDetails.zipcode,
                  })
                }
              >
                {"Autofill"}
              </StyledButton>
            </Col>
          ) : null}
        </Row>
        <Row className={styles.row}>
          <CardholderInfo
            isLoading={isLoading}
            formState={addSourceAccountState}
            generalDispatch={(payload) => dispatch(payload)}
            clearNetworkValidationMessage={() => {
              // This is handled in dispatch as each field changes
            }}
            networkValidationMessages={validationMessages}
          />
        </Row>
        <Row className={styles.buttonRow}>
          <Col md={2} offset={{ md: 8 }}>
            <StyledButton
              disabled={isLoading}
              className={styles.button}
              onClick={
                onCancel /* TODO: confirmation modal: https://github.com/1fbusa/clarity-web/issues/571 */
              }
              variant={"danger"}
            >
              {"Cancel"}
            </StyledButton>
          </Col>
          <Col md={2}>
            <StyledButton
              className={styles.button}
              type="submit"
              disabled={!addSourceAccountState.isComplete || isLoading}
            >
              {"Save"}
            </StyledButton>
          </Col>
        </Row>
      </form>
      <OverrideCardAddressCheckModal
        currentDynamicError={currentDynamicError}
        onOverride={(dynamicErrorKey) => {
          dynamicErrorOverridesRef.current.push(dynamicErrorKey);
          setCurrentDynamicError(undefined);
          onSubmit();
        }}
        onClose={() => setCurrentDynamicError(undefined)}
      />
    </>
  );
}
