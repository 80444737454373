import classNames from "classnames";
import checkMark from "../../../assets/check-mark.svg";
import inProgressIcon from "../../../assets/in-progress.svg";
import CloseActiveTool from "../CloseActiveToolIcon";
import styles from "./ActiveToolButton.module.css";

type Props = {
  icon: string;
  label: string;
  isExpanded: boolean;
  onClickCloseTool: NonNullable<React.ComponentProps<"button">["onClick"]>;
  onClickActiveTool: NonNullable<React.ComponentProps<"button">["onClick"]>;
  inProgress: boolean;
  className?: string;
};

const strings = {
  close: "Close",
  collapse: "Collapse",
  completed: ", completed",
  expand: "Expand",
  inProgress: ", in progress",
};

export function ActiveToolButton({
  icon,
  label,
  isExpanded,
  onClickCloseTool,
  onClickActiveTool,
  inProgress,
  className,
}: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <button
        className={classNames({
          [styles.button]: true,
          [styles.buttonExpanded]: isExpanded,
        })}
        type="button"
        onClick={onClickActiveTool}
        disabled={isExpanded}
        id={"active-tool-button"}
      >
        {isExpanded ? (
          <div className={styles.expandedContainer}>
            <img src={inProgress ? inProgressIcon : checkMark} alt="" />
            <div className={styles.text} aria-hidden="true">
              {label}
            </div>
          </div>
        ) : (
          <>
            <img className={styles.icon} src={icon} alt="" />
            {inProgress ? <div className={styles.inProgress} /> : null}
          </>
        )}
      </button>
      {isExpanded ? (
        <button
          type="button"
          className={styles.closeActiveTool}
          aria-label={`${strings.close} ${label}`}
          onClick={onClickCloseTool}
        >
          <CloseActiveTool className={styles.closeActiveToolIcon} />
        </button>
      ) : null}
    </div>
  );
}
