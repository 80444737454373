import classNames from "classnames";
import { useState } from "react";
import passwordEyeSlashedIcon from "../../assets/password-eye-slashed.svg";
import passwordEyeIcon from "../../assets/password-eye.svg";
import StyledInput from "../common/inputs/StyledInput";
import styles from "./StyledPasswordInput.module.css";

const strings = {
  hidePassword: "Hide Password",
  showPassword: "Show Password",
};

type Props = React.ComponentProps<typeof StyledInput> & {
  alwaysHideInput?: boolean;
};

export default function StyledPasswordInput({
  alwaysHideInput,
  autoComplete = "new-password",
  ...rest
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledInput
      {...rest}
      type={showPassword ? "text" : "password"}
      autoComplete={autoComplete}
      rightContent={
        !alwaysHideInput ? (
          <button
            className={classNames(
              styles.eyeButton,
              rest.invalid || rest.validationMessages?.length
                ? styles.eyeButtonIndent
                : null,
            )}
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <img
              className={classNames(
                styles.eyeImage,
                showPassword && styles.hideEyeImage,
              )}
              src={showPassword ? passwordEyeSlashedIcon : passwordEyeIcon}
              alt={showPassword ? strings.hidePassword : strings.showPassword}
            />
          </button>
        ) : undefined
      }
    />
  );
}
