import { useCallback, useState } from "react";
import {
  DynamicErrorResponse,
  GetAccountResponse,
  OwnershipType,
  PaymentMethodType,
  ValidationErrors,
} from "../../../lib/api/models";
import {
  useAddBankSourceAccount,
  useAddCardSourceAccount,
} from "../../../lib/api/queries";
import { handleAddCardSourceAccountErrorResponse } from "../../../lib/sourceAccounts";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../../lib/validation";

type AddSourceAccountState = {
  /* Derived state */
  isComplete: boolean;

  /* Common */
  ownerType?: OwnershipType;
  cardHolderFirstName: string;
  cardHolderLastName: string;
  phoneNumber?: string | null;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  stateCode: string;
  zipcode: string;
  cardHolderFirstName2?: string | null;
  cardHolderLastName2?: string | null;

  paymentMethodType: PaymentMethodType;
  /* Checking */
  abaNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  /* Cards */
  cardNumber: string;
  confirmCardNumber: string;
  expirationDate: string;
};

function isAddSourceAccountComplete(s: AddSourceAccountState): boolean {
  const hasCommonData: boolean =
    !!s.ownerType &&
    !!s.cardHolderFirstName.trim() &&
    !!s.cardHolderLastName.trim() &&
    !!s.addressLine1.trim() &&
    !!s.city.trim() &&
    !!s.stateCode &&
    !!s.zipcode;

  const hasSourceAccountSpecificData: boolean =
    (s.paymentMethodType === "checking_account" &&
      !!s.abaNumber &&
      !!s.accountNumber &&
      s.accountNumber === s.confirmAccountNumber) ||
    (!!s.cardNumber &&
      s.cardNumber === s.confirmCardNumber &&
      !!s.expirationDate);

  return hasCommonData && hasSourceAccountSpecificData;
}

export function useAddSourceAccountState() {
  const [validationMessages, setValidationMessages] =
    useState<ValidationErrors>();
  const clearValidationMessages = useCallback((...keys: string[]) => {
    setValidationMessages((prev) => {
      const next = { ...prev };
      keys.forEach((key) => {
        next[key] = [];
      });

      return next;
    });
  }, []);

  const [addSourceAccountState, setAddSourceAccountState] =
    useState<AddSourceAccountState>({
      isComplete: false,
      ownerType: undefined,
      paymentMethodType: "checking_account",
      abaNumber: "",
      accountNumber: "",
      confirmAccountNumber: "",
      cardNumber: "",
      confirmCardNumber: "",
      expirationDate: "",
      cardHolderFirstName: "",
      cardHolderLastName: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: undefined,
      city: "",
      stateCode: "",
      zipcode: "",
      cardHolderFirstName2: undefined,
      cardHolderLastName2: undefined,
    });

  const dispatch = useCallback(
    (next: Partial<AddSourceAccountState>) =>
      setAddSourceAccountState((prev) => {
        // reset validation messages on changed fields
        clearValidationMessages(...Object.keys(next));

        const nextState = {
          ...prev,
          ...next,
        };
        nextState.isComplete = isAddSourceAccountComplete(nextState);
        return nextState;
      }),
    [setAddSourceAccountState, clearValidationMessages],
  );

  return {
    addSourceAccountState,
    dispatch,
    validationMessages,
    setValidationMessages,
  };
}

export function useAddSourceAccountQueries(
  cardmemberAccount: GetAccountResponse,
  addSourceAccountState: AddSourceAccountState,
  onSuccess: () => void,
  setValidationMessages: React.Dispatch<
    React.SetStateAction<Record<string, string[]> | undefined>
  >,
  onDisplayGeneralError: (error: unknown) => void,
  setCurrentDynamicError: (dynamicError: DynamicErrorResponse) => void,
  dynamicErrorOverridesRef: React.MutableRefObject<string[]>,
  dyanmicErrorViewCountsRef: React.MutableRefObject<Record<string, number>>,
) {
  const addBankSourceAccount = useAddBankSourceAccount();
  const addCardSourceAccount = useAddCardSourceAccount();
  const isLoading =
    addBankSourceAccount.isLoading || addCardSourceAccount.isLoading;
  const onSubmit = useCallback(() => {
    if (!addSourceAccountState.isComplete || isLoading) {
      return;
    }

    let promise;
    const addSourceAccountCommon = {
      partyId: cardmemberAccount.partyId,
      addressLine1: addSourceAccountState.addressLine1,
      addressLine2: addSourceAccountState.addressLine2,
      city: addSourceAccountState.city,
      stateCode: addSourceAccountState.stateCode,
      zipcode: addSourceAccountState.zipcode,
      phoneNumber: addSourceAccountState.phoneNumber,
      cardHolderFirstName: addSourceAccountState.cardHolderFirstName,
      cardHolderLastName: addSourceAccountState.cardHolderLastName,
      ownerType: addSourceAccountState.ownerType ?? "UNKNOWN",
    };
    if (addSourceAccountState.paymentMethodType === "checking_account") {
      promise = addBankSourceAccount.mutateAsync({
        ...addSourceAccountCommon,
        accountNumber: addSourceAccountState.accountNumber,
        abaNumber: addSourceAccountState.abaNumber,
        cardHolderFirstName2: addSourceAccountState.cardHolderFirstName2,
        cardHolderLastName2: addSourceAccountState.cardHolderLastName2,
      });
    } else {
      promise = addCardSourceAccount.mutateAsync({
        ...addSourceAccountCommon,
        cardNumber: addSourceAccountState.cardNumber,
        expirationDate: addSourceAccountState.expirationDate,
        creditDebitType:
          addSourceAccountState.paymentMethodType === "credit_card"
            ? "CREDIT"
            : "DEBIT",
        dynamicErrorOverrides: dynamicErrorOverridesRef.current,
        dynamicErrorViewCounts: dyanmicErrorViewCountsRef.current,
      });
    }
    promise.then(onSuccess).catch((error) => {
      addSourceAccountState.paymentMethodType === "checking_account"
        ? setValidationErrorsAndDisplayGeneralErrors(
            error,
            setValidationMessages,
            onDisplayGeneralError,
          )
        : handleAddCardSourceAccountErrorResponse(
            error,
            setCurrentDynamicError,
            setValidationMessages,
            onDisplayGeneralError,
          );
    });
  }, [
    addBankSourceAccount,
    addCardSourceAccount,
    addSourceAccountState,
    cardmemberAccount.partyId,
    dyanmicErrorViewCountsRef,
    dynamicErrorOverridesRef,
    isLoading,
    onDisplayGeneralError,
    onSuccess,
    setCurrentDynamicError,
    setValidationMessages,
  ]);

  return {
    onSubmit,
    isLoading,
  };
}
