import { HTTPError, genericErrorKey } from "./api/endpoints";
import { ValidationErrors } from "./api/models";

/*
  Utility for merging local validation errors with API validation errors.
*/
export function mergeValidation(
  a: ValidationErrors,
  b: ValidationErrors,
): ValidationErrors {
  // Add all entries from a
  const result: ValidationErrors = { ...a };

  // Iterate across entries of b
  for (const [k, v] of Object.entries(b)) {
    if (!Object.prototype.hasOwnProperty.call(result, k)) {
      // In b, not in a
      result[k] = v;
    } else {
      // In a and b
      result[k] = [...result[k], ...v];
    }
  }

  return result;
}

export const getNonGenericValidationList = (
  finalValidation?: ValidationErrors,
) => {
  if (!finalValidation) {
    return [];
  }

  const nonGenericKeys = Object.keys(finalValidation).filter(
    (key) => key !== genericErrorKey,
  );

  return Object.entries(finalValidation).filter(
    (val) => nonGenericKeys.includes(val[0]) && val[1].length > 0,
  );
};

export const setValidationErrorsAndDisplayGeneralErrors = (
  error: unknown,
  setNetworkValidationMessages: React.Dispatch<
    React.SetStateAction<Record<string, string[]> | undefined>
  >,
  onDisplayGeneralErrors: (error: unknown) => void,
) => {
  let hasSetValidationMessages = false;
  if (error instanceof HTTPError) {
    const validationMessages = error.validation?.errors;
    if (getNonGenericValidationList(validationMessages).length >= 1) {
      setNetworkValidationMessages(error.validation?.errors);
      hasSetValidationMessages = true;
    }
    if (validationMessages && validationMessages[genericErrorKey]) {
      onDisplayGeneralErrors(error);
      hasSetValidationMessages = true;
    }
  }
  if (!hasSetValidationMessages) {
    onDisplayGeneralErrors(error);
  }
};
