import classNames from "classnames";
import styles from "./AccountDetailsHeaderRow.module.css";

type Props = {
  label: string;
  value?: string | string[] | null;
  className?: string;
};

const renderValue = (value?: string | string[] | null) => {
  if (!value) {
    return <span className={styles.value}>{"-"}</span>;
  }
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((v, index) => (
          <div key={`${v}-${index}`} className={styles.value}>
            {v}
          </div>
        ))}
      </div>
    );
  }
  return <span className={styles.value}>{value}</span>;
};

export function AccountDetailsHeaderRow({ label, value, className }: Props) {
  return (
    <div className={classNames(styles.accountDetailsHeaderRow, className)}>
      <span className={styles.label}>{label}</span>
      {renderValue(value)}
    </div>
  );
}
