import { Row, Col } from "react-grid-system";
import StyledButton from "../../common/buttons/StyledButton";
import Subtool from "../../common/Subtool";
import {
  GetAccountResponse,
  GetAutopayEnrollmentResponseEligible,
  OwnershipType,
  SourceAccountOwnerType,
} from "../../../lib/api/models";
import TextToggle from "../../common/buttons/TextToggle";
import { TextToggleOption } from "../../common/buttons/TextToggle/TextToggle";
import { useEffect, useState } from "react";
import {
  useRemoveAutopayEnrollment,
  useSkipAutopay,
} from "../../../lib/api/queries";
import { convertDateStringToMonthDayYearFomat } from "../../../lib/formatting";
import EyeButton from "../../common/buttons/EyeButton";
import { useIsAgentRoleViewAccountNumber } from "../../../lib/roleContext";

import styles from "./UpdateAutopay.module.css";

const ABBREVIATED_ACCOUNT_NUMBER_LENGTH = 4;

const strings = {
  skipAutopay: "Skip Autopay Payment",
  removeAutopay: "Remove Autopay Enrollment",
  enrolled: "Enrolled",
  enrolledDate: "Enrolled Date",
  ownership: "Ownership",
  abaNumber: "ABA Number",
  ownerName: "Owner Name",
  bankName: "Bank Name",
  amount: "Amount",
  accountNumber: "Account Number",
  manageActions: "Manage Actions",
  isAuthorized: "Cardmember is Authorized",
  isOwner: "Cardmember is Owner",
  isThirdParty: "Caller is Third Party",
  isOther: "Caller is Other",
  isUnknown: "Caller is Unknown",
  skipAutopayPayment: "Skip Autopay Payment",
  removeAutopayPayment: "Remove Autopay Payment",
  pleaseSelect:
    "Please select whether this request was made by the Card Member or Third Party:",
  cardMember: "Card Member",
  thirdParty: "Third Party",
  confirmSkip: "Are you sure that you want to skip this Autopay Payment?",
  confirmRemove: (reenrollmentDate: string) =>
    `If you continue, you will be able to re-enroll the Card Member after ${reenrollmentDate}.`,
  cancel: "Cancel",
  summarySkip: (dwbuid: string) => `Successfully skipped autopay ${dwbuid}`,
  summaryRemove: (dwbuid: string) => `Successfully removed autopay ${dwbuid}`,
  monthSkipped: "This Month's Payment Skipped",
  hideAccountNumber: "Hide Account Number",
  showAccountNumber: "Show Account Number",
};

const SOURCE_ACCOUNT_OPTIONS: [
  TextToggleOption<SourceAccountOwnerType>,
  TextToggleOption<SourceAccountOwnerType>,
] = [
  {
    key: "ACCOUNT_OWNER",
    label: strings.cardMember,
  },
  {
    key: "THIRD_PARTY",
    label: strings.thirdParty,
  },
];

const translateOwnershipToLegibleString = (
  ownership?: OwnershipType,
): string => {
  switch (ownership) {
    case "ACCOUNT_OWNER":
      return strings.isOwner;
    case "AUTHORIZED_USER":
      return strings.isAuthorized;
    case "THIRD_PARTY":
      return strings.isThirdParty;
    case "OTHER":
      return strings.isOther;
    case "UNKNOWN":
      return strings.isUnknown;
    default:
      return "";
  }
};

const getAccountNumber = (
  showFullAccountNumber: boolean,
  canViewAccountNumber: boolean,
  accNum?: string,
) => {
  if (!accNum || accNum.length < ABBREVIATED_ACCOUNT_NUMBER_LENGTH) {
    return "";
  }

  return showFullAccountNumber && canViewAccountNumber
    ? accNum
    : `x${accNum.slice(accNum.length - ABBREVIATED_ACCOUNT_NUMBER_LENGTH)}`;
};

type Props = {
  account: GetAccountResponse;
  autopayInfo: GetAutopayEnrollmentResponseEligible;
  onComplete: (summaryWithoutTime: string) => void;
  onDisplayGeneralErrors: (err: unknown) => void;
  setToolInProgress: (inProgress: boolean) => void;
  className?: string;
};

export default function UpdateAutopay({
  account,
  autopayInfo,
  onComplete,
  onDisplayGeneralErrors,
  setToolInProgress,
  className,
}: Props) {
  const { enrollmentInfo, isAutopaySkipped, reEnrollDate } = autopayInfo;
  const canViewAccountNumber = useIsAgentRoleViewAccountNumber();

  const [selectedAction, setSelectedAction] = useState<"skip" | "remove">();
  const [selectedOwnerType, setSelectedOwnerType] =
    useState<SourceAccountOwnerType>();
  const [showFullAccountNumber, setShowFullAccountNumber] = useState(false);

  const { mutateAsync: skipAutopay, isLoading: isSkipAutopayLoading } =
    useSkipAutopay();
  const { mutateAsync: removeAutopay, isLoading: isRemoveAutopayLoading } =
    useRemoveAutopayEnrollment();

  const isLoading = isSkipAutopayLoading || isRemoveAutopayLoading;

  useEffect(() => {
    setToolInProgress(!!selectedAction || !!selectedOwnerType);
  }, [setToolInProgress, selectedAction, selectedOwnerType]);

  /* FIXME: Theoretically, enrollmentInfo would not be undefined if this component is rendered since `isEnrolledAutopay === true.`
   Need to enforce this using nested zod discriminatedUnion */

  const onSkipAutopay = () => {
    if (!selectedOwnerType || isSkipAutopayLoading || isAutopaySkipped) {
      return;
    }

    const { partyId, currentDwbuid: dwbuid } = account;

    skipAutopay({
      partyId,
      dwbuid,
      party: selectedOwnerType,
    })
      .then(() => onComplete(strings.summarySkip(dwbuid)))
      .catch(onDisplayGeneralErrors);
  };

  const onRemoveAutopay = () => {
    if (!selectedOwnerType || isRemoveAutopayLoading) {
      return;
    }

    const { partyId, currentDwbuid: dwbuid } = account;

    removeAutopay({
      partyId,
      dwbuid,
      party: selectedOwnerType,
    })
      .then(() => onComplete(strings.summaryRemove(dwbuid)))
      .catch(onDisplayGeneralErrors);
  };

  const renderSkipOrRemoveAutopay = () => {
    if (!selectedAction) {
      return null;
    }

    if (selectedAction === "skip") {
      return (
        <Row className={styles.actionRow}>
          <Col md={5}>
            <Subtool isActive={false}>
              <Row>
                <Col md={12}>
                  <div className={styles.actionHeader}>
                    {strings.skipAutopayPayment}
                  </div>
                </Col>
                <Col md={12}>
                  <div className={styles.pleaseSelectText}>
                    {strings.pleaseSelect}
                  </div>
                </Col>
                <Col md={8}>
                  <TextToggle
                    className={styles.ownerTypeTextToggle}
                    options={SOURCE_ACCOUNT_OPTIONS}
                    selectedOption={selectedOwnerType}
                    onSelectOption={setSelectedOwnerType}
                    disabled={isSkipAutopayLoading}
                  />
                </Col>
                <Col md={12}>
                  <div className={styles.confirmSkipText}>
                    {strings.confirmSkip}
                  </div>
                </Col>
                <Col xs={5}>
                  <StyledButton
                    className={styles.fullSizeButton}
                    variant="secondary"
                    disabled={isSkipAutopayLoading}
                    onClick={() => {
                      setSelectedAction(undefined);
                      setSelectedOwnerType(undefined);
                    }}
                  >
                    {strings.cancel}
                  </StyledButton>
                </Col>
                <Col xs={7}>
                  <StyledButton
                    className={styles.fullSizeButton}
                    variant="primary"
                    onClick={onSkipAutopay}
                    disabled={!selectedOwnerType || isAutopaySkipped}
                    isLoading={isSkipAutopayLoading}
                  >
                    {strings.skipAutopayPayment}
                  </StyledButton>
                </Col>
              </Row>
            </Subtool>
          </Col>
        </Row>
      );
    }

    return (
      <Row className={styles.actionRow}>
        <Col md={5}>
          <Subtool isActive={false}>
            <Row>
              <Col md={12}>
                <div className={styles.actionHeader}>
                  {strings.removeAutopayPayment}
                </div>
              </Col>
              <Col md={12}>
                <div className={styles.pleaseSelectText}>
                  {strings.pleaseSelect}
                </div>
              </Col>
              <Col md={8}>
                <TextToggle
                  className={styles.ownerTypeTextToggle}
                  options={SOURCE_ACCOUNT_OPTIONS}
                  selectedOption={selectedOwnerType}
                  onSelectOption={setSelectedOwnerType}
                  disabled={isRemoveAutopayLoading}
                />
              </Col>
              <Col md={12}>
                <div className={styles.confirmRemoveAutopayText}>
                  {strings.confirmRemove(
                    convertDateStringToMonthDayYearFomat(reEnrollDate),
                  )}
                </div>
              </Col>
              <Col xs={5}>
                <StyledButton
                  className={styles.fullSizeButton}
                  variant="secondary"
                  disabled={isRemoveAutopayLoading}
                  onClick={() => {
                    setSelectedAction(undefined);
                    setSelectedOwnerType(undefined);
                  }}
                >
                  {strings.cancel}
                </StyledButton>
              </Col>
              <Col xs={7}>
                <StyledButton
                  className={styles.fullSizeButton}
                  variant="primary"
                  onClick={onRemoveAutopay}
                  disabled={!selectedOwnerType}
                  isLoading={isRemoveAutopayLoading}
                >
                  {strings.removeAutopayPayment}
                </StyledButton>
              </Col>
            </Row>
          </Subtool>
        </Col>
      </Row>
    );
  };

  return (
    <div className={className}>
      <Row>
        <Col md={8}>
          <Subtool isActive={false}>
            <Row>
              <Col md={3}>
                <div className={styles.enrollmentStatus}>
                  {strings.enrolled}
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <div className={styles.label}>{strings.ownership}</div>
                  <div className={styles.value}>
                    {translateOwnershipToLegibleString(enrollmentInfo?.party)}
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <div className={styles.label}>{strings.ownerName}</div>
                  <div className={styles.value}>
                    {enrollmentInfo?.ownerName}
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <div className={styles.label}>{strings.amount}</div>
                  <div className={styles.value}>{enrollmentInfo?.amount}</div>
                </div>
              </Col>
              <Col md={3}>
                <div className={styles.enrolledDateContainer}>
                  <div className={styles.label}>{strings.enrolledDate}</div>
                  <div className={styles.value}>
                    {convertDateStringToMonthDayYearFomat(
                      autopayInfo.enrolledDate,
                    )}
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <div className={styles.label}>{strings.abaNumber}</div>
                  <div className={styles.value}>
                    {enrollmentInfo?.abaNumber}
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <div className={styles.label}>{strings.bankName}</div>
                  <div className={styles.value}>{enrollmentInfo?.bankName}</div>
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <div className={styles.label}>{strings.accountNumber}</div>
                  <div className={styles.accountNumberValue}>
                    <span className={styles.value}>
                      {getAccountNumber(
                        showFullAccountNumber,
                        canViewAccountNumber,
                        enrollmentInfo?.ddaNumber,
                      )}
                    </span>
                    {canViewAccountNumber ? (
                      <EyeButton
                        className={styles.showAccNumButton}
                        show={showFullAccountNumber}
                        onClick={() =>
                          setShowFullAccountNumber((prev) => !prev)
                        }
                        altShown={strings.showAccountNumber}
                        altHidden={strings.hideAccountNumber}
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </Subtool>
        </Col>
        <Col md={4}>
          <Subtool isActive={false}>
            <Row>
              <Col md={12}>
                <div className={styles.manageActions}>
                  {strings.manageActions}
                </div>
              </Col>
              <Col md={6}>
                <StyledButton
                  className={styles.actionButton}
                  variant="secondary"
                  onClick={() => {
                    setSelectedAction("skip");
                    setSelectedOwnerType(undefined);
                  }}
                  disabled={isAutopaySkipped || isLoading}
                >
                  {isAutopaySkipped
                    ? strings.monthSkipped
                    : strings.skipAutopay}
                </StyledButton>
              </Col>
              <Col md={6}>
                <StyledButton
                  className={styles.actionButton}
                  variant="danger"
                  onClick={() => {
                    setSelectedAction("remove");
                    setSelectedOwnerType(undefined);
                  }}
                  disabled={isLoading}
                >
                  {strings.removeAutopay}
                </StyledButton>
              </Col>
            </Row>
          </Subtool>
        </Col>
      </Row>
      {renderSkipOrRemoveAutopay()}
    </div>
  );
}
