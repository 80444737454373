import { Col, Row } from "react-grid-system";
import { ToolType, useToolState } from "../../modules/AccountDetails";

import ChangeUsername from "../../modules/ChangeUsername";
import CompletedTool from "../../modules/AccountDetails/CompletedTool";
import Container from "../../modules/common/Container";
import CreateAutopayIneligible from "../../modules/ManageAutopay/UpdateAutopay/CreateAutopayIneligible";
import CreditLineIncrease from "../../modules/CreditLineIncrease";
import CycleToDateTransactions from "../../modules/CycleToDateTransactions";
import { EnrollmentType } from "../../lib/api/models";
import LostStolen from "../../modules/LostStolen";
import { MOCK_ACCOUNT } from "../../lib/api/mocks/data.mock";
import ManageSourceAccounts from "../../modules/SourceAccounts";
import PaymentHistory from "../../modules/PaymentHistory";
import PaymentTransactions from "../../modules/PaymentTransactions";
import PermissionToDiscuss from "../../modules/PermissionToDiscuss";
import SendTestPushNotification from "../../modules/SendTestPushNotification";
import StatementHistory from "../../modules/StatementHistory";
import Subtool from "../../modules/common/Subtool";
import Toolbar from "../../modules/Toolbar";
import classNames from "classnames";
import styles from "./ToolsDemoPage.module.css";
import { useGetAccount } from "../../lib/api/queries";
import Terms from "../../modules/Terms";
import ManageAutopay from "../../modules/ManageAutopay";

/* eslint-disable @typescript-eslint/no-empty-function */

function ToolsDemoPage() {
  const getAccount = useGetAccount("4000016");
  const toolState = useToolState();
  if (!getAccount.data) {
    // NOTE: Loading & error handling doesn't matter on this test page.
    return (
      <main>
        <h1>Loading</h1>
      </main>
    );
  }
  return (
    <main className={styles.main}>
      <Toolbar
        account={MOCK_ACCOUNT}
        toolState={toolState}
        scrollToCompleted={() => {}}
      />
      <div className={styles.pageContent}>
        <div className={styles.headingContainer}>
          <h1>Tools Demo Page</h1>
        </div>
        <div className={classNames(styles.toolBackground, styles.toolPadding)}>
          <Container>
            <Row>
              <Col xs={4}>
                <Subtool className={styles.exampleToolCard}>
                  <></>
                </Subtool>
              </Col>
              <Col xs={4}>
                <Subtool isActive={true}>
                  <></>
                </Subtool>
              </Col>
              <Col xs={4}>
                <Subtool>
                  <></>
                </Subtool>
              </Col>
            </Row>
          </Container>
        </div>
        <ChangeUsername
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.ChangeUsername,
            )?.id ?? "change-username"
          }
          account={getAccount.data}
          className={styles.toolMargin}
          onSuccess={() => {}}
          onDisplayGeneralErrors={() => {}}
          onRequestClose={() => {}}
        />
        <SendTestPushNotification
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.TestPushNotification,
            )?.id ?? "send-notification"
          }
          account={getAccount.data}
          className={styles.toolMargin}
          onDisplayGeneralErrors={() => {}}
          onRequestClose={() => {}}
        />
        <PaymentHistory
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.PaymentHistory,
            )?.id ?? "payment-history"
          }
          account={getAccount.data}
          className={styles.toolMargin}
          onRequestClose={() => {}}
        />
        <PaymentTransactions
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.PaymentTransactions,
            )?.id ?? "payment-transactions"
          }
          className={styles.toolMargin}
          account={getAccount.data}
          openScheduledPayment={() => {}}
          activeTools={[]}
          onRequestClose={() => {}}
          onDisplayGeneralErrors={() => {
            throw new Error("Function not implemented.");
          }}
        />
        <ManageSourceAccounts
          account={getAccount.data}
          className={styles.toolMargin}
          onSetToolInProgress={() => {}}
          tool={{
            type: ToolType.SourceAccounts,
            id:
              toolState.activeTools.find(
                (t) => t.type === ToolType.SourceAccounts,
              )?.id ?? "manage-source",
            completed: { summary: "" },
            inProgress: false,
          }}
          onDisplayGeneralErrors={() => {}}
          onRequestClose={() => {}}
        />
        <PermissionToDiscuss
          className={styles.toolMargin}
          account={getAccount.data}
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.PermissionToDiscuss,
            )?.id ?? "PermissionToDiscuss"
          }
          onRequestClose={() => {}}
          onSetToolInProgress={() => {}}
        />
        <CycleToDateTransactions
          className={styles.toolMargin}
          account={getAccount.data}
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.CycleToDateTransactions,
            )?.id ?? "CycleToDateTransactions"
          }
          onRequestClose={() => {}}
        />
        <StatementHistory
          className={styles.toolMargin}
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.StatementHistory,
            )?.id ?? "StatementHistory"
          }
          onRequestClose={() => {}}
          account={getAccount.data}
        />
        <LostStolen
          account={getAccount.data}
          className={styles.toolMargin}
          toolId={
            toolState.activeTools.find((t) => t.type === ToolType.LostStolen)
              ?.id ?? "LostStolen"
          }
          onRequestClose={() => {}}
          onSetToolInProgress={() => {}}
        />
        <CreditLineIncrease
          account={getAccount.data}
          className={styles.toolMargin}
          toolId={
            toolState.activeTools.find(
              (t) => t.type === ToolType.CreditLineIncrease,
            )?.id ?? "CreditLineIncrease"
          }
          onRequestClose={() => {}}
          onSetToolInProgress={() => {}}
          hasSubmittedCreditLineIncreaseThisSession={false}
          onSubmitCreditLineIncrease={() => null}
        />
        <Terms
          className={styles.toolMargin}
          toolId={
            toolState.activeTools.find((t) => t.type === ToolType.Terms)?.id ??
            "terms"
          }
          onRequestClose={() => {}}
        />
        <ManageAutopay
          account={getAccount.data}
          className={styles.toolMargin}
          tool={{
            type: ToolType.ManageAutopay,
            id:
              toolState.activeTools.find(
                (t) => t.type === ToolType.ManageAutopay,
              )?.id ?? "autopay",
            completed: { summary: "" },
            inProgress: false,
          }}
          onRequestClose={() => {}}
          onDisplayGeneralErrors={() => {}}
          onComplete={() => {}}
          onSetToolInProgress={() => {}}
        />
        <div className={classNames(styles.toolMargin, styles.container)}>
          <Container>
            <CreateAutopayIneligible
              onClose={() => {}}
              ineligibleAutopayEnrollmentResponse={{
                enrollmentType: EnrollmentType.Ineligible,
                lastEnrollmentDate: "11/05/2022",
                autopayDisabledReason:
                  "Cannot Enroll in Autopay until 05/04/2023",
              }}
            />
          </Container>
        </div>
        <div className={styles.toolMargin}>
          <CompletedTool
            summary={"Username changed from X to Y at 5:50 PM"}
            type={ToolType.ChangeUsername}
          />
        </div>
        <div className={styles.toolMargin}>
          <CompletedTool
            summary={"Sent Notification to Acount 234234234 at 12:23:25 PM"}
            type={ToolType.TestPushNotification}
          />
        </div>
      </div>
    </main>
  );
}

export default ToolsDemoPage;
