import classNames from "classnames";
import { Employee } from "../../../lib/api/models";
import LabeledInfo from "../../common/LabeledInfo";
import AgentInitials from "../AgentInitials";
import styles from "./MessagesHeader.module.css";

const strings = {
  accountId: "Account ID:",
  cmName: "CM Name:",
  loading: "Loading...",
  loadingSubject: "Loading Subject...",
};

type Props = {
  className?: string;
  subject?: string;
  dwbuid: string;
  customerFirstName?: string;
  customerLastName?: string;
  employees?: Employee[];
  isRequestLoading?: boolean;
};

export default function MessagesHeader({
  className,
  subject,
  dwbuid,
  customerFirstName,
  customerLastName,
  employees,
  isRequestLoading = false,
}: Props) {
  const customerFullName =
    customerFirstName && customerLastName
      ? `${customerFirstName} ${customerLastName}`
      : "";
  return (
    <div
      className={classNames(
        styles.row,
        styles.spaceBetween,
        styles.header,
        className,
      )}
    >
      <div className={styles.col}>
        <span className={styles.messageSubject}>
          {isRequestLoading ? strings.loadingSubject : subject}
        </span>
        {customerFullName ? (
          <LabeledInfo
            label={strings.cmName}
            value={isRequestLoading ? strings.loading : customerFullName}
          />
        ) : null}
        <LabeledInfo
          label={strings.accountId}
          value={isRequestLoading ? strings.loading : dwbuid}
        />
      </div>
      <div className={classNames(styles.row, styles.columnGap)}>
        {employees?.map((employee) => {
          return (
            <AgentInitials
              key={employee.employeeId}
              firstName={employee.firstname}
              lastName={employee.lastname}
            />
          );
        })}
      </div>
    </div>
  );
}
