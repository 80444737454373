import { Col } from "react-grid-system";
import { getDisplayError } from "../../../lib/api/endpoints";
import { GetAccountResponse } from "../../../lib/api/models";
import { useGetAccountDetails } from "../../../lib/api/queries";
import {
  convertDateStringToMonthDayYearFomat,
  validateBeforeCentsToDollarString,
} from "../../../lib/formatting";
import Spinner from "../../common/Spinner";
import ToolLoadError from "../../common/ToolLoadError";
import styles from "./MakePaymentSubInfo.module.css";

const strings = {
  lastPaymentAmount: "Last Payment Amount",
  lastPaymentDate: "Last Payment Date",
  lastStatementBalance: "Last Statement Balance",
  lastStatementDate: "Last Statement Date",
  makePayment: "Make a Payment",
  nextPaymentDueDate: "Next Payment Due Date",
  recommendedPayment: "Recommended Payment",
  title: "Make a Payment Sub Information",
};

type Props = {
  account: GetAccountResponse;
};

export function MakePaymentSubInfo({ account }: Props) {
  const { data, isLoading, isError, error, refetch } = useGetAccountDetails(
    account.currentDwbuid,
  );

  return isLoading ? (
    <Col offset={{ xs: 4 }} xs={1}>
      <Spinner className={styles.spinner} />
    </Col>
  ) : isError ? (
    <Col xs={9}>
      <ToolLoadError
        toolName={strings.title}
        errorDescription={getDisplayError(error)}
        onRetry={refetch}
      />
    </Col>
  ) : (
    <>
      <Col xs={3}>
        <div>
          <div className={styles.row}>
            <span className={styles.label}>{strings.lastStatementBalance}</span>
            <span className={styles.value}>
              {validateBeforeCentsToDollarString(
                data.lastStatementBalanceCents,
              )}
            </span>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <span className={styles.label}>{strings.lastStatementDate}</span>
            <span className={styles.value}>
              {convertDateStringToMonthDayYearFomat(data.lastStatementDate)}
            </span>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <div>
          <div className={styles.row}>
            <span className={styles.label}>{strings.lastPaymentAmount}</span>
            <span className={styles.value}>
              {validateBeforeCentsToDollarString(data.lastPaymentAmountCents)}
            </span>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <span className={styles.label}>{strings.lastPaymentDate}</span>
            <span className={styles.value}>
              {convertDateStringToMonthDayYearFomat(data.lastPaymentDate)}
            </span>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <div>
          <div className={styles.row}>
            <span className={styles.label}>{strings.nextPaymentDueDate}</span>
            <span className={styles.value}>
              {convertDateStringToMonthDayYearFomat(data.nextPaymentDueDate)}
            </span>
          </div>
          <div className={styles.divider} />
          <div className={styles.row}>
            <span className={styles.label}>{strings.recommendedPayment}</span>
            <span className={styles.value}>
              {validateBeforeCentsToDollarString(data.recommendedAmountCents)}
            </span>
          </div>
        </div>
      </Col>
    </>
  );
}
