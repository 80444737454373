import { createBrowserRouter } from "react-router-dom";
import PrimaryLayout from "../layouts/PrimaryLayout";
import DwbuidExtrator from "../modules/DwbuidExtractor";
import AccountDetailsPage from "../pages/AccountDetailsPage";
import AccountSearchPage from "../pages/AccountSearchPage/AccountSearchPage";
import AccountSecureMailPage from "../pages/AccountSecureMailPage";
import ComposeSecureMailPage from "../pages/ComposeSecureMailPage";
import DemoPage from "../pages/DemoPage/DemoPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import SecureMailMessagingPage from "../pages/SecureMailMessagingPage";
import SecureMailQueuePage from "../pages/SecureMailQueuePage";
import ToolsDemoPage from "../pages/ToolsDemoPage";

export const paths = {
  root: "/",
  tools: "/tools",
  componentsDemo: "/components",
  accountSearch: "/",
  accountDetails: (dwbuid: string) => `/account/${dwbuid}`,
  secureMail: (secureMailId: string) => `/secure-mail/${secureMailId}`,
  secureMailQueue: "/secure-mail",
  composeSecureMailPage: (dwbuid: string) =>
    `/account/${dwbuid}/compose-secure-mail`,
  accountSecureMail: (dwbuid: string) => `/account/${dwbuid}/secure-mail`,
} as const;

const router = createBrowserRouter([
  {
    path: paths.root,
    element: <PrimaryLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: paths.tools,
        element: <ToolsDemoPage />,
      },
      {
        path: paths.componentsDemo,
        element: <DemoPage />,
      },
      {
        path: paths.accountSearch,
        element: <AccountSearchPage />,
      },
      {
        path: paths.accountDetails(":dwbuid"),
        element: (
          <DwbuidExtrator>
            {(dwbuid) => <AccountDetailsPage dwbuid={dwbuid} />}
          </DwbuidExtrator>
        ),
      },
      {
        path: paths.secureMail(":secureMailId"),
        element: <SecureMailMessagingPage />,
      },
      {
        path: paths.secureMailQueue,
        element: <SecureMailQueuePage />,
      },
      {
        path: paths.composeSecureMailPage(":dwbuid"),
        element: (
          <DwbuidExtrator>
            {(dwbuid) => <ComposeSecureMailPage dwbuid={dwbuid} />}
          </DwbuidExtrator>
        ),
      },
      {
        path: paths.accountSecureMail(":dwbuid"),
        element: (
          <DwbuidExtrator>
            {(dwbuid) => <AccountSecureMailPage dwbuid={dwbuid} />}
          </DwbuidExtrator>
        ),
      },
    ],
  },
]);

export default router;
