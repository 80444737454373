import classNames from "classnames";

import { Link } from "react-router-dom";
import styles from "./ClassificationCard.module.css";

type Props = {
  className?: string;
  id: string;
  title: string;
  numberOfItems: number;
  selected: boolean;
  to: { pathname: string; search?: string };
};

function ClassificationCard(props: Props) {
  return (
    <Link
      className={classNames(
        props.selected && styles.selected,
        styles.classificationCard,
        props.className,
      )}
      to={props.to}
    >
      <span className={styles.title}>{props.title}</span>
      <span className={styles.number}>{`(${props.numberOfItems})`}</span>
    </Link>
  );
}

export default ClassificationCard;
