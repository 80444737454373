import classNames from "classnames";
import { Col, Row } from "react-grid-system";
import { GetAccountResponse } from "../../lib/api/models";
import { useGetStatementHistory } from "../../lib/api/queries";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import StyledError from "../StyledError";
import Container from "../common/Container";
import Spinner from "../common/Spinner";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./StatementHistory.module.css";
import StatementHistoryTable from "./StatementHistoryTable";

const strings = {
  close: "Close",
  errorTitle: "Failed to load Statement History",
};

type Props = {
  className?: string;
  toolId: string;
  onRequestClose: () => void;
  account: GetAccountResponse;
};

export default function StatementHistory({
  className,
  toolId,
  onRequestClose,
  account,
}: Props) {
  const {
    data: getStatementHistoryResponse,
    isLoading: isGetStatementHistoryResponseLoading,
    isError: isGetStatementHistoryResponseError,
    error: getStatementHistoryResponseError,
    refetch: getStatementHistoryResponseRefetch,
    isRefetching: isGetStatementHistoryResponseRefetching,
  } = useGetStatementHistory(account.currentDwbuid);

  function renderTitle() {
    return (
      <h2 className={styles.header}>
        {toolTypeToName(ToolType.StatementHistory)}
      </h2>
    );
  }

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetStatementHistoryResponseLoading ||
        isGetStatementHistoryResponseRefetching ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </>
        ) : isGetStatementHistoryResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <StyledError
                  errorTitle={strings.errorTitle}
                  error={getStatementHistoryResponseError}
                  refetch={getStatementHistoryResponseRefetch}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={11}>{renderTitle()}</Col>
              <Col md={1}>
                <StyledButton
                  className={styles.closeButton}
                  variant="secondary"
                  onClick={onRequestClose}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <StatementHistoryTable
                  statements={getStatementHistoryResponse.statements}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
