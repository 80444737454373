import styles from "./StyledRadioButton.module.css";

type Props = {
  className?: string;
  label: string;
  checked: boolean;
  onChange: NonNullable<React.ComponentProps<"input">["onChange"]>;
  disabled?: boolean;
  onBlur?: React.ComponentProps<"input">["onBlur"];
};

export default function StyledRadioButton(props: Props) {
  return (
    <label className={props.className}>
      <input
        className={styles.input}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
        onBlur={props.onBlur}
        type="radio"
      />
      {props.label}
    </label>
  );
}
