import classNames from "classnames";
import { useRef } from "react";

import { PaymentAmountOptions } from "../../../../lib/api/models";
import { reportError } from "../../../../lib/bugReporting";
import {
  formatDollarInput,
  handlePaymentAmountInputChange,
  parseOnEditOrFocus,
} from "../../../../lib/formatting";
import StyledButton from "../../../common/buttons/StyledButton";
import { DollarSignIcon } from "./DollarSignIcon";
import styles from "./PaymentAmountSelector.module.css";
const strings = {
  fixed: "Fixed Payment Amount",
  minPayDue: "Minimum Payment Due",
  payInFull: "Payment In Full",
  validationMessageToReplace: "Cannot contain non-numeric input",
};

type Props = {
  selectedPaymentAmount?: PaymentAmountOptions;
  onSelectPaymentAmount: (value: PaymentAmountOptions) => void;
  customInput: string;
  onChangeCustomInput: (s: string) => void;
  className?: string;
  customInputValidation?: string[];
  disabled?: boolean;
  onBlurCustomInput: () => void;
};

export function PaymentAmountSelector({
  selectedPaymentAmount,
  onSelectPaymentAmount,
  customInput,
  onChangeCustomInput,
  className,
  customInputValidation,
  disabled,
  onBlurCustomInput,
}: Props) {
  const customInputRef = useRef<HTMLInputElement>(null);

  const onSelectPayInFull = () => {
    onSelectPaymentAmount("FULL");
  };
  const onSelecMinPayDue = () => {
    onSelectPaymentAmount("MINIMUM");
  };
  const onSelectCustom = () => {
    onSelectPaymentAmount("FIXED");
  };
  const selectAndFocusOnCustomInput = () => {
    onSelectCustom();
    customInputRef.current?.focus();
  };

  const invalid = !!customInputValidation && customInputValidation.length > 0;

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.nonCustom}>
        <StyledButton
          className={classNames({
            [styles.minPayDue]: true,
            [styles.active]: selectedPaymentAmount === "MINIMUM",
          })}
          variant="tertiary"
          onClick={onSelecMinPayDue}
          disabled={disabled}
        >
          {strings.minPayDue}
        </StyledButton>
        <StyledButton
          className={classNames({
            [styles.payInFull]: true,
            [styles.active]: selectedPaymentAmount === "FULL",
          })}
          variant="tertiary"
          onClick={onSelectPayInFull}
          disabled={disabled}
        >
          {strings.payInFull}
        </StyledButton>
      </div>
      <StyledButton
        className={classNames({
          [styles.custom]: true,
          [styles.active]: selectedPaymentAmount === "FIXED",
          [styles.invalid]: invalid,
        })}
        variant="tertiary"
        onClick={selectAndFocusOnCustomInput}
        disabled={disabled}
        id="payment_amount_selector_custom_id" // Used to ignore playwright issue https://github.com/bhw/clarity-web/pull/139#issuecomment-1666176798
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.code === "Enter") {
            selectAndFocusOnCustomInput();
          }
        }}
      >
        <label className={styles.labelContainer}>
          <span className={styles.label}>{strings.fixed}</span>
          <div className={styles.customInputContainer}>
            <DollarSignIcon
              className={classNames({
                [styles.img]: true,
                [styles.invalidCustomInputImg]: invalid,
              })}
              aria-hidden={true}
            />
            <input
              className={classNames({
                [styles.customInput]: true,
                [styles.invalidCustomInput]: invalid,
              })}
              value={customInput}
              onChange={(e) => {
                const val = e.target.value;
                try {
                  const parsedVal = parseOnEditOrFocus(val);
                  if (parsedVal !== null) {
                    onChangeCustomInput(parsedVal);
                  }
                } catch (err) {
                  reportError(
                    new Error("Failed to parse custom input", { cause: err }),
                  );
                  onChangeCustomInput(val);
                }
              }}
              onBlur={() => {
                /*
                  Formatting is different in this onBlur than onChangeText to ensure that final result is fully formatted
                  unlike formatting dollar and cents portions individually while user is entering input in the onChangeText function
                */
                try {
                  const handledChange =
                    handlePaymentAmountInputChange(customInput);
                  if (handledChange) {
                    onChangeCustomInput(formatDollarInput(handledChange));
                  }
                } catch (err) {
                  reportError(
                    new Error("Failed to parse custom input", { cause: err }),
                  );
                }
                onBlurCustomInput();
              }}
              onFocus={() => {
                try {
                  parseOnEditOrFocus(customInput);
                } catch (err) {
                  reportError(
                    new Error("Failed to parse custom input", { cause: err }),
                  );
                }
              }}
              tabIndex={selectedPaymentAmount !== "FIXED" ? -1 : undefined}
              ref={customInputRef}
            />
          </div>
        </label>
      </StyledButton>
      {invalid ? (
        <div className={styles.validationMessage}>
          {Object.values(customInputValidation).map((message) => (
            <div key={message}>{message}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
