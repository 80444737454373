import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAgentRoleUSA } from "./roleContext";
import { paths } from "./router";

const useRedirectWithoutRole = (
  requiredRole: boolean,
  destinationPath: string,
) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!requiredRole) {
      navigate(destinationPath);
    }
  }, [requiredRole, navigate, destinationPath]);
};

export const useSecureMailRedirectWithoutRole = () => {
  useRedirectWithoutRole(useIsAgentRoleUSA(), paths.root);
};
