import { formatInTimeZone } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import { DateString } from "./api/models";
// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const dollarStrFormat = /\B(?=(\d{3})+(?!\d))/g;

export function centsToDollarString(
  centsAmount: number,
  excludeDollarSign?: boolean, // needed for PaymentAmountSelector, which already has the "$" in its UI
) {
  let centsAmountStr = centsAmount.toString();
  if (centsAmountStr.length === 0 || Number.isNaN(centsAmount)) {
    return "";
  }
  let prefix = "";
  if (centsAmount < 0) {
    centsAmountStr = centsAmountStr.slice(1);
    prefix = "-";
  }
  const dollarStr =
    centsAmountStr.length > 2 ? centsAmountStr.slice(0, -2) : "0";
  const formattedDollarStr = dollarStr.replace(dollarStrFormat, ",");
  const centsStr =
    centsAmountStr.length > 2
      ? centsAmountStr.slice(-2)
      : centsAmountStr.padStart(2, "0");
  return `${prefix}${
    !excludeDollarSign ? "$" : ""
  }${formattedDollarStr}.${centsStr}`;
}

export function dollarStringToCents(dollarStr: string) {
  if (!dollarStr) {
    return 0;
  }

  if (RegExp(/[^0-9.,$]/g).test(dollarStr)) {
    throw Error(
      `dollarStr '${dollarStr}' contains non-digit, non-decimal, non-comma, non-dollar-sign characters`,
    );
  }

  if (!RegExp(/[0-9]/g).test(dollarStr)) {
    return 0;
  }

  const arr = dollarStr.split(".");
  if (arr.length > 2) {
    throw Error(`dollarStr '${dollarStr}' contains more than one decimal`);
  }

  const integerPart = arr[0]
    ? Number.parseInt(arr[0].replace(/\D/g, ""), 10)
    : 0;
  const fractionalPart = arr[1]
    ? parseInt(arr[1].slice(0, 2).padEnd(2, "0"), 10)
    : 0;

  return integerPart * 100 + fractionalPart;
}

export const validateBeforeCentsToDollarString = (
  value?: number | null,
  excludeDollarSign?: boolean,
) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return centsToDollarString(value, excludeDollarSign);
};

export function getEmployeeInitials(
  employeeFirstName: string | null | undefined,
  employeeLastName: string | null | undefined,
) {
  if (employeeFirstName || employeeLastName) {
    let initialsToReturn = "";
    if (employeeFirstName) {
      initialsToReturn = employeeFirstName.charAt(0);
    }
    if (employeeLastName) {
      initialsToReturn += employeeLastName.charAt(0);
    }
    return initialsToReturn;
  }
  return "1FB";
}

export function getEmployeeFullName(
  isEmployee: boolean,
  employeeFirstName: string | null | undefined,
  employeeLastName: string | null | undefined,
) {
  if (isEmployee) {
    if (employeeFirstName || employeeLastName) {
      let fullNameToReturn = "";
      if (employeeFirstName) {
        fullNameToReturn = employeeFirstName;
        if (employeeLastName) {
          fullNameToReturn += " ";
        }
      }
      if (employeeLastName) {
        fullNameToReturn += employeeLastName;
      }
      return fullNameToReturn;
    }
  }
  return undefined;
}

export const formatInCentralTime = (date: Date, formatStr: string): string => {
  return formatInTimeZone(date, "America/Chicago", formatStr, {
    locale: enUS,
  });
};

// DateString reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#date_strings
export const formatToDateStringInCentralTime = (date: Date): DateString => {
  return formatInCentralTime(date, "yyyy-MM-dd") as DateString;
};

export const convertDateStringToMonthDayYearFomat = (
  dateString?: string | null,
) => {
  if (!dateString) {
    return "-";
  }
  const dateArr = dateString.split("-");
  if (dateArr.length < 2) {
    return "-";
  }
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
};

export const convertYearMonthDateStringToMonthYearFormat = (
  date?: string | null,
) => {
  if (!date) {
    return "-";
  }
  const dateArr = date.split("-");
  if (dateArr.length < 1) {
    return "-";
  }
  return `${dateArr[1]}/${dateArr[0]}`;
};

export const handlePaymentAmountInputChange = (
  inputValue: string,
): string | undefined => {
  // Version that is only these characters
  const newNumber = inputValue.replace(RegExp(/[^0-9.]/g), "");

  if (
    (newNumber &&
      !isNaN(Number(newNumber)) &&
      Number(newNumber) > Number.MAX_SAFE_INTEGER) ||
    Number(newNumber) < Number.MIN_SAFE_INTEGER
  ) {
    return;
  }
  return newNumber;
};

export const formatDollarInput = (inputValue: string): string => {
  if (!RegExp(/\d+/).test(inputValue)) {
    return "";
  }
  const newCentsAmount = dollarStringToCents(inputValue);
  return centsToDollarString(newCentsAmount, true); // exclude dollar sign
};

export const formatWholeDollarInput = (inputValue: string): string => {
  return formatDollarInput(inputValue).split(".")[0].replace(/,/g, "");
};

export const parseOnEditOrFocus = (val: string): string | null => {
  /*
    Splitting up the dollar and cents portions ensures that the user can type out
    both dollar and cents while formatting each portion simultaneously
  */
  const dollarInput = handlePaymentAmountInputChange(val);
  if (dollarInput === undefined) {
    return null; // exit if change not allowed
  }
  const wholeDollarInput = dollarInput.split(".")[0];
  const centsInput = dollarInput.split(".")[1];
  const formattedDollarInput = formatWholeDollarInput(wholeDollarInput);
  if (centsInput === undefined) {
    return formattedDollarInput;
  }
  const formattedCentsInput = centsInput.substring(0, 2).replace(/\D/g, "");
  return `${formattedDollarInput}.${formattedCentsInput}`;
};

export const validateInputAndFormatToWholeDollar = (
  val: string,
): string | null => {
  const dollarInput = handlePaymentAmountInputChange(val);
  if (dollarInput === undefined) {
    return null;
  }
  const wholeDollarInput = dollarInput.split(".")[0];
  return formatWholeDollarInput(wholeDollarInput);
};

export const INPUT_MASKS = {
  date: "99/99",
  zipCode: "99999-9999",
  abaNumber: "999999999",
  checkingAccountNumber: "99999999999999999999",
  creditCard: "9999999999999999",
  phoneNumber: "(999) 999-9999",
} as const;

export const maskInput = (pattern: string, input: string): string => {
  let result = input;
  for (let i = 0; i < pattern.length && i < result.length; i++) {
    if (isNaN(parseInt(pattern[i]))) {
      if (result[i] !== pattern[i]) {
        result = result.slice(0, i).concat(pattern[i], result.slice(i));
      }
    } else {
      while (result.length > i && isNaN(parseInt(result[i]))) {
        result = result.slice(0, i).concat(result.slice(i + 1));
      }
    }
  }
  result = result.slice(0, pattern.length);
  return result;
};
