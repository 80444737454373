import styles from "./APRTable.module.css";

const strings = {
  aprTerms: "APR Terms",
  balance: "Balance: ",
  purchaseAPR: "Purchase APR",
  cashAPR: "Cash APR",
  tierOne: "Tier 1 Penalty APR",
  tierTwo: "Tier 2 Penalty APR",
};

export default function APRTable() {
  return (
    <table className={styles.table}>
      <thead>
        <th>{strings.aprTerms}</th>
      </thead>
      <tbody>
        <tr className={styles.blueRow}>
          <td>
            <span>{strings.balance}</span>
            <span>{"0 - 100,000"}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td className={styles.row}>
            <span>{strings.purchaseAPR}</span>
            <span>{"23.90% (20.65% + Prime)"}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.cashAPR}</span>
            <span>{"26.90% (23.65% + Prime)"}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td className={styles.row}>
            <span>{strings.tierOne}</span>
            <span>{"N/A"}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td className={styles.row}>
            <span>{strings.tierTwo}</span>
            <span>{"27.90% (24.65% + Prime)"}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
