import classNames from "classnames";
import styles from "./Terms.module.css";
import { Col, Container, Row } from "react-grid-system";
import { toolTypeToName } from "../AccountDetails/utils";
import { ToolType } from "../AccountDetails";
import StyledButton from "../common/buttons/StyledButton";
import RoundButton from "../common/buttons/RoundButton";
import { useState } from "react";
import APRTable from "./APRTable";
import CurrentTermsTable from "./CurrentTermsTable";
import FeesTable from "./FeesTable";

const strings = {
  currentTerms: "Current Terms",
  aprTerms: "APR Terms",
  fees: "Fees",
  close: "Close",
};

type Props = {
  toolId: string;
  onRequestClose: () => void;
  className?: string;
};

export default function Terms({ toolId, onRequestClose, className }: Props) {
  const [currentTab, setCurrentTab] = useState<"current" | "apr" | "fees">(
    "current",
  );

  function renderTitle() {
    return <h2 className={styles.header}>{toolTypeToName(ToolType.Terms)}</h2>;
  }

  const renderTable = () => {
    switch (currentTab) {
      case "current":
        return <CurrentTermsTable />;
      case "apr":
        return <APRTable />;
      case "fees":
        return <FeesTable />;
    }
  };

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row>
          <Col md={1} className={styles.centerCol}>
            {renderTitle()}
          </Col>
          <Col md={4} className={styles.centerCol}>
            <div className={styles.tabGroup}>
              <RoundButton
                className={styles.tabButton}
                variant={currentTab === "current" ? "blue" : "grey"}
                onClick={() => setCurrentTab("current")}
              >
                {strings.currentTerms}
              </RoundButton>
              <RoundButton
                className={styles.tabButton}
                variant={currentTab === "apr" ? "blue" : "grey"}
                onClick={() => setCurrentTab("apr")}
              >
                {strings.aprTerms}
              </RoundButton>
              <RoundButton
                className={styles.tabButton}
                variant={currentTab === "fees" ? "blue" : "grey"}
                onClick={() => setCurrentTab("fees")}
              >
                {strings.fees}
              </RoundButton>
            </div>
          </Col>
          <Col offset={{ md: 6 }} md={1} className={styles.centerCol}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              onClick={() => onRequestClose()}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        <div className={styles.divider} />
        <Row>
          <Col md={6}>{renderTable()}</Col>
        </Row>
      </Container>
    </div>
  );
}
