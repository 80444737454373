import { Outlet } from "react-router-dom";
import Banner from "../modules/common/Banner";

export default function PrimaryLayout() {
  return (
    <>
      <header>
        <Banner />
      </header>
      <Outlet />
    </>
  );
}
