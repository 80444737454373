import { reportError } from "../../../../lib/bugReporting";
import {
  formatDollarInput,
  handlePaymentAmountInputChange,
  parseOnEditOrFocus,
} from "../../../../lib/formatting";
import StyledInput from "../../../common/inputs/StyledInput";
import { StyledInputProps } from "../../../common/inputs/StyledInput/StyledInput";

type Props = Omit<StyledInputProps, "value" | "onChange"> & {
  value: string;
  onChange: (v: string) => void;
  labelClassName?: string;
} & (
    | {
        invalid: boolean;
        validationMessages?: never;
      }
    | {
        invalid?: never;
        validationMessages?: string[];
      }
  );

export default function CheckAmountInput({
  className,
  labelClassName,
  value,
  onChange,
  validationMessages,
}: Props) {
  return (
    <StyledInput
      className={className}
      labelClassName={labelClassName}
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        try {
          const parsedVal = parseOnEditOrFocus(val);
          if (parsedVal !== null) {
            onChange(`$${parsedVal}`);
          }
        } catch (err) {
          reportError(
            new Error("Failed to parse payment amount", {
              cause: err,
            }),
          );
          onChange(`$${val}`);
        }
      }}
      onBlur={() => {
        /*
            Formatting is different in this onBlur than onChangeText to ensure that final result is fully formatted
            unlike formatting dollar and cents portions individually while user is entering input in the onChangeText function
          */
        try {
          const handledChange = handlePaymentAmountInputChange(value);
          if (handledChange) {
            onChange(`$${formatDollarInput(handledChange)}`);
          } else {
            onChange("$0.00");
          }
        } catch (err) {
          reportError(
            new Error("Failed to parse custom input", { cause: err }),
          );
        }
      }}
      onFocus={() => {
        try {
          parseOnEditOrFocus(value);
        } catch (err) {
          reportError(
            new Error("Failed to parse custom input", { cause: err }),
          );
        }
      }}
      validationMessages={validationMessages}
    />
  );
}
