import { z } from "zod";
import {
  accountNumberMaxLength,
  accountNumberMinLength,
  noWhitespaceRegex,
  numbersOnlyRegex,
  routingNumberLength,
} from "../constants/lengths";
import { dollarStringToCents } from "../formatting";
import { UNMASKED_PHONE_NUMBER_LENGTH } from "../../modules/common/PhoneNumberInput";

export const changeUsernameSchema = z
  .string()
  .min(6, "New username must be at least 6 characters long.")
  .max(20, "New username must not be longer than 20 characters.")
  .regex(noWhitespaceRegex, "Username must not include spaces.");

export const firstNameValidationSchema = z
  .string()
  .min(1, "First name must not be empty.");

export const lastNameValidationSchema = z
  .string()
  .min(1, "Last name must not be empty.");

export const routingNumberSchema = z
  .string()
  .length(
    routingNumberLength,
    `Routing number must be ${routingNumberLength} numbers long.`,
  )
  .regex(noWhitespaceRegex, "Routing number must not include spaces.")
  .regex(numbersOnlyRegex, "Only numbers allowed.");

export const accountNumberSchema = z
  .string()
  .min(
    accountNumberMinLength,
    `Account number must be at least ${accountNumberMinLength} numbers long.`,
  )
  .max(
    accountNumberMaxLength,
    `Account number must not be longer than ${accountNumberMaxLength} numbers.`,
  )
  .regex(noWhitespaceRegex, "Account number must not include spaces.")
  .regex(numbersOnlyRegex, "Only numbers allowed.");

export const creditLineIncreaseNewCreditLimitSchema = z
  .object({
    currCreditLimit: z.string(),
    maxCurrCreditLimit: z.string(),
    newCreditLimit: z.string(),
  })
  .superRefine(
    (
      arg,
      ctx,
    ): arg is {
      currCreditLimit: string;
      maxCurrCreditLimit: string;
      newCreditLimit: string;
    } => {
      if (!arg.newCreditLimit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Please enter a numeric value in the New Credit Limit field.",
          path: ["newCreditLimitAmountCents"],
          fatal: true,
        });

        return z.NEVER;
      }

      if (
        dollarStringToCents(arg.currCreditLimit) >=
          dollarStringToCents(arg.newCreditLimit) ||
        dollarStringToCents(arg.currCreditLimit) === 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `The New Credit Limit cannot be equal to or less than the current credit limit of ${arg.currCreditLimit}`,
          path: ["newCreditLimitAmountCents"],
          fatal: true,
        });

        return z.NEVER;
      }

      if (
        dollarStringToCents(arg.newCreditLimit) >
        dollarStringToCents(arg.maxCurrCreditLimit)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `The New Credit Limit cannot exceed the maximum of ${arg.maxCurrCreditLimit}`,
          path: ["newLimitCents"],
          fatal: true,
        });
      }

      return z.NEVER;
    },
  );

export const creditLineIncreaseReasonSchema = z
  .string()
  .transform((arg) => arg.length)
  .refine((arg) => arg >= 1, {
    message: "Please Select a Reason for the Credit Line Increase request.",
    path: ["reasonId"],
  });

export const creditLineIncreaseContactPhoneSchema = z
  .string()
  .superRefine((arg, ctx): arg is string => {
    if (arg.length >= 1 && arg.length < UNMASKED_PHONE_NUMBER_LENGTH) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "The Contact Phone Number must be a 10 digit number, (example (605) 555-1212.) Please enter a valid phone number.",
        path: ["phoneNumber"],
        fatal: true,
      });
    }

    return z.NEVER;
  });
