import classNames from "classnames";
import { DateString } from "../../../../../lib/api/models";
import StyledCheckbox from "../../../../common/StyledCheckbox";
import CheckAmountInput from "../CheckAmountInput";
import PrintDateInput from "../PrintDateInput";
import styles from "./PaymentSeriesTableRow.module.css";

const strings = {
  blankConfirmationValue: "-",
};

type Props = {
  className?: string;
  rowNumber: string;
  paymentDate: DateString;
  paymentAmount: string;
  checked: boolean;
  isEven: boolean;
  setPaymentDate: (val: DateString) => void;
  setPaymentAmount: (val: string) => void;
  setChecked: (checked: boolean) => void;
  confirmationNumber?: string;
  dateValidationMessage?: string;
};

export default function PaymentSeriesTableRow({
  className,
  rowNumber,
  paymentDate,
  paymentAmount,
  checked,
  isEven,
  setPaymentDate,
  setPaymentAmount,
  setChecked,
  confirmationNumber,
  dateValidationMessage,
}: Props) {
  const rowBackgroundStyle = isEven ? styles.evenRow : styles.oddRow;
  return (
    <tr className={classNames(styles.row, rowBackgroundStyle, className)}>
      <td>{rowNumber}</td>
      <td>{confirmationNumber ?? strings.blankConfirmationValue}</td>
      <td id={"payment_series_table_row_input_custom_id"}>
        <PrintDateInput
          className={styles.printDateInput}
          value={paymentDate}
          onChange={setPaymentDate}
        />
        {dateValidationMessage ? (
          <div className={styles.validation}>{dateValidationMessage}</div>
        ) : null}
      </td>
      <td id={"payment_series_table_row_input_custom_id"}>
        <CheckAmountInput
          className={styles.checkAmountInput}
          labelClassName={styles.checkAmountInputLabel}
          value={paymentAmount}
          onChange={setPaymentAmount}
        />
      </td>
      <td>
        <StyledCheckbox
          label={""}
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </td>
    </tr>
  );
}
