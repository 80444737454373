import classNames from "classnames";
import { useId } from "react";
import styles from "./TextToggle.module.css";

export type TextToggleOption<T> = {
  key: T;
  label: string;
};

type Props<T> = {
  options: [TextToggleOption<T>, TextToggleOption<T>];
  onSelectOption: (key: T) => void;
  selectedOption: T | undefined;
  className?: string;
  disabled?: boolean;
};

export function TextToggle<T>({
  options,
  onSelectOption,
  selectedOption,
  className,
  disabled,
}: Props<T>) {
  const leftLabelId = useId();
  const rightLabelId = useId();

  const [leftOption, rightOption] = options;

  const leftOptionSelected = selectedOption === leftOption.key;
  const rightOptionSelected = selectedOption === rightOption.key;
  return (
    <div className={classNames(styles.container, className)}>
      <>
        <input
          className={styles.input}
          disabled={disabled}
          type="radio"
          onChange={() => onSelectOption(leftOption.key)}
          checked={leftOptionSelected}
          id={leftLabelId}
        />
        <label
          className={classNames({
            [styles.label]: true,
            [styles.leftLabel]: true,
            [styles.leftInactive]: rightOptionSelected && !disabled,
          })}
          htmlFor={leftLabelId}
        >
          {leftOption.label}
        </label>
      </>
      <>
        <input
          className={styles.input}
          disabled={disabled}
          type="radio"
          onChange={() => onSelectOption(rightOption.key)}
          checked={rightOptionSelected}
          id={rightLabelId}
        />
        <label
          className={classNames({
            [styles.label]: true,
            [styles.rightLabel]: true,
          })}
          htmlFor={rightLabelId}
        >
          {rightOption.label}
        </label>
      </>
    </div>
  );
}
