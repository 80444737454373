import StyledButton from "../../common/buttons/StyledButton";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import styles from "./DeleteSourceAccountConfirmationModal.module.css";

type Props = {
  isOpen: boolean;
  sourceAccountName: string;
  onConfirmDelete: () => void;
  onCancelDelete: () => void;
};

export default function DeleteSourceAccountConfirmationModal({
  isOpen,
  sourceAccountName,
  onConfirmDelete,
  onCancelDelete,
}: Props) {
  return (
    <BaseModal
      className={styles.container}
      isOpen={isOpen}
      label={"Are you sure you want to remove this source account?"}
      onRequestClose={onCancelDelete}
    >
      <div className={styles.body}>
        <div className={styles.messageText}>
          {"Are you sure you want to remove "}
          <span className={styles.accountNameText}>{sourceAccountName}</span>
          {"?"}
        </div>
        <StyledButton
          className={styles.firstButton}
          variant="danger"
          onClick={onConfirmDelete}
        >
          {`Remove ${sourceAccountName}`}
        </StyledButton>
        <StyledButton
          className={styles.secondButton}
          variant="secondary"
          onClick={onCancelDelete}
        >
          {"Continue Editing"}
        </StyledButton>
      </div>
    </BaseModal>
  );
}
