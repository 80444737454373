import { MASKED_PHONE_NUMBER_LENGTH } from ".";
import { INPUT_MASKS, maskInput } from "../../../lib/formatting";
import StyledInput, {
  StyledInputProps,
} from "../inputs/StyledInput/StyledInput";

type Props = Omit<StyledInputProps, "value" | "onChange"> & {
  value: string;
  onChange: (v: string) => void;
} & (
    | {
        invalid: boolean;
        validationMessages?: never;
      }
    | {
        invalid?: never;
        validationMessages?: string[];
      }
  );

export default function PhoneNumberInput({ value, onChange, ...rest }: Props) {
  const onChangeText = (nextVal: string) => {
    onChange(nextVal.replace(/\D/g, ""));
  };

  return (
    <StyledInput
      {...rest}
      value={maskInput(INPUT_MASKS.phoneNumber, value)}
      onChange={(e) => onChangeText(e.currentTarget.value)}
      maxLength={MASKED_PHONE_NUMBER_LENGTH}
    />
  );
}
