import { DateString } from "../../../../../lib/api/models";
import DateInput from "../../../../common/DateInput";
import { StyledInputProps } from "../../../../common/inputs/StyledInput/StyledInput";
import styles from "./PrintDateInput.module.css";

type Props = Omit<StyledInputProps, "value" | "onChange"> & {
  value: DateString;
  onChange: (v: DateString) => void;
};

export default function PrintDateInput(props: Props) {
  return (
    <DateInput
      label=""
      className={props.className}
      labelClassName={styles.inputLabel}
      inputClassName={styles.input}
      value={props.value}
      onChangeDate={props.onChange}
    />
  );
}
