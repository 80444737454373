import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import { Mutex } from "async-mutex";

const isMSALEnabled = import.meta.env.VITE_MSAL_ENABLED === "true";
const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${
      import.meta.env.VITE_MSAL_TENANT_ID
    }`,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

const instance = new PublicClientApplication(msalConfig);

// msalMutex is used to ensure that only one MSAL interaction is happening at a time.
// For more context, see here: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
const msalMutex = new Mutex();

async function _getAuthorizationToken(): Promise<string> {
  if (!isMSALEnabled) {
    return Promise.resolve("MOCK_AUTH_TOKEN");
  }

  await instance.initialize();
  await instance.handleRedirectPromise();

  let authResult: AuthenticationResult;
  const authRequest: SilentRequest = {
    scopes: ["openid"],
  };

  const account = instance.getActiveAccount();
  if (!account) {
    // Not yet signed in, so trigger that now
    authResult = await instance.acquireTokenPopup(authRequest);
  } else {
    try {
      // Try to get a token
      authResult = await instance.acquireTokenSilent({
        ...authRequest,
        account: account,
      });
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        // Need to trigger a manual sign in
        authResult = await instance.acquireTokenPopup(authRequest);
      } else {
        // Something else went wrong, re-throw
        throw err;
      }
    }
  }

  // The msal library has an issue where id tokens expire before access tokens, and
  // won't always trigger refresh. Since we're using id tokens, we need to manually
  // check if the id token has expired and refresh manually. More info here:
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206

  // @ts-expect-error missing exp from object definition
  const exp: unknown = authResult.idTokenClaims.exp;
  if (typeof exp !== "number") {
    throw "missing id token exp";
  }

  // Date.now() is milliseconds, but exp is in seconds
  const isExpired = Date.now() >= exp * 1000;
  if (isExpired) {
    authResult = await instance.acquireTokenPopup(authRequest);
  }

  instance.setActiveAccount(authResult.account);
  return authResult.idToken;
}

export async function getAuthorizationToken(): Promise<string> {
  return await msalMutex.runExclusive(async () => _getAuthorizationToken());
}
