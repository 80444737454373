import classNames from "classnames";
import styles from "./AccountNotFound.module.css";

const strings = {
  noAccountFound: "No account matches the provided search.\nPlease try again.",
};

type Props = {
  className?: string;
};
function AccountNotFound({ className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <h2 className={styles.noAccountFound}>{strings.noAccountFound}</h2>
    </div>
  );
}
export default AccountNotFound;
