import { useState } from "react";
import {
  ClassificationOptions,
  GetSecureMailThreadResponse,
  SecureMailConversationType,
} from "../../../lib/api/models";
import {
  useGetClassifications,
  usePatchSecureMailClassification,
  useUpdateSecureMailStatus,
} from "../../../lib/api/queries";
import StyledError from "../../StyledError";
import Select from "../../common/Select";
import Spinner from "../../common/Spinner";
import StyledButton from "../../common/buttons/StyledButton";
import styles from "./AssignedClassification.module.css";

const strings = {
  assignedAriaLabel: "assigned-classification-select",
  assignedClassification: "Assigned Classification",
  classification: "Classification",
  classificationsErrorTitle: "Classifications failed to load",
  closeMail: "Close Mail",
  closedMail: "Closed Mail",
  reopened: "Reopened!",
  reopenMail: "Reopen Mail",
  save: "Save",
  saved: "Saved!",
  tryAgain: "Please try again.",
  updateStatusError: "We were unable to close this Secure Mail at this time.",
};

type AssignedClassificationProps = {
  secureMail: GetSecureMailThreadResponse;
  onError: (err: unknown) => void;
};

export default function AssignedClassification({
  secureMail,
  onError,
}: AssignedClassificationProps) {
  const [reopenedTextVisible] = useState<boolean>(false);
  const isOpen = secureMail.type !== "READONLY";

  const showClassificationSelectorAndSaveButton = true;

  const [selectedClassification, setSelectedClassification] = useState(
    secureMail.classificationId ?? undefined,
  );

  const {
    data: classificationsList,
    isLoading: isGetClassificationsLoading,
    isError: isGetClassificationsError,
    error: getClassificationsError,
    refetch: getClassificationsRefetch,
    isFetching: isGetClassificationsFetching,
  } = useGetClassifications();

  const classificationsToRender = classificationsList?.classifications ?? [];
  const classifications: ClassificationOptions[] = classificationsToRender.map(
    (classification) => ({
      label: classification.description,
      value: classification.id,
    }),
  );

  const {
    mutateAsync: patchSecureMailClassification,
    isLoading: isPatchSecureMailClassificationLoading,
    isSuccess: isPatchSecureMailClassificationSuccess,
  } = usePatchSecureMailClassification();

  const {
    mutateAsync: updateSecureMailStatus,
    isLoading: isUpdateSecureMailStatusLoading,
  } = useUpdateSecureMailStatus();

  const onSubmit = (newClassificationId?: string) => {
    if (!newClassificationId) {
      return;
    }

    patchSecureMailClassification({
      secureMailId: secureMail.id,
      classificationId: newClassificationId,
    }).catch(onError);
  };

  const onUpdateSecureMailStatus = (type: SecureMailConversationType) => {
    if (!secureMail.id) {
      return;
    }

    updateSecureMailStatus({ secureMailId: secureMail.id, type }).catch(
      onError,
    );
  };

  return isGetClassificationsLoading || isGetClassificationsFetching ? (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  ) : isGetClassificationsError ? (
    <StyledError
      variant="vertical"
      errorTitle={strings.classificationsErrorTitle}
      error={getClassificationsError}
      refetch={getClassificationsRefetch}
    />
  ) : (
    <div className={styles.assignedClassification}>
      <span className={styles.classificationText}>
        {isOpen ? strings.assignedClassification : strings.closedMail}
      </span>
      {reopenedTextVisible ? (
        <span className={styles.classificationText}>{strings.reopened}</span>
      ) : null}
      {showClassificationSelectorAndSaveButton ? (
        <>
          <Select
            options={classifications}
            selectedValue={selectedClassification}
            onChange={(selectedId) => setSelectedClassification(selectedId)}
            disabled={!isOpen}
            ariaLabel={strings.assignedAriaLabel}
            placeholder={strings.classification}
          />
          <div className={styles.assignedClassificationButtons}>
            <StyledButton
              className={styles.saveButton}
              variant="secondary"
              onClick={() => onSubmit(selectedClassification)}
              isLoading={isPatchSecureMailClassificationLoading}
              disabled={secureMail.classificationId === selectedClassification}
            >
              {isPatchSecureMailClassificationSuccess &&
              secureMail.classificationId === selectedClassification
                ? strings.saved
                : strings.save}
            </StyledButton>
            <StyledButton
              variant="primary"
              onClick={() =>
                isOpen
                  ? onUpdateSecureMailStatus("READONLY")
                  : onUpdateSecureMailStatus("OPEN")
              }
              isLoading={isUpdateSecureMailStatusLoading}
            >
              {isOpen ? strings.closeMail : strings.reopenMail}
            </StyledButton>
          </div>
        </>
      ) : null}
    </div>
  );
}
