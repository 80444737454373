import classNames from "classnames";
import Spinner from "../../Spinner";
import styles from "./StyledButton.module.css";

type Props = {
  children: React.ComponentProps<"button">["children"];
  className?: string;
  disabled?: boolean;
  id?: string;
  isLoading?: boolean;
  onClick?: React.ComponentProps<"button">["onClick"];
  onKeyDown?: React.ComponentProps<"button">["onKeyDown"];
  type?: React.ComponentProps<"button">["type"];
  variant?: "primary" | "secondary" | "tertiary" | "danger" | "transparent";
  onFocus?: React.ComponentProps<"button">["onFocus"];
  renderLoadingIndicator?: boolean;
};

export function StyledButton({
  children,
  className,
  disabled: disabledProp,
  id,
  onClick,
  onKeyDown,
  isLoading,
  type,
  variant = "primary",
  onFocus,
  renderLoadingIndicator = true,
}: Props) {
  const disabled = disabledProp || isLoading;
  const finalClassName = classNames(
    styles.button,
    variant === "primary" ? styles.primary : null,
    variant === "secondary" ? styles.secondary : null,
    variant === "tertiary" ? styles.tertiary : null,
    variant === "danger" ? styles.danger : null,
    variant === "transparent" ? styles.transparent : null,
    className,
  );
  // Note: Tertiary styles are used for `PaymentAmountSelector` which does not need a spinner
  const renderSpinner =
    variant !== "tertiary" &&
    variant !== "transparent" &&
    renderLoadingIndicator;
  return (
    <button
      type={type ?? "button"}
      className={finalClassName}
      disabled={disabled}
      id={id}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
    >
      {renderSpinner ? <div className={styles.leftSpinnerContainer} /> : null}
      <div className={styles.childrenContainer}>{children}</div>
      {renderSpinner ? (
        <div className={styles.rightSpinnerContainer}>
          {isLoading ? <Spinner /> : null}
        </div>
      ) : null}
    </button>
  );
}
