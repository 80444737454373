import classNames from "classnames";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import { ZodError } from "zod";
import { getDisplayError } from "../../lib/api/endpoints";
import { GetAccountResponse, ValidationErrors } from "../../lib/api/models";
import { useGetAccountDetails, useUpdateUsername } from "../../lib/api/queries";
import {
  mergeValidation,
  setValidationErrorsAndDisplayGeneralErrors,
} from "../../lib/validation";
import { changeUsernameSchema } from "../../lib/validation/schemas";
import Container from "../../modules/common/Container";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import Spinner from "../common/Spinner";
import Subtool from "../common/Subtool";
import ToolLoadError from "../common/ToolLoadError";
import StyledButton from "../common/buttons/StyledButton";
import StyledInput from "../common/inputs/StyledInput";
import styles from "./ChangeUsername.module.css";

const strings = {
  currentUsernameHeader: "Current Username",
  newUsername: "New Username",
  subheader: "Enter New Username",
  submit: "Submit",
  close: "Close",
};

type Props = {
  account: GetAccountResponse;
  className?: string;
  onSuccess: (summaryWithoutTime: string) => void;
  onDisplayGeneralErrors: (error: unknown) => void;
  toolId: string;
  onRequestClose: () => void;
};

type ChangeUsernameForm = {
  newUsername: {
    value: string;
    isValid: boolean;
    validationMessages?: string[];
  };
};

const initialState: ChangeUsernameForm = {
  newUsername: {
    value: "",
    isValid: false,
    validationMessages: undefined,
  },
};

const getUsernameValidationMessages = (value: string): string[] | undefined => {
  try {
    changeUsernameSchema.parse(value);
  } catch (err) {
    if (err instanceof ZodError && err.errors.length > 0) {
      return err.errors.map((vm) => vm.message);
    }
  }

  return undefined;
};

export function ChangeUsername({
  account,
  className,
  onSuccess,
  onDisplayGeneralErrors,
  toolId,
  onRequestClose,
}: Props) {
  const {
    data: getAccountDetailsResponse,
    isLoading: isGetAccountDetailsLoading,
    isError: isGetAccountDetailsError,
    error: getAccountDetailsError,
    refetch: getAccountDetailsRefetch,
  } = useGetAccountDetails(account.currentDwbuid);

  const [formState, setFormState] = useState(initialState);
  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();

  const { mutateAsync: updateUsername, isLoading } = useUpdateUsername();
  const onSubmit = (e: React.FormEvent) => {
    if (isLoading || Object.values(formState).some((v) => !v.isValid)) {
      return;
    }

    e.preventDefault();

    updateUsername({
      partyId: account.partyId,
      newUsername: formState["newUsername"].value,
      dwbuid: account.currentDwbuid,
    })
      .then(() =>
        onSuccess(
          `Successfully changed username from ${getAccountDetailsResponse?.username} to ${formState["newUsername"].value}`,
        ),
      )
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  };

  const finalValidation = mergeValidation(networkValidationMessages ?? {}, {
    newUsername: formState["newUsername"].validationMessages ?? [],
  });

  const isComplete =
    finalValidation["newUsername"].length < 1 &&
    formState["newUsername"].isValid;

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row>
          <Col md={3}>
            <h2 className={styles.header}>
              {toolTypeToName(ToolType.ChangeUsername)}
            </h2>
          </Col>
          <Col md={1}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              renderLoadingIndicator={false}
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        {isGetAccountDetailsLoading ? (
          <Row>
            <Col offset={{ xs: 6 }} xs={1}>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        ) : isGetAccountDetailsError ? (
          <Row>
            <Col>
              <ToolLoadError
                toolName={toolTypeToName(ToolType.ChangeUsername)}
                errorDescription={getDisplayError(getAccountDetailsError)}
                onRetry={getAccountDetailsRefetch}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={4}>
              <Subtool>
                <span className={styles.subheader}>{strings.subheader}</span>
                <div className={styles.currentUsernameContainer}>
                  <span className={styles.currentUsernameHeader}>
                    {strings.currentUsernameHeader}
                  </span>
                  <span className={styles.currentUsername}>
                    {getAccountDetailsResponse.username ?? "-"}
                  </span>
                </div>
                <form onSubmit={onSubmit}>
                  <StyledInput
                    className={styles.newUsernameInput}
                    label={strings.newUsername}
                    value={formState["newUsername"].value}
                    onChange={(e) => {
                      setFormState(() => ({
                        newUsername: {
                          value: e.target.value,
                          isValid: !getUsernameValidationMessages(
                            e.target.value,
                          ),
                          validationMessages: undefined,
                        },
                      }));
                      setNetworkValidationMessages(undefined);
                    }}
                    onBlur={() =>
                      setFormState((prev) => ({
                        newUsername: {
                          value: prev["newUsername"].value,
                          isValid: prev["newUsername"].isValid,
                          validationMessages: getUsernameValidationMessages(
                            prev["newUsername"].value,
                          ),
                        },
                      }))
                    }
                    validationMessages={finalValidation["newUsername"]}
                  />
                  <StyledButton
                    className={styles.submitButton}
                    variant="primary"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!isComplete}
                  >
                    {strings.submit}
                  </StyledButton>
                </form>
              </Subtool>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
